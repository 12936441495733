import { useState } from 'react';
import styled from 'styled-components/macro';
import { TacticUpdateInput } from 'data/graphql/generated';
import { colors } from 'constants/colors';
import { thousandSeparator } from 'utils/thousandSeparator';
import {
  BodyNormal,
  BodySmall,
  Collaboration,
  EditableTextarea,
  Icon,
} from 'components/shared';
import { Button } from 'components/Button';
import { DateType } from 'components/shared/DatePicker';
import { DatePickerDropdown } from 'components/MedicalStrategy/DatePickerDropdown';
import { PicoTactic } from 'components/4-1-medical-strategy/data-access-medical-tactics-picos';
import {
  mapTacticCategoryEnumToString,
  MedicalTacticCategoryDropdown,
  MedicalTacticCategoryDropdownOption,
} from 'components/4-1-medical-strategy/shared-medical-tactics';
import { PicoFieldName } from '../ui/fields';
import { RowStatus } from 'components/shared/shared-row-status';
import { tableColumnWidths } from '../ui/TableColumns';
import { fadeFromPurpleBGCSS } from 'utils/keyframes';

const StatusCollaboration = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const CollaborationSection = styled.div`
  display: flex;
  column-gap: 15px;
`;

const MobileOuterWrapper = styled.div`
  margin: 5px 0;
  position: relative;
  background: ${colors.white};
  border-radius: 5px;
  border: 1px solid transparent;

  ::before {
    position: absolute;
    inset: 0;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 4px;
    z-index: 0;
    background: ${colors.white};
  }
`;

const MobileWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  background: ${colors.white};

  border-radius: 5px;
  border: 1px solid transparent;
  padding: 15px;

  ::before {
    position: absolute;
    inset: 0;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 4px;
    z-index: 0;

    background: ${colors.white};
  }

  &.originalTactic::before {
    ${fadeFromPurpleBGCSS};
  }
`;

const TimingWrapper = styled.div`
  display: flex;
  flex: none;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

const ColumnsWrapper = styled.div`
  display: grid;
  ${tableColumnWidths}
  grid-auto-rows: minmax(65px, auto);
  position: relative;
  align-items: start;
  column-gap: 15px;
  height: 80px;

  textarea {
    min-width: auto;
  }
`;

const LinkedDetails = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 20px;
`;
const ViewDetailsButton = styled(Button)`
  color: ${colors.purple};
  padding: 0;
  cursor: pointer;
  background: none;
`;
const CurrencyIcon = styled.div`
  width: 20px;
  height: 20px;
  inset: 0;
  top: 15px;
  left: 10px;
`;

interface Props {
  pico: {
    id: number;
    name: string;
  };
  data?: PicoTactic;
  currency: string;
  isLead: boolean;
  isDesktop: boolean;
  region?: string;
  userMayEditRow?: boolean;
  onViewPicoDetails: () => void;
  onUpdate: (
    data: TacticUpdateInput & {
      competitiveAdvantageRow: number;
      localUid: string;
      strategyId: number;
    }
  ) => Promise<void>;
}

export const PicoTacticRow = ({
  pico,
  data,
  onUpdate,
  currency,
  isLead,
  isDesktop,
  region,
  onViewPicoDetails,
  userMayEditRow = false,
}: Props) => {
  const [
    selectedCategory,
    setSelectedCategory,
  ] = useState<MedicalTacticCategoryDropdownOption>(
    !!data?.category
      ? {
          id: data?.category,
          value: mapTacticCategoryEnumToString[data?.category],
        }
      : null
  );

  const validateFields = (values: PicoTactic) => {
    if (
      !values.tacticText ||
      !values.category ||
      !values.audienceText ||
      !values.timingStart.length ||
      !values.timingEnd.length ||
      !values.dueDate.length ||
      !values.responsibility ||
      !values.budget
    ) {
      return false;
    }

    return true;
  };

  const handleOnUpdate = async (value: TacticUpdateInput) => {
    if (!data) {
      console.warn(`[pico][update]: no pico tactic loaded`, { pico, data });
      return;
    }

    return onUpdate({
      localUid: data.localUid,
      strategyId: data.strategyId,
      competitiveAdvantageRow: data.competitiveAdvantageRowId,
      ...value,
    });
  };

  if (!data) return null;

  const canUpdate = (value: string | number) =>
    !!data.id || (!data.id && !!value);

  const fromDateUpdateHandler = (date: DateType | null) => {
    if (typeof date?.month !== 'number') {
      handleOnUpdate({ timingEnd: [], timingStart: [] });
      return;
    }
    if (
      data.timingEnd.length &&
      (date.year > data.timingEnd[1] ||
        (date.year === data.timingEnd[1] && date.month > data.timingEnd[0]))
    ) {
      handleOnUpdate({ timingEnd: [] });
    }
    handleOnUpdate({ timingStart: [date.month, date.year] });
  };

  if (!isDesktop)
    return (
      <>
        <MobileOuterWrapper>
          <MobileWrapper>
            <LinkedDetails>
              <Icon name="Link" size={15} />
              <BodySmall color={colors.greyDark}>Linked</BodySmall>
              <ViewDetailsButton
                text={'View details'}
                onClick={onViewPicoDetails}
              />
            </LinkedDetails>
            <div>
              <BodySmall color={colors.greyDark}>Tactic</BodySmall>
              <PicoFieldName name={pico.name} />
            </div>

            <div>
              <BodySmall color={colors.greyDark}>Category</BodySmall>
              <MedicalTacticCategoryDropdown
                selectedOption={selectedCategory}
                setSelectedOption={setSelectedCategory}
                onUpdate={handleOnUpdate}
                editable={userMayEditRow}
              />
            </div>

            <div>
              <BodySmall color={colors.greyDark}>Audience</BodySmall>
              <EditableTextarea
                style={{ background: 'transparent' }}
                editable={userMayEditRow}
                placeholder="Audience"
                initialValue={data.audienceText}
                onBlur={(value) => {
                  if (canUpdate(value)) handleOnUpdate({ audienceText: value });
                }}
              />
            </div>

            <div>
              <BodySmall color={colors.greyDark}>Timing</BodySmall>
              <div style={{ display: 'flex', gap: 10 }}>
                <DatePickerDropdown
                  style={{ flex: 1 }}
                  placeholder="From"
                  date={data.timingStart}
                  onUpdate={(date) => {
                    fromDateUpdateHandler(date);
                  }}
                  disabled={!userMayEditRow}
                />
                <DatePickerDropdown
                  disabled={!data.timingStart.length || !userMayEditRow}
                  alignRight
                  style={{ flex: 1 }}
                  placeholder="To"
                  lowerLimit={data.timingStart}
                  date={data.timingEnd}
                  onUpdate={(date) => {
                    handleOnUpdate({
                      timingEnd: !!date ? [date.month, date.year] : [],
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <BodySmall color={colors.greyDark}>Due</BodySmall>
              <DatePickerDropdown
                style={{ flex: 1 }}
                placeholder="Due"
                date={data.dueDate}
                lowerLimit={data.timingEnd}
                onUpdate={(date) => {
                  handleOnUpdate({
                    dueDate: !!date ? [date.month, date.year] : [],
                  });
                }}
                disabled={!userMayEditRow}
              />
            </div>
            <div>
              <BodySmall color={colors.greyDark}>Responsibility</BodySmall>
              <EditableTextarea
                style={{ background: 'transparent' }}
                editable={userMayEditRow}
                placeholder="Responsibility"
                initialValue={data.responsibility}
                onBlur={(value) => {
                  if (canUpdate(value)) {
                    handleOnUpdate({ responsibility: value });
                  }
                }}
              />
            </div>
            <div>
              <BodySmall color={colors.greyDark}>Budget</BodySmall>
              <div style={{ display: 'flex' }}>
                <CurrencyIcon>
                  <BodyNormal color={colors.greyDark}>{currency[0]}</BodyNormal>
                </CurrencyIcon>
                <EditableTextarea
                  style={{ background: 'transparent' }}
                  editable={userMayEditRow}
                  placeholder="Budget"
                  initialValue={
                    typeof data.budget === 'number' ||
                    typeof data.budget === 'string'
                      ? thousandSeparator(data.budget)
                      : ''
                  }
                  onBlur={(value) => {
                    if (canUpdate(value)) {
                      handleOnUpdate({
                        budget: value.replace(',', ''),
                      });
                    }
                  }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <RowStatus isComplete={validateFields(data)} />
              <Collaboration
                style={{
                  marginLeft: 'auto',
                  pointerEvents: data.id ? 'all' : 'none',
                }}
                collaboration={data.collaboration}
              />
            </div>
          </MobileWrapper>
        </MobileOuterWrapper>
      </>
    );

  return (
    <Wrapper id={`${pico.id}`}>
      <LinkedDetails>
        <Icon name="Link" size={15} />
        <BodySmall color={colors.greyDark}>Linked</BodySmall>
        <ViewDetailsButton text={'View details'} onClick={onViewPicoDetails} />
      </LinkedDetails>
      <ColumnsWrapper>
        <PicoFieldName name={pico.name} />
        <div>
          <MedicalTacticCategoryDropdown
            selectedOption={selectedCategory}
            setSelectedOption={setSelectedCategory}
            onUpdate={handleOnUpdate}
            editable={userMayEditRow}
          />
        </div>
        <div>
          <EditableTextarea
            style={{ background: 'transparent' }}
            editable={userMayEditRow}
            placeholder="Audience"
            initialValue={data.audienceText}
            onBlur={(value) => {
              if (canUpdate(value)) {
                handleOnUpdate({ audienceText: value });
              }
            }}
          />
        </div>
        <TimingWrapper>
          <div style={{ display: 'flex', gap: 10 }}>
            <DatePickerDropdown
              style={{ width: 90 }}
              placeholder="From"
              date={data.timingStart}
              onUpdate={(date) => {
                fromDateUpdateHandler(date);
              }}
              disabled={!userMayEditRow}
            />
            <DatePickerDropdown
              disabled={!data.timingStart.length || !userMayEditRow}
              style={{ width: 90 }}
              placeholder="To"
              lowerLimit={data.timingStart}
              date={data.timingEnd}
              onUpdate={(date) => {
                handleOnUpdate({
                  timingEnd: !!date ? [date.month, date.year] : [],
                });
              }}
            />
          </div>
        </TimingWrapper>
        <div>
          <DatePickerDropdown
            style={{ width: 90 }}
            placeholder="Due"
            lowerLimit={data.timingEnd}
            date={data.dueDate}
            onUpdate={(date) => {
              handleOnUpdate({
                dueDate: !!date ? [date.month, date.year] : [],
              });
            }}
            disabled={!userMayEditRow}
          />
        </div>
        <div>
          <EditableTextarea
            style={{ background: 'transparent' }}
            editable={userMayEditRow}
            placeholder="Responsibility"
            initialValue={data.responsibility}
            onBlur={(value) => {
              if (canUpdate(value)) {
                handleOnUpdate({ responsibility: value });
              }
            }}
          />
        </div>
        <CollaborationSection>
          <div style={{ display: 'flex' }}>
            <CurrencyIcon>
              <BodyNormal color={colors.greyDark}>{currency[0]}</BodyNormal>
            </CurrencyIcon>
            <EditableTextarea
              style={{ background: 'transparent' }}
              editable={userMayEditRow}
              placeholder="Budget"
              initialValue={
                typeof data.budget === 'number' ||
                typeof data.budget === 'string'
                  ? thousandSeparator(data.budget)
                  : ''
              }
              onBlur={(value) => {
                if (canUpdate(value)) {
                  handleOnUpdate({
                    budget: value.replace(',', ''),
                  });
                }
              }}
            />
          </div>
          <StatusCollaboration>
            <RowStatus isComplete={validateFields(data)} />
            <Collaboration
              style={{
                marginLeft: 'auto',
                pointerEvents: data.id ? 'all' : 'none',
              }}
              collaboration={data.collaboration}
            />
          </StatusCollaboration>
        </CollaborationSection>
      </ColumnsWrapper>
    </Wrapper>
  );
};
