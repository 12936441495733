import { useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { InsightGroup } from 'components/InsightGroup';
import { Navbar } from 'components/Navbar';
import { Observations } from 'components/Observations';
import { Page } from 'components/Page';
import {
  BodyNormal,
  RotateScreenTip,
  StepHeaderBar,
  StepTab,
  Subtitle2,
  Toggle,
} from 'components/shared';
import { ObservationDragLayer } from 'components/shared/Observation/ObservationDragLayer';
import { colors, polling } from 'constants/index';
import {
  BlockType,
  KeyInsightsQueryVariables,
  ObservationsQueryVariables,
  Sort,
  Stakeholder,
  Step,
  SubStep,
  useKeyInsightCreateMutation,
  useKeyInsightDeleteMutation,
  useKeyInsightsQuery,
  useKeyInsightUpdateMutation,
  useObservationsQuery,
  usePatientJourneyBlocksQuery,
  usePatientJourneyColumnsQuery,
  useStakeholderDefinitionsWithPatientFlowBlockStarQuery,
} from 'data/graphql/generated';
import styled, { css } from 'styled-components/macro';
import { PostItGroupAndCards, URLParams } from 'types';
import { device } from 'utils/breakpoints';
import buildStrategyURL from 'utils/buildStrategyURL';
import { ObservationsPane } from './ObservationsPane';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import useDesktop from 'hooks/useDesktop';
import AssignObservationsModal from 'components/AssignObservationsModal';
import { PostIts } from 'components/PostIts';
import { usePostItGroups } from 'hooks/usePostItGroups';
import { usePostItCards } from 'hooks/usePostItCards';
import { usePostItCardMove } from 'hooks/usePostItCardMove';
import { sortPostIts } from 'hooks';
import { useWidth } from 'hooks/useWidth';
import { GroupDelete } from 'components/PostItGroup';
import _ from 'lodash';
import { useToastContext } from 'contexts/ToastContext';
import { uid } from 'uid';
import { themes } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import { verifyUserRole } from 'utils/verifyUserRole';

interface Props { }

const PageWrapper = styled(Page) <{ showAddObservations: boolean }>`
  position: relative;

  @media (max-width: 1570px) {
    padding-top: 50px;
  }

  @media ${device.mobile} {
    > div {
      width: 100%;
      padding: 0;
    }
  }
`;

const PostItsWrapper = styled(Page) <{ showAddObservations: boolean, width: number }>`
  position: relative;
  padding-top: 15px;
  padding-left: 10px;

  @media ${device.mobile} {
    padding: 0;
    > div {
      width: 100%;
      padding: 0;
    }
  }

  @media ${device.tablet} {
    padding: 0;
    padding-top: 15px;
    > div {
      width: 100%;
      padding: 0;
    }
  }

  ${({ showAddObservations, width }) => {
    if (showAddObservations) {
      const calculatedWidth = width / 2 - 370;
      return css`
        transform: translateX(-40px);
        margin-lft: 15px;
        width: calc(50% + ${calculatedWidth}px);
        @media ${device.tabletMax} {
          transform: translateX(0);
          width: 100%;
        }
      `;
    }
  }}
 transition: all 0.3s;
`;

const StyledPostIts = styled(PostIts) <{ lastGroup: boolean }>`
  @media ${device.tabletMax} {
    width: 100%;
  }

  ${GroupDelete} {
    display: ${({ lastGroup }) => lastGroup && 'none'};
  }
`;

const StepHeaderBarWrapper = styled.div`
  margin-top: 155px;

  @media ${device.tabletMin} {
    margin-top: 160px;
  }

  @media ${device.desktopMin} {
    margin-top: 110px;
  }

  ${StepHeaderBar} {
    padding: 15px;

    ${Subtitle2} {
      margin-bottom: 5px;
    }
  }
`;

const ObservationsToggleWrapper = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
`;

const InsightGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 15px;
  }
`;

const Steps = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;

   @media ${device.tabletMax} {
    margin-top: 10px;
  }
`;

export enum TabPartType {
  Ideas = 'Ideas',
  Insights = 'Insights',
}

export const KeyInsights = (props: Props) => {
  const {
    drugId,
    strategyId,
    stakeholder: keyInsightPartParam,
  }: URLParams = useParams();
  const history = useHistory();
  const isDesktop = useDesktop();
  const width = useWidth();
  const [enableAddObservations, setEnableAddObservations] = useState<boolean>(
    true
  );
  const [isObservationPanelOpen, setIsObservationPanelOpen] = useState<boolean>(
    isDesktop ? true : false
  );

  const [showAddObservations, setShowAddObservations] = useState<
    boolean | number
  >(false); // ID of the Insight

  const [observationsPaneFilterData, setObservationsPaneFilterData] = useState<
    (number | null)[]
  >([]); // observationIds of the selected insight

  const [createToast, removeToast] = useToastContext();

  const {
    data: stakeholderDefinitionData,
    error,
    loading: stakeholderDefinitionDataLoading,
    startPolling,
    stopPolling,
  } = useStakeholderDefinitionsWithPatientFlowBlockStarQuery({
    variables: {
      where: { strategyId: Number(strategyId) },
    },
    fetchPolicy: 'network-only',
  });
  // stop polling
  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const observationsQueryVars: ObservationsQueryVariables = {
    where: { strategyId: Number(strategyId) },
    orderBy: { createdAt: Sort.Desc },
  };

  const {
    data: ObservationsData,
    loading,
    refetch: observationsRefetch,
    startPolling: observationsStartPolling,
    stopPolling: observationsStopPolling,
  } = useObservationsQuery({
    variables: {
      ...observationsQueryVars,
    },
  });

  useEffect(() => {
    observationsStartPolling(polling.default);
    return () => {
      observationsStopPolling();
    };
  }, [observationsStartPolling, observationsStopPolling]);

  const {
    data: patientJourneyBlocksData,
    loading: patientJourneyBlocksDataLoading,
  } = usePatientJourneyBlocksQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        strategyId: Number(strategyId),
        type: BlockType.Step,
      },
    },
  });

  const {
    data: patientJourneyColumnsData,
    loading: patientJourneyColumnsDataLoading,
  } = usePatientJourneyColumnsQuery({
    variables: {
      where: { strategyId: Number(strategyId) },
    },
  });

  const patientJourneyColumns =
    patientJourneyColumnsData?.patientJourneyColumns.items;

  const getLastColumnId = Number(_.max(patientJourneyColumns)?.id);

  const [createKeyInsight] = useKeyInsightCreateMutation();
  const [updateKeyInsight] = useKeyInsightUpdateMutation();
  const [deleteKeyInsight] = useKeyInsightDeleteMutation();

  const keyInsightsQueryVars: KeyInsightsQueryVariables = {
    where: { strategyId: Number(strategyId) },
    orderBy: { createdAt: Sort.Desc },
  };

  const {
    data: keyInsightsData,
    loading: keyInsightsLoading,
    refetch: keyInsightsRefetch,
    startPolling: keyInsightsStartPolling,
    stopPolling: keyInsightsStopPolling,
  } = useKeyInsightsQuery({
    variables: {
      ...keyInsightsQueryVars,
    },
  });

  useEffect(() => {
    keyInsightsStartPolling(polling.default);
    return () => {
      keyInsightsStopPolling();
    };
  }, [keyInsightsStartPolling, keyInsightsStopPolling]);

  useEffect(() => {
    if (keyInsightPartParam) {
      (async () => {
        await keyInsightsRefetch();
        observationsRefetch();
      })();
    }
  }, [
    keyInsightPartParam,
    keyInsightsRefetch,
    observationsRefetch,
  ]);

  const [removingFromInsight, setRemovingFromInsight] = useState(false);
  const [selectedTab, setSelectedTab] = useState<TabPartType>(TabPartType.Ideas);

  const insightIsOpen = typeof showAddObservations === 'number';

  useEffect(() => {
    if (!keyInsightPartParam || keyInsightPartParam === 'undefined') {
      history.replace(
        `/d/${drugId}/strategy/${strategyId}/1_5/${selectedTab}`
      );
    }
  }, [drugId, history, selectedTab, keyInsightPartParam, strategyId]);

  const dataMissing =
    !stakeholderDefinitionData ||
    !ObservationsData ||
    !patientJourneyBlocksData ||
    !patientJourneyColumnsData ||
    !keyInsightsData;

  const dataLoading =
    stakeholderDefinitionDataLoading ||
    loading ||
    patientJourneyBlocksDataLoading ||
    patientJourneyColumnsDataLoading ||
    keyInsightsLoading;

  const observations = ObservationsData?.observations?.items;
  const selectedInsight = keyInsightsData?.keyInsights?.items?.find(
    (i) => i.id === showAddObservations
  );

  const { path } = useRouteMatch();
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const {
    items: cardItems,
    createCard,
    updateCard,
    removeCard,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Keyinsights,
        substep: SubStep.KeyInsightIdeas
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Keyinsights,
      substep: SubStep.KeyInsightIdeas
    }
  );

  const {
    items: groupItems,
    createGroup,
    updateGroup,
    removeGroup,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        substep: SubStep.KeyInsightIdeas,
        step: Step.Keyinsights,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Keyinsights,
      substep: SubStep.KeyInsightIdeas,
    }
  );


  const setPageState = (state: any) => {
    const generatedPath = generatePath(path, {
      drugId,
      strategyId,
      state: state.toLowerCase(),
    });
    history.replace(generatedPath);
  }

  const copyKeyInsights = async (title: string) => {
    if (!isLead) return

    const previewUID = uid();

    await createKeyInsight({
      variables: {
        data: {
          strategy: Number(strategyId),
          drug: Number(drugId),
          text: title,
          stakeholder: Stakeholder.Patient,
          columnId: getLastColumnId,
          localUid: previewUID,
        },
      },
    });

    const { id } = createToast(
      'Insight created in Part 2',
      'Copy'
    );

    // duration for toast message
    setTimeout(() => {
      removeToast(id);
    }, 2000);
  }

  const addCardsToGroups = useMemo(
    () =>
      groupItems.reduce((acc, val) => {
        // find cards for each group
        return [
          ...acc,
          {
            ...val,
            cards:
              cardItems
                .filter((c) =>
                  c.postItGroupId === val.id
                )
                .sort(({ pos: a }, { pos: b }) => {
                  return b - a;
                }) || [],
          },
        ];
      }, [] as PostItGroupAndCards[]),
    [cardItems, groupItems]
  );

  const groups = sortPostIts(addCardsToGroups, cardItems);

  const onChange = usePostItCardMove(groups, updateCard);

  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <RotateScreenTip />
      <Navbar
        prev={{
          title: themes.discover.tools['1.4'].name,
          url: buildStrategyURL(drugId, strategyId, '1_4'),
        }}
        next={{
          title: themes.explore.tools['2.1'].name,
          url: buildStrategyURL(drugId, strategyId, '2_1'),
        }}
        stepNumber="1.5"
        title={themes.discover.tools['1.5'].name}
        url={`/d/${drugId}/strategy/${strategyId}?nav=1`}
        navMenuChildren={null}
      >

        <Observations
          isOpen={isObservationPanelOpen}
          setIsOpen={setIsObservationPanelOpen}
          readonly
          step={Step.Keyinsights}
        />

        <Steps>
          <StepTab
            active={keyInsightPartParam === TabPartType.Ideas}
            text="Part 1: Ideas"
            onClick={() => {
              setSelectedTab(TabPartType.Ideas);
              setPageState(TabPartType.Ideas);
            }}
          />
          <StepTab
            active={keyInsightPartParam === TabPartType.Insights}
            text="Part 2: Insights"
            onClick={() => {
              setSelectedTab(TabPartType.Insights);
              setPageState(TabPartType.Insights);
            }}
          />
        </Steps>
      </Navbar>

      <StepHeaderBarWrapper>
        <StepHeaderBar>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Subtitle2>
              {(keyInsightPartParam === TabPartType.Ideas && isLead) ?
                'Suggest insights based on the observations.' :
                'Leads should create patient insights supported by observations.'
              }
            </Subtitle2>
            <BodyNormal color={colors.greyDark}>
              {(keyInsightPartParam === TabPartType.Ideas && isLead) ?
                'Add notes and group into themes to organise ideas.' :
                'The ideas added in part 1 should inspire the key insights.'}

            </BodyNormal>

            <div
              style={{
                position: 'absolute',
                top: 15,
                right: 95,
                display: isDesktop ? 'flex' : 'none',
              }}
            >
            </div>
          </div>
        </StepHeaderBar>
      </StepHeaderBarWrapper>

      <ErrorWrapper
        isLoading={dataLoading}
        errors={[error]}
        dataMissing={dataMissing}
      >
        {keyInsightPartParam === TabPartType.Ideas ? (
          <PostItsWrapper showAddObservations={isObservationPanelOpen} paddingTop={false} width={width}>
            <StyledPostIts
              step={Step.Keyinsights}
              subStep={SubStep.KeyInsightIdeas}
              groups={groups}
              addCard={createCard}
              removeCard={removeCard}
              updateCard={updateCard}
              handleCardChange={onChange}
              createGroup={createGroup}
              updateGroup={updateGroup}
              removeGroup={removeGroup}
              lastGroup={groups.length === 1}
              copyAction={copyKeyInsights}
              copyEnabled
            />
          </PostItsWrapper>
        ) : (
          <PageWrapper showAddObservations={insightIsOpen} paddingTop={false}>
            <ObservationsToggleWrapper>
              <Toggle
                disabled={insightIsOpen}
                value={enableAddObservations}
                onChange={() => {
                  if (!insightIsOpen) {
                    setEnableAddObservations(!enableAddObservations);
                  }
                }}
                label="Show observations"
                toggleClassName="observations--toggle"
              />
            </ObservationsToggleWrapper>
            {(keyInsightsLoading || loading) && (
              <p style={{ margin: '0 auto', textAlign: 'center' }}>Loading...</p>
            )}
            <InsightGroupWrapper>
              <InsightGroup
                setRemovingFromInsight={setRemovingFromInsight}
                enableAddObservations={enableAddObservations}
                setShowAddObservations={setShowAddObservations}
                showAddObservations={showAddObservations}
                keyInsightsQueryVars={keyInsightsQueryVars}
                keyInsightsRefetch={() => keyInsightsRefetch()}
                key={getLastColumnId}
                stakeholder={Stakeholder.Patient}
                queryVars={observationsQueryVars}
                drugId={drugId}
                strategyId={strategyId}
                columnId={getLastColumnId}
                createKeyInsight={createKeyInsight}
                updateKeyInsight={updateKeyInsight}
                deleteKeyInsight={deleteKeyInsight}
                title={''}
                setObservationsPaneFilterData={setObservationsPaneFilterData}
                data={keyInsightsData}
                observationsData={ObservationsData?.observations?.items}
                stakeholderDefinitions={
                  stakeholderDefinitionData?.stakeholderDefinitions?.items.filter(
                    (b) => {
                      // leveragePoints are patientBlocks with a global star
                      return !!b.PatientFlowBlockStar.find(
                        (star) => star.global
                      );
                    }
                  ) || []
                }
              />
            </InsightGroupWrapper>
            <ObservationsPane
              removingFromInsight={removingFromInsight}
              filter={observationsPaneFilterData}
              show={insightIsOpen}
              ObservationsData={ObservationsData}
            />
            <AssignObservationsModal
              visible={insightIsOpen && !isDesktop}
              handleClose={() => setShowAddObservations(false)}
              observations={observations || []}
              insight={selectedInsight}
              updateKeyInsight={updateKeyInsight}
            />
          </PageWrapper>
        )}
        <ObservationDragLayer data={ObservationsData?.observations?.items} />
      </ErrorWrapper>
    </DndProvider>
  );
};
