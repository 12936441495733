import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { StepBarsWrapper, TabGroupWrapper } from 'containers/StrategicQuestion';
import { kebabCase } from 'lodash';
import { Navbar } from 'components/Navbar';
import { SubStep } from 'data/graphql/generated';
import { StepTab, TabGroup } from 'components/shared';
import { themes } from 'constants/index';

interface URLParams {
  drugId: string;
  strategyId: string;
}

interface Props {
  substep: SubStep;
}

export const LongTermStrategyNav: React.FC<Props> = ({ substep }) => {
  const { drugId, strategyId }: URLParams = useParams();
  const history = useHistory();

  return (
    <Navbar
      stepNumber="4.5"
      title={themes.activate.tools['4.5'].name}
      prev={{
        url: `/d/${drugId}/strategy/${strategyId}/4_4/ideas`,
        title: themes.activate.tools['4.4'].name,
      }}
    >
      <TabGroupWrapper>
        <TabGroup componentName="StepTab">
          <StepBarsWrapper>
            {[
              {
                title: 'Part 1: Solutions',
                substep: SubStep.Solutions,
              },
              {
                title: 'Part 2: The Plan',
                substep: SubStep.ThePlan,
              },
              {
                title: 'Summary',
                substep: SubStep.Summary,
              },
            ].map((tab) => {
              return (
                <StepTab
                  key={tab.substep}
                  active={tab.substep === substep}
                  text={tab.title}
                  onClick={() => {
                    history.push(
                      `/d/${drugId}/strategy/${strategyId}/4_5/${kebabCase(
                        tab.substep
                      )}`
                    );
                  }}
                />
              );
            })}
          </StepBarsWrapper>
        </TabGroup>
      </TabGroupWrapper>
    </Navbar>
  );
};
