import { useAuthContext } from 'contexts/AuthContext';
import { isTouchDevice } from 'utils/isTouchDevice';

const useCanShowModeInfoModal = () => {
  const isTouch = isTouchDevice();
  const [{ user }] = useAuthContext();
  console.log(user?.patientJourneyModeModalDismissed);
  return () => !isTouch && !user?.patientJourneyModeModalDismissed;
};

export default useCanShowModeInfoModal;
