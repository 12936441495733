import  { useEffect, useRef, useState } from 'react';
import {
  BlurredBGImage,
  BodySmall,
  ButtonRound,
  Caption,
  Icon,
  Subtitle2,
} from '.';
import styled from 'styled-components';
import { colors, polling, zIndex } from 'constants/index';
import { device } from 'utils/breakpoints';
import {
  PostItCardFragment,
  Step,
  SubStep,
  usePostItCardsQuery,
  useStatementsQuery,
  useStrategyQuery,
} from 'data/graphql/generated';
import { useParams } from 'react-router';
import { URLParams } from 'types';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { ScrollWrapper, ImageCarousel } from './ImageCarousel';
import { Transition } from 'react-transition-group';
import { Loading } from 'components/Loading';
import ReactDOM from 'react-dom';

const StyledImageCarousel = styled(ImageCarousel)`
  margin-top: 5px;
  ${ScrollWrapper} {
    border-radius: 6px;
  }
`;

const SectionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Divider = styled.div`
  background: ${colors.greyLight};
  margin: 15px 0px;
  height: 1px;
`;

const StyledSectionGradientWrapper = styled.section`
  padding: 1px;
  border-radius: 5px;
  background: ${colors.purplePinkGradient};
`;
const StyledSection = styled.section<{ noBorder?: boolean }>`
  background: ${colors.white};
  border: ${({ noBorder }) =>
    noBorder ? 'none' : `1px solid ${colors.black10a}`};
  border-radius: 5px;
  padding: 13px;
`;

const SummaryContent = styled.div`
  overflow: auto;
  padding: 15px;

  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SummaryHeader = styled.div`
  display: flex;
  padding: 15px 20px;
  background: ${colors.white};
  justify-content: space-between;
  align-items: center;
`;

const Summary = styled.div<{
  open: boolean;
  offsetTopOverride: number;
  offsetTop: number;
}>`
  background: ${colors.black05};

  display: flex;
  flex-direction: column;
  position: fixed;
  width: 430px;
  height: ${({ offsetTopOverride }) =>
    `calc(100vh - 50px - ${offsetTopOverride}px)`};
  right: 0;
  top: 50px;
  transform: ${({ open }) => `translateX(${open ? 0 : '100%'})`};

  z-index: ${zIndex.observationsMenu};

  transition: transform 0.3s;

  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);

  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);

  @media ${device.mobile} {
    max-width: 360px;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div<{ open: boolean }>`
  position: fixed;
  right: 20px;
  top: 125px;

  z-index: ${zIndex.observationsBtn};

  opacity: ${({ open }) => Number(!open)};
  pointer-events: ${({ open }) => (open ? 'none' : 'all')};
  transition: all 0.1s;

  @media ${device.tabletMax} {
    top: auto;
    bottom: 15px;
    right: 15px;
  }
`;

interface Props {
  offsetTopOverride?: number;
}

export const StrategicSummary = ({ offsetTopOverride = 0 }: Props) => {
  const { strategyId }: URLParams = useParams();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  //When the status of skip changes, the data is discarded so this prevents that from happening until the element is out of the screen.
  const [discardData, setDiscardData] = useState(true);

  const {
    data: strategyData,
    loading,
    startPolling,
    stopPolling,
  } = useStrategyQuery({
    skip: !open && !!discardData,
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });

  const {
    loading: statementsLoading,
    startPolling: statementsStartPolling,
    stopPolling: statementsStopPolling,
  } = useStatementsQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        strategyId: +strategyId,
      },
    },
  });

  const {
    data: postItCardsData,
    loading: postItCardLoading,
    startPolling: startPostItPolling,
    stopPolling: stopPostItPolling,
  } = usePostItCardsQuery({
    skip: !open && !!discardData,
    fetchPolicy: 'network-only',

    variables: {
      where: {
        strategyId: +strategyId,
        step: Step.Strategicquestion,
        substep: SubStep.WinningAspiration,
      },
    },
  });

  const cardsWithImages = postItCardsData?.postItCards?.items
    .filter((card) => !!card.image && !!card.collaboration?.alignmentCount)
    .sort(
      (a, b) =>
        Number(b.collaboration?.alignmentCount) -
        Number(a.collaboration?.alignmentCount)
    ) as (Omit<PostItCardFragment, 'image'> & { image: string })[];

  useClickOutsideComponent(containerRef, (e) => {
    if (open) setOpen(false);
  });

  useEffect(() => {
    startPolling(polling.default);
    startPostItPolling(polling.default);
    statementsStartPolling(polling.default);

    return () => {
      stopPolling();
      stopPostItPolling();
      statementsStopPolling();
    };
  }, [
    startPolling,
    startPostItPolling,
    statementsStartPolling,
    statementsStopPolling,
    stopPolling,
    stopPostItPolling,
  ]);

  return ReactDOM.createPortal(
    <>
      <ButtonWrapper open={open}>
        <ButtonRound
          iconName="Strategy"
          level="primary"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
        />
      </ButtonWrapper>
      <Transition
        timeout={300}
        in={open}
        onEntering={() => {
          setDiscardData(false);
        }}
        onExited={() => {
          setDiscardData(true);
        }}
      >
        <Summary
          ref={containerRef}
          offsetTop={0}
          open={open}
          offsetTopOverride={offsetTopOverride}
        >
          <SummaryHeader>
            <Icon
              size={30}
              name="GradientStrategy"
              style={{ marginRight: 5 }}
            />
            <Subtitle2 style={{ marginRight: 'auto' }}>
              Strategic summary
            </Subtitle2>

            <Icon
              name="Collapse right"
              size={30}
              color={colors.purple}
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
            />
          </SummaryHeader>
          <SummaryContent>
            {loading || postItCardLoading || statementsLoading ? (
              <Loading />
            ) : (
              <>
                <StyledSectionGradientWrapper>
                  <StyledSection noBorder style={{ borderRadius: 4 }}>
                    <SectionContentWrapper>
                      <Caption>Winning aspiration</Caption>
                      <BodySmall
                        color={colors.greyDark}
                        style={{ wordBreak: 'break-word' }}
                      >
                        {strategyData?.strategy?.winningAspiration ||
                          'Not identified yet'}
                      </BodySmall>
                      {(!cardsWithImages || !!cardsWithImages.length) && (
                        <StyledImageCarousel calculateScroll={open}>
                          {cardsWithImages?.map((card) => (
                            <div
                              key={card.id}
                              style={{
                                width: 178,
                                height: 178,
                                overflow: 'hidden',
                                borderRadius: 5,
                              }}
                            >
                              <BlurredBGImage
                                imageURL={card.image}
                                blurAmount={2}
                              />
                            </div>
                          ))}
                        </StyledImageCarousel>
                      )}
                    </SectionContentWrapper>
                  </StyledSection>
                </StyledSectionGradientWrapper>
                <StyledSection>
                  <SectionContentWrapper>
                    <Caption>Strategic problem</Caption>
                    <BodySmall
                      color={colors.greyDark}
                      style={{ wordBreak: 'break-word' }}
                    >
                      {strategyData?.strategy?.strategicProblem ||
                        'Not identified yet'}
                    </BodySmall>
                  </SectionContentWrapper>
                  <Divider />
                  <div
                    style={{ display: 'flex', flexDirection: 'column', gap: 5 }}
                  >
                    <Caption>Strategic question</Caption>
                    <BodySmall
                      color={colors.greyDark}
                      style={{ wordBreak: 'break-word' }}
                    >
                      {strategyData?.strategy?.strategicQuestion ||
                        'Not identified yet'}
                    </BodySmall>
                  </div>
                </StyledSection>
              </>
            )}
          </SummaryContent>
        </Summary>
      </Transition>
    </>,
    document.body
  );
};
