import { BodyNormal, BodySmall, Subtitle2 } from 'components/shared';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TheWho } from '../../components/Positioning/TheWho';
import { colors, polling } from '../../constants';
import { PageWrapper, StepHeader } from '.';
import {
  StatementsDocument,
  Step,
  SubStep,
  usePostItGroupsWithCardsQuery,
  useStatementUpdateMutation,
  useStatementsQuery,
  useStrategyQuery,
} from '../../data/graphql/generated';
import useDesktop from '../../hooks/useDesktop';
import { usePostItCardMove } from '../../hooks/usePostItCardMove';
import { usePostItCards } from '../../hooks/usePostItCards';
import { usePostItGroups } from '../../hooks/usePostItGroups';
import { PositioningTabKebabCase, PostItGroupAndCards } from '../../types';
import { ErrorWrapper } from '../../components/ErrorLoadingComponent';
import { WhatToConsiderModal } from '../../components/Positioning/WhatToConsiderModal';
import client from '../../data/apollo';
import _ from 'lodash';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { useAuthContext } from 'contexts/AuthContext';
import { verifyUserRole } from 'utils/verifyUserRole';

type Props = {};

interface URLParams {
  drugId: string;
  strategyId: string;
  positioningTab: PositioningTabKebabCase;
  competitorId: string;
}

export const TheWhoPage = (props: Props) => {
  const [whatToConsiderModal, setWhatToConsiderModal] = useState(false);

  const { drugId, strategyId }: URLParams = useParams();

  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const {
    data: strategyData,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
  } = useStrategyQuery({
    variables: { id: +strategyId },
  });

  const {
    data: statementsData,
    loading: statementsLoading,
    startPolling: statementsStartPolling,
    stopPolling: statementsStopPolling,
    error: statementsError,
  } = useStatementsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        step: Step.Positioning,
        substep: [SubStep.TheWho],
      },
    },
    nextFetchPolicy: 'cache-and-network',
  });

  const statementRefetch = {
    refetchQueries: [
      {
        query: StatementsDocument,
        variables: {
          where: {
            strategyId: +strategyId,
            step: Step.Positioning,
            substep: [SubStep.TheWho],
          },
        },
      },
    ],
  };

  const [updateStatement] = useStatementUpdateMutation({
    ...statementRefetch,
  });

  const {
    items: groupItems,
    loading: groupsLoading,
    createGroup,
    updateGroup,
    removeGroup,
    error: groupsError,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWho,
        preset: false,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.TheWho,
    }
  );

  const {
    items: cardItems,
    loading: cardsLoading,
    createCard,
    updateCard,
    removeCard,
    error: cardsError,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWho,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.TheWho,
    }
  );

  const isDesktop = useDesktop();

  // get "What are the brand position that matter to key insights" cards to sort the groups
  const {
    data: groupsWithCardsData,
    refetch: refetchGroupsWithCards,
    startPolling: groupsStartPolling,
    stopPolling: groupsStopPolling,
  } = usePostItGroupsWithCardsQuery({
    variables: {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWho,
        title: `What are the brand position that matter to key insights?`,
        preset: true,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const whatAreTheBrandPositionGroup = groupsWithCardsData?.postItGroups?.items[0];

  useEffect(() => {
    statementsStartPolling(polling.default);
    startStrategyPolling(polling.default);
    groupsStartPolling(polling.default);
    return () => {
      statementsStopPolling();
      stopStrategyPolling();
      groupsStopPolling();
    };
  }, [
    groupsStartPolling,
    groupsStopPolling,
    refetchGroupsWithCards,
    startStrategyPolling,
    statementsStartPolling,
    statementsStopPolling,
    stopStrategyPolling
  ]);

  const addCardsToGroups = useMemo(
    () =>
      groupItems.reduce((acc, val) => {
        // find cards for each group
        return [
          ...acc,
          {
            ...val,
            cards:
              cardItems
                .filter((c) =>
                  c.postItGroupId === val.id &&
                  (!isLead ? c.user.country === user?.country : true)
                )
                .sort(({ pos: a }, { pos: b }) => {
                  return b - a;
                }) || [],
          },
        ];
      }, [] as PostItGroupAndCards[]),
    [cardItems, groupItems, isLead, user?.country]
  );

  const cardsByAlignmentOrder = useMemo(
    () => whatAreTheBrandPositionGroup?.cards || [],
    [whatAreTheBrandPositionGroup?.cards]
  );

  const unsortedGroups = useMemo(
    () =>
      cardsByAlignmentOrder
        .filter((e) => e.include === true)
        .map((card) => {
          const group = addCardsToGroups.find((group) => group.title === card.title);
          if (group) {
            return {
              ...group,
              pos: card.pos,
            };
          }
          return null;
        })
        .filter((v) => !!v) as PostItGroupAndCards[],
    [addCardsToGroups, cardsByAlignmentOrder]
  );

  const groups = useMemo(() => _.orderBy(unsortedGroups, ['pos'], ['desc']), [unsortedGroups]);

  const onChange = usePostItCardMove(groups, updateCard);

  const noCardsData = !groups;

  return (
    <>
      <WhatToConsiderModal
        visible={whatToConsiderModal}
        handleClose={() => setWhatToConsiderModal(false)}
        strategyId={+strategyId}
        drugId={+drugId}
      />

      <StepHeader isDesktop={isDesktop}>
        <Subtitle2 style={{ marginBottom: '5px' }}>
          Who is our brand for and what is their motivation?
        </Subtitle2>
        <div
          style={{
            whiteSpace: 'pre-wrap',
            marginBottom: '15px',
          }}
        >
          <BodyNormal color={colors.greyDark} style={{ display: 'inline' }}>
            Explore different possibilities around the key insights below.
          </BodyNormal>
          {/* hiding this for now, might need on the next tickets.
          <ButtonLabel
            color={colors.purple}
            style={{ display: 'inline', whiteSpace: 'nowrap' }}
            onClick={() => setWhatToConsiderModal(true)}
          >
            View what to consider
          </ButtonLabel> */}
        </div>
      </StepHeader>
      <ErrorWrapper
        isLoading={groupsLoading || cardsLoading || statementsLoading}
        errors={[groupsError, cardsError, statementsError]}
        dataMissing={noCardsData || !statementsData}
      >
        {noCardsData || statementsData?.statements?.items.length === 0 || groups.length === 0 ? (
          <PostItsEmpty style={{ margin: 20 }} title="Nothing to discuss">
            <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
              Work can only begin when a Lead has chosen key insights
            </BodySmall>
          </PostItsEmpty>
        ) : (
          <PageWrapper paddingTop={false} fullWidthMobile>
            <TheWho
              strategyData={strategyData?.strategy}
              statements={statementsData?.statements?.items}
              updateStatement={updateStatement}
              postIts={{
                step: Step.Positioning,
                subStep: SubStep.TheWho,
                groups: groups,
                addCard: createCard,
                removeCard: removeCard,
                updateCard: updateCard,
                handleCardChange: onChange,
                createGroup: async (group) => {
                  const newGroup = await createGroup(
                    group,
                    statementRefetch.refetchQueries
                  );

                  return newGroup;
                },
                updateGroup: updateGroup,
                createGroupHide: true,
                deleteGroupHide: true,
                updateGroupDisabled: true,
                removeGroup: async (group) => {
                  const groupStatement = statementsData?.statements?.items?.find(
                    (statement) => {
                      return statement.postItGroupId === group;
                    }
                  );
                  if (!groupStatement) return;

                  const newGroup = await removeGroup(group);

                  client.cache.evict({
                    id: `Statement:${groupStatement.id}`,
                  });

                  client.cache.gc();

                  return newGroup;
                },
              }}
            />
          </PageWrapper>
        )}
      </ErrorWrapper>
    </>
  );
};
