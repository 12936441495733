import Textarea from 'react-expanding-textarea';
import { colors } from 'constants/index';
import styled from 'styled-components';

const DISABLED_COLOR = '#E8E8EA';

export const TextAreaInput = styled(Textarea)<{
  $disabled?: boolean;
  $small?: boolean;
  $invalid?: boolean;
  $borderless?: boolean;
  $table?: boolean;
}>`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 5px;

  padding: 0;
  overflow-y: hidden;
  resize: none;

  font-family: ABCFavorit;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ $small }) => ($small ? 14 : 18)}px;
  line-height: ${({ $small }) => ($small ? 14 : 20)}px;
  letter-spacing: 0em;
  text-align: left;

  border: 0.5px solid
    ${({ $disabled, $invalid, $borderless }) => {
      if ($disabled) return '#D7D7DB';
      if ($invalid) return colors.red;
      if ($borderless) return 'transparent';
      return colors.greyDark;
    }};
  border-radius: ${({ $table }) => ($table ? 2 : 0)}px;
  color: ${({ $disabled }) => ($disabled ? colors.black50 : colors.black)};

  background: ${({ $disabled, $borderless }) => {
    if ($disabled && $borderless) return colors.white;
    return 'transparent';
  }};
  cursor: ${({ $disabled }) => {
    if ($disabled) return 'not-allowed';
  }};

  &:hover {
    border-color: ${({ $disabled, $invalid, $borderless, $table }) => {
      if ($disabled) return DISABLED_COLOR;
      if ($invalid) return colors.red;
      if ($table) return colors.greyDark;
      if ($borderless) return 'transparent';
      return colors.black;
    }};
  }

  &:focus {
    outline: none;
    border-color: ${({ $disabled, $invalid, $borderless }) => {
      if ($disabled) return DISABLED_COLOR;
      if ($invalid) return colors.red;
      if ($borderless) return colors.blue;
      return colors.blue;
    }};
  }
  &:disabled {
    // Safari
    opacity: 1;
    -webkit-opacity: 1;
    -webkit-text-fill-color: ${colors.greyDark};
  }

  transition: background 0.3s, color 0.3s, border 0.3s;
`;
