import {
  PostItCardFragment,
  PostItGroupFragment,
  Stakeholder,
  Step,
} from '../../data/graphql/generated';
import { PostItGroupAndCards } from '../../types';

/**
 * Sorts post it groups by createdAt date
 * @param postItGroups
 * @param postItCards
 * @param sort
 * sort is a boolean that defaults to true
 * if false, it will not sort the post it groups but will create an object with the cards for each group
 **/
export function sortPostIts(
  postItGroups: PostItGroupFragment[],
  postItCards: PostItCardFragment[],
  sort?: boolean
) {
  const mappedGroups = postItGroups.reduce((acc, val) => {
    // find cards for each group
    return [
      ...acc,
      {
        ...val,
        cards:
          postItCards
            .filter((c) => c?.postItGroupId === val.id)
            .sort((cardA, cardB) => {
              return (cardB?.pos || 0) - (cardA?.pos || 0);
            }) || [],
      },
    ];
  }, [] as PostItGroupAndCards[]);

  if (sort === false) return mappedGroups;

  mappedGroups.sort((a, b) => {
    return a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0;
  });

  return mappedGroups;
}

export function sortPostItsOutcomes(
  postItGroups: PostItGroupFragment[],
  postItCards: PostItCardFragment[],
  sort?: boolean
) {
  const mappedGroups = postItGroups.filter(p => p.title.includes('What are the outcomes that matter to') && p.stakeholder !== Stakeholder.Provider && p.stakeholder !== Stakeholder.Policymaker).reduce((acc, val) => {
    // find cards for each group
    return [
      ...acc,
      {
        ...val,
        cards:
          postItCards
            .filter((c) => c?.postItGroupId === val.id)
            .sort((cardA, cardB) => {
              return (cardB?.pos || 0) - (cardA?.pos || 0);
            })
            .map(e => {
              return { ...e, stakeholder: val.stakeholder }
            }) as PostItCardFragment[] || [],
      },
    ];
  }, [] as PostItGroupAndCards[]);

  if (sort === false) return mappedGroups;

  mappedGroups.sort((a, b) => {
    return a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0;
  });

  return mappedGroups;
}

export function sortPostItsKeyInsights(
  postItGroups: PostItGroupFragment[],
  postItCards: PostItCardFragment[],
  sort?: boolean
) {
  const mappedGroups = postItGroups.filter(p =>
    p.title.includes('What are the brand position that matter to key insights?') &&
    p.step === Step.Positioning && p.preset).reduce((acc, val) => {
      // find cards for each group
      return [
        ...acc,
        {
          ...val,
          cards:
            postItCards
              .filter((c) => c?.postItGroupId === val.id)
              .sort((cardA, cardB) => {
                return (cardB?.pos || 0) - (cardA?.pos || 0);
              })
              .map(e => {
                return { ...e, stakeholder: val.stakeholder }
              }) as PostItCardFragment[] || [],
        },
      ];
    }, [] as PostItGroupAndCards[]);

  if (sort === false) return mappedGroups;

  mappedGroups.sort((a, b) => {
    return a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0;
  });

  return mappedGroups;
}
