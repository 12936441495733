import { Navbar as NavbarComponent } from 'components/Navbar';
import { StepTab, TabGroup } from 'components/shared';
import { useEffect } from 'react';
import { kebabCase } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  EvaluationButton,
} from '../../components/3-5-critical-metrics/shared/shared-ui-critical-metrics/src';
import { PositioningTabKebabCase } from '../../types';
import { StepBarsWrapper, TabGroupWrapper } from '../StrategicQuestion';
import buildStrategyURL from '../../utils/buildStrategyURL';
import { SubStep } from '../../data/graphql/generated';
import { useGlobalContext } from 'contexts/GlobalContext';
import { KeyInsightsMenu } from 'components/KeyInsightsMenu';
import styled from 'styled-components';
import { useAuthContext } from 'contexts/AuthContext';
import { verifyUserRole } from 'utils/verifyUserRole';
import { themes } from 'constants/index';

type Props = {
  drugId: string;
  strategyId: string;
  competitorId: string;

  currentPositioningTab: PositioningTabKebabCase | 'summary';
};

const NavbarContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: end;
  gap: 5px;
`;

export const ButtonContainerDesktop = styled.div`
  display: flex;
  width: 100%;
`;

const positioningTabs = [
  { title: 'Competitor Positioning', subStep: SubStep.CompetitorPositioning },
  {
    title: 'The "Who"',
    subStep: SubStep.TheWho,
  },
  {
    title: 'The "Why"',
    subStep: SubStep.TheWhy,
  },
];

export const Navbar = ({
  drugId,
  strategyId,
  competitorId,
  currentPositioningTab,
}: Props) => {
  const history = useHistory();
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role);
  const partTwoPage = history.location.pathname.includes(kebabCase(SubStep.TheWho))

  const [brandPositioning, setBrandPositioning] = useGlobalContext(
    'brandPositioning'
  );

  useEffect(() => {
    // NOTE: Additional condition to prevent summary
    // from being pushed back to the history stack
    if (!competitorId 
      && brandPositioning 
      && !brandPositioning.includes('summary')
      && brandPositioning !== history.location.pathname
    ) {
      history.replace(
        brandPositioning
      );
    }
  }, [drugId, history, competitorId, brandPositioning]);
  return (
    <NavbarComponent
      stepNumber="3.1"
      title={themes.develop.tools['3.1'].name}
      prev={{
        title: themes.explore.tools['2.5'].name,
        url: buildStrategyURL(drugId, strategyId, '2_5'),
      }}
      next={{
        title: themes.develop.tools['3.2'].name,
        url: buildStrategyURL(drugId, strategyId, '3_2'),
      }}
      pageNavChildren={<></>}
    >
      <TabGroupWrapper>
        <TabGroup componentName="StepTab">
          <StepBarsWrapper style={{ paddingBottom: !partTwoPage ? 10 : 0 }}>
            {positioningTabs?.map((p, idx) => {
              return (
                <StepTab
                  className={`cypress-stepTab-${kebabCase(p.subStep)}`}
                  key={p.subStep}
                  active={kebabCase(p.subStep) === currentPositioningTab}
                  text={`Part ${idx + 1}: ${p.title}`}
                  onClick={() => {
                    const url = `/d/${drugId}/strategy/${strategyId}/3_1/${kebabCase(
                      p.subStep
                    )}/${competitorId || ''}`;
                    history.push(url);
                    setBrandPositioning(url)
                  }}
                />
              );
            })}

            <ButtonContainerDesktop>
              <EvaluationButton
                text={'Summary'}
                isActive={currentPositioningTab === 'summary'}
                onClick={() => {
                  const url = `/d/${drugId}/strategy/${strategyId}/3_1/summary`;
                  history.push(url);
                  setBrandPositioning(url);
                }}
              />

            </ButtonContainerDesktop>

            {partTwoPage && (
              <NavbarContent>
                <KeyInsightsMenu
                  isLead={isLead}
                />
              </NavbarContent>
            )}
          </StepBarsWrapper>

        </TabGroup>
      </TabGroupWrapper>
    </NavbarComponent>
  );
};
