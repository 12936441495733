import {
  ButtonNote,
  ButtonPill,
  ButtonPillExpand,
  ButtonRound,
  ButtonRoundExpand,
} from 'components/shared';
import {
  PostItCardInput,
  PostItCardType,
  Step as PageStep,
  SubStep,
  Step,
  Stakeholder,
  CompetitiveCategory
} from 'data/graphql/generated';
import React, { useState } from 'react';
import { PostItGroupAndCards } from 'types';
import { useParams } from 'react-router-dom';

interface Props {
  addCard: (
    card: Pick<PostItCardInput, 'pos' | 'postItGroup' | 'title' | 'type'>
  ) => void;
  cardArgs: Pick<PostItCardInput, 'pos' | 'postItGroup' | 'title' | 'stakeholder' | 'stakeholderDefinition'>;
  group?: PostItGroupAndCards;
  loading?: boolean;
}


interface URLParams {
  drugId: string;
  strategyId: string;
  stakeholder: Stakeholder;
  category: CompetitiveCategory;
  stakeholderDefinitionId: string;
  competitorId: string;
}
interface MobileProps {
  mobile?: boolean;
  step: PageStep;
  subStep?: SubStep;
}

interface OpenProps {
  setButtonOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoteChildren = ({
  setButtonOpen,
  addCard,
  cardArgs,
  step,
}: OpenProps & Props & { step: PageStep }) => {
  return (
    <>
      <ButtonNote
        onClick={() => {
          setButtonOpen(false);
          addCard({ ...cardArgs, type: PostItCardType.Trigger });
        }}
        className="cypress-postit-create-trigger"
        level={PostItCardType.Trigger}
      />
      <ButtonNote
        onClick={() => {
          setButtonOpen(false);
          addCard({ ...cardArgs, type: PostItCardType.Unexpected });
        }}
        className="cypress-add-note-button"
        level={PostItCardType.Unexpected}
      />
      <ButtonNote
        onClick={() => {
          setButtonOpen(false);
          addCard({ ...cardArgs, type: PostItCardType.Adaptation });
        }}
        className="cypress-add-note-button"
        level={PostItCardType.Adaptation}
      />
      <ButtonNote
        onClick={() => {
          setButtonOpen(false);
          addCard({ ...cardArgs, type: PostItCardType.Pattern });
        }}
        level={PostItCardType.Pattern}
        className="cypress-add-note-button"
      />
      <ButtonNote
        text="Other"
        level={PostItCardType.Other}
        className="cypress-add-note-button"
        onClick={() => {
          setButtonOpen(false);
          addCard({ ...cardArgs, type: PostItCardType.Other });
        }}
      />
    </>
  );
};

export const AddNoteButton = ({
  step,
  subStep,
  addCard,
  cardArgs,
  mobile,
  group,
  loading
}: Props & MobileProps) => {

  const {
    category,
  }: URLParams = useParams();

  const [buttonOpen, setButtonOpen] = useState(false);

  const singularize = (word: string) => {
    const mappings: Record<string, string> = {
      Outcomes: 'Outcome',
      Technologies: 'Technology',
      Actions: 'Action',
    };
    return mappings[word] || word;
  };

  if ([Step.Keystakeholders].includes(step)) {
    if (mobile) {
      return (
        <ButtonRound
          level="add"
          iconName="Plus"
          btnClassName="cypress-add-note-button"
          size={40}
          loading={loading}
          onClick={() => {
            setButtonOpen(false);
            addCard({ ...cardArgs, type: PostItCardType.Plain });
          }}
        />
      );
    }

    return (
      <ButtonPill
        text={`Add ${singularize(category).toLowerCase()}`}
        iconName="Plus"
        clickClassName="cypress-add-note-button"
        loading={loading}
        color="#FFFFFF"
        onClick={() => {
          setButtonOpen(false);
          addCard({ ...cardArgs, type: PostItCardType.Plain });
        }}
      />
    );
  }

  return mobile ? (
    <ButtonRoundExpand setOpen={setButtonOpen} open={buttonOpen}>
      <NoteChildren
        setButtonOpen={setButtonOpen}
        addCard={addCard}
        cardArgs={cardArgs}
        group={group}
        step={step}
      />
    </ButtonRoundExpand>
  ) : (
    <ButtonPillExpand
      text="Add note"
      setOpen={setButtonOpen}
      open={buttonOpen}
      className="cypress-open-note-type-menu"
    >
      <NoteChildren
        setButtonOpen={setButtonOpen}
        addCard={addCard}
        cardArgs={cardArgs}
        group={group}
        step={step}
      />
    </ButtonPillExpand>
  );
};
