import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { Loading } from 'components/Loading';
import { PostIts } from 'components/PostIts';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { StrategyDefinitionHeader } from 'components/StrategyDefinitionHeader';
import {
  BodyNormal,
  BodySmall,
  ContributionTab,
  Subtitle2,
  TabGroup,
} from 'components/shared';
import { colors, polling, themes } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import {
  Sort,
  Step,
  StrategicQuestionType,
  StrategicQuestionsDocument,
  SubStep,
  useCompetitiveAdvantageRowsQuery,
  useKeyStatementsQuery,
  useStrategicQuestionUpdateMutation,
  useStrategicQuestionsQuery,
  useStrategyQuery,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import useGetElementTop from 'hooks/useGetElementTop';
import { usePostItCardMove } from 'hooks/usePostItCardMove';
import { usePostItCards } from 'hooks/usePostItCards';
import { usePostItGroups } from 'hooks/usePostItGroups';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import { ImperativeSummarySidebar } from 'components/shared/ImperativeSummarySidebar';
import {
  MainContent,
  PostItsPageWrapper,
  SidebarDesktopWrapper,
  SidebarMobileWrapper,
} from 'components/shared/SidebarPageComponents';
import URLHelper from 'utils/URLHelper';
import useSidePaneTopOffset from 'utils/useSidePaneTopOffset';
import { verifyUserRole } from 'utils/verifyUserRole';
import { sortPostIts } from '../../../../../../hooks';
import {
  CriticalMetricsNavbar,
  CriticalMetricsTabGroup,
  IndicatorsPageStepHeader,
} from '../../../../shared/shared-ui-critical-metrics/src';
import { useGlobalContext } from 'contexts/GlobalContext';

const overlapWidth = 675;

interface URLParams {
  drugId: string;
  strategyId: string;
  competitiveAdvantageRowUrlParam: string;
}

export const IndicatorsPage = () => {
  const {
    drugId,
    strategyId,
    competitiveAdvantageRowUrlParam,
  }: URLParams = useParams();

  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);
  const isDesktop = useDesktop();
  const history = useHistory();
  const totalNavHeight = isDesktop ? 50 : 0;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const sidebarDesktopRef = useRef<HTMLDivElement | null>(null);
  const desktopWrapperTop = useGetElementTop(sidebarDesktopRef);
  const topOffset = useSidePaneTopOffset(sidebarDesktopRef, totalNavHeight);

  const {
    data: strategyData,
    loading: strategyLoading,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });

   // handle global context path
   const [ strategicImperativeId, setStrategicImperativeId] = useGlobalContext(
    'strategicImperativeId'
  );

  const {
    data: competitiveAdvantageRowsData,
    error: competitiveAdvantageRowsError,
    loading: competitiveAdvantageRowsLoading,
    startPolling: startCompetitiveAdvantageRowsPolling,
    stopPolling: stopCompetitiveAdvantageRowsPolling,
  } = useCompetitiveAdvantageRowsQuery({
    variables: {
      orderBy: {
        idx: Sort.Desc,
      },
      where: {
        strategyId: +strategyId,
        focus: true,
      },
      include: {
        strategicPossibility: true,
        successConditions: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const competitiveAdvantageRows =
    competitiveAdvantageRowsData?.competitiveAdvantageRows?.items;

  const selectedCompetitiveAdvantageRow = competitiveAdvantageRows?.find(
    (row) => row.id === +competitiveAdvantageRowUrlParam
  );
  const selectedRowImperative =
    selectedCompetitiveAdvantageRow?.strategicPossibility;

  const {
    data: strategicQuestionsData,
    error: strategicQuestionsError,
    loading: strategicQuestionsLoading,
    startPolling: startStrategicQuestionsPolling,
    stopPolling: stopStrategicQuestionsPolling,
  } = useStrategicQuestionsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam,
        field: 'leadIndicators\nlagIndicators',
      },
    },
    fetchPolicy: 'network-only',
  });

  const leadLagStrategicQuestion =
    strategicQuestionsData?.strategicQuestions?.items?.[0];

  const [updateLeadAndLagIndicators] = useStrategicQuestionUpdateMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: StrategicQuestionsDocument,
        variables: {
          where: {
            strategyId: +strategyId,
            field: 'leadIndicators\nlagIndicators',
            competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam,
          },
        },
      },
    ],
  });

  const {
    items: groupItems,
    loading: groupsLoading,
    createGroup,
    updateGroup,
    removeGroup,
    error: groupsError,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.CriticalMetrics,
        competitiveAdvantageRowId:
          +competitiveAdvantageRowUrlParam || undefined,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.CriticalMetrics,
      competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam || undefined,
    }
  );

  const {
    items: cardItems,
    loading: cardsLoading,
    createCard,
    updateCard,
    removeCard,
    error: cardsError,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.CriticalMetrics,

        competitiveAdvantageRowId:
          +competitiveAdvantageRowUrlParam || undefined,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.CriticalMetrics,

      competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam || undefined,
    }
  );

  const groups = sortPostIts(groupItems, cardItems);

  const onChange = usePostItCardMove(groups, updateCard);

  const {
    data: keyStatementsData,
    loading: keyStatementsLoading,
    startPolling: keyStatementsObjectivesPollingStart,
    stopPolling: keyStatementsObjectivesPollingStop,
  } = useKeyStatementsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        step: Step.DistinctiveCapabilities,
        competitiveAdvantageRowId:
          +competitiveAdvantageRowUrlParam || undefined,
        region: 'global',
      },
    },
  });

  const keyStatementsForSideBar = useMemo(
    () =>
      keyStatementsData?.keyStatements?.items.reduce(
        (acc, curr) => {
          if (curr.subStep === SubStep.StrategicObjectives) {
            acc['strategicObjectives'].push(curr.text);
          }
          if (curr.subStep === SubStep.Capabilities) {
            acc['distinctiveCapabilities'].push(curr.text);
          }

          return acc;
        },
        { strategicObjectives: [], distinctiveCapabilities: [] } as Record<
          'strategicObjectives' | 'distinctiveCapabilities',
          string[]
        >
      ),
    [keyStatementsData?.keyStatements?.items]
  );

  useEffect(() => {
    //set url on mount

    if (strategicImperativeId) {
      const url = URLHelper.getCriticalMetricsLeadAndLagIndicatorsUrl(
        drugId,
        strategyId,
        strategicImperativeId
      );
      history.replace(url);
    }
    if (competitiveAdvantageRows?.[0]?.id && !selectedCompetitiveAdvantageRow) {
      const url = URLHelper.getCriticalMetricsLeadAndLagIndicatorsUrl(
        drugId,
        strategyId,
        competitiveAdvantageRows?.[0]?.id
      );
      history.replace(url);
    }
  }, [
    strategicImperativeId,
    selectedCompetitiveAdvantageRow,
    competitiveAdvantageRows,
    drugId,
    history,
    strategyId,
  ]);

  useEffect(() => {
    startCompetitiveAdvantageRowsPolling(polling.default);
    startStrategyPolling(polling.default);
    startStrategicQuestionsPolling(polling.default);
    keyStatementsObjectivesPollingStart(polling.default);

    return () => {
      stopCompetitiveAdvantageRowsPolling();
      stopStrategyPolling();
      stopStrategicQuestionsPolling();
      keyStatementsObjectivesPollingStop();
    };
  }, [
    startCompetitiveAdvantageRowsPolling,
    startStrategyPolling,
    startStrategicQuestionsPolling,
    stopCompetitiveAdvantageRowsPolling,
    stopStrategyPolling,
    stopStrategicQuestionsPolling,
    keyStatementsObjectivesPollingStart,
    keyStatementsObjectivesPollingStop,
  ]);

  return (
    <>
      <CriticalMetricsNavbar substep={SubStep.Indicators} />

      <SidebarMobileWrapper totalNavHeight={totalNavHeight}>
        <IndicatorsPageStepHeader>
          <Subtitle2 style={{ marginBottom: '5px' }}>
            Consider lead and lag performance indicators for this strategic
            imperative.
          </Subtitle2>
          <BodyNormal
            color={colors.greyDark}
            style={{ display: 'inline-block', marginBottom: 15 }}
          >
            Add notes and organise similar ideas into themes. Leads should
            summarise the key indicators.
          </BodyNormal>
          {!!competitiveAdvantageRows?.length && (
            <>
              <CriticalMetricsTabGroup>
                <TabGroup componentName="ContributionTab">
                  <>
                    {competitiveAdvantageRows?.map(
                      (competitiveAdvantageRow) => {
                        return (
                          <ContributionTab
                            key={competitiveAdvantageRow.id}
                            id={`contribution-tab-${competitiveAdvantageRow.strategicPossibility?.id}`}
                            secondary=""
                            text={
                              competitiveAdvantageRow.strategicPossibility
                                ?.name || ''
                            }
                            active={
                              +competitiveAdvantageRowUrlParam ===
                              competitiveAdvantageRow.id
                            }
                            onClick={() => {
                              const url = URLHelper.getCriticalMetricsLeadAndLagIndicatorsUrl(
                                drugId,
                                strategyId,
                                competitiveAdvantageRow.id
                              );
                              history.replace(url);
                              setStrategicImperativeId(competitiveAdvantageRow.id)
                            }}
                            displaySummary={() => setSidebarOpen(true)}
                            revealTruncatedText={false}
                            sidebarOpen={sidebarOpen}
                          />
                        );
                      }
                    )}
                  </>
                </TabGroup>
              </CriticalMetricsTabGroup>
              {strategicQuestionsLoading ? (
                <Loading style={{ margin: 0, height: 115 }} />
              ) : (
                <StrategyDefinitionHeader
                  type={leadLagStrategicQuestion?.type}
                  bulletPoints={{
                    type: StrategicQuestionType.MultiList,
                    bulletPoints: leadLagStrategicQuestion?.lists || [],
                  }}
                  collaboration={
                    leadLagStrategicQuestion?.collaboration || undefined
                  }
                  totalUsers={strategyData?.strategy?.users.length}
                  isLead={isLead}
                  disabled={!isLead}
                  tooltipMessage={'Only Leads can edit'}
                  title={leadLagStrategicQuestion?.title || ''}
                  handleSave={async (val) => {
                    if (!!leadLagStrategicQuestion?.id && isLead) {
                      await updateLeadAndLagIndicators({
                        variables: {
                          id: leadLagStrategicQuestion?.id,
                          data: {
                            ...val,
                          },
                        },
                      });
                    }
                  }}
                />
              )}
            </>
          )}
        </IndicatorsPageStepHeader>

        <SidebarDesktopWrapper
          ref={sidebarDesktopRef}
          sidebarOpen={sidebarOpen}
          isRelative={desktopWrapperTop > totalNavHeight}
          totalNavHeight={topOffset}
        >
          <ImperativeSummarySidebar
            activeImperative={selectedRowImperative}
            activeImperativeId={selectedRowImperative?.id}
            strategicObjectives={keyStatementsForSideBar?.strategicObjectives}
            distinctiveCapabilities={
              keyStatementsForSideBar?.distinctiveCapabilities
            }
            sidebarOpen={sidebarOpen && !!competitiveAdvantageRowUrlParam}
            setSidebarOpen={setSidebarOpen}
            desktopWrapperTop={desktopWrapperTop}
            totalNavHeight={totalNavHeight}
          />

          <ErrorWrapper
            isLoading={
              strategyLoading ||
              competitiveAdvantageRowsLoading ||
              strategicQuestionsLoading ||
              keyStatementsLoading ||
              groupsLoading ||
              cardsLoading
            }
            errors={[
              strategicQuestionsError,
              groupsError,
              cardsError,
              strategyError,
              competitiveAdvantageRowsError,
            ]}
            dataMissing={false}
          >
            <PostItsPageWrapper
              paddingTop={false}
              fullWidthMobile
              totalNavHeight={totalNavHeight}
              overlapWidth={overlapWidth}
              sidebarOpen={sidebarOpen}
            >
              <MainContent
                sidebarOpen={sidebarOpen}
                overlapWidth={overlapWidth}
              >
                {!competitiveAdvantageRows?.length ? (
                  <PostItsEmpty
                    style={{ margin: 20 }}
                    title="Nothing to discuss"
                  >
                    <BodySmall
                      color={colors.greyDark}
                      style={{ display: 'inline' }}
                    >
                      Leads must first choose {themes.develop.tools['3.3'].name} to focus on
                      in{' '}
                    </BodySmall>
                    <Link
                      to={`/d/${drugId}/strategy/${strategyId}/3_3`}
                      style={{
                        color: colors.greyDark,
                        display: 'inline',
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      3.3 {themes.develop.tools['3.3'].name}
                    </Link>
                  </PostItsEmpty>
                ) : (
                  <PostIts
                    step={Step.CriticalMetrics}
                    groups={groups}
                    addCard={createCard}
                    removeCard={removeCard}
                    updateCard={updateCard}
                    handleCardChange={onChange}
                    createGroup={createGroup}
                    updateGroup={updateGroup}
                    removeGroup={removeGroup}
                    sidebarOpen={sidebarOpen}
                  />
                )}
              </MainContent>
            </PostItsPageWrapper>
          </ErrorWrapper>
        </SidebarDesktopWrapper>
      </SidebarMobileWrapper>
    </>
  );
};
