import { AnimatingWaveIcon, ButtonLabel, Icon } from 'components/shared';
import { colors } from 'constants/colors';
import {
  Stakeholder,
  CompetitorFragment,
  StakeholderDefinitionFragment,
  StakeholderDefinitionsDocument,
  StakeholderDefinitionsQueryVariables,
  useCompetitorUpdateMutation,
  Step,
  SubStep
} from 'data/graphql/generated';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { CompetitorModalState } from 'types';
import { usePostItGroups } from '../../hooks/usePostItGroups';

const Wrapper = styled.div<{ active: boolean }>`
  height: fit-content;
  padding: 5px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  background: ${({ active }) => (active ? colors.purple : colors.greyLight)};

  &:hover {
    cursor: pointer;
  }
`;

const EditIcon = styled(Icon)`
  height: 20px;

  svg {
    width: 12px;
    stroke-width: 1.8;
  }
`;

const TrashIcon = styled(Icon)`
  svg {
    width: 15px;
    margin-right: -1px;
  }
`;

const WhiteCircle = styled.div`
  height: 20px;
  width: 20px;
  border: 2px solid white;
  border-radius: 50%;
`;

interface Props {
  setCreateUpdateCompetitorModal: React.Dispatch<
    React.SetStateAction<CompetitorModalState>
  >;
  drugId: number;
  strategyId: string;
  competitor: CompetitorFragment;
  stakeholder: Stakeholder;
  type: string;
  setTreatmentInUseModal: React.Dispatch<React.SetStateAction<boolean>>;
  stakeholderDefinitionsQueryVars: StakeholderDefinitionsQueryVariables;
  selectedStakeholderDefinition: StakeholderDefinitionFragment;
  displayDeleteCompetitorModal: (
    competitor: CompetitorFragment
  ) => Promise<void>;
}

export const CompetitorChip: React.FC<Props> = ({
  setCreateUpdateCompetitorModal,
  competitor,
  stakeholder,
  strategyId,
  drugId,
  type,
  setTreatmentInUseModal,
  stakeholderDefinitionsQueryVars,
  selectedStakeholderDefinition,
  displayDeleteCompetitorModal,
}) => {
  const [competitorUpdate] = useCompetitorUpdateMutation();
  const [loading, setLoading] = useState(false);
  let { id, title, image } = competitor;
  const [defaultCompetitorId, setCompetitorId] = useState(0);

  const [active, setActive] = useState<boolean>(
    !!selectedStakeholderDefinition?.CompetitorDetails?.some(
      (details) =>
        details?.competitorId === competitor.id &&
        details.stakeholder === stakeholder
    )
  );
  
  const {
    items: groupItems,
    loading: groupsLoading,
    createGroup,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.CompetitorPositioning,
        competitorId: defaultCompetitorId ? +defaultCompetitorId : null,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.CompetitorPositioning,
      competitorId: defaultCompetitorId ? +defaultCompetitorId : 0,
    }
  );

  useEffect(() => {
    setActive(
      !!selectedStakeholderDefinition.CompetitorDetails?.some(
        (details) =>
          details?.competitorId === competitor.id &&
          details?.stakeholder === stakeholder && details?.type === 'CompetitorPositioning'
      )
    );
  }, [selectedStakeholderDefinition, competitor, stakeholder]);

  const hasCreatedGroup = useRef(false);

  useEffect(() => {
    if(!groupsLoading) {

      if (defaultCompetitorId && !hasCreatedGroup.current) {
        hasCreatedGroup.current = true; // Mark as executed
        (async () => {
          if (groupItems.length === 0 ) {
            try {
              await createGroup({
                title: 'Unnamed group',
              });
            } catch (err) {
              console.error('Error creating group:', err);
              hasCreatedGroup.current = false; // Allow retry on error
            }
          }
        })();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCompetitorId, groupsLoading]);

  async function toggleActive() {
    if (active) {
      setLoading(true);
      // Competitor has no post its for the selected stakeholder definition,
      // we can disable its active state
      try {
        await competitorUpdate({
          variables: {
            id: +competitor.id,
            data: {
              stakeholder,
              type,
              removeStakeholderDefinitionId: +selectedStakeholderDefinition.id,
            },
          },
          refetchQueries: [
            {
              query: StakeholderDefinitionsDocument,
              variables: stakeholderDefinitionsQueryVars,
            },
          ],
        });
        setActive(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (
          error instanceof Error &&
          error.message === 'Cannot remove treatment in use'
        ) {
          return setTreatmentInUseModal(true);
        }

        alert(error);
      }
    } else {
      // Competitor is inactive, so we activate it
      try {
        setLoading(true);

        setCompetitorId(+competitor.id)
        await competitorUpdate({
          variables: {
            id: +competitor.id,
            data: {
              stakeholder,
              type,
              stakeholderDefinitionId: +selectedStakeholderDefinition.id,
            },
          },

          refetchQueries: [
            {
              query: StakeholderDefinitionsDocument,
              variables: stakeholderDefinitionsQueryVars,
            },
          ],
        });
        setActive(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        alert(error);
      }
    }
  }

  return (
    <Wrapper active={active}>
      {active ? (
        loading ? (
          <AnimatingWaveIcon className="icon" size={20} color={'white'} />
        ) : (
          <Icon
            name="Tick Small"
            size={20}
            color={colors.purple}
            style={{
              backgroundColor: colors.white,
              borderRadius: '50%',
            }}
            onClick={toggleActive}
          />
        )
      ) : loading ? (
        <AnimatingWaveIcon className="icon" size={20} />
      ) : (
        <WhiteCircle onClick={toggleActive} />
      )}
      <ButtonLabel
        style={{
          color: active ? colors.white : colors.black,
        }}
        onClick={toggleActive}
      >
        {title}
      </ButtonLabel>
      <EditIcon
        name="Pencil"
        size={20}
        color={colors.greyDark}
        style={{ backgroundColor: colors.cream, borderRadius: '50%' }}
        onClick={() =>
          setCreateUpdateCompetitorModal({
            type: 'edit',
            competitorId: id,
            title,
            error: '',
            image: image || '',
          })
        }
      />
      <TrashIcon
        name="Trash"
        className="cypress-competitor-chip-delete-icon"
        size={20}
        color={colors.darkRed}
        style={{ backgroundColor: colors.cream, borderRadius: '50%' }}
        onClick={() => displayDeleteCompetitorModal(competitor)}
      />
    </Wrapper>
  );
};
