import React, { useState, useEffect, useMemo } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Navbar } from 'components/Navbar';
import { Page, Wrapper } from 'components/Page';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import {
  BodyNormal,
  BodySmall,
  StepHeaderBar,
  StepTab,
  StrategicSummary,
  Subtitle2,
  TabGroup,
  Table,
  Tooltip,
} from 'components/shared';
import { colors, polling, themes } from 'constants/index';
import {
  useStrategyQuery,
  useLeveragePointsQuery,
  useStrategicPossibilitiesQuery,
  useSuccessConditionsQuery,
  SuccessConditionType,
  SuccessConditionFragment,
  useCompetitiveAdvantageRowsQuery,
  useCompetitiveAdvantageRowUpdateMutation,
  CompetitiveAdvantageRowInput,
  StrategicPossibilityFragment,
  CompetitiveAdvantageRowFragment,
  usePostItCardsQuery,
  Step,
  SubStep,
  PostItCardType,
  PostItCardFragment,
  Sort,
} from 'data/graphql/generated';
import { useAuthContext } from 'contexts/AuthContext';
import { imperativeRowColumns, Row } from 'components/CompetitiveAdvantage/Row';
import useDesktop from 'hooks/useDesktop';
import { MobileContentDivider, TableDivHeaders } from 'components/shared/Table';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { device } from 'utils/breakpoints';
import { ErrorModal } from 'components/ErrorModal';
import { kebabCase } from 'lodash';
import { TabGroupWrapper, StepBarsWrapper } from './StrategicQuestion';
import { Summary } from 'components/CompetitiveAdvantage/Summary/Summary';
import buildStrategyURL from 'utils/buildStrategyURL';
import { verifyUserRole } from 'utils/verifyUserRole';
import {
  ButtonContainerDesktop,
  Divider,
  EvaluationButton,
} from '../components/3-5-critical-metrics/shared/shared-ui-critical-metrics/src';

const StyledTable = styled(Table)`
  ${TableDivHeaders} {
    ${imperativeRowColumns};
    padding-left: 20px;
  }

  @media ${device.mobile} {
    border-radius: 0;
    border: none;
  }

  ${MobileContentDivider}:last-of-type {
    display: none;
  }
  ${imperativeRowColumns};
` as typeof Table;

const StyledStepHeaderBar = styled(StepHeaderBar)`
  min-height: auto;
  padding: 15px;

  @media ${device.tabletMax} {
    margin-top: 160px;
  }
`;

const PageWrapper = styled(Page)<{ summaryPage: boolean }>`
  padding: ${({ summaryPage }) => `19.5px ${!!summaryPage && '0 0 0'}`};

  @media ${device.tabletMax} {
    padding-top: 14.5px;
  }

  ${Wrapper} {
    width: 100%;
    padding: ${({ summaryPage }) => (!!summaryPage ? 0 : '0 20px')};

    max-width: ${({ summaryPage }) => (!!summaryPage ? 'none' : '1440px')};

    @media ${device.tabletMax} {
      width: 100%;
      padding: 0;
    }
  }
`;

export type ImperativeWithSuccessConditionType = (StrategicPossibilityFragment & {
  successCondition: (SuccessConditionFragment | undefined)[] | undefined;
  competitiveAdvantageRow: CompetitiveAdvantageRowFragment | undefined;
})[];

interface URLParams {
  drugId: string;
  strategyId: string;
  subStep: string;
}

const keyIssueSubStepName = kebabCase('Key Issues');
const summarySubStepName = 'summary';

export const CompetitiveAdvantage = () => {
  const { drugId, strategyId, subStep }: URLParams = useParams();
  const [{ user }] = useAuthContext();
  const history = useHistory();
  const isDesktop = useDesktop();

  const [activeSubStep, setActiveSubStep] = useState(
    subStep || keyIssueSubStepName
  );

  useEffect(() => {
    // Default url
    if (!subStep) {
      history.replace(
        `/d/${drugId}/strategy/${strategyId}/3_3/${activeSubStep}`
      );
    }
  }, [history, drugId, strategyId, activeSubStep, subStep]);

  useEffect(() => {
    //respond to changes in url
    setActiveSubStep(kebabCase(subStep) || keyIssueSubStepName);
  }, [subStep]);

  const [preventUnfocusModal, setPreventUnfocusModal] = useState(false);

  const {
    data: strategyData,
    error: strategyError,
    loading: strategyLoading,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });

  const { isLead } = verifyUserRole(user?.role, user?.country);

  const {
    data: leveragePointsData,
    error: leveragePointsError,
    loading: leveragePointsLoading,
    startPolling: leveragePointsStartPolling,
    stopPolling: leveragePointsStopPolling,
  } = useLeveragePointsQuery({
    skip: activeSubStep !== keyIssueSubStepName,
    variables: {
      where: {
        strategyId: +strategyId,
        hasFocusedStrategicPossibilities: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: strategicPossibilitiesData,
    error: strategicPossibilitiesError,
    loading: strategicPossibilitiesLoading,
    startPolling: strategicPossibilitiesStartPolling,
    stopPolling: strategicPossibilitiesStopPolling,
  } = useStrategicPossibilitiesQuery({
    variables: {
      where: {
        imperative: true,
        strategyId: +strategyId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: successConditionsData,
    error: successConditionsError,
    loading: successConditionsLoading,
    startPolling: successConditionsStartPolling,
    stopPolling: successConditionsStopPolling,
  } = useSuccessConditionsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        type: [SuccessConditionType.Barrier, SuccessConditionType.Driver],
      },
    },
    fetchPolicy: 'network-only',
  });

  // TODO: QUERY Competitive Advantage Rows Withoout Imperative
  const {
    data: competitiveAdvantageRowsData,
    error: competitiveAdvantageRowsError,
    loading: competitiveAdvantageRowsLoading,
    startPolling: competitiveAdvantageRowsStartPolling,
    stopPolling: competitiveAdvantageRowsStopPolling,
  } = useCompetitiveAdvantageRowsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
      },
      include: { strategicPossibility: true, successConditions: true },
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: postItCardsData,
    loading: postItCardLoading,
    startPolling: startPostItPolling,
    stopPolling: stopPostItPolling,
    error: postItCardsError,
  } = usePostItCardsQuery({
    fetchPolicy: 'network-only',
    skip: activeSubStep === keyIssueSubStepName,
    variables: {
      orderBy: { pos: Sort.Desc },
      where: {
        strategyId: +strategyId,
        step: Step.Strategicquestion,
        substep: SubStep.WinningAspiration,
        type: PostItCardType.Image,
      },
    },
  });

  const cardsWithImages = postItCardsData?.postItCards?.items
    .filter((card) => !!card.image && !!card.collaboration?.alignmentCount)
    .sort((a, b) => {
      const countA = Number(a.collaboration?.alignmentCount);
      const countB = Number(b.collaboration?.alignmentCount);
      return countB - countA;
    }) as (Omit<PostItCardFragment, 'image'> & { image: string })[];

  const [
    updateCompetitiveAdvantageRow,
  ] = useCompetitiveAdvantageRowUpdateMutation();

  useEffect(() => {
    startStrategyPolling(polling.default);
    leveragePointsStartPolling(polling.default);
    strategicPossibilitiesStartPolling(polling.default);
    successConditionsStartPolling(polling.default);
    competitiveAdvantageRowsStartPolling(polling.default);
    startPostItPolling(polling.default);
    return () => {
      stopStrategyPolling();
      leveragePointsStopPolling();
      strategicPossibilitiesStopPolling();
      successConditionsStopPolling();
      competitiveAdvantageRowsStopPolling();
      stopPostItPolling();
    };
  }, [
    competitiveAdvantageRowsStartPolling,
    competitiveAdvantageRowsStopPolling,
    leveragePointsStartPolling,
    leveragePointsStopPolling,
    startPostItPolling,
    startStrategyPolling,
    stopPostItPolling,
    stopStrategyPolling,
    strategicPossibilitiesStartPolling,
    strategicPossibilitiesStopPolling,
    successConditionsStartPolling,
    successConditionsStopPolling,
  ]);

  const strategicImperatives =
    strategicPossibilitiesData?.strategicPossibilities.items;

  const imperativeWithSuccessCondition: ImperativeWithSuccessConditionType = useMemo(
    () =>
      strategicImperatives?.map((imperative) => {
        const successCondition = successConditionsData?.successConditions?.items.filter(
          (condition) => condition.strategicPossibility.id === imperative.id
        );

        const competitiveAdvantageRow = competitiveAdvantageRowsData?.competitiveAdvantageRows?.items.filter(
          (row) => {
            return row.strategicPossibilityId === imperative.id;
          }
        )?.[0];

        return { ...imperative, successCondition, competitiveAdvantageRow };
      }) || [],

    [
      competitiveAdvantageRowsData?.competitiveAdvantageRows?.items,
      strategicImperatives,
      successConditionsData?.successConditions?.items,
    ]
  );

  const totalUsers = strategyData?.strategy?.users.length;
  const winningAspiration = strategyData?.strategy?.winningAspiration;

  const columnData = useMemo(() => {
    return imperativeWithSuccessCondition
      .filter((condition) => !!condition.competitiveAdvantageRow?.focus)
      .sort((a, b) => {
        const aRow = a?.competitiveAdvantageRow?.idx || 0;
        const bRow = b?.competitiveAdvantageRow?.idx || 0;

        return aRow > bRow ? -1 : aRow < bRow ? 1 : 0;
      });
  }, [imperativeWithSuccessCondition]);

  return (
    <>
      <StrategicSummary />

      <ErrorModal
        visible={preventUnfocusModal}
        handleClose={() => setPreventUnfocusModal(false)}
        title="Cannot remove this strategic imperative"
        text="Content in later steps depends on this strategic possibility as a strategic imperative. Remove content and try again."
      />

      <Navbar
        stepNumber="3.3"
        title={themes.develop.tools['3.3'].name}
        prev={{
          title: themes.develop.tools['3.2'].name,
          url: buildStrategyURL(drugId, strategyId, '3_2'),
        }}
        next={{
          url: buildStrategyURL(drugId, strategyId, '3_4'),
          title: themes.develop.tools['3.4'].name,
        }}
      >
        <TabGroupWrapper>
          <TabGroup componentName="StepTab">
            <StepBarsWrapper>
              {[
                {
                  title: 'Key Issues',
                  subStep: keyIssueSubStepName,
                },
              ].map((v) => {
                return (
                  <StepTab
                    key={v.title}
                    active={activeSubStep === v.subStep}
                    text={v.title}
                    onClick={() => {
                      history.push(
                        `/d/${drugId}/strategy/${strategyId}/3_3/${kebabCase(
                          v.subStep
                        )}`
                      );
                    }}
                  />
                );
              })}

              <ButtonContainerDesktop>
                <Divider />

                <EvaluationButton
                  text={'Summary'}
                  isActive={activeSubStep === summarySubStepName}
                  onClick={() => {
                    history.push(
                      `/d/${drugId}/strategy/${strategyId}/3_3/${kebabCase(
                        summarySubStepName
                      )}`
                    );
                  }}
                />
              </ButtonContainerDesktop>
            </StepBarsWrapper>
          </TabGroup>
        </TabGroupWrapper>
      </Navbar>
      <StyledStepHeaderBar>
        {activeSubStep === keyIssueSubStepName ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Subtitle2 style={{ marginBottom: '5px' }}>
              Consider the Key Issues and align to Strategic Possibilities you
              want want to move forward with.
            </Subtitle2>
            <BodyNormal color={colors.greyDark}>
              Discuss reasons for and against the Strategic Possibilities. Leads
              summarise the Key Issue and summarise the discussion.
            </BodyNormal>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Subtitle2 style={{ marginBottom: '5px' }}>
              A summary of the competitive advantage
            </Subtitle2>
            <BodyNormal
              color={colors.greyDark}
              style={{ display: 'inline-block' }}
            >
              Leads may re-order the cards to set the priority of Key Issues and
              Strategic Possibilities
            </BodyNormal>
          </div>
        )}
      </StyledStepHeaderBar>
      <ErrorWrapper
        isLoading={
          leveragePointsLoading ||
          strategicPossibilitiesLoading ||
          successConditionsLoading ||
          competitiveAdvantageRowsLoading ||
          strategyLoading ||
          postItCardLoading
        }
        errors={[
          leveragePointsError,
          strategicPossibilitiesError,
          successConditionsError,
          competitiveAdvantageRowsError,
          strategyError,
          postItCardsError,
        ]}
        dataMissing={false}
      >
        <PageWrapper
          paddingTop={false}
          fullWidthMobile
          summaryPage={activeSubStep === summarySubStepName}
        >
          {activeSubStep === keyIssueSubStepName &&
          !leveragePointsData?.leveragePoints?.items?.length ? (
            <PostItsEmpty title="Nothing to discuss">
              <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
                Leads must first choose Strategic Possibilities to focus on in{' '}
              </BodySmall>
              <Link
                to={`/d/${drugId}/strategy/${strategyId}/2_3`}
                style={{
                  color: colors.greyDark,
                  display: 'inline',
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                2.3 {themes.explore.tools['2.3'].name}{' '}
              </Link>
            </PostItsEmpty>
          ) : activeSubStep === keyIssueSubStepName ? (
            <main
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 15,
              }}
            >
              {leveragePointsData?.leveragePoints.items.map((leveragePoint) => {
                if (!strategicImperatives) {
                  return null;
                }

                const imperative = imperativeWithSuccessCondition.filter(
                  (item) => item.patientFlowBlock?.id === leveragePoint.blockId
                );

                return (
                  <StyledTable
                    useDiv
                    key={leveragePoint.blockId}
                    data={imperative}
                    emptyStateText={{
                      header: 'No strategic imperatives yet',
                      subHeading: (
                        <div
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <BodySmall
                            style={{
                              display: 'inline',
                              color: colors.greyDark,
                            }}
                          >
                            Strategic possibilities the Lead chooses to focus on
                            in
                          </BodySmall>
                          <Link
                            to={`/d/${drugId}/strategy/${strategyId}/2_4`}
                            style={{
                              color: colors.greyDark,
                              display: 'inline',
                              fontSize: 14,
                              fontWeight: 500,
                            }}
                          >
                            {' '}
                            2.3 {themes.explore.tools['2.3'].name}{' '}
                          </Link>
                          <BodySmall
                            style={{
                              display: 'inline',
                              color: colors.greyDark,
                            }}
                          >
                            will appear here
                          </BodySmall>
                        </div>
                      ),
                    }}
                    header={
                      <div
                        style={{
                          padding: isDesktop ? '15px' : '0 0 0 10px',
                        }}
                      >
                        <BodySmall color={colors.greyDark}>
                          {leveragePoint.stage}
                        </BodySmall>
                        <Subtitle2>{leveragePoint.title}</Subtitle2>
                      </div>
                    }
                    isDesktop={isDesktop}
                    cols={[
                      'Key Issue',
                      'Drivers',
                      'Barriers',
                      'Strategic Possibility',
                    ]}
                    rows={(data) => {
                      if (!data.competitiveAdvantageRow) {
                        return null;
                      }

                      const drivers: SuccessConditionFragment[] = [];
                      const barriers: SuccessConditionFragment[] = [];
                      data.successCondition?.forEach((condition) => {
                        if (
                          condition?.type &&
                          [
                            SuccessConditionType.Barrier,
                            SuccessConditionType.Driver,
                          ].includes(condition?.type)
                        ) {
                          condition?.type === SuccessConditionType.Driver
                            ? drivers.push(condition)
                            : barriers.push(condition);
                        }
                      });

                      const id = data.competitiveAdvantageRow.id;

                      return (
                        <Row
                          isLead={isLead}
                          isDesktop={isDesktop}
                          totalUsers={totalUsers || 0}
                          drivers={drivers}
                          barriers={barriers}
                          name={data.name}
                          behaviourToDrive={data.behaviourToDrive || ''}
                          driverOfTheBehaviour={data.driverOfTheBehaviour || ''}
                          competitiveAdvantageRow={data.competitiveAdvantageRow}
                          updateCompetitiveAdvantageRow={async (
                            data: CompetitiveAdvantageRowInput
                          ) => {
                            try {
                              await updateCompetitiveAdvantageRow({
                                variables: {
                                  id,
                                  data,
                                },
                              });
                            } catch (error) {
                              if (error instanceof Error) {
                                throw new Error(error.message);
                              }
                            }
                          }}
                          setPreventUnfocusModal={setPreventUnfocusModal}
                        />
                      );
                    }}
                    isAddingRow={false}
                  />
                );
              })}
            </main>
          ) : (
            <Summary
              isLead={isLead}
              winningAspiration={winningAspiration || ''}
              updateCompetitiveAdvantageRow={updateCompetitiveAdvantageRow}
              columnData={columnData}
              mostAlignedImage={cardsWithImages?.[0]}
            />
          )}
        </PageWrapper>
        <Tooltip id={'competitiveAdvantageTooltip'} effect="float" multiline />
      </ErrorWrapper>
    </>
  );
};
