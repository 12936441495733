import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { PostItGroupAndCards, Step } from 'types';

import { useAuthContext } from 'contexts/AuthContext';
import { GridContextProvider } from 'react-grid-dnd';
import { ButtonPill } from 'components/shared';
import { useWidth } from 'hooks/useWidth';
import { PostItGroupView, SliderUpsertFn } from './PostItGroup';
import { Transition, TransitionGroup } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { usePostItCardsPayload } from 'hooks/usePostItCards';
import {
  CompetitiveLandscapeRatingFragment,
  PostItGroup,
  SubStep,
} from 'data/graphql/generated';
import { useEffect } from 'react';
import { postItCardWidths } from 'constants/index';
import { Payload } from 'hooks/usePostItGroups';
import { sidebarTransition } from './shared/ImperativeSummarySidebar';

export const Wrapper = styled.div``;

const Center = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
`;

export const TransitionWrapper = styled.div<{ state: TransitionStatus }>`
  opacity: 1;
  max-height: 10000px;

  ${({ state }) => {
    if (state === 'exiting' || state === 'exited') {
      return css`
        opacity: 0;
        max-height: 0px;
      `;
    }
  }}

  transition:0.5s ease;
`;
interface Props {
  step: Step;
  subStep?: SubStep;
  groups: PostItGroupAndCards[];

  handleCardChange(
    sourceId: string,
    sourceIndex: number,
    targetIndex: number,
    targetId?: string
  ): void;
  addCard: usePostItCardsPayload['createCard'];
  removeCard: usePostItCardsPayload['removeCard'];
  updateCard: usePostItCardsPayload['updateCard'];
  createGroup: Payload['createGroup'];
  updateGroup(group: PostItGroup): void;
  removeGroup(groupId: number): void;
  createGroupDisabled?: boolean;
  createGroupHide?: boolean;
  slider?: {
    enable?: boolean;
    isLoading?: boolean;
    ratings: CompetitiveLandscapeRatingFragment[];
    upsert?: SliderUpsertFn;
  };
  className?: string;
  sidebarOpen?: boolean;
  Content?: (groupId: number) => React.ReactNode;
  copyEnabled?: boolean;
  copyAction?: (title: string) => void;
  deleteGroupHide?: boolean;
  updateGroupDisabled?: boolean;
}

export const PostIts: React.FC<Props> = ({
  step,
  subStep,
  groups,
  addCard,
  handleCardChange,
  removeCard,
  updateCard,
  createGroup,
  updateGroup,
  removeGroup,
  createGroupDisabled = false,
  createGroupHide = false,
  className,
  sidebarOpen = false,
  deleteGroupHide = false,
  updateGroupDisabled = false,
  slider = {
    enable: false,
    isLoading: false,
    ratings: [],
    upsert: undefined,
  },
  Content,
  copyEnabled,
  copyAction,
}) => {
  const [{ user }] = useAuthContext();
  const [newGroupId, setNewGroupId] = useState<number | undefined>();
  const [groupCreating, setGroupCreating] = useState<boolean>(false);

  // disabled card dragging while editing
  const [dragDisabled, setDragDisabled] = useState<boolean>(false);
  const [columns, setColumns] = useState<number>(0);
  const width = useWidth();

  useEffect(() => {
    setTimeout(() => {
      const groupContent = document.getElementsByClassName(
        'group-content'
      )[0] as HTMLDivElement;

      const groupContentWidth = groupContent?.offsetWidth;

      if (!!groupContent) {
        const padding = 15 * 2;

        const postItWidthWithMargin =
          width < 481
            ? postItCardWidths.min + postItCardWidths.gutter / 2
            : postItCardWidths.max + postItCardWidths.gutter / 2;

        const newColumnCount = Math.floor(
          (groupContentWidth - padding) / postItWidthWithMargin
        );

        setColumns(newColumnCount);
      }
    }, sidebarTransition.duration);
  }, [width, groups, sidebarOpen]);

  if (!user) return null;

  return (
    <Wrapper className={className}>
      <GridContextProvider onChange={handleCardChange}>
        <TransitionGroup component={null}>
          {groups.map((group) => {
            return (
              <Transition key={group.id} timeout={500}>
                {(state) => {
                  return (
                    <TransitionWrapper state={state}>
                      <PostItGroupView
                        step={step}
                        subStep={subStep}
                        userRole={user.role}
                        userId={user.id || 0}
                        group={group}
                        deleteGroupHide={deleteGroupHide}
                        updateGroupDisabled={updateGroupDisabled}
                        allGroupsCount={groups.length}
                        addCard={addCard}
                        columns={columns}
                        updateCard={updateCard}
                        removeCard={removeCard}
                        removeGroup={removeGroup}
                        updateGroup={updateGroup}
                        dragDisabled={dragDisabled}
                        setDragDisabled={setDragDisabled}
                        newGroup={[newGroupId, setNewGroupId]}
                        slider={{
                          enable: slider.enable,
                          isLoading: slider.isLoading,
                          rating: slider.ratings.find(
                            (rating) => rating.postItGroupId === group.id
                          ),
                          upsert: slider.upsert,
                        }}
                        copyEnabled={copyEnabled}
                        copyAction={copyAction}
                      />
                      {Content && Content(group.id)}
                    </TransitionWrapper>
                  );
                }}
              </Transition>
            );
          })}
        </TransitionGroup>
      </GridContextProvider>

      {createGroupHide ? null : (
        <Center>
          <ButtonPill
            clickClassName="cypress-create-group-button"
            text="Add group"
            level="secondary"
            iconName="Plus"
            disabled={createGroupDisabled}
            loading={groupCreating}
            onClick={async () => {
              setGroupCreating(true);
              const groupId = await createGroup({
                title: 'New group',
              });
              setNewGroupId(groupId);
              setGroupCreating(false);
            }}
          />
        </Center>
      )}
    </Wrapper>
  );
};
