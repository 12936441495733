import styled from 'styled-components/macro';
import * as Sentry from '@sentry/react';

import {
  TacticUpdateInput,
  useTacticUpsertMutation,
} from 'data/graphql/generated';
import { Table } from 'components/shared';
import {
  TableDivHeaders,
  MobileContentDivider,
  MobileContentWrapper,
} from 'components/shared/Table';

import {
  PicoSuggestionRowData,
  PicoTacticConstants,
} from 'components/4-1-medical-strategy/data-access-medical-tactics-picos';
import useDesktop from 'hooks/useDesktop';
import { noopAsync } from 'utils/noop';
import { MedicalPicoTacticTableHeader } from '../ui/MedicalPicoTacticTableHeader';
import { PicoSuggestionWithTacticRow } from './PicoSuggestionWithTacticRow';
import { tableColumnWidths } from '../ui/TableColumns';

const StyledTable = styled(Table)`
  ${TableDivHeaders} {
    ${tableColumnWidths}
  }

  ${MobileContentDivider} {
    margin-top: 5px;
  }

  ${MobileContentWrapper} {
    padding-bottom: 0px;
  }
` as typeof Table;

interface MedicalTacticsPicosProps {
  currency: string;
  picoSuggestions: PicoSuggestionRowData[];
}

export const MedicalTacticsPicos = ({
  currency,
  picoSuggestions,
}: MedicalTacticsPicosProps) => {
  const isDesktop = useDesktop();
  const [mutateTactic] = useTacticUpsertMutation();

  const onUpdate = async (
    data: TacticUpdateInput & {
      competitiveAdvantageRow: number;
      localUid: string;
      strategyId: number;
    }
  ) => {
    try {
      await mutateTactic({ variables: { data: { ...data } } });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  if (!picoSuggestions.length) {
    return null;
  }

  return (
    <StyledTable
      keyField={'id'}
      useDiv
      hideAddButton
      isDesktop={isDesktop}
      addRow={noopAsync}
      emptyStateText={{}}
      header={<MedicalPicoTacticTableHeader />}
      cols={PicoTacticConstants.MEDICAL_PICO_TACTICS_COLUMNS}
      data={picoSuggestions}
      rows={(data, idx) => (
        <PicoSuggestionWithTacticRow
          key={data.id}
          isFirstRow={!idx}
          isDesktop={isDesktop}
          currency={currency}
          picoSuggestion={data}
          onUpdate={onUpdate}
        />
      )}
    />
  );
};
