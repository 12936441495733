import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';

import { colors } from 'constants/index';
import { Icon } from './Icon';
import { Tooltip } from './Tooltip';
import { uid } from 'uid';
import { IconName } from 'types';
import { AnimatingWaveIcon, ButtonLabel } from '.';

export type ButtonLevel =
  | 'primary'
  | 'secondary'
  | 'primary-solid'
  | 'note'
  | 'advantage'
  | 'limitation'
  | 'export'
  | 'danger'
  | 'align'
  | 'aligned'
  | 'evaluate'
  | 'evaluating';

function getLevelStyle(level: ButtonLevel) {
  switch (level) {
    case 'primary':
      return css`
        color: ${colors.white};
        background: linear-gradient(315deg, #ff00c8 -83%, #7800ff 93.75%);
        position: relative;
        > p,
        > div {
          z-index: 1;
        }
        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border: none;
          border-radius: 25px;
          background: linear-gradient(
              0deg,
              rgba(20, 20, 39, 0.1),
              rgba(20, 20, 39, 0.1)
            ),
            linear-gradient(315deg, #ff00c8 -83%, #7800ff 93.75%);
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        &:hover::before {
          opacity: 1;
        }
      `;

    case 'secondary':
      return css<ButtonProps>`
        color: ${colors.purple};
        background: ${colors.purple10};
        ${({ disabled }) => {
          return css`
            opacity: ${disabled ? 0.5 : 1};
            &:hover {
              background: ${disabled ? colors.purple10 : colors.purple15};
            }
          `;
        }}
      `;
    case 'primary-solid':
      return css`
        color: ${colors.white};
        background: ${colors.purple};

        &:hover {
          background: ${colors.purpleDark};
        }
      `;
    case 'note':
      return css`
        color: ${colors.black};
        background: ${colors.yellow50};

        &:hover {
          background: ${colors.yellow75};
        }
      `;
    case 'advantage':
      return css`
        color: ${colors.black};
        background: ${colors.white};
        border: 1px solid ${colors.green};

        &:hover {
          background: ${colors.green03};
        }
      `;
    case 'limitation':
      return css`
        color: ${colors.black};
        background: ${colors.white};
        border: 1px solid ${colors.red};

        &:hover {
          background: ${colors.red03};
        }
      `;
    case 'export':
      return css`
        color: ${colors.purple};
        background: ${colors.white};

        &:hover {
          background: ${colors.grey03};
        }
        &:active {
          background: ${colors.black05};
        }
      `;
    case 'danger':
      return css<ButtonProps>`
        color: ${colors.white};
        background: ${colors.darkRed};

        ${({ disabled }) => {
          return css`
            opacity: ${disabled ? 0.5 : 1};
          `;
        }}
      `;
    case 'align':
      return css`
        color: ${colors.purple};
        background: ${colors.cream};
        height: 30px;
        svg {
          height: 20px;
        }
        &:hover {
          background: #f6eee6;
        }
      `;
    case 'aligned':
      return css`
        height: 30px;
        color: ${colors.white};
        background: linear-gradient(315deg, #ff00c8 -83%, #7800ff 93.75%);
        svg {
          height: 20px;
        }
        position: relative;
        > p,
        > div {
          z-index: 1;
        }
        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border: none;
          border-radius: 25px;
          background: linear-gradient(
              0deg,
              rgba(20, 20, 39, 0.1),
              rgba(20, 20, 39, 0.1)
            ),
            linear-gradient(315deg, #ff00c8 -83%, #7800ff 93.75%);
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        &:hover::before {
          opacity: 1;
        }
      `;
    case 'evaluate':
      return css`
        height: 26px;
        width: 108px;
        background: ${colors.lightPinkGradient};
        align-self: center;

        svg {
          color: ${colors.purple};
        }

        :hover {
          background: ${colors.lightPinkGradientHover};
        }
      `;

    case 'evaluating':
      return css`
        height: 26px;
        width: 122px;
        background: ${colors.purplePinkGradient};
        color: ${colors.white};
        align-self: center;

        svg {
          fill: ${colors.white};
        }

        :hover {
          svg {
            fill: ${colors.white70};
          }
        }
      `;
    default:
      break;
  }
}

export const TooltipWrapper = styled.div<{
  width?: string;
  level: ButtonLevel;
}>`
  display: flex;
  border: none;
  height: ${({ level }) =>
    ['evaluate', 'evaluating'].includes(level)
      ? '28px'
      : ['align', 'aligned'].includes(level)
      ? '30px'
      : '40px'};
  width: ${({ width }) => (width ? width : 'fit-content')};
`;

interface ButtonProps {
  level: ButtonLevel;
  icon: boolean;
  disabled: boolean;
  width?: string;
}

export const ButtonPillWrapper = styled.button<ButtonProps>`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 25px;
  opacity: ${({ disabled, level }) =>
    disabled && level.includes('primary') ? 0.3 : 1};
  padding: ${({ icon }) => (icon ? `5px 20px 5px 10px` : `5px 20px`)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  * {
    pointer-events: none;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  ${({ level }) => getLevelStyle(level)}

  > .icon {
    pointer-events: none;
  }
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  &:focus {
    outline: none;
  }
  p {
    white-space: nowrap;
    height: 20px;
  }

  transition: all 0.3s ease;
`;

export interface ButtonPillProps {
  text: string;
  iconName?: IconName;
  disabled?: boolean;
  level?: ButtonLevel;
  loading?: boolean;
  tooltip?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  forceHover?: boolean;
  width?: string;
  className?: string;
  clickClassName?: string;
  color?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

function getLevelColor(level: ButtonLevel) {
  switch (level) {
    case 'primary':
    case 'danger':
    case 'primary-solid':
    case 'aligned':
      return colors.white;
    case 'secondary':
    case 'export':
    case 'align':
      return colors.purple;
    default:
      return colors.black;
  }
}

export const ButtonPill = ({
  text,
  level = 'primary',
  loading = false,
  tooltip = '',
  iconName,
  onClick,
  disabled = false,
  width,
  className = '',
  clickClassName = '',
  color,
  type,
}: ButtonPillProps) => {
  const key = uid();
  const [pressed, setPressed] = useState(false);
  const AnimatingWaveIconColor = color === colors.white ? 'white' : undefined;

  return (
    <TooltipWrapper
      className={className}
      onMouseUp={() => {
        setPressed(false);
      }}
      onMouseDown={() => {
        setPressed(text === 'Export');
      }}
      data-for={key}
      data-tip={tooltip}
      width={width}
      tabIndex={0}
      level={level}
    >
      <ButtonPillWrapper
        type={type}
        icon={!!iconName}
        level={level}
        disabled={disabled || loading}
        className={clickClassName}
        onClick={(e) => {
          e.stopPropagation();
          if (!disabled) onClick?.(e);
        }}
      >
        {iconName ? (
          loading || pressed ? (
            <AnimatingWaveIcon
              className="icon"
              size={30}
              color={AnimatingWaveIconColor}
            />
          ) : (
            <Icon
              className="icon"
              name={iconName}
              size={30}
              color={color ?? getLevelColor(level)}
            />
          )
        ) : null}

        {!iconName && (loading || pressed) ? (
          <AnimatingWaveIcon
            className="icon"
            size={30}
            color={level === 'secondary' ? colors.purple : 'white'}
          />
        ) : (
          <ButtonLabel color={color ?? getLevelColor(level)}>
            {text}
          </ButtonLabel>
        )}
      </ButtonPillWrapper>
      <Tooltip id={key} />
    </TooltipWrapper>
  );
};
