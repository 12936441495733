import React from 'react';
import styled from 'styled-components';
import GradientModal from 'components/shared/GradientModal';
import { CompanyFragment } from 'data/graphql/generated';
import { Heading3, Heading1, Subtitle1, Icon } from 'components/shared';
import { device } from 'utils/breakpoints';
import useDesktop from 'hooks/useDesktop';
import { colors } from 'constants/index';
import { ButtonArrow } from 'components/shared/ButtonArrow';

const EditIcon = styled(Icon)`
  background-color: ${colors.cream};
  border-radius: 15px;
  height: 30px;
  width: 30px;
  cursor: pointer;
`;

const CONTENT_PADDING = 15;

const Wrapper = styled(GradientModal)`
  border: none;
  border-radius: 0;
  max-width: 928px;
  margin: 0 auto 30px auto;

  @media ${device.tabletMin} {
    border-radius: 5px;
    border: 1px solid rgba(20, 20, 39, 0.1);
  }
`;

const DropdownMenu = styled.div`
  padding: 5px 0px;
  position: absolute;
  width: 140px;
  top: 30px;
  right: 0;
  background: white;
  border: 1px solid #e8e8e9;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  border-radius: 5px;
  z-index: 2;
`;

const DropdownItem = styled.div`
  padding: 8px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;

  &:hover {
    background-color: ${colors.greyLight};
  }

  svg {
    margin-right: 10px;
  }

  &.delete {
    color: ${colors.red};
  }
`;

const ContentWrapper = styled.div`
  padding: ${CONTENT_PADDING}px;
  position: relative;
`;

const HeadingWrapper = styled.div`
  & > .CompanyModal__divider {
    width: 100%;
    height: 1px;
    background-color: ${colors.black30};
  }

  ${Heading1},${Heading3} {
    background-image: linear-gradient(134deg, #3c4ec5, #be40ae);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    margin-bottom: 15px;
  }
`;

const SubContent = styled.div`
  align-items: center;
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
`;

const NumBrands = styled.div`
  display: flex;
`;

const BrandsText = styled(Subtitle1)`
  color: ${colors.greyDark};
`;

interface Props {
  company: CompanyFragment;
  onEditClick(): void;
  onRemoveClick(): void;
  className?: string;
}

const CompanyModal = ({ company, onEditClick, onRemoveClick, className }: Props) => {
  const isDesktop = useDesktop();
  const brandCount = company?.Drug?.length;
  const [ isDropdownVisible, setIsDropdownVisible ] = React.useState(false);

  React.useEffect(() => {
    function handleClickOutside(event: any) {
      // if edit icon is clicked
      if (event.target.closest(`.company-edit-icon-${company.id}`)) return;
      // if dropdown is clicked
      if (event.target.closest(`.company-dropdown-menu-${company.id}`)) return;

      setIsDropdownVisible(false);
    }

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [company.id]);

  return (
    <Wrapper className={className}>
      <ContentWrapper>
        <HeadingWrapper>
          <div
            className="CompanyModal__heading__topRow"
            style={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              position: 'relative'
            }}
          >
            {isDesktop ? (
              <Heading1>{company.name}</Heading1>
            ) : (
              <Heading3>{company.name}</Heading3>
            )}

            <EditIcon
              className={`company-edit-icon-${company.id}`}
              name="More"
              size={30}
              color={colors.black}
              onClick={() => setIsDropdownVisible(!isDropdownVisible)}
            />

            {isDropdownVisible && (
              <DropdownMenu className={`company-dropdown-menu-${company.id}`}>
                <DropdownItem 
                  onClick={() => {
                    onEditClick();
                    setIsDropdownVisible(false);
                  }}
                >
                  <Icon name="Pencil" color={colors.black} size={30} />
                  Rename
                </DropdownItem>
                <DropdownItem 
                  className="delete"
                  onClick={() => {
                    onRemoveClick();
                    setIsDropdownVisible(false);
                  }}
                >
                  <Icon name="Trash"  color={colors.red} size={30} />
                  Delete
                </DropdownItem>
              </DropdownMenu>
            )}
          </div>
          <div className={'CompanyModal__divider'}></div>
        </HeadingWrapper>

        <SubContent>
          <NumBrands>
            <Subtitle1>{brandCount || 0}</Subtitle1>
            &nbsp;
            <BrandsText>{brandCount === 1 ? 'brand' : 'brands'}</BrandsText>
          </NumBrands>
          <ButtonArrow
            className={'cypress-manage-company-btn'}
            text="Manage"
            link={`/admin/company/${company.id}`}
            name="Complete"
          />
        </SubContent>
      </ContentWrapper>
    </Wrapper>
  );
};

export default CompanyModal;