import React from 'react';
import styled from 'styled-components/macro';
import { Redirect, useParams } from 'react-router-dom';
import { device } from 'utils/breakpoints';
import { colors } from 'constants/index';

export const ArchetypeButton = styled.button`
  border: 0;
  cursor: pointer;
  margin-left: auto;

  display: flex;
  width: 190px;
  height: 40px;
  background-color: ${colors.cream};
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;

  @media ${device.tabletMax} {
    margin-left: 0px;
    order: -1;
    height: 30px;
    text-align: left;

    margin-right: 5px;
    margin-left: -5px;
    min-width: 89px;
  }
`;

interface URLParams {
  drugId: string;
  strategyId: string;
}

export const AccessStrategy = () => {
  const { drugId, strategyId }: URLParams = useParams();

  return (
    <Redirect to={`/d/${drugId}/strategy/${strategyId}/4_2/prioritisation`} />
  );
};
