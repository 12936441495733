import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import {
  generatePath,
  Link,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { useToastContext } from 'contexts/ToastContext';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { MedicalObjectives } from 'components/MedicalStrategy/MedicalObjectives';
import { MedicalTactics } from 'components/MedicalStrategy/MedicalTactics';
import { Navbar } from 'components/Navbar';
import { PostItsEmpty } from 'components/PostItsEmpty';
import {
  BodySmall,
  StepTab,
  StrategicSummary,
  TabGroup,
} from 'components/shared';
import { colors, themes, polling } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import {
  Sort,
  StakeholderDefinition,
  Step,
  StrategicQuestionsDocument,
  SubStep,
  useCompetitiveAdvantageRowsQuery,
  useKeyStatementUpsertMutation,
  useKeyStatementUpdateMutation,
  useKeyStatementDeleteMutation,
  useKeyStatementsQuery,
  useStrategicQuestionsQuery,
  useStrategicQuestionUpdateMutation,
  useStrategyQuery,
  useTacticsQuery,
  CompetitiveAdvantageRowFragment,
  TacticFragment,
  useStrategyUpdateMutation,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import { kebabCase, uniq, lowerCase } from 'lodash';
import { CountryGlobal } from 'types';
import { StepBarsWrapper } from '../StrategicQuestion';
import useGetElementTop from 'hooks/useGetElementTop';
import {
  SidebarMobileWrapper,
  SidebarDesktopWrapper,
} from 'components/shared/SidebarPageComponents';
import { ImperativeSummarySidebar } from 'components/shared/ImperativeSummarySidebar';
import {
  MedicalSummary,
  SMALLEST_MOBILE_WIDTH,
} from 'components/MedicalStrategy/MedicalSummary';
import useSidePaneTopOffset from 'utils/useSidePaneTopOffset';
import { getUniqueRegions } from 'utils/getUniqueRegions';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { CountriesDropdownSmall } from 'components/shared/CountriesDropdownSmall';
import useMobile from 'hooks/useMobile';
import { verifyUserRole } from 'utils/verifyUserRole';
import {
  ContributorCountryPlansStatus,
  KeyEventsSidebar,
} from 'components/4-1-medical-strategy/shared/feature-key-events-sidebar/src';
import { useWidth } from 'hooks/useWidth';
import KeyMedicalObjectives from './KeyMedicalObjectives';
import {
  FinancialSummaryCountryDropdown,
  GlobalPlanButton,
  StyledTwoButtonModal,
  SpinOffsToggle,
  StyledMainContent,
  StyledPageWrapper,
  StyledTabGroupWrapper,
  TabsAndButton,
} from './index.style';
import { MedicalStrategyStepHeader } from '../../components/4-1-medical-strategy/shared/feature-step-header/src/lib/components/MedicalStrategyStepHeader';
import { useGetImperativeSidebarData } from 'components/4-1-medical-strategy/shared/data-access-imperative-summary-sidebar/src';
import {
  MedicalStrategyTabNames,
  MEDICAL_STRATEGY_TABS,
} from 'components/4-1-medical-strategy/shared/data-access-medical-strategy';
import { HurdlesAndGapsSubstepView } from 'components/4-1-medical-strategy/feature-hurdles-and-gaps';
import {
  useFetchPicoSuggestionWithTactics,
  useFetchPicoTactics,
} from 'components/4-1-medical-strategy/data-access-medical-tactics-picos';
import { useGlobalContext } from 'contexts/GlobalContext';
import URLHelper from 'utils/URLHelper';

interface URLParams {
  drugId: string;
  strategyId: string;
  subStep: string;
  strategicImperativeId: string;
  region: string;
  /* Area To Explore Id */
  area: string;
}

export const MedicalStrategy = () => {
  const {
    drugId,
    strategyId,
    subStep,
    strategicImperativeId,
    region,
    area,
  }: URLParams = useParams();
  const { path } = useRouteMatch();

  const history = useHistory();
  const width = useWidth();
  const isDesktop = useDesktop();
  const isMobile = useMobile();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [keyEventsSidebarOpen, setKeyEventsSidebarOpen] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState<CountryGlobal>(
    (region?.replaceAll('-', ' ') as CountryGlobal) || 'global'
  );

  const sidebarDesktopRef = useRef<HTMLDivElement | null>(null);
  const desktopWrapperTop = useGetElementTop(sidebarDesktopRef);

  const totalNavHeight = useMemo(
    () => (isDesktop ? 110 : isMobile ? 200 : 155),
    [isDesktop, isMobile]
  );
  const topOffset = useSidePaneTopOffset(sidebarDesktopRef, totalNavHeight);

  const [createToast, removeToast] = useToastContext();

  const [{ user }] = useAuthContext();
  const { isLead, isCountryContributor, isGlobalContributor } = verifyUserRole(
    user?.role,
    user?.country
  );
  const viewingOwnRegion = isLead
    ? selectedRegion === 'global'
    : selectedRegion === user?.country;

  const [showCountrySpinOffs, setShowCountrySpinOffs] = useState(
    !(isLead || isGlobalContributor)
  );

   // handle global context path
   const [ medicalStrategy, setMedicalStrategy] = useGlobalContext(
    'medicalStrategy'
  );
   // handle global context path
   const [ strategicImperativeIdGlobal ] = useGlobalContext(
    'strategicImperativeId'
  );


  const [updateStrategy] = useStrategyUpdateMutation();
  const [countryTacticsWarningModal, setCountryTacticsWarningModal] = useState(
    false
  );

  // Region selection
  const countryDropDownRef = useRef(null);
  const [countryDropdownActive, setCountryDropdownActive] = useState(false);

  useClickOutsideComponent(countryDropDownRef, () => {
    setCountryDropdownActive(false);
  });

  const [activeSubStep, setActiveSubStep] = useState(
    subStep || medicalStrategy || MedicalStrategyTabNames.HURDLES_AND_GAPS
  );

  const isMedicalTacticsPage =
    activeSubStep === MedicalStrategyTabNames.MEDICAL_TACTICS;
  const isSummaryPage = subStep === SubStep.Summary;
  const isHurdlesAndGapsPage =
    activeSubStep === MedicalStrategyTabNames.HURDLES_AND_GAPS;
  const isMedicalObjectivesPage =
    activeSubStep === MedicalStrategyTabNames.MEDICAL_OBJECTIVES;

  const [
    activeStrategicImperativeId,
    setActiveStrategicImperativeId,
  ] = useState<number>(+strategicImperativeId || 0);

  useEffect(() => {

    if(medicalStrategy){
      setActiveSubStep(
        kebabCase(medicalStrategy) 
      );

    }
    if (strategicImperativeIdGlobal) {
      setActiveStrategicImperativeId(strategicImperativeIdGlobal);
    }
  }, [medicalStrategy, strategicImperativeId, strategicImperativeIdGlobal, setActiveSubStep, setActiveStrategicImperativeId]);
  
  const [
    financialSummarySelectedRegions,
    setFinancialSummarySelectedRegions,
  ] = useState<CountryGlobal[]>(['global']);

  const [
    financialCountryDropdownActive,
    setFinancialCountryDropdownActive,
  ] = useState(false);
  const financialCountryDropDownRef = useRef<HTMLDivElement | null>(null);

  useClickOutsideComponent(
    financialCountryDropDownRef,
    () => {
      financialCountryDropdownActive &&
        setFinancialCountryDropdownActive(false);
    },
    ['cypress-country-dropdown-toggle', 'cypress-countries-dropdown-item']
  );

  const {
    data: strategyData,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });

  const strategy = strategyData?.strategy;

  // Financial summary dropdown logic - state is kept at this level to preserve state as the user navigates between the steps in 4.2
  const [countryTacticsEnabled, setCountryTacticsEnabled] = useState<boolean>(
    strategy?.enableCountryTactics ?? false
  );

  useEffect(() => {
    if (!countryTacticsEnabled && (isMedicalTacticsPage || isSummaryPage)) {
      setSelectedRegion('global');
      setFinancialSummarySelectedRegions(['global']);
    }
  }, [countryTacticsEnabled, isMedicalTacticsPage, isSummaryPage]);

  useEffect(() => {
    if (strategy?.enableCountryTactics) {
      setCountryTacticsEnabled(true);
    } else {
      setCountryTacticsEnabled(false);
    }
  }, [strategy?.enableCountryTactics]);

  const {
    data: strategyTacticsData,
    error: strategyTacticsError,
    loading: strategyTacticsLoading,
    startPolling: strategyTacticsStartPolling,
    stopPolling: strategyTacticsStopPolling,
  } = useTacticsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const {
    data: competitiveAdvantageRowsData,
    error: competitiveAdvantageRowsError,
    loading: competitiveAdvantageRowsLoading,
    startPolling: startCompetitiveAdvantageRowsPolling,
    stopPolling: stopCompetitiveAdvantageRowsPolling,
  } = useCompetitiveAdvantageRowsQuery({
    variables: {
      orderBy: {
        idx: Sort.Desc,
      },
      where: {
        strategyId: +strategyId,
        focus: true,
      },
      include: {
        strategicPossibility: true,
        successConditions: true,
        patientFlowBlockStarStakeholderDefinitions: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const competitiveAdvantageRows = useMemo(
    () => competitiveAdvantageRowsData?.competitiveAdvantageRows?.items || [],
    [competitiveAdvantageRowsData?.competitiveAdvantageRows?.items]
  );

  const activeImperative = useMemo(
    () =>
      competitiveAdvantageRows?.find((row) => row.id === +strategicImperativeId)
        ?.strategicPossibility,
    [competitiveAdvantageRows, strategicImperativeId]
  );

  const relevantDefinitions =
    uniq(
      competitiveAdvantageRows?.flatMap((rows) => {
        return rows.strategicPossibility?.patientFlowBlock?.stars
          ?.flatMap((star) => star.stakeholderDefinitions)
          .filter((v) => !!v);
      })
    ).filter(
      (v): v is Pick<StakeholderDefinition, 'id' | 'title' | 'image'> => !!v
    ) || [];

  const {
    data: strategicQuestionsData,
    error: strategicQuestionsError,
    loading: strategicQuestionsLoading,
    startPolling: startStrategicQuestionsPolling,
    stopPolling: stopStrategicQuestionsPolling,
  } = useStrategicQuestionsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        field: 'hurdles\ngaps',
        competitiveAdvantageRowId: +activeStrategicImperativeId,
        region: selectedRegion,
      },
    },
    fetchPolicy: 'network-only',
  });

  // Given I am viewing a country’s medical objectives, when I open the sidebar, then also show the hurdles/gaps for that country
  // When viewing a country's medical objectives, we also show global hurdles and gaps
  const {
    data: globalStrategicQuestionsData,
    error: globalStrategicQuestionsError,
    loading: globalStrategicQuestionsLoading,
    startPolling: globalStartStrategicQuestionsPolling,
    stopPolling: globalStopStrategicQuestionsPolling,
  } = useStrategicQuestionsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        field: 'hurdles\ngaps',
        competitiveAdvantageRowId: +activeStrategicImperativeId,
        region: 'global',
      },
    },
    fetchPolicy: 'network-only',
  });

  const showStrategicQuestions =
    activeSubStep === MedicalStrategyTabNames.HURDLES_AND_GAPS;

  const strategicQuestion =
    strategicQuestionsData?.strategicQuestions?.items?.[0];

  // Hide sidebar on navigating to summary page
  useEffect(() => {
    if (isSummaryPage && sidebarOpen) {
      setSidebarOpen(false);
    }
  }, [isSummaryPage, sidebarOpen]);

  const keyStatementsQueryVars = {
    where: {
      strategyId: Number(strategyId),
      step: Step.MedicalStrategy,
      subStep: SubStep.MedicalObjectives,
      competitiveAdvantageRowId: +activeStrategicImperativeId,
      region:
        activeSubStep === MedicalStrategyTabNames.MEDICAL_OBJECTIVES
          ? selectedRegion
          : undefined,
    },
  };

  const {
    data: keyStatementsData,
    error: keyStatementsError,
    loading: keyStatementsLoading,
    startPolling: startKeyStatementsPolling,
    stopPolling: stopKeyStatementsPolling,
  } = useKeyStatementsQuery({
    skip:
      (activeSubStep !== MedicalStrategyTabNames.MEDICAL_TACTICS &&
        activeSubStep !== MedicalStrategyTabNames.MEDICAL_OBJECTIVES) ||
      !+activeStrategicImperativeId,
    fetchPolicy: 'cache-and-network',
    variables: keyStatementsQueryVars,
  });

  const keyStatements = useMemo(
    () =>
      keyStatementsData?.keyStatements?.items.filter((keyStatement) => {
        return (
          keyStatement.competitiveAdvantageRowId ===
          +activeStrategicImperativeId
        );
      }) || [],
    [activeStrategicImperativeId, keyStatementsData?.keyStatements?.items]
  );

  const [upsertKeyStatement] = useKeyStatementUpsertMutation();
  const [updateKeyStatement] = useKeyStatementUpdateMutation();
  const [deleteKeyStatement] = useKeyStatementDeleteMutation();

  const tacticsQueryVars = {
    where: {
      strategyId: +strategyId,
      competitiveAdvantageRowId: isSummaryPage
        ? undefined
        : +activeStrategicImperativeId || undefined,
    },
    orderBy: {
      id: Sort.Asc,
    },
  };

  const skipTacticsFetch = !(isMedicalTacticsPage || isSummaryPage);

  const {
    data: tacticsData,
    error: tacticsError,
    loading: tacticsLoading,
    startPolling: startTacticsPolling,
    stopPolling: stopTacticsPolling,
    refetch: refetchTactics,
  } = useTacticsQuery({
    skip: !+activeStrategicImperativeId && skipTacticsFetch,
    variables: {
      ...tacticsQueryVars,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const {
    tactics: picoTactics,
    isLoading: picoTacticsLoading,
  } = useFetchPicoTactics(+strategyId);

  function orderByImperative(
    tactics: TacticFragment[] | undefined,
    rows: CompetitiveAdvantageRowFragment[] | undefined
  ) {
    if (!tactics || !rows) return [];

    const competitiveAdvantageRowOrder = rows.map((r) => r.id);
    const imperativeIdGroups = new Map<number, TacticFragment[]>();
    // A JavaScript Map preserves the keys in the order of insertion, whereas an Object orders the keys. Hence, I use a Map here
    competitiveAdvantageRowOrder.forEach((id) =>
      imperativeIdGroups.set(id, [])
    );
    tactics.forEach((t) => {
      const rowId = t.competitiveAdvantageRowId;
      const existingTacticsForId = imperativeIdGroups.get(rowId);
      if (!!existingTacticsForId) {
        imperativeIdGroups.set(rowId, [...existingTacticsForId, t]);
      }
    });

    const tacticsOrderedByImperative = Array.from(
      imperativeIdGroups.values()
    ).flat();

    return tacticsOrderedByImperative;
  }

  const validTactics = useMemo(
    () => {
      const filteredTactics = tacticsData?.tactics?.items?.filter(
        (val) => !!val.keyStatementId
      );

      return [...picoTactics, ...(filteredTactics || [])];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tacticsData?.tactics?.items, tacticsLoading, picoTacticsLoading]
  );

  const tactics = orderByImperative(validTactics, competitiveAdvantageRows);

  const tacticsForTimeline = useMemo(
    () => tactics?.filter((t) => t?.focused && t?.region === selectedRegion),
    [selectedRegion, tactics]
  );

  const tacticsForFinancials = tactics;

  async function toggleEnableCountryTactics() {
    if (countryTacticsEnabled) {
      // We disable country tactics
      // Warn user if some countries have added tactics
      const countryTacticsExist = strategyTacticsData?.tactics?.items.some(
        (t) => !!t.parentTacticId
      );

      if (countryTacticsExist) {
        setCountryTacticsWarningModal(true);
      } else {
        setCountryTacticsEnabled(false);

        try {
          await updateStrategy({
            variables: {
              id: +strategyId,
              data: {
                enableCountryTactics: false,
              },
            },
          });
        } catch (err) {
          alert('Something went wrong');
          setCountryTacticsEnabled(true);
        }
      }
    } else {
      // We enable country tactics
      setCountryTacticsEnabled(true);

      try {
        await updateStrategy({
          variables: {
            id: +strategyId,
            data: {
              enableCountryTactics: true,
            },
          },
        });
      } catch (err) {
        alert('Something went wrong');
        setCountryTacticsEnabled(false);
      }
    }
  }

  const {
    isLoading: picoSuggestionsIsLoading,
    picoSuggestions,
  } = useFetchPicoSuggestionWithTactics(+activeStrategicImperativeId, true);

  useEffect(() => {
    // Default url
    if (
      !subStep ||
      (!activeStrategicImperativeId && !!competitiveAdvantageRows?.length)
    ) {
      const strategicImperativeId = competitiveAdvantageRows?.length
        ? competitiveAdvantageRows[0].id
        : undefined;

      const region =
        competitiveAdvantageRows?.length &&
        activeSubStep === MedicalStrategyTabNames.HURDLES_AND_GAPS
          ? kebabCase(
              countryTacticsEnabled ||
                isHurdlesAndGapsPage ||
                isMedicalObjectivesPage
                ? selectedRegion
                : 'global'
            )
          : undefined;

      const generatedPath = generatePath(path, {
        drugId,
        strategyId,
        subStep: activeSubStep,
        strategicImperativeId,
        region,
      });

      history.replace(generatedPath);
    }
  }, [
    activeStrategicImperativeId,
    activeSubStep,
    competitiveAdvantageRows,
    countryTacticsEnabled,
    drugId,
    history,
    isHurdlesAndGapsPage,
    isMedicalObjectivesPage,
    path,
    selectedRegion,
    strategyId,
    subStep,
  ]);

  useEffect(() => {
    // When the countryTactics are disabled, the url should always be 'global'
    if (!countryTacticsEnabled && selectedRegion !== 'global') {
      const generatedPath = generatePath(path, {
        drugId,
        strategyId,
        subStep: activeSubStep,
        strategicImperativeId,
        region: 'global',
      });

      history.replace(generatedPath);
    }
  }, [
    activeSubStep,
    countryTacticsEnabled,
    drugId,
    history,
    path,
    selectedRegion,
    strategicImperativeId,
    strategyId,
  ]);

  // User's choices update the URL, then update state based on the URL
  useEffect(() => {
    if (medicalStrategy) {
      setActiveSubStep(
        kebabCase(medicalStrategy) 
      );
    } else {
      //respond to changes in url
      setActiveSubStep(
        kebabCase(subStep) || MedicalStrategyTabNames.HURDLES_AND_GAPS
      );
    }

    if (!!strategicImperativeId && !isNaN(parseInt(strategicImperativeId))) {
      setActiveStrategicImperativeId(+strategicImperativeId);
    }

    setSelectedRegion(
      countryTacticsEnabled || isHurdlesAndGapsPage || isMedicalObjectivesPage
        ? (region?.replaceAll('-', ' ') as CountryGlobal) || 'global'
        : 'global'
    );
  }, [
    medicalStrategy,
    subStep,
    strategicImperativeId,
    isSummaryPage,
    region,
    countryTacticsEnabled,
    isMedicalTacticsPage,
    isHurdlesAndGapsPage,
    isMedicalObjectivesPage,
  ]);

  const [updateStrategicQuestion] = useStrategicQuestionUpdateMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: StrategicQuestionsDocument,
        variables: {
          where: {
            strategyId: +strategyId,
            field:
              activeSubStep === MedicalStrategyTabNames.HURDLES_AND_GAPS
                ? 'hurdles\ngaps'
                : activeSubStep === MedicalStrategyTabNames.MEDICAL_OBJECTIVES
                ? 'medicalObjectives'
                : '',
            competitiveAdvantageRowId: +activeStrategicImperativeId,
          },
        },
      },
    ],
  });

  useEffect(() => {
    startStrategyPolling(polling.default);
    startCompetitiveAdvantageRowsPolling(polling.default);
    startStrategicQuestionsPolling(polling.default);
    startKeyStatementsPolling(polling.default);
    startTacticsPolling(polling.default);
    globalStartStrategicQuestionsPolling(polling.default);
    strategyTacticsStartPolling(polling.default);
    return () => {
      stopStrategyPolling();
      stopCompetitiveAdvantageRowsPolling();
      stopStrategicQuestionsPolling();
      stopKeyStatementsPolling();
      stopTacticsPolling();
      globalStopStrategicQuestionsPolling();
      strategyTacticsStopPolling();
    };
  }, [
    startCompetitiveAdvantageRowsPolling,
    startKeyStatementsPolling,
    startStrategicQuestionsPolling,
    startStrategyPolling,
    startTacticsPolling,
    stopCompetitiveAdvantageRowsPolling,
    stopKeyStatementsPolling,
    stopStrategicQuestionsPolling,
    stopStrategyPolling,
    stopTacticsPolling,
    globalStartStrategicQuestionsPolling,
    globalStopStrategicQuestionsPolling,
    strategyTacticsStartPolling,
    strategyTacticsStopPolling,
  ]);

  const unlockingInsight = [
    MedicalStrategyTabNames.MEDICAL_TACTICS,
    MedicalStrategyTabNames.MEDICAL_OBJECTIVES,
  ].includes(activeSubStep)
    ? activeImperative?.unlockingInsight?.text || ''
    : undefined;

  const globalStrategicQuestion =
    globalStrategicQuestionsData?.strategicQuestions?.items?.[0];

  const {
    globalHurdlesAndGaps,
    hurdlesAndGapsSidebarData,
    localHurdlesAndGaps,
  } = useGetImperativeSidebarData({
    activeSubStep,
    globalStrategicQuestion,
    strategicQuestion,
    unlockingInsight: activeImperative?.unlockingInsight?.text || '',
  });

  // refetch tactics when we change step
  useEffect(() => {
    refetchTactics();
  }, [activeSubStep, refetchTactics]);

  const uniqueRegions = getUniqueRegions(strategy?.users || [], false);
  const regionsExcludingGlobal = uniqueRegions.slice().sort();

  const handleSave = useCallback(
    async (val: any) => {
      if (!val) return;
      if (!strategicQuestion?.id) return;

      const { id } = createToast('', 'Saving');

      await updateStrategicQuestion({
        variables: {
          id: strategicQuestion?.id,
          data: {
            ...val,
          },
        },
      });

      removeToast(id);
    },
    [createToast, removeToast, strategicQuestion?.id, updateStrategicQuestion]
  );

  const showKeyStatement =
    activeSubStep === MedicalStrategyTabNames.MEDICAL_OBJECTIVES &&
    !competitiveAdvantageRowsLoading &&
    !!competitiveAdvantageRows?.length &&
    !!keyStatements;

  const showSpinOffsToggle =
    countryTacticsEnabled &&
    isLead &&
    !isDesktop &&
    activeSubStep === MedicalStrategyTabNames.MEDICAL_TACTICS;

  const showKeyEvents =
    activeSubStep === MedicalStrategyTabNames.MEDICAL_TACTICS;

  const showKeyEventsSidebar =
    activeSubStep === MedicalStrategyTabNames.MEDICAL_TACTICS ||
    activeSubStep === SubStep.Summary;

  const showContributorCountryPlan =
    (isCountryContributor || isGlobalContributor) && countryTacticsEnabled;

  const handleGlobalPlanDisableCountryTactics = async () => {
    setCountryTacticsWarningModal(false);
    setCountryTacticsEnabled(false);
    try {
      await updateStrategy({
        variables: {
          id: +strategyId,
          data: {
            enableCountryTactics: false,
          },
        },
      });
    } catch (err) {
      alert('Something went wrong');
      setCountryTacticsEnabled(true);
    }
  };

  return (
    <>
      <StyledTwoButtonModal
        modalOpen={countryTacticsWarningModal}
        handleClose={() => setCountryTacticsWarningModal(false)}
        title="Disabling country plans will hide content"
        body="Contributors have added country-specific tactics. These will be hidden for all users if you disable country tactics."
        rightButtonText="Disable country tactics"
        leftButtonOnClick={() => setCountryTacticsWarningModal(false)}
        rightButtonOnClick={handleGlobalPlanDisableCountryTactics}
      />
      <StrategicSummary />
      <Navbar
        stepNumber="4.1"
        title={themes.activate.tools['4.1'].name}
        prev={{
          url: URLHelper.getCriticalMetricsLeadAndLagIndicatorsUrl(
            drugId,
            strategyId
          ),
          title: themes.develop.tools['3.5'].name
        }}
        next={{
          title: themes.activate.tools['4.2'].name,
          url: `/d/${drugId}/strategy/${strategyId}/4_2`,
        }}
        pageNavChildren={
          <>
            {showContributorCountryPlan && !isDesktop && (
              <ContributorCountryPlansStatus />
            )}
          </>
        }
      >
        <TabsAndButton showGlobalPlanButton={isLead}>
          {isLead && (
            <GlobalPlanButton
              onClick={toggleEnableCountryTactics}
              complete={countryTacticsEnabled}
              messaging={['Enable country plans']}
            />
          )}
          {showContributorCountryPlan && !!isDesktop && (
            <ContributorCountryPlansStatus />
          )}

          <StyledTabGroupWrapper>
            <TabGroup componentName="StepTab">
              <StepBarsWrapper>
                {MEDICAL_STRATEGY_TABS.map((v) => {
                  return (
                    <StepTab
                      key={v.substep}
                      active={v.substep === subStep}
                      text={v.title}
                      onClick={() => {
                        history.push(
                          `/d/${drugId}/strategy/${strategyId}/4_1/${kebabCase(
                            v.substep
                          )}${
                            activeStrategicImperativeId
                              ? '/' +
                                activeStrategicImperativeId +
                                '/' +
                                kebabCase(selectedRegion)
                              : ''
                          }`
                        );

                        setMedicalStrategy(kebabCase(v.substep))
                      }}
                    />
                  );
                })}
              </StepBarsWrapper>
            </TabGroup>
          </StyledTabGroupWrapper>
        </TabsAndButton>
      </Navbar>

      <SidebarMobileWrapper totalNavHeight={totalNavHeight}>
        {activeSubStep !== SubStep.Summary && (
          <MedicalStrategyStepHeader
            countryTacticsEnabled={countryTacticsEnabled}
            subStep={subStep}
            showKeyEvents={showKeyEvents}
            isMobile={isMobile}
            setKeyEventsSidebarOpen={setKeyEventsSidebarOpen}
            competitiveAdvantageRows={competitiveAdvantageRows}
            activeStrategicImperativeId={activeStrategicImperativeId}
            history={history}
            drugId={drugId}
            strategyId={strategyId}
            selectedRegion={selectedRegion}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            activeSubStep={activeSubStep}
            showStrategicQuestions={showStrategicQuestions}
            strategicQuestionsLoading={strategicQuestionsLoading}
            countryDropdownActive={countryDropdownActive}
            setCountryDropdownActive={setCountryDropdownActive}
            countryDropDownRef={countryDropDownRef}
            isSummaryPage={isSummaryPage}
            regionsExcludingGlobal={regionsExcludingGlobal}
            strategicQuestion={strategicQuestion}
            isDesktop={isDesktop}
            viewingOwnRegion={viewingOwnRegion}
            totalUsers={strategyData?.strategy?.users.length}
            handleSave={handleSave}
            relevantDefinitions={relevantDefinitions}
          />
        )}

        <SidebarDesktopWrapper
          ref={sidebarDesktopRef}
          sidebarOpen={sidebarOpen}
          isRelative={desktopWrapperTop > totalNavHeight}
          totalNavHeight={topOffset}
        >
          <ErrorWrapper
            isLoading={
              picoSuggestionsIsLoading ||
              competitiveAdvantageRowsLoading ||
              strategicQuestionsLoading ||
              globalStrategicQuestionsLoading ||
              keyStatementsLoading ||
              tacticsLoading ||
              strategyTacticsLoading
            }
            errors={[
              strategyError,
              competitiveAdvantageRowsError,
              strategicQuestionsError,
              keyStatementsError,
              tacticsError,
              globalStrategicQuestionsError,
              strategyTacticsError,
            ]}
            dataMissing={
              !competitiveAdvantageRowsData || !strategicQuestionsData
            }
          >
            {showKeyStatement && (
              <KeyMedicalObjectives
                countryTacticsEnabled={countryTacticsEnabled}
                viewingOwnRegion={viewingOwnRegion}
                selectedRegion={selectedRegion}
                countryDropdownActive={countryDropdownActive}
                setCountryDropdownActive={setCountryDropdownActive}
                countryDropDownRef={countryDropDownRef}
                drugId={drugId}
                strategyId={strategyId}
                activeSubStep={activeSubStep}
                summaryPage={isSummaryPage}
                competitiveAdvantageRows={competitiveAdvantageRows}
                activeStrategicImperativeId={activeStrategicImperativeId}
                history={history}
                regionsExcludingGlobal={regionsExcludingGlobal}
                subStep={subStep}
                isLead={isLead}
                user={user}
                upsertKeyStatement={upsertKeyStatement}
                keyStatementsQueryVars={keyStatementsQueryVars}
                deleteKeyStatement={deleteKeyStatement}
                updateKeyStatement={updateKeyStatement}
                keyStatements={keyStatements}
              />
            )}
            <StyledPageWrapper
              paddingTop={false}
              fullWidthMobile
              totalNavHeight={totalNavHeight}
            >
              <ImperativeSummarySidebar
                activeImperative={activeImperative}
                activeImperativeId={activeImperative?.id}
                sidebarOpen={sidebarOpen && !!strategicImperativeId}
                setSidebarOpen={setSidebarOpen}
                desktopWrapperTop={desktopWrapperTop}
                totalNavHeight={totalNavHeight}
                {...hurdlesAndGapsSidebarData}
                unlockingInsight={unlockingInsight}
                globalHurdlesAndGaps={globalHurdlesAndGaps}
                localHurdlesAndGaps={localHurdlesAndGaps} // Only used when a country is selected
                selectedRegion={selectedRegion}
              />

              {showKeyEventsSidebar && (
                <KeyEventsSidebar
                  isOpen={keyEventsSidebarOpen}
                  onClose={() => setKeyEventsSidebarOpen(false)}
                  strategyId={Number(strategyId)}
                  region={lowerCase(region)}
                  step={Step.MedicalStrategy}
                  type={Step.MedicalStrategy}
                  isGlobalContributor={isGlobalContributor}
                  canEnable={
                    (isLead || isCountryContributor) &&
                    activeSubStep === SubStep.Summary &&
                    lowerCase(region) ===
                      (isLead ? 'global' : lowerCase(user?.country || ''))
                  }
                />
              )}

              <StyledMainContent
                sidebarOpen={
                  sidebarOpen && subStep !== MedicalStrategyTabNames.SUMMARY
                }
              >
                {showSpinOffsToggle && (
                  <SpinOffsToggle
                    disabled={false}
                    value={showCountrySpinOffs}
                    onChange={() =>
                      setShowCountrySpinOffs(!showCountrySpinOffs)
                    }
                    label="Show country tactics"
                  />
                )}

                {activeSubStep === SubStep.Summary ? (
                  <MedicalSummary
                    isLeadOrIsCountryContributor={
                      isLead || isCountryContributor
                    }
                    currency={strategyData?.strategy?.currency || '¥ CNY'}
                    tacticsForTimeline={tacticsForTimeline}
                    tacticsForFinancials={tacticsForFinancials}
                    rows={competitiveAdvantageRows || []}
                    showCountryTimeline={selectedRegion !== 'global'}
                    isUserCountry={
                      isLead
                        ? selectedRegion === 'global'
                        : lowerCase(selectedRegion) ===
                          lowerCase(user?.country || '')
                    }
                    countryTacticsEnabled={countryTacticsEnabled}
                    timelineCountryDropdown={
                      <CountriesDropdownSmall
                        country={selectedRegion}
                        active={countryDropdownActive}
                        onClick={() =>
                          setCountryDropdownActive(!countryDropdownActive)
                        }
                        viewOnly={false}
                        hideCountryName={width < SMALLEST_MOBILE_WIDTH}
                        largeIcon
                        globalOptionText={'Global plan'}
                        dropDownRef={countryDropDownRef}
                        showDropdown={countryDropdownActive}
                        setShowDropdown={setCountryDropdownActive}
                        setCountry={(country) => {
                          let baseURL = `/d/${drugId}/strategy/${strategyId}/4_1/summary/${
                            strategicImperativeId || 0
                          }`;
                          baseURL += `/${kebabCase(country as CountryGlobal)}`;
                          history.replace(baseURL);
                        }}
                        allCountries={regionsExcludingGlobal}
                      />
                    }
                    financialCountryDropdown={
                      <FinancialSummaryCountryDropdown
                        multiSelect
                        selectedRegions={financialSummarySelectedRegions}
                        setCountry={(country: CountryGlobal) => {
                          if (
                            financialSummarySelectedRegions.includes(country)
                          ) {
                            setFinancialSummarySelectedRegions(
                              financialSummarySelectedRegions.filter(
                                (c) => c !== country
                              )
                            );
                          } else {
                            setFinancialSummarySelectedRegions([
                              ...financialSummarySelectedRegions,
                              country,
                            ]);
                          }
                        }}
                        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                          const target = e.target as HTMLDivElement;
                          const dropdown = financialCountryDropDownRef.current;
                          if (!dropdown) return;
                          if (dropdown.contains(target)) return;

                          setFinancialCountryDropdownActive(
                            !financialCountryDropdownActive
                          );
                        }}
                        dropDownRef={financialCountryDropDownRef}
                        viewOnly={false}
                        hideCountryName={isMobile}
                        largeIcon
                        globalOptionText={'Global plan'}
                        showDropdown={financialCountryDropdownActive}
                        setShowDropdown={setFinancialCountryDropdownActive}
                        allCountries={regionsExcludingGlobal}
                      />
                    }
                    financialSummarySelectedRegions={
                      financialSummarySelectedRegions
                    }
                    openKeyEventsSidebar={() => setKeyEventsSidebarOpen(true)}
                  />
                ) : !competitiveAdvantageRowsLoading &&
                  !competitiveAdvantageRows?.length ? (
                  <PostItsEmpty
                    style={{ margin: 20 }}
                    title="Nothing to discuss"
                  >
                    <BodySmall
                      color={colors.greyDark}
                      style={{ display: 'inline' }}
                    >
                      Leads must first choose {themes.develop.tools['3.3'].name} to focus on
                      in{' '}
                    </BodySmall>
                    <Link
                      to={`/d/${drugId}/strategy/${strategyId}/3_3`}
                      style={{
                        color: colors.greyDark,
                        display: 'inline',
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      3.3 {themes.develop.tools['3.3'].name}{' '}
                    </Link>
                  </PostItsEmpty>
                ) : activeSubStep ===
                  MedicalStrategyTabNames.HURDLES_AND_GAPS ? (
                  <HurdlesAndGapsSubstepView
                    area={area}
                    region={region}
                    drugId={+drugId}
                    strategyId={+strategyId}
                    competitiveAdvantageRowId={+activeStrategicImperativeId}
                  />
                ) : activeSubStep ===
                  MedicalStrategyTabNames.MEDICAL_OBJECTIVES ? (
                  <MedicalObjectives
                    drugId={+drugId}
                    strategyId={+strategyId}
                    competitiveAdvantageRowId={+activeStrategicImperativeId}
                  />
                ) : (
                  <MedicalTactics
                    sidebarOpen={sidebarOpen}
                    keyStatements={keyStatements}
                    picoSuggestions={picoSuggestions}
                    strategyId={strategyId}
                    drugId={drugId}
                    currency={strategyData?.strategy?.currency || '¥ CNY'}
                    competitiveAdvantageRowId={activeStrategicImperativeId}
                    allTactics={validTactics}
                    isDesktop={isDesktop}
                    tacticsQueryVars={tacticsQueryVars}
                    user={user}
                    showCountrySpinOffs={showCountrySpinOffs}
                    setShowCountrySpinOffs={setShowCountrySpinOffs}
                    countryTacticsEnabled={countryTacticsEnabled}
                  />
                )}
              </StyledMainContent>
            </StyledPageWrapper>
          </ErrorWrapper>
        </SidebarDesktopWrapper>
      </SidebarMobileWrapper>
    </>
  );
};
