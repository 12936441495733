import { kebabCase } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { BigIdeaPreview } from 'components/CommercialStrategy/BigIdea';
import { BigIdeaCreate } from 'components/CommercialStrategy/BigIdeaCreate';
import { BigIdeaSidebar } from 'components/CommercialStrategy/BigIdeaSidebar';
import { CommercialStrategyStepHeader } from 'components/CommercialStrategy/CommercialStrategyStepHeader';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { Page, Wrapper } from 'components/Page';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { BodySmall } from 'components/shared';
import { colors } from 'constants/colors';
import { polling, themes } from 'constants/index';
import {
  Sort,
  SubStep,
  useBigIdeaCreateMutation,
  useBigIdeasQuery,
  useCompetitiveAdvantageRowsQuery,
} from 'data/graphql/generated';
import { device } from 'utils/breakpoints';
import { CommercialStrategyNav } from '../../components/4-3-commercial-strategy/shared/ui/feature-navbar/CommercialStrategyNav';
import { useCommercialStrategyNav } from '../../hooks/CommercialStrategy/useCommercialStrategyNav';

const PageWrapper = styled(Page)`
  padding: 0px 0 60px;
  overflow: visible; // for the dropdown components

  margin-top: 15px;

  @media ${device.tabletMin} {
    margin-top: 15px;

    ${Wrapper} {
      width: auto;
      padding: 0 15px;
    }
  }

  @media ${device.desktopMin} {
    margin-top: 20px;

    ${Wrapper} {
      padding: 0 20px;
    }
  }
`;

const BigIdeasWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BIGIDEA_WIDTH_WITH_GAP = 354;

const BigIdeas = styled.div<{ amount: number; sidebarOpen: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
  gap: 15px;

  ${({ amount, sidebarOpen }) => {
    if (amount === 1) {
      return css`
        max-width: ${BIGIDEA_WIDTH_WITH_GAP * 1}px;
      `;
    }

    if (amount === 2 && sidebarOpen) {
      return css`
        max-width: ${BIGIDEA_WIDTH_WITH_GAP * 1}px;

        @media (min-width: 1293px) {
          max-width: ${BIGIDEA_WIDTH_WITH_GAP * 2}px;
        }
      `;
    }

    if (amount === 2) {
      return css`
        max-width: ${BIGIDEA_WIDTH_WITH_GAP * 1}px;

        @media (min-width: 730px) {
          max-width: ${BIGIDEA_WIDTH_WITH_GAP * 2}px;
        }
      `;
    }

    if (sidebarOpen) {
      return css`
        max-width: ${BIGIDEA_WIDTH_WITH_GAP * 1}px;

        @media (min-width: 1287px) {
          max-width: ${BIGIDEA_WIDTH_WITH_GAP * 2}px;
        }
      `;
    }

    return css`
      max-width: ${BIGIDEA_WIDTH_WITH_GAP * 1}px;

      @media (min-width: 730px) {
        max-width: ${BIGIDEA_WIDTH_WITH_GAP * 2}px;
      }

      @media (min-width: 1087px) {
        max-width: ${BIGIDEA_WIDTH_WITH_GAP * 3}px;
      }
    `;
  }}
`;

const Content = styled.div<{ sidebarActive: boolean }>`
  position: relative;
  width: calc(100% - ${({ sidebarActive }) => (sidebarActive ? 554 : 0)}px);
  transition: width 0.2s;
`;

const StyledPostItsEmpty = styled(PostItsEmpty)`
  @media ${device.tabletMin} {
    width: calc(100% - 30px);
    margin-right: auto;
    margin-left: auto;
  }

  @media ${device.mobile} {
    margin-top: 160px;
    padding-bottom: 88px;
    height: auto;
    width: calc(100% - 30px);
    margin-right: auto;
    margin-left: auto;
  }
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  strategicImperativeId: string;
}

export const CommercialStrategyBigIdeas = () => {
  const { drugId, strategyId, strategicImperativeId }: URLParams = useParams();
  const substep = SubStep.BigIdeas;
  const history = useHistory();

  const [showBigIdeaSidebar, setShowBigIdeaSidebar] = useState(false);
  const [bigIdeaActiveId, setBigIdeaActive] = useState<number | null>(null);
  const [activeSubStep] = useState(kebabCase(SubStep.BigIdeas));

  const {
    localCountryTacticsEnabled,
    setCountryTacticsEnabled,
    strategyLoading,
    strategyError,
  } = useCommercialStrategyNav({ strategyId: +strategyId });

  const {
    data: competitiveAdvantageRowsData,
    error: competitiveAdvantageRowsError,
    loading: competitiveAdvantageRowsLoading,
    startPolling: startCompetitiveAdvantageRowsPolling,
    stopPolling: stopCompetitiveAdvantageRowsPolling,
  } = useCompetitiveAdvantageRowsQuery({
    variables: {
      orderBy: {
        idx: Sort.Desc,
      },
      where: {
        strategyId: +strategyId,
        focus: true,
      },
      include: {
        strategicPossibility: true,
        successConditions: true,
        patientFlowBlockStarStakeholderDefinitions: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: bigIdeasData,
    error: bigIdeasError,
    loading: bigIdeasLoading,
    refetch: bigIdeasRefetch,
    startPolling: bigIdeasStartPolling,
    stopPolling: bigIdeasStopPolling,
  } = useBigIdeasQuery({
    variables: {
      orderBy: {
        createdAt: Sort.Desc,
      },
      where: {
        strategyId: +strategyId,
        competitiveAdvantageRowId: +strategicImperativeId,
      },
    },
  });

  useEffect(() => {
    startCompetitiveAdvantageRowsPolling(polling.default);
    bigIdeasStartPolling(polling.default);
    return () => {
      bigIdeasStopPolling();
      stopCompetitiveAdvantageRowsPolling();
    };
  }, [
    bigIdeasStartPolling,
    bigIdeasStopPolling,
    startCompetitiveAdvantageRowsPolling,
    stopCompetitiveAdvantageRowsPolling,
  ]);

  const [createBigIdea] = useBigIdeaCreateMutation();

  const competitiveAdvantageRows =
    competitiveAdvantageRowsData?.competitiveAdvantageRows?.items;

  useEffect(() => {
    // Default url
    if (!strategicImperativeId && !!competitiveAdvantageRows?.length) {
      history.replace(
        `/d/${drugId}/strategy/${strategyId}/4_3/${activeSubStep}${
          competitiveAdvantageRows?.length
            ? '/' + competitiveAdvantageRows[0].id
            : ''
        }`
      );
    }
  }, [
    drugId,
    history,
    strategyId,
    activeSubStep,
    competitiveAdvantageRowsData,
    competitiveAdvantageRows,
    strategicImperativeId,
  ]);

  async function handleBigIdeaCreate() {
    try {
      const res = await createBigIdea({
        variables: {
          data: {
            strategyId: +strategyId,
            competitiveAdvantageRowId: +strategicImperativeId,
            title: '',
            image: '',
            content: '',
          },
        },
      });
      setBigIdeaActive(res?.data?.bigIdeaCreate?.id || null);
      setShowBigIdeaSidebar(true);
      bigIdeasRefetch();
    } catch (err) {
      alert('Something went wrong');
      console.error(err);
    }
  }

  const activeBigIdea = bigIdeasData?.bigIdeas?.items.find(
    (bigIdea) => bigIdea.id === bigIdeaActiveId
  );

  const countryContributionsExist = useMemo(
    () =>
      bigIdeasData?.bigIdeas?.items?.some((idea) => {
        return idea.BigIdeaCountryFocus.length > 0;
      }),
    [bigIdeasData?.bigIdeas?.items]
  );

  return (
    <>
      <CommercialStrategyNav
        drugId={drugId}
        strategyId={strategyId}
        subStep={SubStep.BigIdeas}
        strategicImperativeId={+strategicImperativeId}
        countryTacticsEnabled={Boolean(localCountryTacticsEnabled)}
        setCountryTacticsEnabled={setCountryTacticsEnabled}
        countryContributionsExist={Boolean(countryContributionsExist)}
      />

      <Content sidebarActive={!!bigIdeaActiveId}>
        <CommercialStrategyStepHeader
          substep={substep}
          imperatives={competitiveAdvantageRows}
        />

        <ErrorWrapper
          isLoading={
            competitiveAdvantageRowsLoading ||
            bigIdeasLoading ||
            strategyLoading
          }
          errors={[competitiveAdvantageRowsError, bigIdeasError, strategyError]}
          dataMissing={false}
        >
          <PageWrapper paddingTop={false} fullWidthMobile>
            {competitiveAdvantageRows?.length === 0 ? (
              <StyledPostItsEmpty
                style={{ marginTop: 20 }}
                title="Nothing to discuss"
              >
                <BodySmall
                  color={colors.greyDark}
                  style={{ display: 'inline' }}
                >
                  Leads must first choose {themes.develop.tools['3.3'].name} to focus on in{' '}
                </BodySmall>
                <Link
                  to={`/d/${drugId}/strategy/${strategyId}/3_3`}
                  style={{
                    color: colors.greyDark,
                    display: 'inline',
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  3.3 {themes.develop.tools['3.3'].name}
                </Link>
              </StyledPostItsEmpty>
            ) : (
              <BigIdeasWrapper>
                <BigIdeas
                  amount={(bigIdeasData?.bigIdeas?.items.length || 0) + 1}
                  sidebarOpen={!!showBigIdeaSidebar}
                >
                  <BigIdeaCreate
                    onClick={handleBigIdeaCreate}
                    amount={(bigIdeasData?.bigIdeas?.items.length || 0) + 1}
                    sidebarOpen={!!showBigIdeaSidebar}
                  />
                  {bigIdeasData?.bigIdeas?.items.map((bigIdea) => (
                    <BigIdeaPreview
                      countryTacticsEnabled={Boolean(
                        localCountryTacticsEnabled
                      )}
                      active={bigIdeaActiveId === bigIdea.id}
                      onClick={() => {
                        setShowBigIdeaSidebar(true);
                        setBigIdeaActive(null);
                        // NOTE this setTimeout is needed since we need
                        // to rerender the editor for the value to be updated
                        setTimeout(() => {
                          setBigIdeaActive(bigIdea.id);
                        }, 0);
                      }}
                      bigIdea={bigIdea}
                      bigIdeasRefetch={bigIdeasRefetch}
                      key={bigIdea.id}
                    />
                  ))}
                </BigIdeas>
              </BigIdeasWrapper>
            )}
          </PageWrapper>
        </ErrorWrapper>

        <BigIdeaSidebar
          visible={!!showBigIdeaSidebar}
          bigIdea={activeBigIdea}
          handleClose={() => {
            setBigIdeaActive(null);
            setShowBigIdeaSidebar(false);
          }}
          strategyId={strategyId}
          bigIdeasRefetch={bigIdeasRefetch}
          countryTacticsEnabled={Boolean(localCountryTacticsEnabled)}
        />
      </Content>
    </>
  );
};
