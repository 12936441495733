import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Link, useLocation, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { colors, themes } from '../constants';
import { useAuthContext } from 'contexts/AuthContext';
import apolloClient from 'data/apollo';
import { ButtonRound, Icon, Subtitle2 } from './shared';
import buildStrategyURL from 'utils/buildStrategyURL';
import { zIndex } from 'constants/index';
import { ExcludedFeature } from 'types';
import { useDrugsQuery, useSignOutMutation, useDrugQuery, useStrategyQuery, useStakeholderDefinitionsQuery } from 'data/graphql/generated';
import getInitials from 'utils/getInitials';
import { NotificationBell } from './notifications/NotificationBell';
import { checkIfFullNmblr } from 'containers/Strategies';
import { PageNav } from 'components/PageNav';
import useMobile from 'hooks/useMobile';
import _ from 'lodash';
import { device } from 'utils/breakpoints';
import { TooltipCSS } from './shared/Tooltip';
import { useWidth } from 'hooks/useWidth';
import useTablet from 'hooks/useTablet';

const TermsAndPolicies = styled(Subtitle2)`
  // position: absolute;
  // bottom: 92px;
  // left: 30px;
  // display: flex;
  height: 40px;
  margin-top: 10px;
  color: ${colors.cream};
  padding-left: 15px;

  cursor: pointer;
  pointer-events: all;

  a {
    color: inherit;
  }
`;

const ProfileIConWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  height: 40px;
    padding-left: 15px;

  // position: absolute;
  // bottom: 145px;
  // left: 30px;
  cursor: pointer;
  pointer-events: all;
  &:hover {
    background: ${colors.purple10a}; 
  }
`;
const Mobile = styled.div<{ isAdmin: boolean }>`
  display: ${({ isAdmin }) => (isAdmin ? 'none' : 'block')};

  // @media screen and (min-width: 945px) {
  //   display: none;
  // }
`;

const Content = styled.div`
  background: ${colors.black};
  position: fixed;
  display: block;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  text-align: center;
  z-index: ${zIndex.nav};
`;

const Logo = styled.div`
  height: 50px;
  display: grid;
  place-content: center;
  position: absolute;
  left: 50%;
  width: 50px;
  transform: translateX(-50%);
  a {
    display: inline-block;
    vertical-align: top;
  }
`;

const SidebarTrigger = styled.div`
  display:flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  // display: grid;
  place-content: center;
  padding: 0px 10px;
  vertical-align: top;
  float: left;
  cursor: pointer;
`;

const TargetPopulation = styled.div<{ isLimit: boolean, show: boolean, hideArrows: boolean, overlayMaxWidth: number }>`
  max-width: ${({ overlayMaxWidth }) => (overlayMaxWidth)}px;

 ${Subtitle2} {
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: ${({ show }) => (show ? 0 : 1)};
    transition-delay: ${({ show }) => (!show ? '0s' : '0.5s')};
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }
  
  display: flex;
  align-items: center;
`;


const SideMenu = styled.nav<{ visible: boolean }>`
box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05), 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
border-radius: 5px;
justify-content: space-between;
  background: ${colors.black};
  position: fixed;
  top: 60px;
  // left: 0px;
  left: ${({ visible }) => (visible ? '10px' : '0px')};
  
  bottom: 10px;
  width: 350px;
  overflow: hidden;
  text-align: left;
  transform: translate(${({ visible }) => (visible ? 0 : '-100%')}, 0);
  transition: transform 0.5s;
  display: flex;
  flex-direction: column;
  z-index: ${zIndex.navSideMenu};

  @media ${device.mobile} {
    border-radius: 0;
    top: 50px;
    left: 0px;
    bottom: 0px;
    width: 100%;
  }

`;

const SideMenuBG = styled.div<{ visible: boolean }>`
  // background: ${colors.black};
  opacity: 0.95;
  position: fixed;
  top: 50px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: left;
  z-index: ${zIndex.navSideMenuBG};
  transform: translate(${({ visible }) => (visible ? 0 : '-100%')}, 0);
  transition: transform 0.3s;
  cursor: pointer;
`;

const SideMenuFooter = styled.div`
  // position: absolute;
  // bottom: 32px;
  // left: 20px;
  overflow-y: auto;
  // padding: 10px 1px;
  display: flex;
  // gap: 10px;
  flex-direction: column;
`;

const Signout = styled.div`
  padding-left: 15px;
  height: 40px;
  // position: absolute;
  // bottom: 32px;
  // left: 30px;
  // display: flex;
  // gap: 28px;
  // flex-direction: column;
  cursor: pointer;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
  padding: 15px 23px;
`;

const Section = styled.div`
  border-bottom: 1px solid ${colors.black};
  // padding: 10px 0;
`;

const SectionTitle = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  height: 40px;
  background: ${colors.black};
  cursor: pointer;
  color: ${colors.white};
 
  &:hover {
    background: ${colors.purple10a}; 
  }
`;

const Submenu = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  overflow: hidden;
  // transition: max-height 0.3s ease;
  background: ${colors.cream};
`;

const MenuItem = styled(Link) <{ active?: boolean }>`
  height: 40px;
  padding: 10px 15px;
  display: block;
  color: ${colors.black};
  background: ${({ active }) => (active ? '#FAF5E5' : '#FAF5E5')};
  position: relative;
  border-bottom: 1px solid #B9B9BE;

  // Add a vertical purple bar as an indicator for the active state
  ${({ active }) =>
    active &&
    `
    &::before {
      content: '';
      border-radius: 10px;
      position: absolute;
      left: 3px;
      height: 30px;
      top: 5px;
      bottom: 5px;
      width: 5px;
      background-color: ${colors.purple};
    }
  `}

  &:hover {
    &::before {
      content: '';
      border-radius: 10px;
      position: absolute;
      left: 3px;
      height: 30px;
      top: 4px;
      bottom: 3px;
      width: 5px;
      background-color: ${colors.purple};
    }
  }
`;

const CurrentPageWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const MenuLink = styled(Link) <{ active?: boolean }>`
  background: ${({ active }) => (active ? '#7800FF' : '')};
  height: 40px;
  padding-left: 15px;
  gap: 20px;

  display: flex;
  align-items: center;

  ${({ active }) => !active && `
    &:hover {
      background: ${colors.purple10a}; 
    }
  `};
`;

const Logo2 = styled.div`
  float: left;
  height: 50px;
  width: 60px;
  display: inline-block;
  overflow: hidden;

  a {
    width: 60px;
    height: 100%;
    display: grid;
    vertical-align: top;
    place-content: center;
  }
`;

interface Props {
  title?: string;
  url?: string;
  prev: { url: string; title: string };
  next: { url: string; title: string };
  hideArrows: boolean;
  drugId: string;
  strategyId: string;
  navMenuChildren?: React.ReactNode | null;
  exclude?: ExcludedFeature[];
  showNotifications(): void;
  notificationsVisible: boolean;
  notificationsUnread: number;
  showAccountModal(): void;
  stepNumber?: string;
  mobileNavRef?: React.RefObject<HTMLDivElement>;
}

export const NavbarMobile: React.FC<Props> = ({
  title,
  drugId,
  prev,
  next,
  url,
  hideArrows,
  strategyId,
  exclude,
  showNotifications,
  notificationsVisible,
  notificationsUnread,
  showAccountModal,
  stepNumber,
  mobileNavRef,
}) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const width = useWidth();
  const [auth, setAuth] = useAuthContext();
  const [signOut] = useSignOutMutation();
  const [isNavHovered, setIsNavHovered] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const strategyHome = buildStrategyURL(drugId, strategyId, '');
  const peoplePage = buildStrategyURL(drugId, strategyId, 'people');
  const welcomeHome = buildStrategyURL(drugId, strategyId, 'welcome');
  // const filesPage = buildStrategyURL(drugId, strategyId, 'files');
  const onHomePage = !!pathname.match(/^\/$/g);
  const isLandingPage = !!pathname.match(/^\/$/g);
  const isAdminPage = !!pathname.match(/^\/admin(\/.*)?$/g) || pathname.includes('/products');
  const isWelcomePage = !!pathname.match(/^\/d\/\d+\/strategy\/\d+\/welcome(\/)?$/);
  const isCreatePeriod = !!pathname.match(/^\/d\/\d+\/strategy\/\d+\/create(\/)?$/);
  const isCollaboratorPage = !!pathname.match(/^\/d\/\d+\/strategy\/\d+\/people(\/)?$/);
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({
    insights: false, // Example of default open section
    possibilities: false,
    strategy: false,
    success: false
  });

  const toggleSection = (section: string) => {
    setOpenSections((prev) => ({
      insights: section === 'insights' ? !prev.insights : false,
      possibilities: section === 'possibilities' ? !prev.possibilities : false,
      strategy: section === 'strategy' ? !prev.strategy : false,
      success: section === 'success' ? !prev.success : false,
    }));
  };

  const initials = getInitials(auth?.user?.name);

  const {
    data: drug,
  } = useDrugQuery({
    variables: { id: Number(drugId) },
  });

  const {
    data,
  } = useStrategyQuery({
    variables: { id: Number(strategyId) },
  });

  const { data: drugData } = useDrugsQuery({
    variables: {
      where: {
        id: Number(drugId),
      },
    },
    fetchPolicy: 'network-only',
  });

  const possibleDrugs = drugData?.drugs?.items;
  const ProductOnDrugs = drugData?.drugs?.items?.filter(
    (e) => e.id === parseInt(drugId)
  )[0]?.ProductOnDrugs;

  const miniFeatureSet = useMemo(() => {
    if (possibleDrugs) {
      return possibleDrugs.filter(i => i.id === Number(drugId)).flatMap((p: any) =>
        p.ProductOnDrugs?.flatMap((f: any) =>
          f.Product?.features?.map((g: any) => g.featureSet)
        )
      );
    }
    return [];
  }, [drugId, possibleDrugs]);

  const isFullNmblr = checkIfFullNmblr(miniFeatureSet);
  const overlayMaxWidth = hideArrows ? Math.floor(width / 2 - 100) : Math.floor(width / 2 - 220);

  useEffect(() => {
    // Check if the pathname contains 1_1 to 1_5 for insights section
    if (pathname.includes('1_1') 
      || pathname.includes('1_2') 
      || pathname.includes('1_3') 
      || pathname.includes('1_4') 
      || pathname.includes('1_5')
    ) {
      setOpenSections((prev) => ({ ...prev, insights: true }));
    }

    // Check if the pathname contains 2_1 to 2_5 for possibilities section
    if (pathname.includes('2_1')
      || pathname.includes('2_2')
      || pathname.includes('2_3')
      || pathname.includes('2_4')
      || pathname.includes('2_5')
    ) {
      setOpenSections((prev) => ({ ...prev, possibilities: true }));
    }

    // Check if the pathname contains 3_1 to 3_5 for strategy section
    if (pathname.includes('3_1')
      || pathname.includes('3_2')
      || pathname.includes('3_3')
      || pathname.includes('3_4')
      || pathname.includes('3_5')
    ) {
      setOpenSections((prev) => ({ ...prev, strategy: true }));
    }

    // Check if the pathname contains 4_1 to 4_5 for success section
    if (pathname.includes('4_1') 
      || pathname.includes('4_2') 
      || pathname.includes('4_3') 
      || pathname.includes('4_4') 
      || pathname.includes('4_5')
    ) {
      setOpenSections((prev) => ({ ...prev, success: true }));
    }
  }, [pathname]);

  function buildURL(page: string) {
    return `/d/${drugId}/strategy/${strategyId}/${page}`;
  }

  const isMenuItemActive = (pathname: string, targetUrl: string): boolean => {
    return pathname.includes(targetUrl);
  };

  const drugName = drug?.drug?.name;

  const {
    data: stakeholderDefinitions,
  } = useStakeholderDefinitionsQuery({
    fetchPolicy: 'network-only',
    variables: { where: { id: data?.strategy?.stakeholderDefinitionId, strategyId: +strategyId } },
  });

  const targetPopulation = stakeholderDefinitions?.stakeholderDefinitions?.items?.filter(
    (e) => e.id === data?.strategy?.stakeholderDefinitionId
  )[0]?.title || null;

  const ref = React.useRef<HTMLDivElement | null>(null);
  const hasOverflow = ref.current ? ref.current.scrollWidth > ref.current.clientWidth : false;

  const titleNew = data?.strategy && drugName
    ? drugName +
    (data?.strategy?.startYear && data?.strategy?.endYear
      ? ' • ' + data.strategy.startYear + ' - ' + data.strategy.endYear
      : '')
    : '';

  const combinedTitle = titleNew + ' • ' + targetPopulation;

  const isPatternMatch = (pathname: string) => {
    const regex = /^\/d\/\d+\/strategy\/\d+(\/)?$/;
    return regex.test(pathname);
  };

  const getFeatureSetSubtitle = (featureSet: string) => {
    switch (featureSet) {
      case '1.1':
        return themes.discover.tools['1.1'].name;
      case '1.2':
        return themes.discover.tools['1.2'].name;
      case '1.3':
        return themes.discover.tools['1.3'].name;
      case '1.4':
        return themes.discover.tools['1.4'].name;
      case '1.5':
        return themes.discover.tools['1.5'].name;
      case '2.1':
        return themes.explore.tools['2.1'].name;
      case '2.2':
        return themes.explore.tools['2.2'].name;
      case '2.3':
        return themes.explore.tools['2.3'].name;
      case '2.4':
        return themes.explore.tools['2.4'].name;
      case '2.5':
        return themes.explore.tools['2.5'].name;
      case '3.1':
        return themes.develop.tools['3.1'].name;
      case '3.2':
        return themes.develop.tools['3.2'].name;
      case '3.3':
        return themes.develop.tools['3.3'].name;
      case '3.4':
        return themes.develop.tools['3.4'].name;
      case '3.5':
        return themes.develop.tools['3.5'].name;
      case '4.1':
        return themes.activate.tools['4.1'].name;
      case '4.2':
        return themes.activate.tools['4.2'].name;
      case '4.3':
        return themes.activate.tools['4.3'].name;
      case '4.4':
        return themes.activate.tools['4.4'].name;
      case '4.5':
        return themes.activate.tools['4.5'].name;

      default:
        return '';
    }
  };

  return (
    <Mobile isAdmin={isAdminPage}>
      <Content ref={mobileNavRef} className="main__mobileNav">
        {!exclude?.includes('sidebarTrigger') && (
          <SidebarTrigger onClick={() => setDropdownVisible(!dropdownVisible)}>
            {isMobile && dropdownVisible ?
              <>
                <Icon name="Close" color={colors.cream} size={30} />
              </>
              :
              <>
                <Icon name="Menu" color={colors.cream} size={30} />
                {(!isMobile && !isTablet) &&
                  <>
                    {targetPopulation && (
                      <>
                        <TooltipCSS
                          text={combinedTitle}
                          style={{
                            backgroundColor: colors.black,
                            color: colors.white,
                            cursor: 'pointer',
                            pointerEvents: hasOverflow ? 'auto' : 'none',
                          }}
                          tooltipStyle={{
                            fontSize: 18,
                            position: 'fixed',
                            top: 11,
                            textAlign: 'left',
                            left: 35,
                            lineHeight: 1,
                            maxWidth: overlayMaxWidth,
                            transitionDelay: isNavHovered ? '0.5s' : '0s',
                            transition: 'opacity 0s step-start 0.5s, visibility 0s step-start 0.5s',
                            opacity: isNavHovered ? 1 : 0,
                            visibility: isNavHovered ? 'visible' : 'hidden',
                            display: 'flex',
                            width: '100%',
                          }}
                          direction="bottom"
                        >
                          <TargetPopulation
                            overlayMaxWidth={overlayMaxWidth}
                            isLimit={hasOverflow}
                            hideArrows={hideArrows}
                            show={isNavHovered}
                            onMouseEnter={() => { setIsNavHovered(true) }}
                            onMouseLeave={() => { setIsNavHovered(false) }}
                          >
                            <Subtitle2 color={colors.white} ref={ref}>
                              {combinedTitle}
                            </Subtitle2>
                          </TargetPopulation>
                        </TooltipCSS>
                      </>
                    )}
                  </>
                }
              </>
            }

          </SidebarTrigger>
        )}
        {(isMenuItemActive(pathname, 'terms/terms-of-service')
          || isMenuItemActive(pathname, '/terms/privacy-policy')) &&
          <Logo2>
            <Link to="/">
              <Icon name="Home" color={colors.cream} size={30} />
            </Link>
          </Logo2>
        }
        {(onHomePage || isPatternMatch(pathname) || isWelcomePage) || isTablet || isMobile ? (
          <Logo>
            <Link to="/">
              <Icon name="FullLogoWhite" color={colors.cream} size={130} />
            </Link>
          </Logo>
        ) : (
          <>
            {!!title && !isLandingPage ? (
              <CurrentPageWrapper>
                <PageNav
                  hideArrows={hideArrows}
                  prev={prev}
                  next={next}
                  title={title}
                  url={url}
                  stepNumber={stepNumber}
                />
              </CurrentPageWrapper>
            ) : null}
          </>
        )
        }

        {!exclude?.includes('notifications') && (
          <div style={{ marginRight: 10 }}>
            <NotificationBell
              onClick={showNotifications}
              visible={notificationsVisible}
              notificationsUnread={notificationsUnread}
            />
          </div>
        )}
      </Content>
      <>
        <SideMenu visible={dropdownVisible}>
          <div>
            {(!isLandingPage || !isCreatePeriod) &&
              <>
                {!isFullNmblr && ProductOnDrugs &&
                  ProductOnDrugs.map((drug) => (
                    <Section key={drug?.drugId}>
                      <SectionTitle
                        isOpen={openSections.insights}
                        onClick={() => toggleSection('insights')}
                      >
                        <Icon 
                          name={
                            openSections.insights 
                            ? "Chevron-down-full" 
                            : "Chevron-right-full"
                          } 
                          size={40} 
                          color={colors.white} 
                        />
                        <span>{drug?.Product?.productName}</span>
                      </SectionTitle>
                      <Submenu isOpen={openSections.insights}>
                        {_.sortBy(drug.Product?.features, ['featureSet']).map(
                          (d: any) => (
                            <MenuItem 
                              key={d.featureSet}
                              to={buildURL(d.featureSet.replace('.', '_'))} 
                              active={isMenuItemActive(
                                pathname, 
                                `/${d.featureSet.replace('.', '_')}`
                              )}
                            >
                              {getFeatureSetSubtitle(d.featureSet)}
                            </MenuItem>
                          )
                        )}
                      </Submenu>
                    </Section>
                  ))}

                {isFullNmblr &&
                  <>
                    <Section>
                      <SectionTitle
                        isOpen={openSections.insights}
                        onClick={() => toggleSection('insights')}
                      >
                        <Icon 
                          name={
                            openSections.insights 
                            ? "Chevron-down-full" 
                            : "Chevron-right-full"
                          } 
                          size={40} 
                          color={colors.white} 
                        />
                        <span>{themes.discover.label}</span>
                      </SectionTitle>
                      <Submenu isOpen={openSections.insights}>
                        <MenuItem to={buildURL(`1_1`)} active={isMenuItemActive(pathname, '/1_1')}>
                          1.1 {themes.discover.tools['1.1'].name}
                        </MenuItem>
                        <MenuItem to={buildURL(`1_2`)} active={isMenuItemActive(pathname, '/1_2')}>
                          1.2 {themes.discover.tools['1.2'].name}
                        </MenuItem>
                        <MenuItem to={buildURL(`1_3`)} active={isMenuItemActive(pathname, '/1_3')}>
                          1.3 {themes.discover.tools['1.3'].name}
                        </MenuItem>
                        <MenuItem to={buildURL(`1_4`)} active={isMenuItemActive(pathname, '/1_4')}>
                          1.4 {themes.discover.tools['1.4'].name}
                        </MenuItem>
                        <MenuItem to={buildURL(`1_5`)} active={isMenuItemActive(pathname, '/1_5')}>
                          1.5 {themes.discover.tools['1.5'].name}
                        </MenuItem>
                      </Submenu>
                    </Section>

                    <Section>
                      <SectionTitle
                        isOpen={openSections.possibilities}
                        onClick={() => toggleSection('possibilities')}
                      >
                        <Icon 
                          name={
                            openSections.possibilities 
                            ? "Chevron-down-full" 
                            : "Chevron-right-full"
                          } 
                          size={40} 
                          color={colors.white} 
                        />
                        <span>{themes.explore.label}</span>
                      </SectionTitle>
                      <Submenu isOpen={openSections.possibilities}>
                        <MenuItem to={buildURL(`2_1`)} active={isMenuItemActive(pathname, '/2_1')}>
                          2.1 {themes.explore.tools['2.1'].name}
                        </MenuItem>
                        <MenuItem to={buildURL(`2_2`)} active={isMenuItemActive(pathname, '/2_2')}>
                          2.2 {themes.explore.tools['2.2'].name}
                        </MenuItem>
                        <MenuItem to={buildURL(`2_3`)} active={isMenuItemActive(pathname, '/2_3')}>
                          2.3 {themes.explore.tools['2.3'].name}
                        </MenuItem>
                        <MenuItem to={buildURL(`2_4`)} active={isMenuItemActive(pathname, '/2_4')}>
                          2.4 {themes.explore.tools['2.4'].name}
                        </MenuItem>
                        <MenuItem to={buildURL(`2_5`)} active={isMenuItemActive(pathname, '/2_5')}>
                          2.5 {themes.explore.tools['2.5'].name}
                        </MenuItem>
                      </Submenu>
                    </Section>

                    <Section>
                      <SectionTitle
                        isOpen={openSections.strategy}
                        onClick={() => toggleSection('strategy')}
                      >
                        <Icon 
                          name={
                            openSections.strategy 
                            ? "Chevron-down-full" 
                            : "Chevron-right-full"
                          } 
                          size={40} 
                          color={colors.white} 
                        />
                        <span>{themes.develop.label}</span>
                      </SectionTitle>
                      <Submenu isOpen={openSections.strategy}>
                        <MenuItem to={buildURL(`3_1`)} active={isMenuItemActive(pathname, '/3_1')}>
                          3.1 {themes.develop.tools['3.1'].name}
                        </MenuItem>
                        <MenuItem to={buildURL(`3_2`)} active={isMenuItemActive(pathname, '/3_2')}>
                          3.2 {themes.develop.tools['3.2'].name}
                        </MenuItem>
                        <MenuItem to={buildURL(`3_3`)} active={isMenuItemActive(pathname, '/3_3')}>
                          3.3 {themes.develop.tools['3.3'].name}
                        </MenuItem>
                        <MenuItem to={buildURL('3_4/strategic-objectives?')} active={isMenuItemActive(pathname, '/3_4')}>
                          3.4 {themes.develop.tools['3.4'].name}
                        </MenuItem>
                        <MenuItem to={buildURL('3_5/indicators')} active={isMenuItemActive(pathname, '/3_5')}>
                          3.5 {themes.develop.tools['3.5'].name}
                        </MenuItem>
                      </Submenu>
                    </Section>

                    <Section>
                      <SectionTitle
                        isOpen={openSections.success}
                        onClick={() => toggleSection('success')}
                      >
                        <Icon 
                          name={
                            openSections.success 
                            ? "Chevron-down-full" 
                            : "Chevron-right-full"
                          } 
                          size={40} 
                          color={colors.white} 
                        />
                        <span>{themes.activate.label}</span>
                      </SectionTitle>
                      <Submenu isOpen={openSections.success}>
                        <MenuItem to={buildURL(`4_1`)} active={isMenuItemActive(pathname, '/4_1')}>
                          4.1 {themes.activate.tools['4.1'].name}
                        </MenuItem>
                        <MenuItem to={buildURL(`4_2`)} active={isMenuItemActive(pathname, '/4_2')}>
                          4.2 {themes.activate.tools['4.2'].name}
                        </MenuItem>
                        <MenuItem to={buildURL(`4_3`)} active={isMenuItemActive(pathname, '/4_3')}>
                          4.3 {themes.activate.tools['4.3'].name}
                        </MenuItem>
                        <MenuItem to={buildURL(`4_4`)} active={isMenuItemActive(pathname, '/4_4')}>
                          4.4 {themes.activate.tools['4.4'].name}
                        </MenuItem>
                        <MenuItem to={buildURL(`4_5`)} active={isMenuItemActive(pathname, '/4_5')}>
                          4.5 {themes.activate.tools['4.5'].name}
                        </MenuItem>
                      </Submenu>
                    </Section>
                  </>
                }
              </>
            }
          </div>
          <SideMenuFooter>
            {!isLandingPage && !isAdminPage &&
              <>
                <MenuLink active={isPatternMatch(pathname)} to={drugId && strategyId && isFullNmblr ? strategyHome : drugId && strategyId && !isFullNmblr ? welcomeHome : '/'}>
                  <Icon name="Strategy" color={colors.white} size={30} />
                  <Subtitle2 color={colors.cream}>Dashboard</Subtitle2>
                </MenuLink>

                <MenuLink active={isCollaboratorPage} to={drugId && strategyId ? peoplePage : '/'}>
                  <Icon name="People" color={colors.white} size={30} />
                  <Subtitle2 color={colors.cream}>Collaborators</Subtitle2>
                </MenuLink>

                <MenuLink active={onHomePage} to='/'>
                  <Icon name="Gap" color={colors.white} size={30} />
                  <Subtitle2 color={colors.cream}>Switch Brand</Subtitle2>
                </MenuLink>
              </>
            }

            {isAdminPage &&
              <>
                <MenuLink to={'/admin'}>
                  <Icon name="People" color={colors.white} size={30} />
                  <Subtitle2 color={colors.cream}>Company</Subtitle2>
                </MenuLink>

                <MenuLink to={'/products'}>
                  <Icon name="Product" color={colors.white} size={30} />
                  <Subtitle2 color={colors.cream}>Products</Subtitle2>
                </MenuLink>
              </>
            }
            <ProfileIConWrapper
              onClick={() => {
                showAccountModal();
                setDropdownVisible(false);
              }}
            >
              <ButtonRound text={initials} level="primary" size={30} />
              <Subtitle2 color={colors.cream}>Account</Subtitle2>
            </ProfileIConWrapper>
            <TermsAndPolicies>
              <a href="/terms/terms-of-service" target="_blank">
                Terms and Policies
              </a>
            </TermsAndPolicies>
            <Signout
              onClick={async () => {
                await signOut();
                setDropdownVisible(false);
                history.push('/signin');
                setAuth({
                  loading: false,
                  user: undefined,
                });
                apolloClient.clearStore();
                Sentry.configureScope((scope) => scope.setUser(null));
              }}
            >
              <Subtitle2 color={colors.cream}>Sign out</Subtitle2>
            </Signout>
          </SideMenuFooter>
        </SideMenu>

        <SideMenuBG
          onClick={() => setDropdownVisible(false)}
          visible={dropdownVisible}
        >
          <CloseBtn onClick={() => setDropdownVisible(false)}>
          </CloseBtn>
        </SideMenuBG>
      </>
    </Mobile >
  );
};