import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { CompetitorChips } from 'components/CompetitiveLandscape/CompetitorChips';
import {
  CompetitorTabs,
  Wrapper as CompetitorTabsWrapper,
} from 'components/CompetitiveLandscape/CompetitorTabs';
import {
  CreateUpdateCompetitorModal,
  competitorModalDefaultState,
} from 'components/CompetitiveLandscape/CreateUpdateCompetitorModal';
import {
  DeleteCompetitorModal,
  DeleteCompetitorModalShape,
  deleteCompetitorModalDefaultState,
} from 'components/CompetitiveLandscape/DeleteCompetitorModal';
import { Evaluation } from 'components/CompetitiveLandscape/Evaluation';
import { TreatmentInUseModal } from 'components/CompetitiveLandscape/TreatmentInUseModal';
import { CompetitiveLandscapeErrors } from 'components/CompetitiveLandscapeErrors';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { Loading } from 'components/Loading';
import { Navbar } from 'components/Navbar';
import { Page, Wrapper } from 'components/Page';
import { GroupWrapper, SliderUpsertFn } from 'components/PostItGroup';
import { PostIts } from 'components/PostIts';
import { CategoryTabs } from 'components/CategoryTabs';
import { OutcomesStakeHolderTabs } from 'components/OutcomesStakeHolderTabs';
import { StakeHolderMenu } from 'components/StakeHolderMenu';
import { StakeholderDefinitionSummaryModal } from 'components/StakeholderDefinitionSummaryModal';
import {
  BodyNormal,
  ButtonPill,
  Icon,
  StepHeaderBar,
  SubMenu,
  Subtitle2,
} from 'components/shared';
import { TooltipWrapper } from 'components/shared/ButtonPill';
import {
  Wrapper as StakeholderDefinitionsWrapper,
} from 'components/shared/StakeholderDefinitions';
import { colors } from 'constants/colors';
import { polling, themes } from 'constants/index';
import { zIndex } from 'constants/zIndex';
import { useAuthContext } from 'contexts/AuthContext';
import { useStakeholderContext } from 'contexts/StakeholderContext';
import { useCategoryContext } from 'contexts/CategoryContext';
import {
  CompetitiveLandscapeRatingFragment,
  CompetitorFragment,
  PostItGroupsDocument,
  Sort,
  Stakeholder,
  CompetitiveCategory,
  StakeholderDefinitionsQueryVariables,
  Step,
  useCompetitiveLandscapeRatingUpsertMutation,
  useCompetitorsQuery,
  usePostItCardsQuery,
  usePostItGroupsWithCardsQuery,
  useStakeholderDefinitionsQuery,
  useStrategyQuery,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import { usePostItCardMove } from 'hooks/usePostItCardMove';
import { usePostItCards } from 'hooks/usePostItCards';
import { usePostItGroups } from 'hooks/usePostItGroups';
import { useQuery } from 'hooks/useQuery';
import { CompetitorModalState, PostItGroupAndCards } from 'types';
import { device } from 'utils/breakpoints';
import { mapStakeholderEnumToPostItGroupTitle } from 'utils/mapStakeholderEnumToSingularStakeholder';
import { verifyUserRole } from 'utils/verifyUserRole';
import buildStrategyURL from '../utils/buildStrategyURL';
import _ from 'lodash';
import useMobile from 'hooks/useMobile';

const PostItGroupHeader = styled.div`
  display: block;

  @media ${device.tabletMax} {
    display: none;
  }
`;

const ComponentWrapper = styled.div``;

const StepHeader = styled(StepHeaderBar)`
  display: block;
  margin-top: 125px;
  padding: 15px 20px 0px;
  border-bottom: 0.5px solid ${colors.greyMedium};

  @media ${device.tabletMax} {
    margin-top: 164px;
    display: block;
  }

  ${TooltipWrapper} {
    position: relative;
  }

  ${StakeholderDefinitionsWrapper} {
    margin-bottom: 15px;
  }
`;

const PageWrapper = styled(Page) <{
  showEvaluation: boolean;
  showingErrorPage: boolean;
}>`
  overflow: hidden;
  padding-top: 30px;

  ${Wrapper} {
    max-width: ${({ showingErrorPage }) => showingErrorPage && 'unset'};
    padding: 0 15px;
    width: 100%;
  }

  @media ${device.desktopMin} {
    ${Wrapper} {
      padding: 0px;
      width: ${({ showingErrorPage }) =>
    showingErrorPage ? '100%' : 'calc(100% - 40px)'};
    }
  }

  @media ${device.tabletMax} {
    ${GroupWrapper} {
      border-radius: 0px;
    }
    ${({ showEvaluation }) => {
    if (!showEvaluation) {
      return css`
          > ${Wrapper} {
            width: 100%;
            padding: 0;
          }
        `;
    }
  }}
  }
`;

const HeaderContainer = styled.div``;

export const ChooseCompetitorsBar = styled.div`
  left: 0;
  min-height: 115px;
  padding: 15px;
  position: absolute;
  border-bottom: 0.5px solid ${colors.greyMedium};
  z-index: ${zIndex.chooseCompetitorsBar};
  background: ${colors.white};
  width: 100%;

  ${TooltipWrapper} {
    display: inline-grid;
    margin-right: 20px;
  }
`;

export const CompetitorTabsAndButton = styled.div<{ gap: number }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  gap: ${({ gap }) => `${gap}px`};
  margin-top: 15px;

  ${CompetitorTabsWrapper} {
    overflow: auto;
    width: fit-content;
  }
`;

export const Divider = styled.div`
  height: 30px;
  width: 1px;
  background: ${colors.white50};
  margin-right: 15px;
`;

export const EvaluateButtonContainerDesktop = styled.div`
  margin-top: 20px;
  display: flex;
  margin-left: 5px;

  ${Divider} {
    margin-right: 10px;
  }

  @media ${device.desktopMin} {
    margin-top: 0;
  }
`;

export const PageNavChildren = styled.div`
  display: flex;
  justify-content: space-center;
  order: -1;
  flex-grow: 1;
  margin-left: 15px;
`;

const NavbarContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  category: CompetitiveCategory;
  stakeholder: Stakeholder;
  stakeholderDefinitionId: string;
  competitorId: string;
}

export const CompetitiveLandscape = () => {
  const {
    drugId,
    strategyId,
    category: categoryParam,
    stakeholder: stakeholderParam,
    stakeholderDefinitionId,
    competitorId,
  }: URLParams = useParams();

  const [stakeholder, setStakeholder] = useStakeholderContext(
    stakeholderParam && (stakeholderParam as any) !== 'undefined'
      ? stakeholderParam
      : 'Patient'
  );
  const [category, setCategory] = useCategoryContext(
    categoryParam && (categoryParam as any) !== 'undefined'
      ? categoryParam
      : 'Outcomes'
  );

  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const history = useHistory();
  const isMobile = useMobile();
  const isDesktop = useDesktop();
  const query = useQuery();
  const [chooseCompetitorsBar, setChooseCompetitorsBar] = useState<boolean>(
    false
  );

  const [
    createUpdateCompetitorModal,
    setCreateUpdateCompetitorModal,
  ] = useState<CompetitorModalState>(competitorModalDefaultState);

  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [showEvaluation,] = useState(
    query.get('evaluating') === 'true'
  );

  const [
    deleteCompetitorModal,
    setDeleteCompetitorModal,
  ] = useState<DeleteCompetitorModalShape>(deleteCompetitorModalDefaultState);

  const [treatmentInUseModal, setTreatmentInUseModal] = useState<boolean>(
    false
  );

  const [showDistribution, setShowDistribution] = useState(true);

  const stakeholderDefinitionsQueryVars: StakeholderDefinitionsQueryVariables = {
    where: {
      stakeholder: Stakeholder.Patient,
      strategyId: +strategyId,
    },
    orderBy: { createdAt: Sort.Asc },
    hasImageOrTitle: true,
  };

  const competitorsQueryVars = {
    where: { strategyId: +strategyId },
  };

  const {
    data: strategyCompetitorsData,
    loading: competitorsLoading,
    error: competitorsError,
    refetch: refetchCompetitors,
    startPolling: startPollingCompetitors,
    stopPolling: stopPollingCompetitors,
  } = useCompetitorsQuery({
    variables: competitorsQueryVars,
  });

  const {
    items: groupItems,
    loading: groupsLoading,
    disabled: groupsDisabled,
    createGroup,
    updateGroup,
    removeGroup,
    error: groupsError,
    refetch: refetchGroups,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        stakeholder,
        step: Step.Competitivelandscape,
        category: category,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Competitivelandscape,
    }
  );

  const {
    items: cardItems,
    loading: cardsLoading,
    createCard,
    updateCard,
    removeCard,
    error: cardsError,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Competitivelandscape,
        category: category,

        stakeholder,
        stakeholderDefinitionId: Number(stakeholderDefinitionId),
        competitorId: Number(competitorId),
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Competitivelandscape,
      stakeholder,
      stakeholderDefinitionId: Number(stakeholderDefinitionId),
      competitorId: Number(competitorId),
    }
  );

  const [
    mutateCompetitiveLandscapeRatingUpsert,
    { loading: isSliderLoading },
  ] = useCompetitiveLandscapeRatingUpsertMutation({
    refetchQueries: [
      {
        query: PostItGroupsDocument,
        variables: {
          where: {
            strategyId: Number(strategyId),
            stakeholder,
            step: Step.Competitivelandscape,
          },
        },
      },
    ],
  });

  const upsertCompetitiveLandscapeRating: SliderUpsertFn = async (
    extraVars
  ) => {
    await mutateCompetitiveLandscapeRatingUpsert({
      variables: {
        data: {
          ...extraVars,
          stakeholder,
          strategy: +strategyId,
          competitor: +competitorId,
          stakeholderDefinition: +stakeholderDefinitionId,
        },
      },
    });
  };

  // get "What are the outcomes that matter?" cards to sort the groups
  const {
    data: groupsWithCardsData,
    error: groupsWithCardsError,
    refetch: refetchGroupsWithCards,
    loading: groupsWithCardsLoading,
  } = usePostItGroupsWithCardsQuery({
    variables: {
      where: {
        strategyId: Number(strategyId),
        stakeholder,
        step: Step.Keystakeholders,
        title: `What are the outcomes that matter to ${mapStakeholderEnumToPostItGroupTitle[stakeholder]}?`,
        preset: true,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const {
    data: strategyData,
  } = useStrategyQuery({
    variables: { id: Number(strategyId) },
  });

  const strategyStakeholderDefinitionId = strategyData?.strategy?.stakeholderDefinitionId;

  const addCardsToGroups = useMemo(
    () =>
      groupItems.reduce((acc, val) => {
        // find cards for each group
        return [
          ...acc,
          {
            ...val,
            cards:
              cardItems
                .filter((c) =>
                  c.postItGroupId === val.id &&
                  (!isLead ? c.user.country === user?.country : true)
                )
                .sort(({ pos: a }, { pos: b }) => {
                  return b - a;
                }) || [],
          },
        ];
      }, [] as PostItGroupAndCards[]),
    [cardItems, groupItems, user?.country, isLead]
  );

  const whatAreTheOutcomesGroup = groupsWithCardsData?.postItGroups?.items[0];

  const cardsByAlignmentOrder = useMemo(
    () =>
      (whatAreTheOutcomesGroup?.cards || []).sort((a, b) => {
        return (
          (b?.collaboration?.alignmentCount || 0) -
          (a?.collaboration?.alignmentCount || 0)
        );
      }),
    [whatAreTheOutcomesGroup?.cards]
  );

  const unsortedGroups = useMemo(
    () =>
      cardsByAlignmentOrder
        .filter((e) => e.include === true && e.stakeholderDefinitionId === +stakeholderDefinitionId)
        .map((card) => {
          const group = addCardsToGroups.find((group) => group.title === card.title);
          if (group) {
            return {
              ...group,
              pos: card.pos,
            };
          }
          return null;
        })
        .filter((v) => !!v) as PostItGroupAndCards[],
    [addCardsToGroups, cardsByAlignmentOrder, stakeholderDefinitionId]
  );

  const groups = useMemo(() => _.orderBy(unsortedGroups, ['pos'], ['desc']), [unsortedGroups]);

  const onChange = usePostItCardMove(groups, updateCard);

  const {
    data: stakeholderDefinitions,
    error: stakeholderDefinitionsError,
    loading: stakeholderDefinitionsLoading,
    startPolling,
    stopPolling,
    refetch: refetchStakeholderDefinitions,
  } = useStakeholderDefinitionsQuery({
    variables: {
      orderBy: { createdAt: Sort.Asc },
      where: {
        strategyId: +strategyId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const validStakeholderDefinitions = useMemo(
    () =>
      stakeholderDefinitions?.stakeholderDefinitions?.items?.filter(
        (definition) => definition.title
      ) || [],
    [stakeholderDefinitions]
  );

  const selectedStakeholderDefinition = useMemo(
    () =>
      validStakeholderDefinitions?.find(
        (s) => s.id === strategyStakeholderDefinitionId
      ),
    [validStakeholderDefinitions, strategyStakeholderDefinitionId]
  );

  const selectedCompetitor = useMemo(
    () =>
      strategyCompetitorsData?.competitors?.items.find(
        (c) => c.id === +competitorId
      ),
    [competitorId, strategyCompetitorsData?.competitors?.items]
  );

  const firstStakeholderDefinition = strategyStakeholderDefinitionId;

  const competitorsSorted =
    strategyCompetitorsData?.competitors?.items
      .slice()
      .sort((a, b) => a.title.localeCompare(b.title)) || [];

  const [activeCompetitors, setActiveCompetitors] = useState<
    CompetitorFragment[]
  >([]);

  const openChooseCompetitorsBar = () => setChooseCompetitorsBar(true);
  const closeChooseCompetitorsBar = () => setChooseCompetitorsBar(false);

  const hasStakeholderError =
    !stakeholderDefinitionsLoading && strategyStakeholderDefinitionId === null;

  const hasGroupsError = !groupsLoading && groups.length === 0;

  const { refetch: refetchPostItCardsForCompetitor } = usePostItCardsQuery({
    skip: true,
  });

  const [ratings, setRatings] = useState<CompetitiveLandscapeRatingFragment[]>(
    []
  );

  const hasCompetitorError =
  !competitorsLoading && activeCompetitors?.length === 0;

  const hasNoSelectedCompetitor =
    !selectedCompetitor ||
    !competitorId ||
    !activeCompetitors.some((c) => c.id === +competitorId);

  const targetPatientError = JSON.stringify(stakeholderDefinitionsError) || '';

  const hasNoTargetPatientsError = targetPatientError.includes(
    'NO_TARGET_PATIENTS'
  );
  const hasStakeholderNotIdentifiedError = targetPatientError.includes(
    'STAKEHOLDER_NOT_IDENTIFIED'
  );

  const hasError =
    hasNoSelectedCompetitor ||
    hasStakeholderError ||
    hasGroupsError ||
    hasNoTargetPatientsError ||
    hasStakeholderNotIdentifiedError ||
    hasCompetitorError;

  const dataIsLoading =
    cardsLoading ||
    groupsLoading ||
    stakeholderDefinitionsLoading ||
    groupsWithCardsLoading ||
    competitorsLoading;

  const dataMissing =
    !groupItems ||
    !cardItems ||
    !groupsWithCardsData ||
    !strategyCompetitorsData;

  const dataErrors = [
    groupsError || cardsError || groupsWithCardsError,
    competitorsError,
  ];

  const showingErrorPage =
    dataIsLoading || dataMissing || dataErrors.some((error) => !!error);

  // useEffect(() => {
  //   if (!stakeholderParam && stakeholder || (!categoryParam && category)) {
  //     history.replace(
  //       `/d/${drugId}/strategy/${strategyId}/1_3/${category || CompetitiveCategory.Outcomes}/${stakeholder || Stakeholder.Patient
  //       }`
  //     );
  //   }
  // }, [drugId, history, stakeholder,category, categoryParam, stakeholderParam, strategyId]);

  useEffect(() => {
    startPolling(polling.default);
    startPollingCompetitors(polling.default);
    return () => {
      stopPolling();
      stopPollingCompetitors();
    };
  }, [
    startPolling,
    stopPolling,
    startPollingCompetitors,
    stopPollingCompetitors,
  ]);

  useEffect(() => {
    if (strategyCompetitorsData?.competitors?.items) {
      const stakeholderCompetitorIds =
        selectedStakeholderDefinition?.CompetitorDetails?.filter(
          (cd) => cd?.stakeholder === stakeholder && cd?.type === category
        )?.map((cd) => cd?.competitorId) || [];

      setActiveCompetitors(
        (strategyCompetitorsData?.competitors?.items || [])
          .filter(
            (c) =>
              c?.CompetitorDetails?.some(
                (detail) => detail?.stakeholder === stakeholder
              ) && stakeholderCompetitorIds.includes(c.id)
          )
          .slice()
          .sort((a, b) => a.title.localeCompare(b.title)) || []
      );
    }
  }, [
    strategyCompetitorsData?.competitors?.items,
    stakeholder,
    category,
    selectedStakeholderDefinition,
  ]);

  useEffect(() => {
    if (stakeholderDefinitionsLoading) return;

    let queryParam = showEvaluation ? '?evaluating=true' : '';
    let url = `/d/${drugId}/strategy/${strategyId}/1_3/${category || 'Outcomes'
  }/${stakeholder || 'Patient'
      }`;

    if (stakeholderDefinitionId || firstStakeholderDefinition) {
      url += '/' + (stakeholderDefinitionId || firstStakeholderDefinition);

      if (
        !!competitorId &&
        activeCompetitors.some((val) => val.id === +competitorId)
      ) {
        url += '/' + competitorId;
      } else if (!!selectedCompetitor?.id || activeCompetitors[0]?.id) {
        url += '/' + (activeCompetitors[0]?.id || selectedCompetitor?.id);
      }
    }
    history.replace(url + queryParam);
  }, [
    activeCompetitors,
    competitorId,
    drugId,
    firstStakeholderDefinition,
    history,
    selectedCompetitor?.id,
    showEvaluation,
    stakeholder,
    category,
    stakeholderDefinitionId,
    stakeholderDefinitionsLoading,
    strategyId,
  ]);

  useEffect(() => {
    if (groupsLoading || showingErrorPage) return;

    setRatings(
      groups
        .map((group) => {
          const competitiveLandscapeRating = group?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];

          return competitiveLandscapeRating.find(
            (rating) =>
              rating.stakeholderDefinitionId === +stakeholderDefinitionId &&
              rating.competitorId === +competitorId &&
              rating.user?.id === user?.id
          );
        })
        //For some annoying reason TS doesn't realise it's filtering out undefined
        //https://stackoverflow.com/questions/43010737/way-to-tell-typescript-compiler-array-prototype-filter-removes-certain-types-fro/54317362
        //T extends unknown is just syntax that allows me to use arrow functions for generics
        .filter(<T extends unknown>(val: T | undefined): val is T => {
          return !!val;
        })
    );
    //the groups variable should probably be memoized, but to prevent breaking anything, I am updating based on the initial data fetch instead as that's what matters
  }, [
    groupsLoading,
    groupItems,
    groupsWithCardsData,
    stakeholderDefinitionId,
    competitorId,
    user,
    groups,
    showingErrorPage,
  ]);

  async function displayDeleteCompetitorModal(
    competitorToDelete: CompetitorFragment
  ) {
    const data = await refetchPostItCardsForCompetitor({
      where: {
        competitorId: competitorToDelete.id,
        step: Step.Competitivelandscape,
        strategyId: +strategyId,
      },
    });

    const competitorHasPostItCards = data?.data?.postItCards?.items?.length;

    if (competitorHasPostItCards) {
      setDeleteCompetitorModal({
        competitor: competitorToDelete,
        hasPostIts: true,
      });
    } else {
      setDeleteCompetitorModal({
        competitor: competitorToDelete,
        hasPostIts: false,
      });
    }
  }

  return (
    <ComponentWrapper>
      {!!showSummaryModal && (
        <StakeholderDefinitionSummaryModal
          strategyId={Number(strategyId)}
          drugId={Number(drugId)}
          stakeholderDefId={selectedStakeholderDefinition?.id}
          handleClose={() => setShowSummaryModal(false)}
          visible={showSummaryModal}
        />
      )}

      <Navbar
        title={themes.discover.tools['1.3'].name}
        prev={{
          title: themes.discover.tools['1.2'].name,
          url: buildStrategyURL(String(drugId), String(strategyId), '1_2'),
        }}
        next={{
          title: themes.discover.tools['1.4'].name,
          url: buildStrategyURL(String(drugId), String(strategyId), '1_4'),
        }}
        stepNumber="1.3"
        pageNavChildren={
          <>
            {isDesktop ? null : (
              <div style={{ 
                display: 'flex',
                order: -1,
                marginLeft: isMobile ? 0 : 8,
                marginRight: 8
              }}>
                {!isMobile ? <Divider /> : null}
                <SubMenu 
                  style={isMobile ? { padding: 0 } : {}}
                  border={true}
                  gradient={true}
                  onClick={() => {
                    history.push(
                      `/d/${drugId}/strategy/${strategyId}/1_3/${stakeholder}/result/${stakeholderDefinitionId}`
                    )
                  }}
                  icon="Lightning"
                  text={isMobile ? '' : 'Results'}
                />
              </div>
            )}
          </>
        }
      >
        <NavbarContent>
          <div style={{ maxWidth: '100%', width: 'calc(100% - 110px)' }}>
             <CategoryTabs
              category={category}
              setCategory={(category) => {
                history.push(
                  `/d/${drugId}/strategy/${strategyId}/1_3/${category}/${stakeholder}`
                );
                setCategory(category);
              }}
            />
          </div>
          <div>
            <StakeHolderMenu
              isLead={isLead}
              refetchGroupsWithCards={async () => {
                // re-fetch both groups and cards
                // this fixes the outcomes group update
                // being slow or unpredictable
                await refetchGroupsWithCards();
                await refetchGroups();
              }}
              hasNoSelectedCompetitor={hasNoSelectedCompetitor}
              hasNoTargetPatientsError={hasNoTargetPatientsError}
              stakeholder={stakeholder}
              selectedStakeholderDefinition={
                selectedStakeholderDefinition
              }
              refetchStakeholderDefinitions={refetchStakeholderDefinitions}
              setStakeholder={(stakeholder) => {
                history.push(
                  `/d/${drugId}/strategy/${strategyId}/1_3/${stakeholder}`
                );
                setStakeholder(stakeholder);
              }}
            />
          </div>
        </NavbarContent>
      </Navbar>

      {!!createUpdateCompetitorModal.type && (
        <CreateUpdateCompetitorModal
          stakeholder={stakeholder}
          createUpdateCompetitorModal={createUpdateCompetitorModal}
          setCreateUpdateCompetitorModal={setCreateUpdateCompetitorModal}
          strategyId={strategyId}
          stakeholderDefinitionId={stakeholderDefinitionId}
          refetchCompetitors={async () => {
            await refetchCompetitors();
            await refetchStakeholderDefinitions();
          }}
        />
      )}

      <DeleteCompetitorModal
        deleteCompetitorModal={deleteCompetitorModal}
        setDeleteCompetitorModal={setDeleteCompetitorModal}
        refetchCompetitors={async () => {
          await refetchCompetitors();
          await refetchStakeholderDefinitions();
        }}
        stakeholderDefinitionsQueryVars={stakeholderDefinitionsQueryVars}
      />

      <TreatmentInUseModal
        visible={treatmentInUseModal}
        setTreatmentInUseModal={setTreatmentInUseModal}
      />

      <HeaderContainer>
        {!hasStakeholderError &&
          !hasNoTargetPatientsError &&
          !hasStakeholderNotIdentifiedError && (
            <StepHeader style={chooseCompetitorsBar ? { paddingBottom: 20 } : {}}>
              {stakeholderDefinitionsLoading ? (
                <Loading 
                  style={{ 
                    width: 80, 
                    marginTop: 0, 
                    marginLeft: 'auto', 
                    marginRight: 'auto'
                  }} 
                />
              ) : (
                <>
                  <Subtitle2 style={{ marginBottom: '5px' }}>
                    {category === 'Outcomes' ? 'Assess how well outcomes are being achieved for each stakeholder' 
                    : category === 'Technologies' ? 'Assess technology developments that might impact the future' 
                    : 'Assess competitor actions that are changing the status quo.'}
                  </Subtitle2>
                  <BodyNormal
                    color={colors.greyDark}
                  >
                    {category === 'Outcomes' ? 'Choose competitors, consider their advantages and disadvantages, and rate their strength against each outcome.' 
                    : category === 'Technologies' ? 'Choose competitors, consider their advantages and disadvantages, and rate the impact.' 
                    : 'Choose competitors, consider their advantages and disadvantages, and rate the impact.'}
                    
                  </BodyNormal>
                 
                  {category === 'Outcomes' && 
                  <OutcomesStakeHolderTabs
                    stakeholder={stakeholder}
                    setStakeholder={(stakeholder) => {
                      history.push(
                        `/d/${drugId}/strategy/${strategyId}/1_3/${category}/${stakeholder}`
                      );
                      setStakeholder(stakeholder);
                    }}
                  />
                  }
                  {!showEvaluation && (
                    <>
                      {!chooseCompetitorsBar &&
                        strategyStakeholderDefinitionId &&
                        activeCompetitors &&
                        selectedStakeholderDefinition ? (
                        <CompetitorTabsAndButton
                          gap={activeCompetitors.length ? 15 : 0}
                        >
                          <CompetitorTabs
                            category={category}
                            competitors={activeCompetitors}
                            selectedCompetitorId={+competitorId}
                            drugId={+drugId}
                            strategyId={+strategyId}
                            stakeholder={stakeholder}
                            selectedStakeholderDefinitionId={
                              +selectedStakeholderDefinition?.id
                            }
                          />
                          {isLead && (
                            <>
                              {activeCompetitors.length ? (
                                <Icon
                                  name="Pencil"
                                  color={colors.purple}
                                  size={40}
                                  style={{
                                    background: colors.purple10,
                                    borderRadius: '50%',
                                    cursor: 'pointer',
                                    height: '40px',
                                  }}
                                  onClick={openChooseCompetitorsBar}
                                />
                              ) : (
                                <ButtonPill
                                  text="Choose competitors"
                                  clickClassName="cypress-choose-competitors"
                                  onClick={openChooseCompetitorsBar}
                                  level="secondary"
                                />
                              )}
                            </>
                          )}
                          
                        </CompetitorTabsAndButton>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </StepHeader>
          )}

        {!showEvaluation && (
          <>
            {chooseCompetitorsBar &&
              selectedStakeholderDefinition &&
              competitorsSorted && (
                <ChooseCompetitorsBar>
                  <Subtitle2 style={{ marginBottom: '5px' }}>
                    Choose the competitive set relevant to this stakeholder
                  </Subtitle2>
                  <BodyNormal
                    color={colors.greyDark}
                    style={{ marginBottom: '15px' }}
                  >
                    Select from existing competitors or add future ones.
                  </BodyNormal>

                  <CompetitorChips
                    stakeholder={stakeholder}
                    competitors={competitorsSorted}
                    setCreateUpdateCompetitorModal={
                      setCreateUpdateCompetitorModal
                    }
                    setDeleteCompetitorModal={setDeleteCompetitorModal}
                    setTreatmentInUseModal={setTreatmentInUseModal}
                    selectedStakeholderDefinition={
                      selectedStakeholderDefinition
                    }
                    stakeholderDefinitionsQueryVars={
                      stakeholderDefinitionsQueryVars
                    }
                    displayDeleteCompetitorModal={displayDeleteCompetitorModal}
                  />
                  <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <ButtonPill
                      text="Done"
                      iconName="Tick"
                      level="secondary"
                      clickClassName="cypress-competitors-done"
                      onClick={closeChooseCompetitorsBar}
                    />
                  </div>
                </ChooseCompetitorsBar>
              )}
          </>
        )}
      </HeaderContainer>

      <PageWrapper
        showEvaluation={
          showEvaluation && !hasStakeholderError && !hasGroupsError
        }
        paddingTop={true}
        showingErrorPage={showingErrorPage}
      >
        <ErrorWrapper
          isLoading={dataIsLoading}
          errors={dataErrors}
          dataMissing={dataMissing}
        >
          {hasError ? (
            <CompetitiveLandscapeErrors
              showEvaluation={showEvaluation}
              hasNoSelectedCompetitor={hasNoSelectedCompetitor}
              hasCompetitorError={hasCompetitorError}
              hasGroupsError={hasGroupsError}
              hasGroupItemsError={groupItems.length === 0}
              hasNoTargetPatientsError={hasNoTargetPatientsError}
              hasStakeholderNotIdentifiedError={
                hasStakeholderNotIdentifiedError
              }
              isDesktop={isDesktop}
              drugId={drugId}
              strategyId={strategyId}
              stakeholder={stakeholder}
            />
          ) : (
            <>
              {showEvaluation ? (
                <Evaluation
                  groups={groups}
                  strategyId={+strategyId}
                  stakeholder={stakeholder}
                  stakeholderDefinitionId={+stakeholderDefinitionId}
                  competitors={activeCompetitors || []}
                  showDistribution={showDistribution}
                  setShowDistribution={setShowDistribution}
                />
              ) : (
                <>
                  <PostItGroupHeader />
                  <PostIts
                    step={Step.Competitivelandscape}
                    groups={groups}
                    addCard={createCard}
                    removeCard={removeCard}
                    updateCard={updateCard}
                    handleCardChange={onChange}
                    createGroup={createGroup}
                    updateGroup={updateGroup}
                    removeGroup={removeGroup}
                    createGroupDisabled={groupsDisabled}
                    createGroupHide
                    slider={{
                      enable: true,
                      isLoading: isSliderLoading,
                      ratings: ratings,
                      upsert: upsertCompetitiveLandscapeRating,
                    }}
                  />
                </>
              )}
            </>
          )}
        </ErrorWrapper>
      </PageWrapper>
    </ComponentWrapper>
  );
};
