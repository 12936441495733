import { EmptyState, KeyEventsButton, Subtitle1 } from 'components/shared';
import { colors } from 'constants/colors';
import {
  CompetitiveAdvantageRowFragment,
  TacticFragment,
  Step,
  KeyEventFragment,
} from 'data/graphql/generated';
import { groupBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { mapTacticCategoryEnumToString } from './TacticRow';
import { thousandSeparator } from 'utils/thousandSeparator';
import { ButtonPillWrapper, ButtonPill } from 'components/shared/ButtonPill';
import {
  budgetChartMaxWidth,
  budgetGroupsFlexGap,
  polling,
} from 'constants/index';
import { useParams } from 'react-router-dom';
import { MedicalFinancialSummary } from './MedicalFinancialSummary';
import { CountryGlobal } from 'types';
import { useGetKeyEvents } from 'components/4-1-medical-strategy/shared/data-access-key-events-sidebar/src';
import { TimelineContainer } from './SummaryTimeline/TimelineContainer';
import { useWidth } from 'hooks/useWidth';
import { getBudgetSum } from './utils';

export const SMALLEST_MOBILE_WIDTH =
  375 +
  // space for scrollbar
  30;
const MAX_WIDTH_TO_FIT_BUTTONS_ON_PAGE = 512;

const MedicalSummaryKeyEventsButton = styled(KeyEventsButton)`
  margin-left: auto;

  @media ${device.desktopMin} {
    margin-right: 76px;
  }
  @media (max-width: ${SMALLEST_MOBILE_WIDTH}px) {
    margin-right: 0;
  }
`;

const CountryDropdownKeyEventsWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  width: 100%;
  margin-left: auto;

  @media (max-width: ${SMALLEST_MOBILE_WIDTH}px) {
    flex-direction: row-reverse;
  }

  @media (max-width: ${MAX_WIDTH_TO_FIT_BUTTONS_ON_PAGE}px) {
    width: fit-content;
  }
`;

const columnSettings = css`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 160px 140px;
  padding: 0 15px;

  @media ${device.tabletMin} {
    grid-template-columns: 236px 140px;
  }

  @media ${device.desktopMin} {
    grid-template-columns: 478px 85px;
  }
`;

const StyledButtonPill = styled(ButtonPill)`
  max-height: 30px;
  width: 95px;
  ${ButtonPillWrapper} {
    max-height: 30px;
  }
`;

const SummaryWrapper = styled.div``;

const Header = styled.header<{ showTimeline?: boolean }>`
  padding: 0 15px;
  display: ${({ showTimeline }) => (showTimeline ? 'flex' : 'block')};
  justify-content: space-between;

  @media ${device.desktopMin} {
    padding: 0;
  }
`;

const ButtonsAndCountryDropdown = styled.div`
  display: flex;

  align-items: center;
  width: 100%;
  margin-bottom: 15px;

  @media ${device.tabletMin} {
    justify-content: flex-start;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
`;

const TotalInvestment = styled.div`
  margin-bottom: 20px;
`;

export const FinancialSummary = styled.section``;

export const BudgetGroups = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${budgetGroupsFlexGap}px;
  margin: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  @media ${device.desktopMin} {
    flex-direction: row;
    align-items: stretch;
  }
`;

export const FinancialTables = styled.div`
  padding: 30px 15px;
  background: ${colors.white};

  @media ${device.tabletMin} {
    max-width: ${budgetChartMaxWidth}px;

    margin: auto;
    border-radius: 5px;
    border: 1px solid ${colors.black10};
  }

  @media ${device.desktopMin} {
    max-width: ${3 * budgetChartMaxWidth + 2 * budgetGroupsFlexGap}px;
    padding: 30px 121px;
  }
`;

export const ImperativeBudgets = styled.div`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TitleAndBudget = styled.div`
  margin-bottom: 15px;
  ${columnSettings}
`;

export const SubTableHeaders = styled.div`
  ${columnSettings}
  margin-bottom: 10px;
`;

export const PurpleSeparator = styled.div`
  height: 1px;
  background: ${colors.purple};
`;

export const TacticAndBudget = styled.div`
  ${columnSettings}
  padding-top: 15px;
  padding-bottom: 15px;

  border-bottom: 1px solid ${colors.greyLight};

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledEmptyState = styled(EmptyState)`
  border-radius: 5px;
  margin-top: 10px;
`;

export function groupTacticsByImperative(
  rows: CompetitiveAdvantageRowFragment[],
  tactics: TacticFragment[]
) {
  const competitiveAdvantageRowOrder = rows.map((r) => r.id);
  const imperativeIdGroups = new Map();
  competitiveAdvantageRowOrder.forEach((id) => imperativeIdGroups.set(id, []));
  tactics.forEach((t) => {
    const rowId = t.competitiveAdvantageRowId;
    const existingTacticsForId = imperativeIdGroups.get(rowId);
    imperativeIdGroups.set(rowId, [...existingTacticsForId, t]);
  });

  return imperativeIdGroups;
}

export function getImperativeBudgets(
  tactics: TacticFragment[],
  rows: CompetitiveAdvantageRowFragment[]
) {
  const competitiveAdvantageRowOrder = rows.map((r) => r.id);
  const imperativeIdGroups = groupTacticsByImperative(rows, tactics);

  for (const rowId of competitiveAdvantageRowOrder) {
    const tacticsForRow: TacticFragment[] = imperativeIdGroups.get(rowId);
    imperativeIdGroups.set(rowId, getBudgetSum(tacticsForRow));
  }

  const res = competitiveAdvantageRowOrder.map((key) => {
    return {
      id: key,
      amount: imperativeIdGroups.get(key),
      subtitle: rows.find((r) => r.id === +key)?.strategicPossibility?.name,
    };
  });

  return res.sort((a, b) => b.amount - a.amount);
}

export function getYearBudgets(
  tactics: Pick<TacticFragment, 'timingStart' | 'timingEnd' | 'budget'>[]
) {
  let years: { [key: string]: number } = {};

  const setOrIncrement = (year: string | number, amount: number) => {
    if (years[year]) {
      years[year] += amount;
    } else {
      years[year] = amount;
    }
  };

  for (const tactic of tactics) {
    const { timingStart: startDate, timingEnd: endDate, budget } = tactic;
    if (!budget) continue;

    const budgetNum: number = budget ? +budget : 0;

    // If neither date is provided, don't assign budget to any years
    if (!startDate.length && !endDate.length) continue;

    const [, startYear] = startDate;
    const [endMonth, endYear] = endDate;

    // If no "To" date provided, assign full budget to the "From" year
    if (startYear && !endYear) {
      setOrIncrement(startYear, budgetNum);
      continue;
    }

    // We have both the "From" and "To" dates, so distribute the budget equally across all months
    let totalMonths = 1;
    let [month, year] = startDate;

    while (year < endYear || month < endMonth) {
      totalMonths++;

      if (month < 11) {
        month++;
      } else {
        year++;
        month = 0;
      }
    }

    // Spread budget across all months
    const monthlyBudget: number = budgetNum / totalMonths;
    [month, year] = startDate;

    do {
      setOrIncrement(year, monthlyBudget);
      if (month < 11) {
        month++;
      } else {
        year++;
        month = 0;
      }
    } while (year < endYear || month < endMonth);

    // Append once more for the final month unless startDate is the same as endDate
    if (!(startDate[0] === endDate[0] && startDate[1] === endDate[1])) {
      setOrIncrement(year, monthlyBudget);
    }
  }

  return Object.keys(years).map((year) => ({
    subtitle: year,
    amount: Math.round(years[year]),
  }));
}

export function getCategoryBudgets(tactics: TacticFragment[]) {
  let groupedByCategory = groupBy(tactics, 'category');

  const categoryBudgets = Object.keys(mapTacticCategoryEnumToString).map(
    (key) => {
      // @ts-ignore
      const subtitle = mapTacticCategoryEnumToString[key];
      const tactics = groupedByCategory[key];
      const amount = tactics ? getBudgetSum(tactics) : 0;

      return {
        subtitle,
        amount,
      };
    }
  );

  return categoryBudgets.sort((a, b) => b.amount - a.amount);
}

export function getReadableBudget(currency: string, budget?: number | null) {
  const budgetAmount = budget || 0;

  const parsedValue = thousandSeparator(budgetAmount);
  const text = currency ? `${currency[0] + parsedValue}` : parsedValue;

  return text;
}

export function sortTacticsByBudget(
  tactics: TacticFragment[],
  direction: 'asc' | 'desc' = 'desc',
  includeChildTactics?: boolean,
  selectedRegions?: string[]
) {
  let tacticCopy: any[] = [];

  const getTacticBudget = (tactic: TacticFragment) => {
    const focusedBudget =
      !tactic.focused || !tactic.budget ? 0 : Number(tactic.budget);

    const childrenBudgets = (tactic?.childTactics || []).filter(
      (ct) => ct && selectedRegions?.includes(ct?.region) && tactic.focused
    ) as TacticFragment[];

    return focusedBudget + getBudgetSum(childrenBudgets);
  };

  if (includeChildTactics) {
    for (const t of tactics) {
      let item = {
        ...t,
        budget: getTacticBudget(t),
      };

      tacticCopy.push(item);
    }
  } else {
    tacticCopy = tactics;
  }

  const sortedDescending = tacticCopy
    ?.sort((a: TacticFragment, b: TacticFragment) => {
      if (!a.budget && !b.budget) return 0;
      if (!a.budget) return 1;
      if (!b.budget) return -1;

      return +b.budget > +a.budget ? 1 : -1;
    })
    .map((t) => tactics.find((originalTac) => originalTac.id === t.id));

  return direction === 'desc' ? sortedDescending : sortedDescending?.reverse();
}

interface Props {
  currency: string;
  tacticsForTimeline: TacticFragment[];
  tacticsForFinancials: TacticFragment[];
  rows: CompetitiveAdvantageRowFragment[];
  timelineCountryDropdown?: React.ReactNode;
  showCountryTimeline: boolean;
  financialCountryDropdown?: React.ReactNode;
  financialSummarySelectedRegions: CountryGlobal[];
  countryTacticsEnabled: boolean;
  openKeyEventsSidebar(): void;
  isLeadOrIsCountryContributor: boolean;
  isUserCountry: boolean;
}

interface URLParams {
  drugId: string;
  strategyId: string;
  region: string;
}

export const MedicalSummary: React.FC<Props> = ({
  currency,
  tacticsForTimeline,
  tacticsForFinancials,
  rows,
  timelineCountryDropdown,
  showCountryTimeline,
  financialCountryDropdown,
  financialSummarySelectedRegions,
  countryTacticsEnabled,
  openKeyEventsSidebar,
  isLeadOrIsCountryContributor,
  isUserCountry,
}) => {
  const { strategyId, region }: URLParams = useParams();
  const useMobileIcon = useWidth() < 512;
  const [showTimeline, setShowTimeline] = useState(true);
  const tacticsForFinancialsFromSelectedRegions = tacticsForFinancials.filter(
    (t) => financialSummarySelectedRegions.includes(t.region as CountryGlobal)
  );

  const { keyEvents, loading, startPolling, stopPolling } = useGetKeyEvents({
    skip: !showTimeline,
    where: {
      strategyId: Number(strategyId),
      type: Step.MedicalStrategy
    },
    enabledConditions: {
      region,
      step: Step.MedicalStrategy,
    },
  });

  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const totalInvestment = getBudgetSum(
    showTimeline
      ? tacticsForTimeline
      : tacticsForFinancialsFromSelectedRegions.filter((t) => t.focused)
  );

  const sortedTimestampedKeyEvents = useMemo(
    () =>
      keyEvents.reduce((acc, curr) => {
        const existingItem = acc[curr.date];
        // only include keyEvents that are enabled for the chosen region
        if (!curr.enabledForRequestRegion) {
          return acc;
        }

        if (existingItem) {
          existingItem.push(curr);

          return acc;
        }

        acc[curr.date] = [curr];

        return acc;
      }, {} as Record<string, KeyEventFragment[]>),
    [keyEvents]
  );

  const showKeyEventsButton =
    isLeadOrIsCountryContributor && isUserCountry && showTimeline;

  return (
    <SummaryWrapper>
      <Header showTimeline={showTimeline}>
        <ButtonsAndCountryDropdown>
          <Buttons>
            <StyledButtonPill
              text="Timeline"
              level={showTimeline ? 'primary-solid' : 'secondary'}
              color={showTimeline ? colors.white : colors.black}
              onClick={() => {
                setShowTimeline(true);
              }}
            />
            <StyledButtonPill
              text="Financials"
              level={!showTimeline ? 'primary-solid' : 'secondary'}
              color={!showTimeline ? colors.white : colors.black}
              onClick={() => {
                setShowTimeline(false);
              }}
            />
          </Buttons>

          {countryTacticsEnabled &&
            financialCountryDropdown &&
            !showTimeline &&
            financialCountryDropdown}

          <CountryDropdownKeyEventsWrapper>
            {countryTacticsEnabled &&
              timelineCountryDropdown &&
              showTimeline &&
              timelineCountryDropdown}

            {showKeyEventsButton && (
              <MedicalSummaryKeyEventsButton
                buttonText="Show events"
                mobile={useMobileIcon}
                onClick={openKeyEventsSidebar}
              />
            )}
          </CountryDropdownKeyEventsWrapper>
        </ButtonsAndCountryDropdown>
        {!showTimeline && (
          <TotalInvestment>
            <Subtitle1 color={colors.greyDark} style={{ display: 'inline' }}>
              Total investment required:{' '}
            </Subtitle1>
            <Subtitle1 style={{ display: 'inline' }}>
              {currency[0] +
                (totalInvestment ? thousandSeparator(totalInvestment) : '-')}
            </Subtitle1>
          </TotalInvestment>
        )}
      </Header>
      {showTimeline ? (
        <TimelineContainer
          loading={loading}
          tactics={tacticsForTimeline}
          rows={rows}
          isCountryTimeline={showCountryTimeline}
          keyEvents={sortedTimestampedKeyEvents}
        />
      ) : (
        <MedicalFinancialSummary
          tactics={tacticsForFinancials}
          rows={rows}
          currency={currency}
          totalInvestment={totalInvestment}
          selectedRegions={financialSummarySelectedRegions}
          countryTacticsEnabled={countryTacticsEnabled}
        />
      )}
    </SummaryWrapper>
  );
};
