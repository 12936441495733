import {
  BodyNormal,
  BodySmall,
  ButtonLabel,
  ContributionTab,
  Subtitle2,
  TabGroup,
} from 'components/shared';
import { kebabCase, uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PageWrapper, StepHeader } from '.';
import { ErrorWrapper } from '../../components/ErrorLoadingComponent';
import { ErrorModal } from '../../components/ErrorModal';
import { SpaceOccupiedModal } from '../../components/Positioning/SpaceOccupiedModal';
import { TheWhy } from '../../components/Positioning/TheWhy';
import { colors, polling } from '../../constants';
import {
  CompetitorFragment,
  Sort,
  StatementsDocument,
  Step,
  SubStep,
  useStakeholderDefinitionsWithPatientFlowBlockStarQuery,
  useStatementUpdateMutation,
  useStatementsQuery,
  useStrategyQuery,
  useTheWhyPageQuery,
} from '../../data/graphql/generated';
import { sortPostIts } from '../../hooks';
import useDesktop from '../../hooks/useDesktop';
import { usePostItCardMove } from '../../hooks/usePostItCardMove';
import { usePostItCards } from '../../hooks/usePostItCards';
import { usePostItGroups } from '../../hooks/usePostItGroups';
import { PositioningTabKebabCase } from '../../types';
import { useGlobalContext } from 'contexts/GlobalContext';
import { PostItsEmpty } from 'components/PostItsEmpty';

type Props = {};

interface URLParams {
  drugId: string;
  strategyId: string;
  positioningTab: PositioningTabKebabCase;
  groupId: string;
}

export const TheWhyPage = (props: Props) => {
  const [spaceOccupiedModal, setSpaceOccupiedModal] = useState(false);
  const [noGroupModal, setNoGroupModal] = useState(false);

  const { drugId, strategyId, groupId }: URLParams = useParams();

  const history = useHistory();

  const isDesktop = useDesktop();
  const kebabCaseSubStep = kebabCase(SubStep.TheWhy);

  // handle global context path
  const [theWhyGroupId, setTheWhyGroupId] = useGlobalContext(
    'theWhyGroupId'
  );

  useEffect(() => {
    if (!groupId && theWhyGroupId) {
      history.replace(
        `/d/${drugId}/strategy/${strategyId}/3_1/${kebabCaseSubStep}/${theWhyGroupId}`
      );
    }
  }, [drugId, history, groupId, theWhyGroupId, kebabCaseSubStep, strategyId]);

  const {
    data: strategyData,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
  } = useStrategyQuery({
    variables: { id: +strategyId },
  });

  const {
    data: focusedTheWhyPageData,
    loading: focusedTheWhyPageLoading,
    error: focusedTheWhyPageError,
  } = useTheWhyPageQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        focus: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: theWhyPageData,
    loading: theWhyPageLoading,
    startPolling: theWhyPageStartPolling,
    stopPolling: theWhyPageStopPolling,
    error: theWhyPageError,
  } = useTheWhyPageQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        statementParentId: Number(groupId),
      },
    },
    fetchPolicy: 'network-only',
  });

  const theWhyPageChildId = theWhyPageData?.theWhyPage?.id;

  const {
    data: theWhoStatementsData,
    loading: theWhoStatementsLoading,
    startPolling: theWhoStatementsStartPolling,
    stopPolling: theWhoStatementsStopPolling,
    error: theWhoStatementsError,
  } = useStatementsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        step: Step.Positioning,
        substep: [SubStep.TheWho],
      },
    },
  });

  const {
    data: statementsData,
    loading: statementsLoading,
    startPolling: statementsStartPolling,
    stopPolling: statementsStopPolling,
    error: statementsError,
  } = useStatementsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        step: Step.Positioning,
        substep: [SubStep.TheWhy],
        theWhyPageId: theWhyPageChildId,
      },
    },
  });

  const statementRefetch = {
    refetchQueries: [
      {
        query: StatementsDocument,
        variables: {
          where: {
            strategyId: +strategyId,
            step: Step.Positioning,
            substep: SubStep.TheWhy,
            theWhyPageId: theWhyPageChildId,
          },
        },
      },
    ],
  };

  const [updateStatement] = useStatementUpdateMutation({
    ...statementRefetch,
  });

  const {
    data: stakeholderDefinitionsData,
    loading: stakeholderDefinitionsLoading,
    startPolling,
    stopPolling,
  } = useStakeholderDefinitionsWithPatientFlowBlockStarQuery({
    variables: { where: { strategyId: +strategyId } },
    fetchPolicy: 'network-only',
  });

  const theWhoStatementGroups = useMemo(() => {
    return theWhoStatementsData?.statements?.items.map((statement) => {
      return {
        id: statement.id,
        title: statement.text[statement.text.length - 1] || 'Achieve something',
      };
    });
  }, [theWhoStatementsData?.statements?.items]);

  const noWhoGroups = !theWhoStatementGroups?.length;

  const {
    items: groupItems,
    loading: groupsLoading,
    createGroup,
    updateGroup,
    removeGroup,
    error: groupsError,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWhy,
        theWhyPageId: theWhyPageChildId,
      },
      orderBy: {
        id: Sort.Asc,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.TheWhy,
    }
  );

  const {
    items: cardItems,
    loading: cardsLoading,
    createCard,
    updateCard,
    removeCard,
    error: cardsError,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWhy,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.TheWhy,
    }
  );

  const groups = sortPostIts(groupItems, cardItems);

  const onChange = usePostItCardMove(groups, updateCard);

  const noCardsData = !groups;

  const featuredCompetitors = useMemo(
    () =>
      uniqBy(
        stakeholderDefinitionsData?.stakeholderDefinitions?.items
          .reduce((acc, curr) => {
            const validCompetitors = curr?.CompetitorDetails?.filter(
              (cd) => !!cd?.isStarred
            ).map((cd) => cd?.Competitor) as CompetitorFragment[];

            if (!!validCompetitors) {
              acc.push(validCompetitors);
            }

            return acc;
          }, [] as CompetitorFragment[][])
          .flat() || [],
        'id'
      ),
    [stakeholderDefinitionsData?.stakeholderDefinitions?.items]
  );

  useEffect(() => {
    if (!theWhoStatementGroups) return;
    let url = `/d/${drugId}/strategy/${strategyId}/3_1/${kebabCase(
      SubStep.TheWhy
    )}`;

    if (groupId && !theWhoStatementGroups) setNoGroupModal(true);

    if (groupId || focusedTheWhyPageLoading || theWhoStatementsLoading) return;

    const focusedTheWhyPageParentStatementId =
      focusedTheWhyPageData?.theWhyPage?.statementParentId;
    // focused group
    if (focusedTheWhyPageParentStatementId) {
      url += `/${focusedTheWhyPageParentStatementId}`;
    } else {
      // Ensure the first group is selected by default
      const firstGroup = theWhoStatementGroups[0];
      if (firstGroup) {
        url += `/${firstGroup.id}`;
      }
    }
    return history.replace(url);
  }, [
    drugId,
    focusedTheWhyPageData?.theWhyPage?.statementParentId,
    focusedTheWhyPageLoading,
    groupId,
    history,
    strategyId,
    theWhoStatementGroups,
    theWhoStatementsLoading,
  ]);

  useEffect(() => {
    startPolling(polling.default);
    statementsStartPolling(polling.default);
    startStrategyPolling(polling.default);
    theWhyPageStartPolling(polling.default);
    theWhoStatementsStartPolling(polling.default);
    return () => {
      stopPolling();
      statementsStopPolling();
      stopStrategyPolling();
      theWhyPageStopPolling();
      theWhoStatementsStopPolling();
    };
  }, [
    startPolling,
    startStrategyPolling,
    statementsStartPolling,
    statementsStopPolling,
    stopPolling,
    stopStrategyPolling,
    theWhyPageStartPolling,
    theWhyPageStopPolling,
    theWhoStatementsStartPolling,
    theWhoStatementsStopPolling,
  ]);

  return (
    <>
      <ErrorModal
        title="Group no longer exists"
        text="The group you are looking for has been deleted."
        handleClose={() => setNoGroupModal(false)}
        visible={noGroupModal}
      />

      <SpaceOccupiedModal
        handleClose={() => setSpaceOccupiedModal(false)}
        competitors={featuredCompetitors}
        visible={spaceOccupiedModal}
        drugId={+drugId}
        strategyId={+strategyId}
      />
      <StepHeader isDesktop={isDesktop}>
        <Subtitle2 style={{ marginBottom: '5px' }}>
          What are the benefits our brand offers and what are the reasons to
          believe?
        </Subtitle2>
        <div
          style={{
            whiteSpace: 'pre-wrap',
            marginBottom: '15px',
          }}
        >
          <BodyNormal color={colors.greyDark} style={{ display: 'inline' }}>
            Focus on gaps left by the competitors.
          </BodyNormal>
          <ButtonLabel
            onClick={() => setSpaceOccupiedModal(true)}
            style={{ display: 'inline', whiteSpace: 'nowrap' }}
            color={colors.purple}
          >
            View space occupied
          </ButtonLabel>
        </div>

        {noWhoGroups ? null : (
          <TabGroup componentName="ContributionTab">
            <>
              {theWhoStatementGroups.map(({ title, id }) => {
                if (!title) return null;
                return (
                  <div
                    key={id}
                    style={{ display: 'inline-block', padding: '0 2px' }}
                  >
                    <ContributionTab
                      key={id}
                      text={title}
                      onClick={() => {
                        history.push(
                          `/d/${drugId}/strategy/${strategyId}/3_1/${kebabCaseSubStep}/${id}`
                        );
                        setTheWhyGroupId(id)
                      }}
                      active={id === +groupId}
                      id={`contribution-tab-${id}`}
                      secondary=""
                      disableSidebar
                      sidebarOpen={false}
                      displaySummary={() => { }}
                      revealTruncatedText={false}
                    />
                  </div>
                );
              })}
            </>
          </TabGroup>
        )}
      </StepHeader>

      <ErrorWrapper
        isLoading={
          groupsLoading ||
          cardsLoading ||
          statementsLoading ||
          stakeholderDefinitionsLoading ||
          theWhyPageLoading ||
          theWhoStatementsLoading ||
          focusedTheWhyPageLoading
        }
        errors={[
          groupsError,
          cardsError,
          statementsError,
          theWhyPageError,
          theWhoStatementsError,
          focusedTheWhyPageError,
        ]}
        dataMissing={
          noCardsData ||
          !statementsData ||
          !strategyData
        }
      >
        {noWhoGroups ? (
          <PostItsEmpty style={{ margin: 20 }} title="Nothing to discuss">
            <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
              Work can only begin when a Lead has chosen key insights in
            </BodySmall>

            <Link
              to={`/d/${drugId}/strategy/${strategyId}/3_1/the-who`}
              style={{
                color: colors.greyDark,
                display: 'inline',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {' '}
              Part 2
            </Link>
          </PostItsEmpty>
        ) : (
          <PageWrapper paddingTop={false} fullWidthMobile>
            <TheWhy
              strategyData={strategyData?.strategy}
              isDesktop={isDesktop}
              strategyId={Number(strategyId)}
              statements={statementsData?.statements?.items}
              step={Step.Positioning}
              substep={SubStep.TheWhy}
              groups={groups}
              addCard={createCard}
              removeCard={removeCard}
              updateCard={updateCard}
              handleCardChange={onChange}
              createGroup={createGroup}
              updateGroup={updateGroup}
              removeGroup={removeGroup}
              updateStatement={updateStatement}
              theWhyPage={theWhyPageData?.theWhyPage}
            />
          </PageWrapper>
        )}
      </ErrorWrapper>
    </>
  );
};
