import { LoadingComponent } from 'components/Loading';
import {
  Subtitle2,
  BodyNormal,
  TabGroup,
  ContributionTab,
} from 'components/shared';
import { StakeholderDefinitionAvatars } from 'components/shared/StakeholderDefinitionAvatars';
import { StrategyDefinitionHeader } from 'components/StrategyDefinitionHeader';
import { MultiListStrategicQuestionType } from 'components/StrategyDefinitionHeader/StrategyDefinitionHeader.types';
import { MedicalStrategyTabNames } from 'components/4-1-medical-strategy/shared/data-access-medical-strategy';
import { colors } from 'constants/index';
import {
  CompetitiveAdvantageRowFragment,
  StakeholderDefinition,
  StrategicQuestionFragment,
  StrategicQuestionType,
  SubStep,
} from 'data/graphql/generated';
import { kebabCase } from 'lodash';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Country, CountryGlobal } from 'types';
import {
  StyledStepHeader,
  TopRow,
  StyledKeyEventsButton,
  StrategicQuestionContainer,
  HurdlesAndGapsCountriesDropdown,
  DividerAndStrategicQuestion,
  PurpleDivider,
} from '../../../../../../../containers/MedicalStrategy/index.style';
import { useGlobalContext } from 'contexts/GlobalContext';

type Props = {
  countryTacticsEnabled: boolean;
  subStep: string;
  showKeyEvents: boolean;
  isMobile: boolean;
  setKeyEventsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  competitiveAdvantageRows: CompetitiveAdvantageRowFragment[];
  activeStrategicImperativeId: number;
  history: ReturnType<typeof useHistory>;
  drugId: string;
  strategyId: string;
  selectedRegion: CountryGlobal;
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activeSubStep: string;
  showStrategicQuestions: boolean;
  strategicQuestionsLoading: boolean;
  countryDropdownActive: boolean;
  setCountryDropdownActive: React.Dispatch<React.SetStateAction<boolean>>;
  countryDropDownRef: React.MutableRefObject<null>;
  isSummaryPage: boolean;
  regionsExcludingGlobal: Country[];
  strategicQuestion: StrategicQuestionFragment | undefined;
  isDesktop: boolean;
  viewingOwnRegion: boolean;
  totalUsers: number | undefined;
  handleSave: (val: any) => Promise<void>;
  relevantDefinitions: Pick<StakeholderDefinition, 'title' | 'image' | 'id'>[];
};

export function MedicalStrategyStepHeader({
  countryTacticsEnabled,
  subStep,
  showKeyEvents,
  isMobile,
  setKeyEventsSidebarOpen,
  competitiveAdvantageRows,
  activeStrategicImperativeId,
  history,
  drugId,
  strategyId,
  selectedRegion,
  sidebarOpen,
  setSidebarOpen,
  activeSubStep,
  showStrategicQuestions,
  strategicQuestionsLoading,
  countryDropdownActive,
  setCountryDropdownActive,
  countryDropDownRef,
  isSummaryPage,
  regionsExcludingGlobal,
  strategicQuestion,
  isDesktop,
  viewingOwnRegion,
  totalUsers,
  handleSave,
  relevantDefinitions,
}: Props) {
  const bulletPoints = useMemo<MultiListStrategicQuestionType>(() => {
    const bp: MultiListStrategicQuestionType = {
      type: StrategicQuestionType.MultiList,
      bulletPoints: strategicQuestion?.lists || [],
    };
    return bp;
  }, [strategicQuestion?.lists]);

  const [ 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    strategicImperativeId, 
    setStrategicImperativeId
  ] = useGlobalContext(
    'strategicImperativeId'
  );

  return (
    <StyledStepHeader>
      <TopRow>
        <div>
          <Subtitle2 style={{ marginBottom: '5px' }}>
            {subStep === MedicalStrategyTabNames.HURDLES_AND_GAPS
              ? `Consider Hurdles and Gaps for this strategic imperative.`
              : subStep === MedicalStrategyTabNames.MEDICAL_OBJECTIVES
              ? 'Consider medical objectives that address the hurdles and gaps identified in part 1.'
              : subStep === MedicalStrategyTabNames.MEDICAL_TACTICS
              ? 'Consider medical tactics for the medical objectives.'
              : ``}
          </Subtitle2>

          <BodyNormal
            color={colors.greyDark}
            style={{ display: 'inline-block', marginBottom: 15 }}
          >
            {subStep === MedicalStrategyTabNames.HURDLES_AND_GAPS
              ? 'Add notes with suggestions. Leads should summarise the key Hurdles and Gaps.'
              : subStep === MedicalStrategyTabNames.MEDICAL_OBJECTIVES
              ? 'Add notes with suggestions. Leads should summarise the key medical objectives.'
              : subStep === MedicalStrategyTabNames.MEDICAL_TACTICS
              ? 'Add suggestions. Leads should choose where to focus and determine the budget and responsibility.'
              : ''}
          </BodyNormal>
        </div>
        {showKeyEvents && (
          <StyledKeyEventsButton
            plusIcon
            buttonText="Key events"
            mobile={isMobile}
            onClick={() => setKeyEventsSidebarOpen(true)}
          />
        )}
      </TopRow>
      {!!competitiveAdvantageRows?.length && (
        <>
          {subStep !== SubStep.Summary && !!activeStrategicImperativeId && (
            <TabGroup componentName="CompetitorTab">
              <>
                {competitiveAdvantageRows?.map((c) => {
                  return (
                    <ContributionTab
                      key={c.id}
                      id={`contribution-tab-${c.strategicPossibility?.id}`}
                      secondary=""
                      text={c.strategicPossibility?.name || ''}
                      active={+activeStrategicImperativeId === c.id}
                      onClick={() => {
                        history.push(
                          `/d/${drugId}/strategy/${strategyId}/4_1/${kebabCase(
                            subStep
                          )}${'/' + c.id}${'/' + kebabCase(selectedRegion)}`
                        );
                        setStrategicImperativeId(c.id)
                      }}
                      sidebarOpen={sidebarOpen}
                      displaySummary={() => setSidebarOpen(true)}
                      revealTruncatedText={false}
                    />
                  );
                })}
              </>
            </TabGroup>
          )}
          <div
            style={{
              borderBottom: `1px solid ${colors.greyMedium}`,
              position: 'absolute',
              width: '100%',
              left: 0,
            }}
          />
          {activeSubStep !== MedicalStrategyTabNames.MEDICAL_OBJECTIVES &&
            activeSubStep !== MedicalStrategyTabNames.MEDICAL_TACTICS && (
              <StrategicQuestionContainer>
                <LoadingComponent
                  isLoading={
                    showStrategicQuestions && strategicQuestionsLoading
                  }
                >
                  {countryTacticsEnabled && (
                    <HurdlesAndGapsCountriesDropdown
                      country={selectedRegion}
                      active={countryDropdownActive}
                      onClick={() =>
                        setCountryDropdownActive(!countryDropdownActive)
                      }
                      viewOnly={false}
                      hideCountryName={true}
                      largeIcon
                      globalOptionText={'Global plan'}
                      dropDownRef={countryDropDownRef}
                      showDropdown={countryDropdownActive}
                      setShowDropdown={setCountryDropdownActive}
                      setCountry={(country) => {
                        let baseURL = `/d/${drugId}/strategy/${strategyId}/4_1/${activeSubStep}`;

                        if (
                          !isSummaryPage &&
                          !!competitiveAdvantageRows?.length
                        ) {
                          baseURL += `/${activeStrategicImperativeId}`;

                          if (
                            activeSubStep ===
                            MedicalStrategyTabNames.HURDLES_AND_GAPS
                          ) {
                            baseURL += `/${kebabCase(
                              country as CountryGlobal
                            )}`;
                          }
                        }

                        history.replace(baseURL);
                      }}
                      allCountries={regionsExcludingGlobal}
                    />
                  )}

                  {strategicQuestion && (
                    <DividerAndStrategicQuestion>
                      {isDesktop && countryTacticsEnabled && <PurpleDivider />}
                      <StrategyDefinitionHeader
                        disabled={!viewingOwnRegion}
                        viewingOtherRegion={!viewingOwnRegion}
                        isLead={true} // This overrides the logic in StrategyDefinitionHeader.tsx with permissions closely tied to user role
                        tooltipMessage={
                          !viewingOwnRegion
                            ? selectedRegion === 'global'
                              ? 'Only Leads can edit'
                              : 'Only local contributors can edit'
                            : 'Only local contributors can edit'
                        }
                        hideAlignment={selectedRegion !== 'global'}
                        type={strategicQuestion?.type}
                        bulletPoints={bulletPoints}
                        collaboration={
                          strategicQuestion?.collaboration || undefined
                        }
                        totalUsers={totalUsers}
                        title={strategicQuestion?.title || ''}
                        placeholder={['Type the hurdles', 'Type the gaps']}
                        handleSave={handleSave}
                      />
                    </DividerAndStrategicQuestion>
                  )}

                  {isDesktop &&
                    activeSubStep ===
                      MedicalStrategyTabNames.HURDLES_AND_GAPS && (
                      <div
                        style={{
                          display: 'flex',
                          alignSelf: 'flex-start',
                        }}
                      >
                        <StakeholderDefinitionAvatars
                          stakeholderDefinitions={relevantDefinitions}
                        />
                      </div>
                    )}
                </LoadingComponent>
              </StrategicQuestionContainer>
            )}
        </>
      )}
    </StyledStepHeader>
  );
}
