import { BodyNormal, BodySmall, Subtitle2, Icon, ButtonPill } from 'components/shared';
import { kebabCase, uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PageWrapper, StepHeader } from '.';
import { ErrorWrapper } from '../../components/ErrorLoadingComponent';
import { ErrorModal } from '../../components/ErrorModal';
import { Statement } from '../../components/Positioning/Statement';
import { StatementModal } from '../../components/Positioning/StatementModal';
import { PostIts } from '../../components/PostIts';
import { colors, polling } from '../../constants';
import { CompetitorModalState } from 'types';
import { useAuthContext } from '../../contexts/AuthContext';
import {
  CompetitorDocument,
  CompetitorFragment,
  Step,
  SubStep,
  useCompetitorUpdateMutation,
  Stakeholder,
  StakeholderDefinitionsQueryVariables,
  useStakeholderDefinitionsWithPatientFlowBlockStarQuery,
  useCompetitorsQuery,
  useStakeholderDefinitionsQuery,
  useStrategyQuery,
  usePostItCardsQuery,
  Sort
} from '../../data/graphql/generated';
import { CompetitorChips } from 'components/BrandPositioning/CompetitorChips';
import {
  CompetitorTabs,
} from 'components/BrandPositioning/CompetitorTabs';
import {
  CreateUpdateCompetitorModal,
  competitorModalDefaultState,
} from 'components/BrandPositioning/CreateUpdateCompetitorModal';
import {
  DeleteCompetitorModal,
  DeleteCompetitorModalShape,
  deleteCompetitorModalDefaultState,
} from 'components/BrandPositioning/DeleteCompetitorModal';
import { ChooseCompetitorsBar, CompetitorTabsAndButton } from 'containers/CompetitiveLandscape'
import { sortPostIts } from '../../hooks';
import useDesktop from '../../hooks/useDesktop';
import { usePostItCardMove } from '../../hooks/usePostItCardMove';
import { usePostItCards } from '../../hooks/usePostItCards';
import { usePostItGroups } from '../../hooks/usePostItGroups';
import { verifyUserRole } from '../../utils/verifyUserRole';
import { PostItsEmpty } from '../../components/PostItsEmpty';
import styled from 'styled-components/macro';
import { GroupDelete } from 'components/PostItGroup';
import { device } from 'utils/breakpoints';

interface URLParams {
  drugId: string;
  strategyId: string;
  competitorId: string;
}


const StyledPostIts = styled(PostIts) <{ lastGroup: boolean }>`
  @media ${device.tabletMax} {
    width: 100%;
  }

  ${GroupDelete} {
    display: ${({ lastGroup }) => lastGroup && 'none'};
  }
`;

export const CompetitorPositioningPage = () => {
  const { drugId, strategyId, competitorId }: URLParams = useParams();

  const [statementModalOpen, setStatementModalOpen] = useState(false);
  const [noCompetitorModal, setNoCompetitorModal] = useState(false);
  const kebabCaseSubStep = kebabCase(SubStep.CompetitorPositioning);
  const history = useHistory();
  const isDesktop = useDesktop();

  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const {
    items: groupItems,
    loading: groupsLoading,
    createGroup,
    updateGroup,
    removeGroup,
    error: groupsError,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.CompetitorPositioning,
        competitorId: +competitorId,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.CompetitorPositioning,
      competitorId: +competitorId,
    }
  );

  const {
    items: cardItems,
    loading: cardsLoading,
    createCard,
    updateCard,
    removeCard,
    error: cardsError,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.CompetitorPositioning,
        competitorId: +competitorId,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.CompetitorPositioning,
      competitorId: +competitorId,
    }
  );

  const groups = sortPostIts(groupItems, cardItems);

  const onChange = usePostItCardMove(groups, updateCard);
  const noCardsData = !groups;

  const {
    data: stakeholderDefinitionsData,
    loading: stakeholderDefinitionsLoading,
    startPolling,
    stopPolling,
  } = useStakeholderDefinitionsWithPatientFlowBlockStarQuery({
    variables: { where: { strategyId: +strategyId } },
    fetchPolicy: 'network-only',
  });


  const featuredCompetitors = useMemo(
    () =>
      uniqBy(
        stakeholderDefinitionsData?.stakeholderDefinitions?.items
          .reduce((acc, curr) => {
            const validCompetitors = curr?.CompetitorDetails?.filter(
              (cd) => !cd?.isStarred
            ).map((cd) => cd?.Competitor) as CompetitorFragment[];

            if (!!validCompetitors) {
              acc.push(validCompetitors);
            }

            return acc;
          }, [] as CompetitorFragment[][])
          .flat() || [],
        'id'
      ),
    [stakeholderDefinitionsData?.stakeholderDefinitions?.items]
  );

  const selectedCompetitor = featuredCompetitors?.find(
    (c) => c?.id === +competitorId
  );

  const [updateCompetitor] = useCompetitorUpdateMutation();

  
  const [, setTreatmentInUseModal] = useState<boolean>(
    false
  );
  const stakeholderDefinitionsQueryVars: StakeholderDefinitionsQueryVariables = {
    where: {
      stakeholder: Stakeholder.Provider,
      strategyId: +strategyId,
    },
    orderBy: { createdAt: Sort.Asc },
    hasImageOrTitle: true,
  };

  const [
    deleteCompetitorModal,
    setDeleteCompetitorModal,
  ] = useState<DeleteCompetitorModalShape>(deleteCompetitorModalDefaultState);
  const [
    createUpdateCompetitorModal,
    setCreateUpdateCompetitorModal,
  ] = useState<CompetitorModalState>(competitorModalDefaultState);
  const [chooseCompetitorsBar, setChooseCompetitorsBar] = useState<boolean>(
    false
  );
  const {
    data: strategyData,
  } = useStrategyQuery({
    variables: { id: Number(strategyId) },
  });

  const strategyStakeholderDefinitionId = strategyData?.strategy?.stakeholderDefinitionId;

  const competitorsQueryVars = {
    where: { strategyId: +strategyId },
  };
  const {
    data: strategyCompetitorsData,
    refetch: refetchCompetitors,
  } = useCompetitorsQuery({
    variables: competitorsQueryVars,
  });

  const {
    data: stakeholderDefinitions,
    // startPolling,
    // stopPolling,
    refetch: refetchStakeholderDefinitions,
  } = useStakeholderDefinitionsQuery({
    variables: {
      orderBy: { createdAt: Sort.Asc },
      where: {
        strategyId: +strategyId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const validStakeholderDefinitions = useMemo(
    () =>
      stakeholderDefinitions?.stakeholderDefinitions?.items?.filter(
        (definition) => definition.title
      ) || [],
    [stakeholderDefinitions]
  );

  const selectedStakeholderDefinition = useMemo(
    () =>
      validStakeholderDefinitions?.find(
        (s) => s.id === strategyStakeholderDefinitionId
      ),
    [validStakeholderDefinitions, strategyStakeholderDefinitionId]
  );

  // const selectedCompetitor = useMemo(
  //   () =>
  //     strategyCompetitorsData?.competitors?.items.find(
  //       (c) => c.id === +competitorId
  //     ),
  //   [competitorId, strategyCompetitorsData?.competitors?.items]
  // );


  const competitorsSorted =
    strategyCompetitorsData?.competitors?.items
      .slice()
      .sort((a, b) => a.title.localeCompare(b.title)) || [];

  const [activeCompetitors, setActiveCompetitors] = useState<
    CompetitorFragment[]
  >([]);

  const openChooseCompetitorsBar = () => setChooseCompetitorsBar(true);
  const closeChooseCompetitorsBar = () => setChooseCompetitorsBar(false);

  const { refetch: refetchPostItCardsForCompetitor } = usePostItCardsQuery({
    skip: true,
  });

  async function displayDeleteCompetitorModal(
    competitorToDelete: CompetitorFragment
  ) {
    const data = await refetchPostItCardsForCompetitor({
      where: {
        competitorId: competitorToDelete.id,
        step: Step.Competitivelandscape,
        strategyId: +strategyId,
      },
    });

    const competitorHasPostItCards = data?.data?.postItCards?.items?.length;

    if (competitorHasPostItCards) {
      setDeleteCompetitorModal({
        competitor: competitorToDelete,
        hasPostIts: true,
      });
    } else {
      setDeleteCompetitorModal({
        competitor: competitorToDelete,
        hasPostIts: false,
      });
    }
  }

  useEffect(() => {
    if (strategyCompetitorsData?.competitors?.items) {
      const stakeholderCompetitorIds =
        selectedStakeholderDefinition?.CompetitorDetails?.filter(
          (cd) => cd?.stakeholder === Stakeholder.Provider && cd?.type === 'CompetitorPositioning'
        )?.map((cd) => cd?.competitorId) || [];

      setActiveCompetitors(
        (strategyCompetitorsData?.competitors?.items || [])
          .filter(
            (c) =>
              c?.CompetitorDetails?.some(
                (detail) => detail?.stakeholder === Stakeholder.Provider
              ) && stakeholderCompetitorIds.includes(c.id)
          )
          .slice()
          .sort((a, b) => a.title.localeCompare(b.title)) || []
      );
    }
  }, [
    strategyCompetitorsData?.competitors?.items,
    // stakeholder,
    selectedStakeholderDefinition,
  ]);

  const noCompetitors = !activeCompetitors.length;

  useEffect(() => {
    // stakeholderDefinitions holds featuredCompetitors data
    if (stakeholderDefinitionsLoading || selectedCompetitor || noCompetitors) {
      return;
    }

    // Ensure the first positioningTab is selected by default
    let url = `/d/${drugId}/strategy/${strategyId}/3_1/${kebabCase(
      SubStep.CompetitorPositioning
    )}`;

    // Ensure the first competitor is selected by default
    if (competitorId && !selectedCompetitor) setNoCompetitorModal(true);
    const firstCompetitor = featuredCompetitors[0];
    if (firstCompetitor) {
      url += `/${firstCompetitor.id}`;
    }

    return history.replace(url);
  }, [
    history,
    drugId,
    strategyId,
    kebabCaseSubStep,
    competitorId,
    stakeholderDefinitionsLoading,
    featuredCompetitors,
    noCompetitors,
    selectedCompetitor,
  ]);


  useEffect(() => {
    startPolling(polling.default);

    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  return (
    <>
      <StatementModal
        placeholder={'A strong brand association'}
        closeModal={() => setStatementModalOpen(false)}
        substep={SubStep.CompetitorPositioning}
        statement={selectedCompetitor?.brandAssociations}
        visible={statementModalOpen}
        drugId={+drugId}
        strategyId={+strategyId}
        title={`Space occupied by ${selectedCompetitor?.title || ''}`}
        subTitle={
          'Summarise the strong brand associations. Each new line will create a new bullet point.'
        }
        onUpdate={async (value) => {
          if (selectedCompetitor?.id) {
            const trimmedValidValues = value
              .filter((arr) => !!arr)
              .map((s) => s.trim());

            await updateCompetitor({
              variables: {
                id: selectedCompetitor?.id,
                data: { brandAssociations: trimmedValidValues },
              },
              refetchQueries: [
                {
                  query: CompetitorDocument,
                  variables: { id: selectedCompetitor?.id },
                },
              ],
            });
          }
        }}
      />

      {!!createUpdateCompetitorModal.type && (
        <CreateUpdateCompetitorModal
          drugId={+drugId}
          stakeholder={Stakeholder.Provider}
          typeCompetitor={'CompetitorPositioning'}
          createUpdateCompetitorModal={createUpdateCompetitorModal}
          setCreateUpdateCompetitorModal={setCreateUpdateCompetitorModal}
          strategyId={strategyId}
          stakeholderDefinitionId={
            strategyStakeholderDefinitionId ? String(strategyStakeholderDefinitionId) : ""
          }
          refetchCompetitors={async () => {
            await refetchCompetitors();
            await refetchStakeholderDefinitions();
          }}
        />
      )}

      <DeleteCompetitorModal
        deleteCompetitorModal={deleteCompetitorModal}
        setDeleteCompetitorModal={setDeleteCompetitorModal}
        refetchCompetitors={async () => {
          await refetchCompetitors();
          await refetchStakeholderDefinitions();
        }}
        stakeholderDefinitionsQueryVars={stakeholderDefinitionsQueryVars}
      />

      <ErrorModal
        title="Competitor no longer exists"
        text="The competitor you are looking for has been deleted."
        handleClose={() => setNoCompetitorModal(false)}
        visible={noCompetitorModal}
      />

      <StepHeader isDesktop={isDesktop}>
        <Subtitle2 style={{ marginBottom: '5px' }}>
          What do customers think and feel about each competitor brand?
        </Subtitle2>
        <div
          style={{
            whiteSpace: 'pre-wrap',
            marginBottom: '15px',
          }}
        >
          <BodyNormal color={colors.greyDark} style={{ display: 'inline' }}>
            Choose competitors that you want to position yourself against and add ideas.
          </BodyNormal>
        </div>

        <>
          {!chooseCompetitorsBar &&
            strategyStakeholderDefinitionId &&
            activeCompetitors &&
            selectedStakeholderDefinition ? (
            <CompetitorTabsAndButton
              gap={activeCompetitors.length ? 15 : 0}
            >
              <CompetitorTabs
                competitors={activeCompetitors}
                selectedCompetitorId={+competitorId}
                drugId={+drugId}
                strategyId={+strategyId}
                stakeholder={Stakeholder.Provider}
                selectedStakeholderDefinitionId={
                  +selectedStakeholderDefinition?.id
                }
              />
              {isLead && (
                <>
                  {activeCompetitors.length ? (
                    <Icon
                      name="Pencil"
                      color={colors.purple}
                      size={40}
                      style={{
                        background: colors.purple10,
                        borderRadius: '50%',
                        cursor: 'pointer',
                        height: '40px',
                      }}
                      onClick={openChooseCompetitorsBar}
                    />
                  ) : (
                    <ButtonPill
                      text="Choose competitors"
                      clickClassName="cypress-choose-competitors"
                      onClick={openChooseCompetitorsBar}
                      level="secondary"
                    />
                  )}
                </>
              )}
            </CompetitorTabsAndButton>
          ) : null}
        </>

          {chooseCompetitorsBar &&
              selectedStakeholderDefinition &&
              competitorsSorted && (
                <ChooseCompetitorsBar>
                  <Subtitle2 style={{ marginBottom: '5px' }}>
                    Choose the competitive set relevant to this stakeholder
                  </Subtitle2>
                  <BodyNormal
                    color={colors.greyDark}
                    style={{ marginBottom: '15px' }}
                  >
                    Select from existing competitors or add future ones.
                  </BodyNormal>

                  <CompetitorChips
                    stakeholder={Stakeholder.Provider}
                    type={'CompetitorPositioning'}
                    strategyId={strategyId}
                    drugId={+drugId}
                    competitors={competitorsSorted}
                    setCreateUpdateCompetitorModal={
                      setCreateUpdateCompetitorModal
                    }
                    setDeleteCompetitorModal={setDeleteCompetitorModal}
                    setTreatmentInUseModal={setTreatmentInUseModal}
                    selectedStakeholderDefinition={
                      selectedStakeholderDefinition
                    }
                    stakeholderDefinitionsQueryVars={
                      stakeholderDefinitionsQueryVars
                    }
                    displayDeleteCompetitorModal={displayDeleteCompetitorModal}
                  />
                  <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <ButtonPill
                      text="Done"
                      iconName="Tick"
                      level="secondary"
                      clickClassName="cypress-competitors-done"
                      onClick={closeChooseCompetitorsBar}
                    />
                  </div>
                </ChooseCompetitorsBar>
              )}
      </StepHeader>

      <ErrorWrapper
        isLoading={
          groupsLoading || cardsLoading || stakeholderDefinitionsLoading
        }
        errors={[groupsError, cardsError]}
        dataMissing={noCardsData}
      >
        {noCompetitors ? (
          <PostItsEmpty style={{ margin: 20 }} title="No competitors yet">
            <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
              Choose competitors to start assessing competitor positioning
            </BodySmall>
          </PostItsEmpty>
        ) : (
          <PageWrapper paddingTop={false} fullWidthMobile>
            <Statement
              onClick={() => setStatementModalOpen(true)}
              allowChange={isLead}
              substep={SubStep.CompetitorPositioning}
              selectedCompetitor={selectedCompetitor}
            />

            <StyledPostIts
              step={Step.Positioning}
              groups={groups}
              subStep={SubStep.CompetitorPositioning}
              addCard={createCard}
              removeCard={removeCard}
              updateCard={updateCard}
              handleCardChange={onChange}
              createGroup={createGroup}
              updateGroup={updateGroup}
              removeGroup={removeGroup}
              lastGroup={groups.length === 1}
            />
          </PageWrapper>
        )}
      </ErrorWrapper>
    </>
  );
};
