import { useEffect, useMemo, useState } from 'react';
import {
  ArchetypeFragment,
  Role,
  SubStep,
  Step,
  SupportingMessageTacticFragment,
  SupportingMessageTacticFragmentDoc,
  SupportingMessageTacticsDocument,
  useArchetypesQuery,
  useStrategyQuery,
  useSupportingMessageTacticCreateMutation,
  useSupportingMessageTacticDeleteMutation,
  useSupportingMessageTacticsQuery,
  useSupportingMessageTacticUpdateMutation,
} from 'data/graphql/generated';
import AccessStrategyNav from '../AccessStrategyNav';
import AccessStrategyStepHeader from '../AccessStrategyStepHeader';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { BodyNormal, ButtonLabel, Subtitle2, Tooltip } from 'components/shared';
import { useParams } from 'react-router-dom';
import { polling, colors } from 'constants/index';
import styled from 'styled-components';
import { useAuthContext } from 'contexts/AuthContext';
import useDesktop from 'hooks/useDesktop';
import { AccessStrategyTacticsTable } from './AccessStrategyTacticsTable';
import { AccessStrategyTacticsTableHeader } from './AccessStrategyTacticsTableHeader';
import { AccessStrategyTacticsExamplesModal } from './AccessStrategyTacticsExamplesModal';
import { PageWrapper } from 'containers/RiskMitigationIdeas';
import { useGetNavHeight } from 'hooks/useGetNavHeight';
import { SupportingMessagesSidebar } from './SupportingMessagesSidebar';
import { AccessStrategyRowData } from 'types';
import {
  apolloCreateHelper,
  apolloDeleteHelper,
} from 'utils/apolloQueryHelpers';
import { getTimestamp } from 'utils/dateStringToMonthYear';
import { verifyUserRole } from 'utils/verifyUserRole';
import {
  StyledKeyEventsButton,
} from 'containers/MedicalStrategy/index.style';
import {
  KeyEventsSidebar,
} from 'components/4-1-medical-strategy/shared/feature-key-events-sidebar/src';
import { lowerCase } from 'lodash';


const Content = styled.main`
  max-width: 1118px;
  width: 100%;
  margin-top: 19.5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Divider = styled.div`
  order: 1;
  height: 2px;
  width: 100%;
  background: ${colors.greyMedium};
`;

interface URLParams {
  drugId: string;
  strategyId: string;
}

export function AccessStrategyTactics() {
  const [exampleModalOpen, setExampleModalOpen] = useState(false);
  const [keyEventsSidebarOpen, setKeyEventsSidebarOpen] = useState(false);
  const [
    activeArchetype,
    setActiveArchetype,
  ] = useState<ArchetypeFragment | null>(null);

  const { strategyId }: URLParams = useParams();
  const [{ user }] = useAuthContext();
  const { isLead, isGlobalContributor, isCountryContributor } = verifyUserRole(
    user?.role,
    user?.country
  );
  const isDesktop = useDesktop();
  const navHeight = useGetNavHeight();

  const {
    data: archetypeData,
    loading: archetypeLoading,
    error: archetypeError,
    startPolling: archetypesStartPolling,
    stopPolling: archetypesStopPolling,
  } = useArchetypesQuery({
    variables: {
      where: { strategyId: Number(strategyId), countriesAssigned: true },
      include: { accessStrategyPrioritiseSupportingMessages: true },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const supportingMessageTacticsVars = {
    where: { strategyId: Number(strategyId) },
  };

  const {
    data: supportingMessageTacticsData,
    loading: supportingMessageTacticsLoading,
    error: supportingMessageTacticsError,
    startPolling: supportingMessageTacticsStartPolling,
    stopPolling: supportingMessageTacticsStopPolling,
  } = useSupportingMessageTacticsQuery({
    variables: supportingMessageTacticsVars,
  });

  const {
    data: strategyData,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
    loading: strategyLoading,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    startStrategyPolling(polling.default);
    archetypesStartPolling(polling.default);
    supportingMessageTacticsStartPolling(polling.default);
    return () => {
      archetypesStopPolling();
      stopStrategyPolling();
      supportingMessageTacticsStopPolling();
    };
  }, [
    supportingMessageTacticsStartPolling,
    supportingMessageTacticsStopPolling,
    archetypesStartPolling,
    archetypesStopPolling,
    startStrategyPolling,
    stopStrategyPolling,
  ]);

  const [createRow] = useSupportingMessageTacticCreateMutation();
  const [updateRow] = useSupportingMessageTacticUpdateMutation();
  const [deleteRow] = useSupportingMessageTacticDeleteMutation();

  const handleCreateRow = async (archetypeId?: number) => {
    return await createRow({
      variables: { data: { strategy: +strategyId, archetype: archetypeId } },
      update: apolloCreateHelper({
        responseField: 'supportingMessageTacticCreate',
        query: SupportingMessageTacticsDocument,
        queryVars: supportingMessageTacticsVars,
        queryName: 'supportingMessageTactics',
      }),
    });
  };
  const handleUpdateRow = async ({
    id,
    data,
    initialData,
  }: {
    id: number;
    data: AccessStrategyRowData;
    initialData: SupportingMessageTacticFragment;
  }) => {
    updateRow({
      variables: { id, data },
      optimisticResponse: {
        supportingMessageTacticUpdate: {
          ...initialData,
          ...data,
          focusRationale: data.focusRationale || '',
        },
      },
      update: (cache, { data }) => {
        if (!data) return;
        const cachedSupportingMessageTactic = cache.readFragment({
          id: `SupportingMessageTactic:${data?.supportingMessageTacticUpdate.id}`,
          fragment: SupportingMessageTacticFragmentDoc,
          fragmentName: 'supportingMessageTactic',
        }) as SupportingMessageTacticFragment;

        cache.writeFragment({
          id: `SupportingMessageTactic:${data?.supportingMessageTacticUpdate.id}`,
          fragment: SupportingMessageTacticFragmentDoc,
          fragmentName: 'supportingMessageTactic',
          data: {
            ...cachedSupportingMessageTactic,
            ...data.supportingMessageTacticUpdate,
            // convert date string to timestamp or null
            due: getTimestamp(data.supportingMessageTacticUpdate.due),
            from: getTimestamp(data.supportingMessageTacticUpdate.from),
            to: getTimestamp(data.supportingMessageTacticUpdate.to),
          },
        });
      },
    });
  };

  const handleDeleteRow = (initialData: SupportingMessageTacticFragment) => {
    deleteRow({
      variables: { id: initialData.id },
      optimisticResponse: {
        supportingMessageTacticDelete: {
          ...initialData,
        },
      },
      update: apolloDeleteHelper({
        responseField: 'supportingMessageTacticDelete',
        query: SupportingMessageTacticsDocument,
        queryVars: supportingMessageTacticsVars,
        queryName: 'supportingMessageTactics',
      }),
    });
  };

  const handleActiveArchetype = (
    activeArchetype: ArchetypeFragment | null,
    val: ArchetypeFragment | null
  ) => {
    if (!!activeArchetype && activeArchetype?.id === val?.id) {
      setActiveArchetype(null);
    } else {
      setActiveArchetype(val);
    }
  };

  const archetypes = useMemo(
    () =>
      archetypeData?.archetypes?.items.slice().sort((a, b) => {
        // Alphabetical sort
        const nameA = a.stakeholderDefinition.title.toUpperCase();
        const nameB = b.stakeholderDefinition.title.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }),
    [archetypeData?.archetypes?.items]
  );

  const allArchetypeCountries = Array.from(
    new Set(
      archetypes?.flatMap((archetype) =>
        archetype.archetypeCountries.map((c) => c.country)
      )
    )
  );

  const userCountryInUse =
    user?.role !== Role.Lead &&
    user?.country &&
    allArchetypeCountries.includes(user?.country);
  const data =
    supportingMessageTacticsData?.supportingMessageTactics?.items || [];

  return (
    <>
      {exampleModalOpen && (
        <AccessStrategyTacticsExamplesModal
          handleClose={() => setExampleModalOpen(false)}
          visible={exampleModalOpen}
        />
      )}
      <AccessStrategyNav substep={SubStep.WinningActions} />

      <AccessStrategyStepHeader substep={SubStep.WinningActions}>
        <div style={{display:'flex', width: '100%', justifyContent: 'space-between'}}>
        <Subtitle2 style={{ marginBottom: 5 }}>
          Suggest access initiatives to drive supporting messages

          
        </Subtitle2>
                {true && (
                      <StyledKeyEventsButton
                        plusIcon
                        buttonText="Key events"
                        mobile={false}
                        onClick={() => setKeyEventsSidebarOpen(true)}
                      />
                    )}

        </div>
        <div
          style={{
            whiteSpace: 'pre-wrap',
          }}
        >
          <ButtonLabel
            onClick={() => {
              setExampleModalOpen(true);
            }}
            color={colors.purple}
            style={{ display: 'inline' }}
          >
            View access initiative examples
          </ButtonLabel>
          <BodyNormal color={colors.greyDark} style={{ display: 'inline' }}>
            {' '}
            and add suggestions. The Lead will confirm which access initiatives
            will be focused on.
          </BodyNormal>

          <KeyEventsSidebar
              type={Step.AccessStrategy}
              isOpen={keyEventsSidebarOpen}
              onClose={() => setKeyEventsSidebarOpen(false)}
              strategyId={Number(strategyId)}
              region={lowerCase('global')}
              step={Step.AccessStrategy}
              isGlobalContributor={isGlobalContributor}
              canEnable={false} 
            />
        </div>
      </AccessStrategyStepHeader>
      <div style={{ display: 'flex' }}>
        <PageWrapper paddingTop={false} fullWidthMobile>
          <ErrorWrapper
            isLoading={
              archetypeLoading ||
              supportingMessageTacticsLoading ||
              strategyLoading
            }
            errors={[
              strategyError,
              supportingMessageTacticsError,
              archetypeError,
            ]}
            dataMissing={
              !archetypeData || !supportingMessageTacticsData || !strategyData
            }
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: 20,
              }}
            >
              <Content>
                <AccessStrategyTacticsTable
                  header={
                    <AccessStrategyTacticsTableHeader
                      title={'Global (Across all archetypes)'}
                    />
                  }
                  isLead={isLead || isGlobalContributor}
                  isGlobalContributor={isGlobalContributor}
                  isCountryContributor={isCountryContributor}
                  isDesktop={isDesktop}
                  handleUpdateRow={handleUpdateRow}
                  currency={strategyData?.strategy?.currency[0] || '¥'}
                  data={data}
                  handleCreateRow={handleCreateRow}
                  handleDeleteRow={handleDeleteRow}
                />
                {userCountryInUse && <Divider />}
                {archetypes?.map((archetype) => {
                  const includesUserCountry =
                    user?.role !== Role.Lead &&
                    archetype.archetypeCountries.some((country) => {
                      return country.country === user?.country;
                    });
                  return (
                    <AccessStrategyTacticsTable
                      isActive={activeArchetype?.id === archetype.id}
                      key={archetype.id}
                      header={
                        <>
                          <AccessStrategyTacticsTableHeader
                            isActive={activeArchetype?.id === archetype.id}
                            setIsActive={() => {
                              handleActiveArchetype(activeArchetype, archetype);
                            }}
                            includesUserCountry={includesUserCountry}
                            countries={archetype.archetypeCountries}
                            title={archetype.stakeholderDefinition.title}
                          />
                        </>
                      }
                      archetype={archetype}
                      includesUserCountry={includesUserCountry}
                      isLead={isLead || isGlobalContributor}
                      isGlobalContributor={isGlobalContributor}
                      isCountryContributor={isCountryContributor}
                      isDesktop={isDesktop}
                      handleUpdateRow={handleUpdateRow}
                      currency={strategyData?.strategy?.currency[0] || '¥'}
                      data={data}
                      handleCreateRow={handleCreateRow}
                      handleDeleteRow={handleDeleteRow}
                    />
                  );
                })}
              </Content>
            </div>
          </ErrorWrapper>
        </PageWrapper>

        <SupportingMessagesSidebar
          activeArchetype={activeArchetype}
          isOpen={!!activeArchetype}
          heightOffset={navHeight}
          onClose={() => {
            setActiveArchetype(null);
          }}
        />
        <Tooltip
          effect="float"
          capitalize
          id={'AccessStrategyTactics__Tooltip'}
        />
      </div>
    </>
  );
}
