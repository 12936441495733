import { BodySmall, EmptyState, Subtitle2 } from 'components/shared';
import { ValueText } from 'components/shared/EditableTextarea';
import { SelectedOption } from 'components/shared/TextDropdown';
import { colors } from 'constants/colors';
import {
  KeyInsightsQuery,
  KeyInsightsQueryVariables,
  LeveragePointFragment,
  Sort,
  StrategicPossibilityUpdateInput,
  useKeyInsightsQuery,
  User,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import { useWidth } from 'hooks/useWidth';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { createStakeholderDropdownList } from 'utils/createStakeholderDropdownList';
import { StrategicPossibilityRow } from './StrategicPossibilityRow';
import { useParams } from 'react-router-dom';

const tableColumns = css`
  grid-template-columns: repeat(4, minmax(110px, 250px));
`;

const Wrapper = styled.div`
  margin-bottom: 15px;

  ${ValueText} {
    @media ${device.desktopMin} {
      margin-top: 5px;
    }
  }

  ${SelectedOption} {
    padding: 4px 0px;
    @media ${device.desktopMin} {
      padding: 4px 5px;
    }
  }

  .UserDropdown__NonEditable__content {
    margin-top: 3px;
  }
`;

const TableContainerMobile = styled.div`
  border-bottom: 1px solid ${colors.greyLight};
`;

const TableContainerDesktop = styled.div<{ emptyState: boolean }>`
  border-bottom: ${({ emptyState }) =>
    !emptyState && `1px solid ${colors.greyLight}`};
`;

const RowTitlesDesktop = styled.div`
  display: grid;
  ${tableColumns}
  grid-gap: 20px;
  padding-left: 15px;
  padding-bottom: 10px;
`;

const RowDataContainerDesktop = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledEmptyState = styled(EmptyState)`
  border-radius: 5px;
  padding: 11px;
`;

export interface LeveragePointTableProps {
  updateStrategicPossibility(
    id: number,
    data: StrategicPossibilityUpdateInput
  ): Promise<void>;
  deleteStrategicPossibility(id: number): Promise<void>;
  leveragePoint: LeveragePointFragment;
  user?: User;
  autoFocus: boolean;
  setAutoFocus: (value: React.SetStateAction<boolean>) => void;
}
export const LeveragePointTable: React.FC<LeveragePointTableProps> = ({
  updateStrategicPossibility,
  deleteStrategicPossibility,
  leveragePoint,
  user,
  autoFocus,
  setAutoFocus,
}) => {
  const isDesktop = useDesktop();
  const width = useWidth();
  const [deleteRowConfirmation, setDeleteRowConfirmation] = React.useState<
    null | number
  >(null);
  const { strategyId } = useParams<{
    strategyId: string;
  }>();

  React.useEffect(() => {
    // Set table titles' columns to match row data columns
    const rowFieldsDesktop = document.getElementById('row-fields-desktop');
    const rowFieldsWidth = rowFieldsDesktop?.clientWidth;
    const rowTitlesDesktop = document.getElementById('row-titles-desktop');

    rowFieldsWidth &&
      rowTitlesDesktop?.style.setProperty(
        'width',
        rowFieldsWidth.toString() + 'px'
      );
  }, [width]);

  const keyInsightsQueryVars: KeyInsightsQueryVariables = {
    where: { strategyId: Number(strategyId) },
    orderBy: { createdAt: Sort.Desc },
  };

  const {
    data: keyInsightsData,
  } = useKeyInsightsQuery({
    variables: {
      ...keyInsightsQueryVars,
    },
  });

  const relevantKeyInsights = keyInsightsData?.keyInsights?.items as KeyInsightsQuery[];
  const strategicPossibilities = leveragePoint.strategicPossibilities;
  const sectionKeyStakeholdersList = useMemo(
    () => createStakeholderDropdownList(leveragePoint),
    [leveragePoint]
  );

  return (
    <Wrapper>
      {!isDesktop ? (
        <TableContainerMobile>
          {strategicPossibilities.map((s) => {
            return (
              <StrategicPossibilityRow
                key={s.id}
                isDesktop={isDesktop}
                user={user}
                autoFocus={autoFocus}
                possibilityId={s.id}
                initialValue={s.behaviourToDrive || ''}
                keyStakeholdersList={sectionKeyStakeholdersList}
                strategicPossibility={s}
                deleteRowConfirmation={deleteRowConfirmation}
                updateStrategicPossibility={updateStrategicPossibility}
                relevantKeyInsights={relevantKeyInsights}
                setDeleteRowConfirmation={setDeleteRowConfirmation}
                deleteStrategicPossibility={deleteStrategicPossibility}
                setAutoFocus={setAutoFocus}
              />
            );
          })}
        </TableContainerMobile>
      ) : (
        <TableContainerDesktop emptyState={!strategicPossibilities.length}>
          <div style={{ display: 'flex' }}>
            <RowTitlesDesktop id="row-titles-desktop">
            <RowTitle text="Key Stakeholder" />
              <RowTitle text="Behaviour to drive" />
              <RowTitle text="Unlocking insight" />
              <RowTitle text="Driver of the behaviour" />
            </RowTitlesDesktop>
            <div style={{ minWidth: 280 }}></div>
          </div>
          <RowDataContainerDesktop>
            {strategicPossibilities.length ? (
              strategicPossibilities.map((s) => {
                return (
                  <StrategicPossibilityRow
                    key={s.id}
                    isDesktop={isDesktop}
                    user={user}
                    autoFocus={autoFocus}
                    possibilityId={s.id}
                    initialValue={s.behaviourToDrive || ''}
                    keyStakeholdersList={sectionKeyStakeholdersList}
                    strategicPossibility={s}
                    deleteRowConfirmation={deleteRowConfirmation}
                    updateStrategicPossibility={updateStrategicPossibility}
                    relevantKeyInsights={relevantKeyInsights}
                    setDeleteRowConfirmation={setDeleteRowConfirmation}
                    deleteStrategicPossibility={deleteStrategicPossibility}
                    setAutoFocus={setAutoFocus}
                  />
                );
              })
            ) : (
              <StyledEmptyState includeIcon={false}>
                <Subtitle2 color={colors.greyDark}>
                  No strategic possibilities yet
                </Subtitle2>
                <BodySmall color={colors.greyDark}>
                  Add strategic possibilities to this leverage point to share
                  your ideas
                </BodySmall>
              </StyledEmptyState>
            )}
          </RowDataContainerDesktop>
        </TableContainerDesktop>
      )}
    </Wrapper>
  );
};

export const RowTitle = ({ text }: { text: string }) => (
  <BodySmall color={colors.greyDark}>{text}</BodySmall>
);
