import { AnimatingWaveIcon, ButtonLabel, Icon } from 'components/shared';
import { colors } from 'constants/colors';
import {
  Stakeholder,
  CompetitorFragment,
  StakeholderDefinitionFragment,
  StakeholderDefinitionsDocument,
  StakeholderDefinitionsQueryVariables,
  useCompetitorUpdateMutation,
  CompetitiveCategory,
} from 'data/graphql/generated';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CompetitorModalState } from 'types';
import { useParams } from 'react-router-dom';

const Wrapper = styled.div<{ active: boolean }>`
  height: fit-content;
  padding: 5px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  background: ${({ active }) => (active ? colors.purple : colors.greyLight)};

  &:hover {
    cursor: pointer;
  }
`;

const EditIcon = styled(Icon)`
  height: 20px;

  svg {
    width: 12px;
    stroke-width: 1.8;
  }
`;

const TrashIcon = styled(Icon)`
  svg {
    width: 15px;
    margin-right: -1px;
  }
`;

const WhiteCircle = styled.div`
  height: 20px;
  width: 20px;
  border: 2px solid white;
  border-radius: 50%;
`;

interface Props {
  setCreateUpdateCompetitorModal: React.Dispatch<
    React.SetStateAction<CompetitorModalState>
  >;
  competitor: CompetitorFragment;
  stakeholder: Stakeholder;
  setTreatmentInUseModal: React.Dispatch<React.SetStateAction<boolean>>;
  stakeholderDefinitionsQueryVars: StakeholderDefinitionsQueryVariables;
  selectedStakeholderDefinition: StakeholderDefinitionFragment;
  displayDeleteCompetitorModal: (
    competitor: CompetitorFragment
  ) => Promise<void>;
}


interface URLParams {
  category: CompetitiveCategory;
}

export const CompetitorChip: React.FC<Props> = ({
  setCreateUpdateCompetitorModal,
  competitor,
  stakeholder,
  setTreatmentInUseModal,
  stakeholderDefinitionsQueryVars,
  selectedStakeholderDefinition,
  displayDeleteCompetitorModal,
}) => {
  const {
    category,
  }: URLParams = useParams();
  const [competitorUpdate] = useCompetitorUpdateMutation();
  const [loading, setLoading] = useState(false);
  let { id, title, image } = competitor;
  const [active, setActive] = useState<boolean>(
    !!selectedStakeholderDefinition?.CompetitorDetails?.some(
      (details) =>
        details?.competitorId === competitor.id &&
        details.stakeholder === stakeholder
    )
  );

  useEffect(() => {
    setActive(
      !!selectedStakeholderDefinition.CompetitorDetails?.some(
        (details) =>
          details?.competitorId === competitor.id &&
          details?.stakeholder === stakeholder && details?.type === category
      )
    );
  }, [selectedStakeholderDefinition, competitor, stakeholder, category]);

  async function toggleActive() {
    if (active) {
      setLoading(true);
      // Competitor has no post its for the selected stakeholder definition,
      // we can disable its active state
      try {
        await competitorUpdate({
          variables: {
            id: +competitor.id,
            data: {
              stakeholder,
              type: category,
              removeStakeholderDefinitionId: +selectedStakeholderDefinition.id,
            },
          },
          refetchQueries: [
            {
              query: StakeholderDefinitionsDocument,
              variables: stakeholderDefinitionsQueryVars,
            },
          ],
        });
        setActive(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (
          error instanceof Error &&
          error.message === 'Cannot remove treatment in use'
        ) {
          return setTreatmentInUseModal(true);
        }

        alert(error);
      }
    } else {
      // Competitor is inactive, so we activate it
      try {
        setLoading(true);

        await competitorUpdate({
          variables: {
            id: +competitor.id,
            data: {
              stakeholder,
              type: category,
              stakeholderDefinitionId: +selectedStakeholderDefinition.id,
            },
          },

          refetchQueries: [
            {
              query: StakeholderDefinitionsDocument,
              variables: stakeholderDefinitionsQueryVars,
            },
          ],
        });
        setActive(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        alert(error);
      }
    }
  }

  return (
    <Wrapper active={active}>
      {active ? (
        loading ? (
          <AnimatingWaveIcon className="icon" size={20} color={'white'} />
        ) : (
          <Icon
            name="Tick Small"
            size={20}
            color={colors.purple}
            style={{
              backgroundColor: colors.white,
              borderRadius: '50%',
            }}
            onClick={toggleActive}
          />
        )
      ) : loading ? (
        <AnimatingWaveIcon className="icon" size={20} />
      ) : (
        <WhiteCircle onClick={toggleActive} />
      )}
      <ButtonLabel
        style={{
          color: active ? colors.white : colors.black,
        }}
        onClick={toggleActive}
      >
        {title}
      </ButtonLabel>
      <EditIcon
        name="Pencil"
        size={20}
        color={colors.greyDark}
        style={{ backgroundColor: colors.cream, borderRadius: '50%' }}
        onClick={() =>
          setCreateUpdateCompetitorModal({
            type: 'edit',
            competitorId: id,
            title,
            error: '',
            image: image || '',
          })
        }
      />
      <TrashIcon
        name="Trash"
        className="cypress-competitor-chip-delete-icon"
        size={20}
        color={colors.darkRed}
        style={{ backgroundColor: colors.cream, borderRadius: '50%' }}
        onClick={() => displayDeleteCompetitorModal(competitor)}
      />
    </Wrapper>
  );
};
