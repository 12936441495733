import styled from 'styled-components/macro';

import { colors } from 'constants/colors';
import { ButtonPill } from 'components/shared';

export const StyledButtonPill = styled(ButtonPill)`
  height: 26px;
  button {
    height: 30px;
    padding: 5px 15px;
    width: 86px;
    border-radius: 25px;
  }
`;

export const StyledButtonContainer = styled.div.attrs({
  'data-cy': 'lightning-button',
})<{ active: boolean }>`
  border-radius: 25px;
  padding: 2px;
 /* TODO: wait for new design
  background: ${({ active }) =>
    active ? colors.white : colors.purplePinkGradient};
  */
  display: flex;
`;
