import {
  ApolloQueryResult,
  FetchResult,
  MutationFunctionOptions,
} from '@apollo/client';
import { ButtonPill, Icon, Subtitle2 } from 'components/shared';
import { ButtonPillWrapper } from 'components/shared/ButtonPill';
import { colors } from 'constants/index';
import {
  StakeholderDefinitionFragment,
  Exact,
  KeyInsightCreateMutation,
  KeyInsightDeleteMutation,
  KeyInsightFragment,
  KeyInsightInput,
  KeyInsightOrderByInput,
  KeyInsightsQuery,
  KeyInsightUpdateInput,
  KeyInsightUpdateMutation,
  KeyInsightWhereInput,
  Maybe,
  ObservationFragment,
  ObservationOrderByInput,
  ObservationWhereInput,
  Stakeholder,
  usePostItGroupCreateMutation,
  Step,
  SubStep,
  StatementsDocument,
  usePostItCardCreateMutation,
  PostItCardType,
} from 'data/graphql/generated';
import useMobile from 'hooks/useMobile';
import React, { useEffect, useState } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled, { css, keyframes } from 'styled-components/macro';
import { uid } from 'uid';
import { device } from 'utils/breakpoints';
import { ErrorModal } from './ErrorModal';
import { Insight } from './Insight';
import { verifyUserRole } from 'utils/verifyUserRole';
import { useAuthContext } from 'contexts/AuthContext';

const shrink = keyframes`
  from {
    opacity: 1;
    max-height: 300px;
    transform: translateY(0px);
    margin-bottom:0px;

  }
  to {
    margin-bottom:-15px;
    opacity: 0;
    max-height: 0vh;
    transform: translateY(-100px);
  }
`;

export const TransitionWrapper = styled.div<{
  state: TransitionStatus;
  isPreview: boolean;
}>`
  transform: translateY(-100px);
  opacity: 0;
  transition: all 0.3s ease;

  ${({ state, isPreview }) => {
    switch (state) {
      case 'entering':
        return css`
          max-height: 300px;
          transform: translateY(-100px);
        `;

      case 'entered':
        return css`
          transform: translateY(0px);
          opacity: 1;
          max-height: max-content;
        `;

      case 'exiting':
        return css`
          animation: ${shrink} 0.3s forwards;
        `;

      case 'exited':
        return css`
          transform: translateY(-100px);
          opacity: 0;
          height: 0px;
          max-height: 0vh;
        `;
    }
  }}
`;

interface Props {
  enableAddObservations: boolean;
  showAddObservations: number | boolean;
  setShowAddObservations: React.Dispatch<
    React.SetStateAction<number | boolean>
  >;
  keyInsightsQueryVars: Exact<{
    where?: KeyInsightWhereInput | null | undefined;
    orderBy?: KeyInsightOrderByInput | null | undefined;
    skip?: number | null | undefined;
    take?: number | null | undefined;
  }>;
  keyInsightsRefetch: () => Promise<ApolloQueryResult<KeyInsightsQuery>>;
  observationsData:
  | Array<Maybe<{ __typename?: 'Observation' } & ObservationFragment>>
  | undefined;
  setObservationsPaneFilterData: React.Dispatch<
    React.SetStateAction<(number | null)[]>
  >;
  title: string;
  data: KeyInsightsQuery | undefined;

  createKeyInsight: (
    options?:
      | MutationFunctionOptions<
        KeyInsightCreateMutation,
        Exact<{
          data: KeyInsightInput;
        }>
      >
      | undefined
  ) => Promise<
    FetchResult<
      KeyInsightCreateMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;

  updateKeyInsight: (
    options?:
      | MutationFunctionOptions<
        KeyInsightUpdateMutation,
        Exact<{
          id: number;
          data: KeyInsightUpdateInput;
        }>
      >
      | undefined
  ) => Promise<
    FetchResult<
      KeyInsightUpdateMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  deleteKeyInsight: (
    options?:
      | MutationFunctionOptions<KeyInsightDeleteMutation, Exact<{ id: number }>>
      | undefined
  ) => Promise<FetchResult<any>>;
  columnId: number;
  drugId: string;
  strategyId: string;
  queryVars: Exact<{
    where?: ObservationWhereInput | null | undefined;
    orderBy?: ObservationOrderByInput | null | undefined;
    skip?: number | null | undefined;
    take?: number | null | undefined;
  }>;
  stakeholder: Stakeholder;
  setRemovingFromInsight: React.Dispatch<React.SetStateAction<boolean>>;
  stakeholderDefinitions: Array<
    Maybe<
      { __typename?: 'StakeholderDefinition' } & StakeholderDefinitionFragment
    >
  >;
}

const Wrapper = styled.div<{
  groupActive: boolean;
  showAddObservations: boolean | number;
}>`
  width: 575px;
  background-color: ${colors.greyLight};
  border-radius: 5px;
  padding: 15px;
  padding-bottom: 25px;

  margin: 0 auto;
  border: 1px solid ${colors.black10};
  position: relative;
  z-index: 1;
  @media ${device.tabletMax} {
    width: 100%;
    max-width: 575px;
    min-height: auto;
    padding-bottom: 15px;
  }
  @media ${device.mobile} {
    border: 0px solid ${colors.black10};
    border-radius: 0px;
    padding-bottom: 15px;
  }

  ${({ showAddObservations }) => {
    if (showAddObservations) {
      return css`
        transform: translateX(-155px);
        @media ${device.tabletMax} {
          transform: translateX(0);
        }
      `;
    }
  }}
  transition: transform 0.2s;
`;

const Header = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 40px;
  margin-bottom: 15px;

  ${ButtonPillWrapper} {
    padding: ${({ isMobile }) => isMobile && '0 5px'};
  }

  @media ${device.tabletMin} {
    justify-content: flex-start;
  }
`;

const StyledSubtitle2 = styled(Subtitle2)`
  text-align: center;

  @media ${device.tabletMin} {
    order: 2;
    margin-left: 15px;
  }
`;

const EmptyWrapper = styled.div`
  background: ${colors.white};
  width: 100%;
  height: 205px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export type InsightWithPreview = Array<
  KeyInsightFragment | { localUid: string } | null
>;

export const InsightGroup = ({
  setObservationsPaneFilterData,
  title,
  data,
  createKeyInsight,
  updateKeyInsight,
  deleteKeyInsight,
  columnId,
  drugId,
  strategyId,
  stakeholder,
  observationsData,
  keyInsightsQueryVars,
  keyInsightsRefetch,
  setShowAddObservations,
  showAddObservations,
  enableAddObservations,
  setRemovingFromInsight,
  stakeholderDefinitions,
}: Props) => {
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);

  const [insights, setInsights] = useState<InsightWithPreview>(
    () =>
      data?.keyInsights?.items.filter((val) => val?.columnId === columnId) || []
  );

  const [previewSaved, setPreviewSaved] = useState(true);
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const [groupCreate] = usePostItGroupCreateMutation();
  const [cardCreate] = usePostItCardCreateMutation();

  useEffect(() => {
    setInsights((insights) => {
      const filteredData = data?.keyInsights?.items

      //If no preview exists, give me the updated data
      if (previewSaved) {
        return filteredData || [];
      }

      //If we have a preview which we have not saved,
      //update the data but keep the preview on top
      if (!previewSaved) {
        return [insights[0], ...(filteredData || [])];
      }

      return filteredData || [];
    });
  }, [columnId, data, previewSaved]);

  const [previewHasText, setPreviewHasText] = useState(false);
  const isMobile = useMobile();

  //This controls the transition of the timestamp when a new item is created
  const [newItemId, setNewItemId] = useState('');

  if (!insights || !data) {
    return null;
  }

  return (
    <Wrapper
      showAddObservations={showAddObservations}
      groupActive={insights.some((val) => {
        return val && 'id' in val && val?.id === showAddObservations;
      })}
    >
      <ErrorModal
        title="Cannot delete this insight"
        text=" Content in later steps depends on this insight. Remove content and try again."
        visible={showCannotDeleteModal}
        handleClose={() => setShowCannotDeleteModal(false)}
      />
      {isLead && (
        <Header isMobile={isMobile}>
          <StyledSubtitle2 color={colors.black70}>{title}</StyledSubtitle2>

          <ButtonPill
            disabled={!previewSaved && !previewHasText}
            iconName="Plus"
            level="primary-solid"
            className="add-insight"
            clickClassName="cypress-insight-create"
            onClick={async (e) => {
              e.stopPropagation();
              if (previewSaved) {
                const previewUID = uid();
                setInsights((state) => {
                  if (state) {
                    const newArr = state.slice();
                    newArr.unshift({ localUid: previewUID });
                    return newArr;
                  }
                  return state;
                });
                setPreviewSaved(false);
              }
            }}
            text={isMobile ? '' : 'Add insight'}
          />
        </Header>
      )}
      {insights.length ? (
        <ChildrenWrapper>
          <TransitionGroup component={null}>
            {insights.map((insight, idx) => {
              //The preview insight is an object that only contains a localUID ,
              //so we can always check for that to determine if
              const isPreview = !!insight && !('id' in insight);
              return (
                <Transition
                  enter={true}
                  exit={true}
                  key={insight?.localUid || -1}
                  timeout={300}
                >
                  {(state) => {
                    return (
                      <TransitionWrapper state={state} isPreview={isPreview}>
                        <Insight
                          setPreviewHasText={setPreviewHasText}
                          setRemovingFromInsight={setRemovingFromInsight}
                          isNewItem={
                            !!(insight && 'localUid' in insight) &&
                            newItemId === insight?.localUid
                          }
                          readonly={!isLead}
                          enableAddObservations={enableAddObservations}
                          showAddObservations={showAddObservations}
                          setShowAddObservations={setShowAddObservations}
                          keyInsightsQueryVars={keyInsightsQueryVars}
                          observationsData={observationsData}
                          insightData={insight}
                          preview={isPreview}
                          //@ts-ignore
                          id={insight && 'id' in insight ? insight?.id : -1}
                          //@ts-ignore
                          theWhoPageId={insight?.theWhoPageId || -1}
                          createKeyInsight={async (
                            text: string,
                            observations: (number | null)[],
                            localUid: string
                          ) => {
                            const d = await createKeyInsight({
                              variables: {
                                data: {
                                  strategy: Number(strategyId),
                                  drug: Number(drugId),
                                  text: text,
                                  stakeholder,
                                  columnId: columnId,
                                  observations,
                                  localUid,
                                },
                              },
                            });

                            const { data } = d;
                            const k = data?.keyInsightCreate || null;

                            if (data && k) {
                              const statementRefetch = {
                                refetchQueries: [
                                  {
                                    query: StatementsDocument,
                                    variables: {
                                      where: {
                                        strategyId: +k?.strategyId,
                                        step: Step.Positioning,
                                        substep: [SubStep.TheWho],
                                      },
                                    },
                                  },
                                ],
                              };

                              const r = await groupCreate({
                                variables: {
                                  data: {
                                    title: text,
                                    company: k.companyId,
                                    step: Step.Positioning,
                                    strategy: Number(k.strategyId),
                                    stakeholder: null,
                                    keyInsightId: k.id,
                                    substep: SubStep.TheWho,
                                    drug: Number(k.drugId),
                                    preset: false,
                                  },
                                },
                                ...statementRefetch,
                                awaitRefetchQueries: true,
                                errorPolicy: 'ignore',
                              });

                              await cardCreate({
                                variables: {
                                  data: {
                                    title: text,
                                    pos: 1000,
                                    type: PostItCardType.Plain,
                                    step: Step.Positioning,
                                    strategy: Number(k.strategyId),
                                    stakeholder: null,
                                    substep: SubStep.TheWho,
                                    drug: Number(k.drugId),
                                    postItGroup: r?.data?.postItGroupCreate?.id,
                                    include: true,
                                  },
                                },
                                ...statementRefetch,
                                awaitRefetchQueries: true,
                                errorPolicy: 'ignore',
                              });

                              const group = r?.data?.postItGroupCreate || null;

                              if (group) {
                                await updateKeyInsight({
                                  variables: {
                                    id: Number(k.id),
                                    data: {
                                      theWhoPageId: group.id,
                                    },
                                  },
                                });
                              }
                            }
                            setNewItemId(localUid);
                            await keyInsightsRefetch();
                            setPreviewSaved(true);
                          }}
                          deleteKeyInsight={async (args) => {
                            try {
                              await deleteKeyInsight(args);
                              return await keyInsightsRefetch();
                            } catch (error) {
                              if (
                                error instanceof Error &&
                                error.message === 'IN_USE'
                              ) {
                                return setShowCannotDeleteModal(true);
                              }
                            }
                          }}
                          updateKeyInsight={updateKeyInsight}
                          deletePreviewInsight={() => {
                            setShowAddObservations(false);
                            setInsights((insights) =>
                              insights.filter((val) => {
                                return val && 'id' in val && !!val?.id;
                              })
                            );
                            setPreviewSaved(true);
                          }}
                          setObservationsPaneFilterData={
                            setObservationsPaneFilterData
                          }
                          key={
                            insight && 'localUid' in insight
                              ? insight?.localUid
                              : //@ts-ignore
                                insight?.id
                          }
                        />
                      </TransitionWrapper>
                    );
                  }}
                </Transition>
              );
            })}
          </TransitionGroup>
        </ChildrenWrapper>
      ) : (
        <EmptyWrapper>
          <Icon
            name="GenericEmptyState"
            size={115}
            height={115}
            color="initial"
          />
          <StyledSubtitle2 color={colors.greyDark}>
            Insights will appear here
          </StyledSubtitle2>
        </EmptyWrapper>
      )}
    </Wrapper>
  );
};
