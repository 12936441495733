import React, {
  createRef,
  RefObject,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import styled from 'styled-components/macro';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { Navbar } from 'components/Navbar';
import { Page, Wrapper } from 'components/Page';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { device } from 'utils/breakpoints';
import {
  BodyNormal,
  BodySmall,
  ButtonPill,
  StepHeaderBar,
  Subtitle2,
} from 'components/shared';
import { colors } from 'constants/colors';
import { useAuthContext } from 'contexts/AuthContext';
import {
  ArchetypeWhereInput,
  useArchetypesQuery,
  useStrategyCountriesQuery,
} from 'data/graphql/generated';
import { ArchetypeTable } from './ArchetypeTable';
import useDesktop from 'hooks/useDesktop';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { countries, polling, themes } from 'constants/index';
import { debounce } from 'lodash';
import { verifyUserRole } from 'utils/verifyUserRole';
import URLHelper from 'utils/URLHelper';

const SideNavWrapper = styled.nav<{ noArchetypes?: boolean }>`
  margin-right: auto;
  inset: 0;
  width: fit-content;

  @media ${device.tabletMax} {
    position: relative;
    inset: unset;
  }

  @media ${device.mobile} {
    position: relative;
    left: 15px;
  }

  @media (min-width: 1000px) {
    // breakpoint at which "Back to step" button won't overlap Empty State
    position: ${({ noArchetypes }) => (noArchetypes ? 'absolute' : 'relative')};
  }
`;

const SideNav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: fit-content;
  height: fit-content;

  position: sticky;
  top: 100px;

  @media ${device.tabletMax} {
    position: relative;
    top: unset;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  gap: 15px;

  @media ${device.tabletMax} {
    flex-direction: column;
  }
`;

const StyledStepHeaderBar = styled(StepHeaderBar)`
  min-height: auto;
  padding: 15px 20px;
  margin-top: 50px;
`;

const PageWrapper = styled(Page)`
  padding: 0px 0 60px;
  overflow: visible; // for the dropdown components

  margin-top: 20px;

  ${Wrapper} {
    max-width: 1440px;
  }

  @media ${device.tabletMax} {
    justify-content: center;
    display: flex;
  }

  @media ${device.tabletMin} {
    ${Wrapper} {
      width: auto;
      padding: 0 15px;
    }
  }

  @media ${device.desktopMin} {
    ${Wrapper} {
      padding: 0 20px;
    }
  }

  @media ${device.mobile} {
    ${Wrapper} {
      width: 100%;
    }
  }
`;

interface URLParams {
  drugId: string;
  strategyId: string;
}

export const AccessStrategySetup = () => {
  const { drugId, strategyId }: URLParams = useParams();
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);
  const history = useHistory();
  const isDesktop = useDesktop();
  const [tableRefs, setTableRefs] = useState<
    Record<number, RefObject<HTMLDivElement>>
  >({});
  const disableObserverRef = useRef(false);
  const [activeArchetypeId, setActiveArchetypeId] = useState<number | null>(
    null
  );

  const archetypesQueryVars: Record<'where', ArchetypeWhereInput> = {
    where: { strategyId: Number(strategyId) },
  };

  const {
    data: archetypeData,
    loading: archetypeLoading,
    error: archetypeError,
    startPolling: archetypeStartPolling,
    stopPolling: archetypeStopPolling,
  } = useArchetypesQuery({
    variables: {
      ...archetypesQueryVars,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const { data: strategyCountries } = useStrategyCountriesQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });

  const archetypes = useMemo(
    () =>
      archetypeData?.archetypes?.items.slice().sort((a, b) => {
        let nameA = a.stakeholderDefinition.title.toUpperCase();
        let nameB = b.stakeholderDefinition.title.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }),
    [archetypeData?.archetypes?.items]
  );

  const assignedCountries = Array.from(
    new Set(
      archetypes?.flatMap((archetype, idx) => {
        return archetype.archetypeCountries.map((val) => val.country);
      })
    )
  );

  const collaboratingCountries = useMemo(
    () =>
      strategyCountries?.strategyCountries?.countries.map((val) => {
        return val;
      }),
    [strategyCountries?.strategyCountries?.countries]
  );

  const sortedCountries = countries
    ?.filter((country) => {
      return !assignedCountries.includes(country.name);
    })
    .map((val) => val.name)
    .sort(
      // Sort by alphabet and collaborating
      (a, b) => {
        if (collaboratingCountries?.includes(a)) {
          if (!collaboratingCountries?.includes(b)) {
            return -1;
          }
        }

        if (collaboratingCountries?.includes(b)) {
          if (!collaboratingCountries?.includes(a)) {
            return 1;
          }
        }

        let nameA = a.toUpperCase();
        let nameB = b.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      }
    );

  useEffect(() => {
    if (!archetypes?.length) {
      return;
    }
    // add or remove refs
    setTableRefs((rowRefs) =>
      archetypes?.reduce((acc, val) => {
        if (!!val) {
          acc[val.id as keyof typeof acc] = createRef();
          return acc;
        }
        return acc;
      }, {} as Record<number, RefObject<HTMLDivElement>>)
    );
  }, [archetypes]);

  useEffect(() => {
    let callback: IntersectionObserverCallback = (entries, observer) => {
      for (let i = 0; i < entries.length; i++) {
        let entry = entries[i];
        let { isIntersecting, intersectionRect } = entry;

        let elem = entry.target;
        if (isIntersecting && intersectionRect.top === 150) {
          const id = elem.getAttribute('data-id');

          if (id && !disableObserverRef.current) {
            setActiveArchetypeId(+id);
          }
        }
      }
    };

    function buildThresholdList() {
      let thresholds = [];
      let numSteps = 50;

      for (let i = 1.0; i <= numSteps; i++) {
        let ratio = i / numSteps;
        thresholds.push(ratio);
      }

      thresholds.push(0);
      return thresholds;
    }

    const navPlusStepBarHeight = -150;

    let options: IntersectionObserverInit = {
      rootMargin: `${navPlusStepBarHeight}px 0px 0px 0px`,
      threshold: buildThresholdList(),
    };

    let observer: IntersectionObserver = new IntersectionObserver(
      callback,
      options
    );
    Object.values(tableRefs).forEach((target, idx) => {
      if (!!target.current) {
        observer.observe(target.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [tableRefs]);

  useEffect(() => {
    archetypeStartPolling(polling.default);
    return () => {
      archetypeStopPolling();
    };
  }, [archetypeStartPolling, archetypeStopPolling]);

  const debouncedDisableObserver = debounce(() => {
    // prevent the observer from overriding the nav
    disableObserverRef.current = false;
  }, 1000);

  return (
    <>
      {!isLead && (
        <Redirect
          to={`/d/${drugId}/strategy/${strategyId}/4_1/prioritisation`}
        />
      )}

      <Navbar
        stepNumber="4.1"
        title={themes.activate.tools['4.1'].name}
        prev={{
          url: URLHelper.getCriticalMetricsLeadAndLagIndicatorsUrl(
            drugId,
            strategyId
          ),
          title: themes.develop.tools['3.5'].name
        }}
        next={{
          title: themes.activate.tools['4.2'].name,
          url: `/d/${drugId}/strategy/${strategyId}/4_2`,
        }}
        disableSecondary
      />
      <StyledStepHeaderBar>
        <div>
          <Subtitle2 style={{ marginBottom: '5px' }}>
            Assign countries to archetypes and add the filing and launch dates
          </Subtitle2>
          <BodyNormal color={colors.greyDark}>
            Add countries to the pre-existing archetypes, or create custom ones.
            Changes are saved automatically.
          </BodyNormal>
        </div>
      </StyledStepHeaderBar>

      <ErrorWrapper
        isLoading={archetypeLoading}
        errors={[archetypeError]}
        dataMissing={false}
      >
        <PageWrapper
          className="archetype__page__wrapper"
          paddingTop={false}
          fullWidthMobile
        >
          <ContentWrapper>
            <SideNavWrapper noArchetypes={!archetypes?.length}>
              <SideNav>
                <ButtonPill
                  iconName="Arrow-left"
                  text="Back to step"
                  level="secondary"
                  onClick={() => {
                    history.push(`/d/${drugId}/strategy/${strategyId}/4_1/`);
                  }}
                />
                {!!isDesktop &&
                  archetypes?.map((archetype, idx) => {
                    return (
                      <Subtitle2
                        color={
                          (!activeArchetypeId && !idx) ||
                          activeArchetypeId === archetype.id
                            ? colors.black
                            : colors.greyDark
                        }
                        key={archetype.id}
                        style={{
                          cursor: 'pointer',
                          pointerEvents: 'all',
                          maxWidth: 176,
                        }}
                        onClick={() => {
                          disableObserverRef.current = true;

                          if (!idx) {
                            window.scrollTo(0, 0);
                          } else {
                            tableRefs?.[
                              archetype.id
                            ]?.current?.scrollIntoView();
                          }
                          setActiveArchetypeId(archetype.id);
                          debouncedDisableObserver();
                        }}
                      >
                        {archetype.stakeholderDefinition.title}
                      </Subtitle2>
                    );
                  })}
              </SideNav>
            </SideNavWrapper>
            {!archetypes?.length ? (
              <PostItsEmpty
                style={{ width: '100%', maxWidth: 664 }}
                title="No payor definitions to use as archetypes"
              >
                <BodySmall
                  color={colors.greyDark}
                  style={{ display: 'inline' }}
                >
                  Leads must create payor definitions in{' '}
                </BodySmall>
                <Link
                  to={`/d/${drugId}/strategy/${strategyId}/1_1/Payor`}
                  style={{
                    color: colors.greyDark,
                    display: 'inline',
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  1.1 Key Stakeholder Analysis{' '}
                </Link>
                <BodySmall
                  color={colors.greyDark}
                  style={{ display: 'inline' }}
                >
                  to use here
                </BodySmall>
              </PostItsEmpty>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 15,
                  marginRight: 'auto',
                  width: isDesktop ? 'auto' : '100%',
                }}
              >
                {archetypes?.map((archetype) => {
                  return (
                    <div
                      key={archetype.id}
                      ref={tableRefs[archetype.id]}
                      data-id={archetype.id}
                      style={{
                        scrollMarginTop: 100,
                      }}
                    >
                      <ArchetypeTable
                        countries={sortedCountries}
                        collaboratingCountries={collaboratingCountries}
                        isLead={isLead}
                        archetype={archetype}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </ContentWrapper>
        </PageWrapper>
      </ErrorWrapper>
    </>
  );
};
