export { zIndex } from './zIndex';

export { countries } from './countries';
export * from './colors';
export { polling } from './polling';
export { themes } from './themes';
export const postItCardWidths = { min: 165, max: 220, gutter: 10 };
export const outcomesCardWidths = { min: 200, max: 390, gutter: 10 };
export const currencies = ['¥ CNY', '€ EUR', '£ GBP', '¥ JPY', '$ USD'];
export const TEMP_ID = 999999999;
export const dragNDropPinOffset = 15;
export const budgetChartMaxWidth = 451;
export const budgetGroupsFlexGap = 15;
export const gridPageSidebarSidePaddingMobile = 15;
export const gridPageSidebarSidePaddingDesktop = 20;
export const accessStrategyGapWrapperPadding = 15;

export const pageHeaderHeight_4_2_1 = 201;
export const pageHeaderHeight_4_3_2 = 243;
export const pageHeaderHeight_4_5_2 = 191;
export const globalContributor = 'global / regional';
export const defaultArchetypes = {
  ClinicalDifferentiation: 'Clinical Differentiation',
  HealthEconomic: 'Health Economic',
  FreeMarketCompetition: 'Free-market Competition',
  SelfPay: 'Self-pay',
  BudgetDriven: 'Budget Driven',
};

export const exampleTactics = [
  'Payment Calculator',
  'Customer Advisory Board Template',
  'Payer Kits',
  'Decision-analytic modelling',
  'Analytic Sales Tools',
  'Value story boards',
  'Marketing Collaterals',
  'ROI Analysis',
  'Publications',
  'Advocacy (professional society, industry organisation)',
  'Global value dossier',
  'Local value dossiers',
];
