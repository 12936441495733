import React from 'react';
import styled from 'styled-components';
import { 
  closestCorners,
  DndContext as DndKitContext, 
  DragOverEvent, 
  DragOverlay, 
  DragStartEvent, 
  useDraggable, 
  useDroppable
} from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

import { Navbar } from 'components/Navbar';
import { Icon } from 'components/shared';

// START: WRAPPERS
const PageContainer = styled.div`
  display: flex;
  gap: 20px;
  margin: 80px auto 80px;
  max-width: 1200px;
`;

const PageWrapper = styled.div`
  display: grid;
  gap: 20px;
`;

const Board = styled.div`
  background-color: white;
  border-radius: 5px;
`;

const BoardHeader = styled.div`
  padding: 10px;

  h3 {
    margin: 0;
  }
`;

const BoardItems = styled.div`
  background-color: #eee;
  border-radius: 5px;
  padding: 10px;
`;

const BoardItem = styled.div`
  align-items: center;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  padding: 10px;

  h4, p {
    margin: 0;
  }
`;
// END: WRAPPERS

// START: DND Containers
// Basic Droppable
const Droppable = ({
  id,
  style = {},
  children = null
} : {
  id: string,
  style?: React.CSSProperties,
  children?: React.ReactNode
}) => {
  const { setNodeRef } = useDroppable({ id });

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  )
}

// Basic Draggrable
const DraggableCard = ({
  id,
  style = {},
  data = {},
  children = null
} : {
  id: string,
  data?: any,
  style?: React.CSSProperties,
  children?: React.ReactNode
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform
  } = useDraggable({ id, data });

  const $style = {
    cursor: 'grab',
    transform: CSS.Translate.toString(transform),
    ...style
  };

  return (
    <div 
      ref={setNodeRef} 
      {...attributes} 
      {...listeners} 
      style={$style}
    >
      {children}
    </div>
  )
}

// Sortable
const Sortable = ({
  id,
  items = [],
  style = {},
  children = null
} : {
  id: string,
  items: any[],
  style?: React.CSSProperties,
  children?: React.ReactNode
}) => {
  const { setNodeRef } = useDroppable({ id });

  return (
    <SortableContext items={items}>
      <div ref={setNodeRef} style={style}>
        {children}
      </div>
    </SortableContext>
  )
}

// Sortable Card
const SortableCard = ({
  id,
  style = {},
  data = {},
  children = null
} : {
  id: string,
  data?: any,
  style?: React.CSSProperties,
  children?: React.ReactNode
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id, data });

  const $style = {
    cursor: 'grab',
    transform: CSS.Transform.toString(transform),
    transition,
    ...style
  };

  return (
    <div 
      ref={setNodeRef} 
      {...attributes} 
      {...listeners} 
      style={$style}
    >
      {children}
    </div>
  )
}

const CustomCard = ({
  id,
  style = {},
  data = {},
  children = null
} : {
  id: string,
  data?: any,
  style?: React.CSSProperties,
  children?: React.ReactNode
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id, data });

  const $style = {
    transform: CSS.Transform.toString(transform),
    transition,
    ...style
  };

  return (
    <div 
      ref={setNodeRef} 
      style={$style}
    >
      {typeof children === 'function' ? children({ attributes, listeners }) : children}
    </div>
  )
}
// END: DND Containers

// START: Reusable Components
const Card = ({ 
  card,
  handle = false,
  attributes = {},
  listeners = {},
  style = {}
}: { 
  card: any,
  handle?: boolean,
  attributes?: any,
  listeners?: any,
  style?: React.CSSProperties
}) => {
  return (
    <BoardItem style={style}>
      {handle && (
        <div
          {...attributes}
          {...listeners}
          style={{ cursor: 'grab' }}
        >
          <Icon name="Grip" size={20} />
        </div>
      )}
      <div>
        <h4>{card.title}</h4>
        <p>{card.content}</p>
      </div>
    </BoardItem>
  )
}
// END: Reusable Components

export const DndKitDemo = () => {
  // board state
  const [ boards, setBoards ] = React.useState<any>({
    'board-1': {
      title: 'Board 1',
      sortable: false,
      cards: [
        { id: 'card-1', title: 'Card 1', content: 'This is a sample basic card' }
      ],
      style: { 
        border: `2px solid purple`
      }
    },
    'board-2': {
      title: 'Board 2',
      sortable: false,
      cards: [],
      style: {
        border: `2px solid green`
      }
    },
    'sortable-1': {
      title: 'Sortable Board 1',
      cards: [
        { id: 'card-2', title: 'Card 2', content: 'This is a sample sortable card' },
        { id: 'card-3', title: 'Card 3', content: 'This is a sample sortable card' }
      ],
      style: {
        border: `2px solid blue`
      }
    },
    'sortable-2': {
      title: 'Sortable Board 2',
      cards: [],
      style: {
        border: `2px solid orange`
      }
    },
    'custom-1': {
      title: 'Custom Board 1',
      cards: [
        { id: 'card-4', title: 'Card 4', content: 'This is a sample card with custom handle activator' },
        { id: 'card-5', title: 'Card 5', content: 'This is a sample card with custom handle activator' },
        { id: 'card-6', title: 'Card 6', content: 'This is a sample card with custom handle activator' },
      ],
      style: {
        border: `2px solid gray`
      }
    },
    'custom-2': {
      title: 'Custom Board 2',
      cards: [],
      style: {
        border: `2px solid red`
      }
    },
    'vertical-1': {
      title: 'Vertical Board 1',
      cards: [
        { id: 'card-7', title: 'Card 7', content: 'This is a sample card with custom handle activator' },
        { id: 'card-8', title: 'Card 8', content: 'This is a sample card with custom handle activator' },
        { id: 'card-9', title: 'Card 9', content: 'This is a sample card with custom handle activator' },
        { id: 'card-10', title: 'Card 10', content: 'This is a sample card with custom handle activator' },
        { id: 'card-11', title: 'Card 11', content: 'This is a sample card with custom handle activator' },
      ]
    },
    'vertical-2': {
      title: 'Vertical Board 2',
      cards: []
    }
  });

  const verticalBoard1 = boards['vertical-1'];
  const verticalBoard2 = boards['vertical-2'];

  // active card state
  const [ activeCard, setActiveCard ] = React.useState<any>(null);

  // START: Context evevnt handlers
  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;

    setActiveCard((prev: any) => {
      if (prev && prev.id === active.id) {
        return prev;
      }

      let activeId = active?.data?.current?.id;
      if (active?.data?.current?.sortable) {
        activeId = active?.data?.current?.id;
      }

      if (!activeId) {
        return null;
      }

      // find the card
      const card = boards[activeId].cards.find((card: any) => card.id === active.id);
      if (!card) {
        return null;
      }

      return card;
    });
  }

  /**
   * Handle drag over, this is typically used to update
   * the state of multiple droppable or sortable container
   * 
   * @param event
   * @returns void
   */
  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;

    // get the board id of both active and over
    let activeId: any = active?.data?.current?.id;
    let overId: any = over?.id;

    // if sortable, get the sortable id
    if (active?.data?.current?.sortable) {
      activeId = active?.data?.current?.id;
    }

    // if sortable, get the sortable id
    if (over?.data?.current?.sortable) {
      overId = over?.data?.current?.id;
    }

    // exclude sorting action
    if (!activeId 
      || !overId
      || activeId === overId
    ) {
      console.log('Invalid move (id): Drag Over');
      return;
    }

    // NOTE: It is very important to do the
    // state updates inside the setStates to
    // avoid maximum update depth exceeded error
    setBoards((prev: any) => {
      const activeCards = prev?.[activeId]?.cards || [];
      const overCards = prev?.[overId]?.cards || [];

      // get the active indexes
      const activeIndex = activeCards.findIndex((card: any) => card.id === active.id);
      const overIndex = overCards.findIndex((card: any) => card.id === over?.id);

      let newIndex;
      if (overId in prev) {
        newIndex = overCards.length + 1;
      } else {
        // determine relative position of the active and over
        const activeRect = active?.rect?.current?.translated;
        const overRect = over?.rect;

        // determine the position and height
        const activeTop = activeRect?.top || 0;
        const overTop = overRect?.top || 0;
        const overHeight = overRect?.height || 0;

        // determine if below last item
        const isBelowLastItem = 
          over 
          && overIndex === overCards.length - 1
          && activeTop > overTop + overHeight;

        // determine modifier
        const modifier = isBelowLastItem ? 1 : 0;
        // determine the new index
        newIndex = overIndex >= 0 ? overIndex + modifier : overCards.length + 1;
      }

      // return updated state
      return {
        ...prev,
        [activeId]: {
          ...prev[activeId],
          cards: activeCards.filter((card: any) => card.id !== active.id)
        },
        [overId]: {
          ...prev[overId],
          cards: [
            ...overCards.slice(0, newIndex),
            prev[activeId].cards[activeIndex],
            ...overCards.slice(newIndex)
          ]
        }
      }
    });
  }

  /**
   * Handle drag end, this function is typically
   * used to update the state of a sortable container
   * 
   * @param event
   * @returns void
   */
  const handleDragEnd = (event: any) => {
    if (activeCard) {
      setActiveCard(null);
    }
    
    const { active, over } = event;
    const state = Object.assign({}, boards);

    // get the board id of both active and over
    let activeId = active?.data?.current?.id;
    let overId = over?.id;

    // if sortable, get the sortable id
    if (active?.data?.current?.sortable) {
      activeId = active?.data?.current?.id;
    }

    // if sortable, get the sortable id
    if (over?.data?.current?.sortable) {
      overId = over?.data?.current?.id;
    }

    // limit the drag end to sortable only
    if (!activeId 
      || !overId
      || activeId !== overId
    ) {
      console.log('Invalid move (id): Drag End');
      return;
    }

    // get the active indexes
    const activeIndex = state[activeId].cards.findIndex((card: any) => card.id === active.id);
    const overIndex = state[overId].cards.findIndex((card: any) => card.id === over.id);

    if (activeIndex < 0 || overIndex < 0) {
      console.log('Invalid move (index): Drag End');
      return;
    }

    // update card state
    if (activeIndex !== overIndex) {
      setBoards((prev: any) => ({
        ...prev,
        [overId]: {
          ...prev[overId],
          cards: arrayMove(prev[overId].cards, activeIndex, overIndex)
        }
      }));
    }
  }
  // END: Context evevnt handlers

  return (
    <>
      <Navbar
        prev={{ title: '', url: '' }}
        next={{ title: '', url: '' }}
        disableSecondary
        title="DndKit Demo"
      />

      <PageContainer>
        <PageWrapper style={{ gridTemplateColumns: 'repeat(1, 1fr 1fr)', maxWidth: 760 }}>
          <DndKitContext
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
          >
            <h3 style={{ margin: 0 }}>Basic</h3>
            <div></div>
            {Object.keys(boards).map((boardId: string) => {
              const board = boards[boardId];

              if (!boardId.startsWith('board-')) {
                return null;
              }

              return (
                <Droppable
                  id={boardId}
                  key={boardId}
                >
                  <Board 
                    key={boardId} 
                    style={board.style}
                  >
                    <BoardHeader>
                      <h3>{board.title}</h3>
                    </BoardHeader>

                    <BoardItems>
                      {!board.cards.length ? (
                        <span style={{ display: 'block', textAlign: 'center' }}>
                          Drag cards here!
                        </span>
                      ) : (
                        <>
                          {board.cards.map((card: any, j: number) => {
                            return (
                              <DraggableCard
                                id={card.id}
                                key={card.id}
                                data={{ id: boardId }}
                                style={{ marginTop: j > 0 ? 10 : 0 }}
                              >
                                <Card 
                                  card={card} 
                                  style={{
                                    ...(activeCard && activeCard.id === card.id ? { 
                                      border: '1px solid gray',
                                      boxShadow: '0 0 10px rgba(0, 0, 0, .2)',
                                    } : {})
                                  }}
                                />
                              </DraggableCard>
                            )
                          })}
                        </>
                      )}
                    </BoardItems>
                  </Board>
                </Droppable>
              );
            })}
          </DndKitContext>
          
          <DndKitContext
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
          >
            {/* Drag Overlay is recommended for sortable items */}
            <DragOverlay modifiers={[restrictToWindowEdges]}>
              {activeCard ? (
                <Card 
                  card={activeCard} 
                  style={{ 
                    border: '1px solid gray',
                    boxShadow: '0 0 10px rgba(0, 0, 0, .2)',
                  }}
                />
              ) : null}
            </DragOverlay>

            <h3 style={{ margin: 0 }}>Sortable</h3>
            <div></div>

            {Object.keys(boards).map((boardId: string) => {
              const board = boards[boardId];

              if (!boardId.startsWith('sortable-')) {
                return null;
              }

              return (
                <Sortable
                  id={boardId}
                  key={boardId}
                  items={board.cards.map((card: any) => card.id)}
                >
                  <Board 
                    key={boardId} 
                    style={board.style}
                  >
                    <BoardHeader>
                      <h3>{board.title}</h3>
                    </BoardHeader>

                    <BoardItems>
                      {!board.cards.length ? (
                        <span style={{ display: 'block', textAlign: 'center' }}>
                          Drag cards here!
                        </span>
                      ) : (
                        <>
                          {board.cards.map((card: any, index: number) => {
                            return (
                              <SortableCard
                                id={card.id}
                                key={card.id}
                                data={{ id: boardId }}
                                style={{ 
                                  marginTop: index > 0 ? 10 : 0,
                                  opacity: activeCard && activeCard.id === card.id ? .5 : 1
                                }}
                              >
                                <Card card={card} />
                              </SortableCard>
                            )
                          })}
                        </>
                      )}
                    </BoardItems>
                  </Board>
                </Sortable>
              );
            })}
          </DndKitContext>

          <DndKitContext
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
          >
            {/* Drag Overlay is recommended for sortable items */}
            <DragOverlay modifiers={[restrictToWindowEdges]}>
              {activeCard ? (
                <Card 
                  card={activeCard} 
                  handle={true} 
                  style={{ 
                    border: '1px solid gray',
                    boxShadow: '0 0 10px rgba(0, 0, 0, .2)',
                  }}
                />
              ) : null}
            </DragOverlay>

            <div>
              <h3 style={{ margin: 0 }}>Custom Handle</h3>
              <em>(Only activates when the drag handle is used)</em>
            </div>
            <div>
            </div>

            {Object.keys(boards).map((boardId: string) => {
              const board = boards[boardId];

              if (!boardId.startsWith('custom-')) {
                return null;
              }

              return (
                <Sortable
                  id={boardId}
                  key={boardId}
                  items={board.cards.map((card: any) => card.id)}
                >
                  <Board 
                    key={boardId} 
                    style={board.style}
                  >
                    <BoardHeader>
                      <h3>{board.title}</h3>
                    </BoardHeader>

                    <BoardItems>
                      {!board.cards.length ? (
                        <span style={{ display: 'block', textAlign: 'center' }}>
                          Drag cards here!
                        </span>
                      ) : (
                        <>
                          {board.cards.map((card: any, index: number) => {
                            return (
                              <CustomCard
                                id={card.id}
                                key={card.id}
                                data={{ id: boardId }}
                                style={{ 
                                  marginTop: index > 0 ? 10 : 0,
                                  opacity: activeCard && activeCard.id === card.id ? .5 : 1
                                }}
                              >
                                {({ attributes, listeners }: any) => (
                                  <Card 
                                    card={card} 
                                    handle={true}
                                    attributes={attributes}
                                    listeners={listeners}
                                  />
                                )}
                              </CustomCard>
                            )
                          })}
                        </>
                      )}
                    </BoardItems>
                  </Board>
                </Sortable>
              );
            })}
          </DndKitContext>
        </PageWrapper>

        <PageWrapper style={{ 
          gridTemplateRows: 'min-content', 
          gap: 0,
          maxWidth: 400, 
          width: '100%' 
        }}>
          <div style={{ marginBottom: 20 }}>
            <h3 style={{ margin: 0 }}>Vertical Containers</h3>
            <em>
              (Vertical boards with modifiers to limit movement to vertical axis for cleaner drag and drop)
            </em>
          </div>
          <div style={{
            alignSelf: 'start',
            borderRadius: 5,
            border: '2px solid black',
          }}>
            <DndKitContext
              collisionDetection={closestCorners}
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
            >
              <DragOverlay zIndex={999}>
                {activeCard ? (
                  <Card 
                    card={activeCard} 
                    handle={true} 
                    style={{ 
                      border: '1px solid gray',
                      boxShadow: '0 0 10px rgba(0, 0, 0, .2)',
                    }}
                  />
                ) : null}
              </DragOverlay>

              <Sortable
                id="vertical-1"
                items={verticalBoard1.cards.map((card: any) => card.id)}
              >
                <Board>
                  <BoardHeader>
                    <h3 style={{ fontSize: 14, textAlign: 'center' }}>
                      {verticalBoard1.title}
                    </h3>
                  </BoardHeader>

                  <BoardItems>
                    {!verticalBoard1.cards.length ? (
                      <span style={{ display: 'block', textAlign: 'center' }}>
                        Drag cards here!
                      </span>
                    ) : (
                      <>
                        {verticalBoard1.cards.map((card: any, index: number) => {
                          return (
                            <CustomCard
                              id={card.id}
                              key={card.id}
                              data={{ id: 'vertical-1' }}
                              style={{ 
                                marginTop: index > 0 ? 10 : 0,
                                opacity: activeCard && activeCard.id === card.id ? .5 : 1
                              }}
                            >
                              {({ attributes, listeners }: any) => (
                                <Card 
                                  card={card} 
                                  handle={true}
                                  attributes={attributes}
                                  listeners={listeners}
                                />
                              )}
                            </CustomCard>
                          )
                        })}
                      </>
                    )}
                  </BoardItems>
                </Board>
              </Sortable>

              <Sortable
                id="vertical-2"
                items={verticalBoard2.cards.map((card: any) => card.id)}
              >
                <Board>
                  <BoardHeader>
                    <h3 style={{ fontSize: 14, textAlign: 'center' }}>
                      {verticalBoard2.title}
                    </h3>
                  </BoardHeader>

                  <BoardItems>
                    {!verticalBoard2.cards.length ? (
                      <span style={{ display: 'block', textAlign: 'center' }}>
                        Drag cards here!
                      </span>
                    ) : (
                      <>
                        {verticalBoard2.cards.map((card: any, index: number) => {
                          return (
                            <CustomCard
                              id={card.id}
                              key={card.id}
                              data={{ id: 'vertical-2' }}
                              style={{ 
                                marginTop: index > 0 ? 10 : 0,
                                opacity: activeCard && activeCard.id === card.id ? .5 : 1
                              }}
                            >
                              {({ attributes, listeners }: any) => (
                                <Card 
                                  card={card} 
                                  handle={true}
                                  attributes={attributes}
                                  listeners={listeners}
                                />
                              )}
                            </CustomCard>
                          )
                        })}
                      </>
                    )}
                  </BoardItems>
                </Board>
              </Sortable>
            </DndKitContext>
          </div>
        </PageWrapper>
      </PageContainer>
    </>
  );
}