import React from 'react';
import styled from 'styled-components';

import { CompetitiveCategory } from 'data/graphql/generated';
import { StepTab, TabGroup } from 'components/shared';
import { device } from 'utils/breakpoints';
import { useHistory } from 'react-router-dom';

const StepBarsWrapper = styled.div`
  width: 100%;
  display: flex;

  > * + * {
    margin-left: 5px;
  }
`;

const TabGroupWrapper = styled.div`
  margin-top: 0px;
  @media ${device.tabletMax} {
    margin-top: 20px;
  }
`;

interface Props {
  category: CompetitiveCategory;
  setCategory(category: CompetitiveCategory): void;
}

export const CategoryTabs: React.FC<Props> = ({
  category,
  setCategory,
}) => {

  const history = useHistory();

  const { pathname } = history.location;
  const [, setContains1_2] = React.useState(false);

  React.useEffect(() => {
    if (pathname.includes('1_2')) {
      setContains1_2(true);
    }
  }, [pathname]);
  
  return (
    <TabGroupWrapper>
      <TabGroup componentName="StepTab">
        <StepBarsWrapper>
          <StepTab
            active={category === CompetitiveCategory.Outcomes}
            text="Outcomes"
            onClick={() => {
              setCategory(CompetitiveCategory.Outcomes);
            }}
          />
          <StepTab
            active={category === CompetitiveCategory.Technologies}
            text="Technologies"
            onClick={() => {
              setCategory(CompetitiveCategory.Technologies);
            }}
          />
          <StepTab
            active={category === CompetitiveCategory.Actions}
            text="Actions"
            onClick={() => {
              setCategory(CompetitiveCategory.Actions);
            }}
          />
        </StepBarsWrapper>
      </TabGroup>
    </TabGroupWrapper>
  );
};
