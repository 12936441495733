import { BodySmall, CountryFlag } from 'components/shared';
import { colors } from 'constants/index';
import useDesktop from 'hooks/useDesktop';
import React, { useEffect, useState } from 'react';
import styled, { CSSProperties } from 'styled-components/macro';
import { getUserTitleOrCountryAndName } from 'utils/getUserTitleOrCountryAndName';
import {
  CompetitiveCategory
} from 'data/graphql/generated';
import { useParams } from 'react-router-dom';

const SliderBG = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 5px;
  border-radius: 100px;
  margin-left: 7.5px;
  display: flex;
  justify-content: space-between;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 180px;
    background: ${colors.purple30};
    border-radius: 5px;

    height: 100%;
  }
  .bg_dot {
    background: ${colors.purple70a};
    width: 5px;
    height: 5px;
    border-radius: 5px;
    z-index: 1;
  }
`;

interface CanRateStyling {
  canRate: boolean;
}

const SliderCover = styled.div<CanRateStyling>`
  background: ${colors.white70};
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  cursor: pointer;

  visibility: ${({ canRate }) => (!canRate ? 'visible' : 'hidden')};
`;

const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  gap: 5px;

  overflow: hidden;
`;

const Slider = styled.div<CanRateStyling>`
  position: relative;
  display: flex;
  align-items: center;

  opacity: ${({ canRate }) => (!canRate ? 0.2 : 1)};
`;

const SliderInput = styled.input`
  width: 195px;
  height: 5px;
  margin: 0;
  z-index: 1;
  background: transparent;
  -webkit-appearance: none;

  //These psuedo selectors need to be seperated to work correctly across browsers (i.e Safari)
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: transparent;
    border-radius: 5px;
    -webkit-appearance: none;
  }

  &::-moz-range-track {
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: transparent;
    border-radius: 5px;
    -webkit-appearance: none;
  }

  &::-moz-range-thumb {
    height: 15px;
    width: 15px;
    border-radius: 100px;
    background: ${colors.purple};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
  }

  //Safari thumb style
  &::-webkit-slider-thumb {
    height: 15px;
    width: 15px;
    border-radius: 100px;
    background: ${colors.purple};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3.5px;
  }

  &::-moz-range-progress {
    cursor: pointer;
  }
`;

const SliderWrapper = styled.div`
  /* width: 195px; */
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const CreamWrapper = styled.div`
  background: ${colors.cream};
  border-radius: 20px;
  max-width: 350px;
  height: 40px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 100;
`;

interface URLParams {
  category: CompetitiveCategory;
}

interface Props {
  max: number;
  value: number;
  min: number;
  step?: number;
  onChange(value: string): void;
  onMouseUp(value: string): void;
  user: Pick<React.ComponentProps<typeof CountryFlag>, 'user'>['user'];
  hideCover: boolean;
  onCoverClick(): void;
  className?: string;
  style?: CSSProperties;
  labels?: string[];
}
//Component styles
//https://www.figma.com/file/3zULn8McRuiqxhNvNUiOrX/nmblr-UI?node-id=5850%3A164337
export const SliderRating = ({
  value,
  onChange,
  onMouseUp,
  min,
  max = 5,
  step = 1,
  user,
  hideCover,
  onCoverClick,
  className,
  style,
  labels = ['Weak', 'Strong'],
}: Props) => {
  const [canRate, setCanRate] = useState(false);
  const {
    category,
  }: URLParams = useParams();

  const updatedLabels =
  category && (category === 'Technologies' || category === 'Actions')
    ? ['Low', 'High']
    : labels;

  useEffect(() => {
    setCanRate(hideCover);
  }, [hideCover]);

  const isDesktop = useDesktop();

  return (
    <Wrapper className={className} style={style}>
      <CountryFlag
        size={30}
        user={user}
        tooltip={getUserTitleOrCountryAndName(user)}
      />
      <CreamWrapper>
        <SliderContainer>
          <BodySmall>{updatedLabels[0]}</BodySmall>
          <SliderWrapper>
            <Slider canRate={canRate}>
              <SliderInput
                type="range"
                value={value}
                min={min}
                max={max}
                step={step}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                onPointerUp={(e) => {
                  const element = e.target as HTMLInputElement;

                  onMouseUp(element.value);
                }}
              />
              <SliderBG>
                {Array(max)
                  .fill(null)
                  .map((_, idx) => (
                    <div key={idx} className="bg_dot" />
                  ))}
              </SliderBG>
            </Slider>

            {!hideCover && (
              <SliderCover
                className="cypress-slider-rating-cover"
                canRate={canRate}
                onClick={() => {
                  setCanRate(!canRate);
                  onCoverClick();
                }}
              >
                {isDesktop ? 'Click to rate' : 'Tap to rate'}
              </SliderCover>
            )}
          </SliderWrapper>
          <BodySmall>{updatedLabels[1]}</BodySmall>
        </SliderContainer>
      </CreamWrapper>
    </Wrapper>
  );
};
