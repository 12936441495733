import React, { useContext, useState } from 'react';
import { createContext } from 'react';
import { CompetitiveCategory } from 'data/graphql/generated';

const CategoryContext = createContext<[CompetitiveCategory, any]>([
  CompetitiveCategory.Outcomes,
  () => {},
]);

export const CategoryProvider: React.FC = ({ children }) => {
  const [category, setCategory] = useState<CompetitiveCategory>(
    CompetitiveCategory.Outcomes
  );

  return (
    <CategoryContext.Provider value={[category, setCategory]}>
      {children}
    </CategoryContext.Provider>
  );
};

export function useCategoryContext(category?: String) {
  const context = useContext(CategoryContext);
  if (category) context[1](category);
  return context;
}
