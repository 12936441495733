import { BodySmall, ButtonLabel, Collaboration } from 'components/shared';
import { AlignmentCount } from 'components/shared/AlignmentCount';
import FocusDropdown from 'components/shared/FocusDropdown';
import { RationaleModal } from 'components/shared/RationaleModal';
import { TextAreaInput } from 'components/shared/TextAreaInput';
import { colors } from 'constants/index';
import {
  CompetitiveAdvantageRowFragment,
  CompetitiveAdvantageRowInput,
  SuccessConditionFragment,
} from 'data/graphql/generated';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { device } from 'utils/breakpoints';

const LastCellTDWrapper = styled.div`
  z-index: 1;
`;

const StyledUL = styled.ul`
  padding-left: 25px;
  margin: 0;
`;

const MobileDivider = styled.div`
  border-bottom: 1px solid ${colors.greyLight};
  padding: 0 10px;
  margin: 5px 0;
`;

const MobileBG = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  width: 100%;
  height: calc(100% + 15px);
  padding: 1px;
  border-radius: 5px;
  z-index: 0;
  background: linear-gradient(315deg, #ff00c8 -83%, #7800ff 93.75%);

  @media ${device.desktopMin} {
    height: 100%;
  }

  > div {
    position: relative;

    border-radius: 5px;
    width: 100%;
    height: 100%;

    background: ${colors.fadedGradient};
    ::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: white;
      z-index: -1;
      border-radius: 4px;
    }
  }
`;

const MobileSectionWrapper = styled.div`
  > ${BodySmall} {
    color: ${colors.greyDark};
  }
`;

const MobileWrapper = styled.div`
  position: relative;
  padding: 0px 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  > :not(${MobileBG}) {
    z-index: 1;
  }
`;

const LastCell = styled.div`
  display: grid;
  grid-template-columns: 103px 60px 1fr;
  gap: 15px;
  align-items: center;
  height: 100%;
`;

const GreyBG = styled.div`
  background: ${colors.black05};
  height: 100%;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  > * {
    word-break: break-word;
  }
`;

const StyledDiv = styled.div`
  z-index: 1;
  padding: 0 15px;
  vertical-align: top;
`;

export const imperativeRowColumns = css`
  grid-template-columns:
    repeat(4, minmax(119px, 243px))
    minmax(300px, 480px);
  column-gap: 15px;
`;

export const StyledRow = styled.div<{ isFocus: boolean }>`
  display: grid;
  ${imperativeRowColumns};

  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid transparent;
  position: relative;

  transition: padding 0.3s;

  padding: ${({ isFocus }) => (isFocus ? '15px' : '10px 15px')};
  background: ${({ isFocus }) =>
    isFocus ? colors.purplePinkGradient : colors.white};
  ::before {
    display: ${({ isFocus }) => (isFocus ? 'block' : 'none')};
    position: absolute;
    inset: 0;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: ${colors.fadedGradient}, white;
    z-index: 0;
  }
`;

interface Props {
  isDesktop: boolean;
  name: string;
  behaviourToDrive: string;
  driverOfTheBehaviour: string;
  drivers: SuccessConditionFragment[];
  barriers: SuccessConditionFragment[];
  totalUsers: number;
  competitiveAdvantageRow: CompetitiveAdvantageRowFragment;
  updateCompetitiveAdvantageRow(
    data: CompetitiveAdvantageRowInput
  ): Promise<any>;
  isLead: boolean;
  setPreventUnfocusModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Row = ({
  isDesktop,
  name,
  behaviourToDrive,
  driverOfTheBehaviour,
  drivers,
  barriers,
  totalUsers,
  competitiveAdvantageRow,
  updateCompetitiveAdvantageRow,
  isLead,
  setPreventUnfocusModal,
}: Props) => {
  const [isFocus, setIsFocus] = useState(competitiveAdvantageRow.focus);
  const [textAreaValue, setTextAreaValue] = useState(
    competitiveAdvantageRow.text
  );

  // Focus rationale modal logic
  const [modalSlideIdx, setModalSlideIdx] = useState<number | null>(null);
  const [, setShowSingleSlide] = useState<boolean>(false);
  const openModal = () => setModalSlideIdx(1);

  const closeModal = () => {
    setModalSlideIdx(null);
  };

  useEffect(() => {
    setTextAreaValue(competitiveAdvantageRow.text);
    setIsFocus(competitiveAdvantageRow.focus);
  }, [competitiveAdvantageRow.text, competitiveAdvantageRow.focus]);

  async function toggleFocus() {
    if (!isLead) return;
    if (!isFocus) {
      openModal();
      return;
    }

    try {
      setIsFocus(false);
      await updateCompetitiveAdvantageRow({ focus: false });
    } catch (error) {
      setIsFocus(true);

      if (
        error instanceof Error &&
        error.message.includes('Cannot be deleted as it has child data')
      ) {
        setPreventUnfocusModal(true);
      } else {
        alert('Something went wrong');
      }
    }
  }

  const handleModalSubmit = useCallback(
    async (focusRationale: string) => {
      try {
        setIsFocus(true);
        await updateCompetitiveAdvantageRow({
          focus: true,
          focusRationale,
        });
      } catch (error) {
        setIsFocus(false);
        alert('Something went wrong');
      }
    },
    [updateCompetitiveAdvantageRow]
  );

  const focusRationaleModal = useMemo(
    () => (
      <RationaleModal
        modalSlideIdx={modalSlideIdx}
        handleClose={closeModal}
        handleSubmit={handleModalSubmit}
        rationaleText={competitiveAdvantageRow.focusRationale}
        showSingleSlide={true}
        modalDetails={{
          'Key Issue': competitiveAdvantageRow.text || '',
        }}
      />
    ),
    [
      modalSlideIdx,
      competitiveAdvantageRow.text,
      competitiveAdvantageRow.focusRationale,
      handleModalSubmit,
    ]
  );

  if (!isDesktop) {
    return (
      <>
        {focusRationaleModal}
        <MobileDivider />
        <MobileWrapper>
          <FocusDropdown
            onClick={toggleFocus}
            checked={isFocus}
            disabled={!isLead}
            setModalSlideIdx={setModalSlideIdx}
            setShowSingleSlide={setShowSingleSlide}
            rationaleText={competitiveAdvantageRow.focusRationale}
          />

          <MobileSectionWrapper>
            <BodySmall>Key issue</BodySmall>
            <TextAreaInput
              data-for={'competitiveAdvantageTooltip'}
              data-tip={!isLead ? 'Only Leads can edit' : ''}
              readOnly={!isLead}
              $disabled={!isLead}
              $borderless
              $table
              $small
              placeholder="Type the key issue"
              style={{
                margin: '0px 0px 0px -1px',
                lineHeight: '20px',
                minHeight: '100%',
                padding: 10,
              }}
              value={textAreaValue}
              onBlur={() => {
                if (competitiveAdvantageRow.text !== textAreaValue) {
                  updateCompetitiveAdvantageRow({ text: textAreaValue });
                }
              }}
              onChange={async (e) => {
                const target = e.target as HTMLTextAreaElement;

                setTextAreaValue(target.value);
              }}
            />
          </MobileSectionWrapper>

          {/* Driver Card */}
          <MobileSectionWrapper>
            <BodySmall>Drivers</BodySmall>

            <GreyBG>
              <Drivers drivers={drivers} />
            </GreyBG>
          </MobileSectionWrapper>

          {/* Barrier Card */}
          <MobileSectionWrapper>
            <BodySmall>Barriers</BodySmall>

            <GreyBG>
              <Barriers barriers={barriers} />
            </GreyBG>
          </MobileSectionWrapper>

          {/* Strategic Possibility Card */}
          <MobileSectionWrapper>
            <BodySmall>Strategic Possibility</BodySmall>
            <GreyBG>
              {!!name && (
                <ButtonLabel style={{ cursor: 'auto' }}>{name}</ButtonLabel>
              )}
              {!!behaviourToDrive && <BodySmall>{behaviourToDrive}</BodySmall>}
              {!!driverOfTheBehaviour && (
                <BodySmall>{driverOfTheBehaviour}</BodySmall>
              )}
            </GreyBG>
          </MobileSectionWrapper>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ width: 'fit-content' }}>
              <Collaboration
                collaboration={competitiveAdvantageRow.collaboration}
              />
            </div>
            <div style={{ width: 'fit-content' }}>
              <AlignmentCount
                totalUsers={totalUsers}
                alignmentCount={
                  competitiveAdvantageRow.collaboration.alignmentCount
                }
              />
            </div>
          </div>

          {isFocus && (
            <MobileBG>
              <div></div>
            </MobileBG>
          )}
        </MobileWrapper>
      </>
    );
  }

  return (
    <>
      {focusRationaleModal}

      <StyledRow className="StyledTbody__row" isFocus={isFocus}>
        {/* Key Issue Column */}
        <TextAreaInput
          data-cy="key-issue-textarea"
          data-for={'competitiveAdvantageTooltip'}
          data-tip={!isLead ? 'Only Leads can edit' : ''}
          readOnly={!isLead}
          $disabled={!isLead}
          $borderless
          $table
          $small
          placeholder="Type the key issue"
          style={{
            margin: '0px 0px 0px -1px',
            lineHeight: '20px',
            minHeight: '100%',
            padding: 10,
            zIndex: 1,
          }}
          value={textAreaValue}
          onBlur={() => {
            if (competitiveAdvantageRow.text !== textAreaValue) {
              updateCompetitiveAdvantageRow({ text: textAreaValue });
            }
          }}
          onChange={async (e) => {
            const target = e.target as HTMLTextAreaElement;

            setTextAreaValue(target.value);
          }}
        />

        {/* Driver Column */}
        <StyledDiv style={{ paddingLeft: 0, paddingRight: 0 }}>
          <GreyBG>
            <Drivers drivers={drivers} />
          </GreyBG>
        </StyledDiv>

        {/* Barrier Column */}
        <StyledDiv style={{ paddingLeft: 0, paddingRight: 0 }}>
          <GreyBG>
            <Barriers barriers={barriers} />
          </GreyBG>
        </StyledDiv>

        {/* Strategic Possibility Column */}
        <StyledDiv style={{ paddingRight: 0, paddingLeft: 0 }}>
          <GreyBG>
            <ButtonLabel style={{ cursor: 'auto' }}>{name}</ButtonLabel>
            <BodySmall>{behaviourToDrive}</BodySmall>
            <BodySmall>{driverOfTheBehaviour}</BodySmall>
          </GreyBG>
        </StyledDiv>

        <LastCellTDWrapper>
          <LastCell>
            <Collaboration
              collaboration={competitiveAdvantageRow.collaboration}
            />

            <AlignmentCount
              totalUsers={totalUsers}
              alignmentCount={
                competitiveAdvantageRow.collaboration.alignmentCount
              }
              inDonut
            />

            <FocusDropdown
              onClick={toggleFocus}
              checked={isFocus}
              disabled={!isLead}
              setModalSlideIdx={setModalSlideIdx}
              setShowSingleSlide={setShowSingleSlide}
              rationaleText={competitiveAdvantageRow.focusRationale}
            />
          </LastCell>
        </LastCellTDWrapper>
      </StyledRow>
    </>
  );
};

const Drivers = ({ drivers }: { drivers: Props['drivers'] }) => {
  return !drivers || !drivers.length ? (
    <BodySmall>None identified yet</BodySmall>
  ) : (
    <StyledUL>
      {drivers.map((driver) => (
        <li key={driver.id}>
          <BodySmall>{driver.text}</BodySmall>
        </li>
      ))}
    </StyledUL>
  );
};

const Barriers = ({ barriers }: { barriers: Props['barriers'] }) => {
  return !barriers || !barriers.length ? (
    <BodySmall>None identified yet</BodySmall>
  ) : (
    <StyledUL>
      {barriers.map((barrier) => (
        <li key={barrier.id}>
          <BodySmall>{barrier.text}</BodySmall>
        </li>
      ))}
    </StyledUL>
  );
};
