import {
  ButtonNote,
  ButtonNoteCardType,
  ButtonPill,
  ButtonPillExpand,
  ButtonRound,
  ButtonRoundExpand,
  Icon,
} from 'components/shared';
import {
  PostItCardInput,
  PostItCardType,
  Step as PageStep,
  SubStep,
} from 'data/graphql/generated';
import React, { useState } from 'react';
import { PostItGroupAndCards } from 'types';

interface Props {
  addCard: (
    card: Pick<PostItCardInput, 'pos' | 'postItGroup' | 'title' | 'type'>
  ) => void;
  cardArgs: Pick<PostItCardInput, 'pos' | 'postItGroup' | 'title'>;
  group?: PostItGroupAndCards;
}

interface MobileProps {
  mobile?: boolean;
  step: PageStep;
  subStep?: SubStep;
}

interface OpenProps {
  setButtonOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoteChildren = ({
  setButtonOpen,
  addCard,
  cardArgs,
  step,
}: OpenProps & Props & { step: PageStep }) => {
  if (step === PageStep.CriticalMetrics) {
    return (
      <>
        <ButtonNote
          onClick={() => {
            setButtonOpen(false);
            addCard({ ...cardArgs, type: PostItCardType.LeadIndicator });
          }}
          className="cypress-add-note-button"
          level={PostItCardType.LeadIndicator}
        />
        <ButtonNote
          onClick={() => {
            setButtonOpen(false);
            addCard({ ...cardArgs, type: PostItCardType.LagIndicator });
          }}
          className="cypress-add-note-button"
          level={PostItCardType.LagIndicator}
        />
      </>
    );
  }

  return (
    <>
      <ButtonNote
        onClick={() => {
          setButtonOpen(false);
          addCard({ ...cardArgs, type: PostItCardType.Trigger });
        }}
        className="cypress-postit-create-trigger"
        level={PostItCardType.Trigger}
      />
      <ButtonNote
        onClick={() => {
          setButtonOpen(false);
          addCard({ ...cardArgs, type: PostItCardType.Unexpected });
        }}
        className="cypress-add-note-button"
        level={PostItCardType.Unexpected}
      />
      <ButtonNote
        onClick={() => {
          setButtonOpen(false);
          addCard({ ...cardArgs, type: PostItCardType.Adaptation });
        }}
        className="cypress-add-note-button"
        level={PostItCardType.Adaptation}
      />
      <ButtonNote
        onClick={() => {
          setButtonOpen(false);
          addCard({ ...cardArgs, type: PostItCardType.Pattern });
        }}
        level={PostItCardType.Pattern}
        className="cypress-add-note-button"
      />
      <ButtonNote
        text="Other"
        level={PostItCardType.Other}
        className="cypress-add-note-button"
        onClick={() => {
          setButtonOpen(false);
          addCard({ ...cardArgs, type: PostItCardType.Other });
        }}
      />
    </>
  );
};

export const AddNoteButton = ({
  step,
  subStep,
  addCard,
  cardArgs,
  mobile,
  group,
}: Props & MobileProps) => {
  const [buttonOpen, setButtonOpen] = useState(false);
  if (step === PageStep.MedicalStrategy && subStep === SubStep.HurdlesAndGaps) {
    if (mobile) {
      return (
        <ButtonRoundExpand setOpen={setButtonOpen} open={buttonOpen}>
          <ButtonNote
            onClick={() => {
              setButtonOpen(false);
              addCard({ ...cardArgs, type: PostItCardType.Hurdle });
            }}
            className="cypress-add-note-button"
            level={PostItCardType.Hurdle}
          />
          <ButtonNote
            onClick={() => {
              setButtonOpen(false);
              addCard({ ...cardArgs, type: PostItCardType.Gap });
            }}
            className="cypress-add-note-button"
            level={PostItCardType.Gap}
          />
        </ButtonRoundExpand>
      );
    }

    return (
      <ButtonPillExpand
        text="Add note"
        setOpen={setButtonOpen}
        open={buttonOpen}
        className="cypress-open-note-type-menu"
      >
        <ButtonNote
          onClick={() => {
            setButtonOpen(false);
            addCard({ ...cardArgs, type: PostItCardType.Hurdle });
          }}
          level={PostItCardType.Hurdle}
          className="cypress-add-note-button"
        />
        <ButtonNote
          onClick={() => {
            setButtonOpen(false);
            addCard({ ...cardArgs, type: PostItCardType.Gap });
          }}
          level={PostItCardType.Gap}
          className="cypress-add-note-button"
        />
      </ButtonPillExpand>
    );
  }

  const positioningPageCheck =
    step === PageStep.Positioning &&
    subStep &&
    [SubStep.TheWho, SubStep.TheWhy].includes(subStep);

  if (
    [
      PageStep.Keystakeholders,
      PageStep.Keyinsights,
      PageStep.MedicalStrategy,
      PageStep.ValueProposition,
      PageStep.DistinctiveCapabilities,
    ].includes(step) ||
    positioningPageCheck
  ) {
    if (mobile) {
      return (
        <ButtonRound
          level="add"
          iconName="Plus"
          btnClassName="cypress-add-note-button"
          size={40}
          onClick={() => {
            setButtonOpen(false);
            addCard({ ...cardArgs, type: PostItCardType.Plain });
          }}
        />
      );
    }

    return (
      <ButtonPill
        text="Add note"
        level="note"
        iconName="Plus"
        clickClassName="cypress-add-note-button"
        onClick={() => {
          setButtonOpen(false);
          addCard({ ...cardArgs, type: PostItCardType.Plain });
        }}
      />
    );
  }

  if (step === 'competitivelandscape') {
    if (mobile) {
      return (
        <ButtonRoundExpand setOpen={setButtonOpen} open={buttonOpen}>
          <ButtonNote
            onClick={() => {
              setButtonOpen(false);
              addCard({ ...cardArgs, type: PostItCardType.Advantage });
            }}
            className="cypress-add-note-button"
            level={PostItCardType.Advantage}
          />
          <ButtonNote
            onClick={() => {
              setButtonOpen(false);
              addCard({ ...cardArgs, type: PostItCardType.Limitation });
            }}
            className="cypress-add-note-button"
            level={PostItCardType.Limitation}
          />
        </ButtonRoundExpand>
      );
    }

    return (
      <ButtonPillExpand
        text="Add"
        setOpen={setButtonOpen}
        open={buttonOpen}
        className="cypress-open-note-type-menu"
      >
        <ButtonNote
          onClick={() => {
            setButtonOpen(false);
            addCard({ ...cardArgs, type: PostItCardType.Advantage });
          }}
          level={PostItCardType.Advantage}
          className="cypress-add-note-button"
        />
        <ButtonNote
          onClick={() => {
            setButtonOpen(false);
            addCard({ ...cardArgs, type: PostItCardType.Limitation });
          }}
          level={PostItCardType.Limitation}
          className="cypress-add-note-button"
        />
      </ButtonPillExpand>
    );
  }

  if (
    step === PageStep.Positioning &&
    subStep === SubStep.CompetitorPositioning
  ) {
    const NoteChildren = (
      <>
        <ButtonNoteCardType
          onClick={() => {
            setButtonOpen(false);
            addCard({ ...cardArgs, type: PostItCardType.Image });
          }}
          text="Image"
          icon={<Icon name="Image" size={30} color="black" />}
        />
        <ButtonNote
          className="cypress-add-note-button"
          onClick={() => {
            setButtonOpen(false);
            addCard({ ...cardArgs, type: PostItCardType.Thoughts });
          }}
          text="Thoughts"
          level={PostItCardType.Thoughts}
          iconName="Unexpected behaviour"
        />
        <ButtonNote
          className="cypress-add-note-button"
          onClick={() => {
            setButtonOpen(false);
            addCard({ ...cardArgs, type: PostItCardType.Feelings });
          }}
          text="Feelings"
          level={PostItCardType.Feelings}
          iconName="Heart"
        />
      </>
    );

    if (mobile) {
      return (
        <ButtonRoundExpand setOpen={setButtonOpen} open={buttonOpen}>
          {NoteChildren}
        </ButtonRoundExpand>
      );
    }

    return (
      <ButtonPillExpand
        text="Add"
        setOpen={setButtonOpen}
        open={buttonOpen}
        className="cypress-open-note-type-menu"
      >
        {NoteChildren}
      </ButtonPillExpand>
    );
  }

  if (step === 'strategicquestion') {
    if (mobile) {
      return group?.title === 'Pictures and words' ? (
        <ButtonRoundExpand setOpen={setButtonOpen} open={buttonOpen}>
          <ButtonNoteCardType
            onClick={() => {
              setButtonOpen(false);
              addCard({ ...cardArgs, type: PostItCardType.Image });
            }}
            text="Image"
            icon={<Icon name="Image" size={30} color="black" />}
          />

          <ButtonNoteCardType
            onClick={() => {
              setButtonOpen(false);
              addCard({ ...cardArgs, type: PostItCardType.Plain });
            }}
            text="Note"
            icon={<Icon name="Text" size={30} color="black" />}
          />
        </ButtonRoundExpand>
      ) : (
        <ButtonRound
          level="add"
          btnClassName="cypress-add-note-button"
          iconName="Plus"
          size={40}
          onClick={() => {
            setButtonOpen(false);
            addCard({
              ...cardArgs,
              type:
                group?.title === 'Picture'
                  ? PostItCardType.Image
                  : PostItCardType.Plain,
            });
          }}
        />
      );
    }

    return group?.title === 'Pictures and words' ? (
      <ButtonPillExpand
        text="Add"
        setOpen={setButtonOpen}
        open={buttonOpen}
        className="cypress-add-note-open"
      >
        <ButtonNoteCardType
          onClick={() => {
            setButtonOpen(false);
            addCard({ ...cardArgs, type: PostItCardType.Image });
          }}
          text="Image"
          icon={<Icon name="Image" size={30} color="black" />}
        />
        <ButtonNoteCardType
          onClick={() => {
            setButtonOpen(false);
            addCard({ ...cardArgs, type: PostItCardType.Plain });
          }}
          text="Note"
          icon={<Icon name="Text" size={30} color="black" />}
        />
      </ButtonPillExpand>
    ) : (
      <ButtonPill
        text={group?.title === 'Picture' ? 'Add image' : 'Add note'}
        clickClassName={
          group?.title === 'Picture'
            ? 'cypress-add-picture-button'
            : 'cypress-add-note-button'
        }
        level="note"
        iconName="Plus"
        onClick={() => {
          setButtonOpen(false);
          addCard({
            ...cardArgs,
            type:
              group?.title === 'Picture'
                ? PostItCardType.Image
                : PostItCardType.Plain,
          });
        }}
      />
    );
  }

  return mobile ? (
    <ButtonRoundExpand setOpen={setButtonOpen} open={buttonOpen}>
      <NoteChildren
        setButtonOpen={setButtonOpen}
        addCard={addCard}
        cardArgs={cardArgs}
        group={group}
        step={step}
      />
    </ButtonRoundExpand>
  ) : (
    <ButtonPillExpand
      text="Add note"
      setOpen={setButtonOpen}
      open={buttonOpen}
      className="cypress-open-note-type-menu"
    >
      <NoteChildren
        setButtonOpen={setButtonOpen}
        addCard={addCard}
        cardArgs={cardArgs}
        group={group}
        step={step}
      />
    </ButtonPillExpand>
  );
};
