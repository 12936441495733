import React, { useEffect, useRef, useState } from 'react';
import {
  SubStep,
  useAccessStrategyEndpointTargetsQuery,
  usePicoSuggestionsQuery,
} from 'data/graphql/generated';
import AccessStrategyNav from './AccessStrategyNav';
import AccessStrategyStepHeader from './AccessStrategyStepHeader';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { useParams } from 'react-router-dom';
import { polling } from 'constants/polling';
import styled from 'styled-components';
import { Page, Wrapper } from 'components/Page';
import PicoTable from './PicoTable';
import { device } from 'utils/breakpoints';
import useDesktop from 'hooks/useDesktop';
import useGetElementTop from 'hooks/useGetElementTop';
import PicoSidebar from './PicoSidebar';
import { NamePicoSuggestionModal } from 'components/4-2-access-strategy/feature-pico-suggestion-modals';
import { useScrollToId } from 'components/4-1-medical-strategy/data-access-medical-tactics-picos';

const PageWrapper = styled(Page)`
  overflow: hidden;

  ${Wrapper} {
    width: unset;
  }

  @media ${device.tabletMin} {
    margin: 0 15px;
  }
`;
interface URLParams {
  strategyId: string;
  drugId: string;
}

export const picoSuggestionsQueryVars = (strategyId: string) => ({
  where: {
    strategyId: Number(strategyId),
  },
});

export default function AccessStrategyPico() {
  const { strategyId, drugId }: URLParams = useParams();
  const [picoModalOpen, setPicoModalOpen] = useState<number | null>(null);

  // sidebar logic
  const isDesktop = useDesktop();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const sidebarDesktopRef = useRef<HTMLDivElement | null>(null);
  const desktopWrapperTop = useGetElementTop(sidebarDesktopRef);
  const totalNavHeight = isDesktop ? 122 : 160;

  // Fetch PICO Suggestions
  const {
    data: picoSuggestionsData,
    loading,
    error,
    startPolling,
    stopPolling,
    refetch,
  } = usePicoSuggestionsQuery({
    variables: picoSuggestionsQueryVars(strategyId),
  });

  // Fetch evidence generation ideas
  const {
    data: accessStrategyEndpointTargetsData,
    error: accessStrategyEndpointTargetsError,
    loading: accessStrategyEndpointTargetsLoading,
    startPolling: startaccessStrategyEndpointTargetsPolling,
    stopPolling: stopaccessStrategyEndpointTargetsPolling,
  } = useAccessStrategyEndpointTargetsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const picoSuggestions = picoSuggestionsData?.picoSuggestions?.items;
  const suggestionForModal = picoSuggestions?.find(
    (ps) => ps.id === picoModalOpen
  );

  const accessStrategyEndpointTargets =
    accessStrategyEndpointTargetsData?.accessStrategyEndpointTargets?.items;

  useEffect(() => {
    startPolling(polling.default);
    startaccessStrategyEndpointTargetsPolling(polling.default);

    return () => {
      stopPolling();
      stopaccessStrategyEndpointTargetsPolling();
    };
  }, [
    startPolling,
    stopPolling,
    startaccessStrategyEndpointTargetsPolling,
    stopaccessStrategyEndpointTargetsPolling,
  ]);

  useScrollToId({
    isLoading: loading || accessStrategyEndpointTargetsLoading,
    isDataPresent: !!picoSuggestions?.length,
    queryString: 'originalPicoId',
    addedClass: 'originalPico',
  });

  return (
    <>
      <AccessStrategyNav substep={SubStep.Pico} />

      <AccessStrategyStepHeader substep={SubStep.Pico} />

      <PageWrapper paddingTop={false} fullWidthMobile>
        <ErrorWrapper
          isLoading={loading || accessStrategyEndpointTargetsLoading}
          errors={[error, accessStrategyEndpointTargetsError]}
          dataMissing={
            !picoSuggestionsData || !accessStrategyEndpointTargetsData
          }
        >
          <PicoTable
            picoSuggestions={picoSuggestions || []}
            drugId={drugId}
            strategyId={strategyId}
            setPicoModalOpen={setPicoModalOpen}
            setSidebarOpen={setSidebarOpen}
            refetch={refetch}
          />
        </ErrorWrapper>
      </PageWrapper>

      <PicoSidebar
        sidebarOpen={sidebarOpen}
        closeSidebar={() => setSidebarOpen(false)}
        strategyId={+strategyId}
        drugId={+drugId}
        desktopWrapperTop={desktopWrapperTop}
        totalNavHeight={totalNavHeight}
        evidenceGenerationIdeas={accessStrategyEndpointTargets || []}
      />

      <NamePicoSuggestionModal
        headerTitle={'Outcome'}
        headerText={suggestionForModal?.outcome || ''}
        handleClose={() => setPicoModalOpen(null)}
        visible={!!picoModalOpen}
        picoSuggestion={suggestionForModal}
        strategyId={strategyId}
      />
    </>
  );
}
