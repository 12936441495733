import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

export const KeyInsightsSortable: React.FC<{
  id: string;
  items: any[];
  disabled?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}> = ({
  id,
  items = [],
  disabled = false,
  style = {},
  children = null
}) => {
  const { setNodeRef } = useDroppable({ id, disabled });

  return (
    <SortableContext 
      id={id} 
      items={items} 
      disabled={disabled}
      strategy={verticalListSortingStrategy}
    >
      <div ref={setNodeRef} style={style}>
        {children}
      </div>
    </SortableContext>
  )
}