import { colors } from 'constants/index';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ButtonPill } from '.';
import { zIndex } from 'constants/zIndex';

const zIndexBackToDefault = keyframes`
  0% { z-index: auto; }
  100% { z-index: ${zIndex.btnExpandOpen}; }
`;

const zIndexBackToDefault2 = keyframes`
  0% { z-index: ${zIndex.btnExpandOpen}; }
  100% { z-index: auto; }
`;

const Wrapper = styled.div<{ open: boolean; width: string }>`
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  border: none;
  background-color: ${colors.yellow75};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: ${({ width }) => width || 0};
  max-width: ${({ width }) => width || 0};
  padding: 15px;
  animation: ${zIndexBackToDefault2} 0s 0.3s both;

  ${({ open, width }) =>
    open
      ? css`
          animation: ${zIndexBackToDefault} 0s 0s both;
          max-height: 1000px;
          border-radius: 5px;
          max-width: 1000px;
          border: 0.5px solid ${colors.black30};
        `
      : css`
          z-index: auto;
          max-height: 40px;
          border-radius: 25px;
          border: 0.5px solid transparent;
        `}

  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  transition: all 0.3s;

  &:focus {
    outline: none;
  }
`;

const ButtonWrapper = styled.div<{ open: boolean }>`
  position: relative;
  z-index: 999;
  ${({ open }) => {
    if (open)
      return css`
        button {
          z-index: ${zIndex.btnExpandOpenText};
          background-color: transparent;
        }
        button:hover {
          background-color: transparent;
        }
      `;
  }}
`;

const InnerBtnWrapper = styled.div`
  margin-top: 35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 10px;
  }
`;

interface Props {
  text: string;
  className?: string;
  open: boolean;
  setOpen(state: boolean): void;
}

export const ButtonPillExpand: React.FC<Props> = ({
  text,
  children,
  open,
  setOpen,
  className,
}) => {
  const [width, setWidth] = useState('auto');

  const pillRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (pillRef.current) {
      setWidth(window.getComputedStyle(pillRef.current).width);
    }
  }, [text]);

  useEffect(() => {
    const closePill = () => {
      setOpen(false);
    };
    window.addEventListener('click', closePill);
    return () => {
      window.removeEventListener('click', closePill);
    };
  }, [setOpen]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ position: 'relative' }}
    >
      <Wrapper open={open} width={width}>
        <InnerBtnWrapper>{children}</InnerBtnWrapper>
      </Wrapper>
      <ButtonWrapper ref={pillRef} open={open}>
        <ButtonPill
          level="note"
          iconName={open ? 'Chevron-up' : 'Chevron-down'}
          clickClassName={className}
          onClick={() => {
            setOpen(!open);
          }}
          text={text}
        />
      </ButtonWrapper>
    </div>
  );
};
