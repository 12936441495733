import { Navbar } from 'components/Navbar';
import { TabGroup, StepTab } from 'components/shared';
import { themes } from 'constants/index';
import { TabGroupWrapper, StepBarsWrapper } from 'containers/StrategicQuestion';
import { SubStep } from 'data/graphql/generated';
import { useHistory, useParams } from 'react-router-dom';

interface Props {
  subStep: SubStep;
  hideButtons?: boolean;
}

interface URLParams {
  drugId: string;
  strategyId: string;
}

export const RiskMitigationNav = ({ subStep, hideButtons }: Props) => {
  const { drugId, strategyId }: URLParams = useParams();
  const history = useHistory();

  return (
    <Navbar
      stepNumber="4.4"
      title={themes.activate.tools['4.4'].name}
      prev={{
        title: themes.activate.tools['4.3'].name,
        url: `/d/${drugId}/strategy/${strategyId}/4_3`,
      }}
      next={{
        title: themes.activate.tools['4.5'].name,
        url: `/d/${drugId}/strategy/${strategyId}/4_5`,
      }}
      disableSecondary={!!hideButtons}
    >
      {!hideButtons && (
        <TabGroupWrapper>
          <TabGroup componentName="StepTab">
            <StepBarsWrapper>
              {[
                {
                  title: 'Solutions',
                  substep: SubStep.Ideas,
                },
                {
                  title: 'Summary',
                  substep: SubStep.Summary,
                },
              ].map((tab) => {
                return (
                  <StepTab
                    key={tab.substep}
                    active={tab.substep === subStep}
                    text={tab.title}
                    onClick={() => {
                      history.push(
                        `/d/${drugId}/strategy/${strategyId}/4_4/${tab.substep}`
                      );
                    }}
                  />
                );
              })}
            </StepBarsWrapper>
          </TabGroup>
        </TabGroupWrapper>
      )}
    </Navbar>
  );
};
