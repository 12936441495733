import { ButtonPill, Subtitle2 } from 'components/shared';
import { ButtonPillWrapper } from 'components/shared/ButtonPill';
import { colors } from 'constants/index';
import { SubStep, Step } from 'data/graphql/generated';
import { useGetNavHeight } from 'hooks/useGetNavHeight';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import AccessStrategyNav from '../AccessStrategyNav';
import { AccessStrategySummaryFinancials } from './AccessStrategySummaryFinancials';
import { AccessStrategySummaryTimeline } from './AccessStrategySummaryTimeline';
import { KeyEventsButton } from 'components/shared';
import { useState } from 'react';
import { useWidth } from 'hooks/useWidth';
import {
  KeyEventsSidebar,
} from 'components/4-1-medical-strategy/shared/feature-key-events-sidebar/src';
import { useAuthContext } from 'contexts/AuthContext';
import { verifyUserRole } from 'utils/verifyUserRole';
import { lowerCase } from 'lodash';

export const SMALLEST_MOBILE_WIDTH =
  375 +
  // space for scrollbar
  30;
const Main = styled.main<{ topMargin: number }>`
  padding: 0px 20px;
  margin-top: ${({ topMargin }) => topMargin + 20 + 'px'};

  @media ${device.mobile} {
    margin-top: ${({ topMargin }) => topMargin + 15 + 'px'};

    padding: 0px;
    .summary_title {
      margin-left: 20px;
    }
  }
`;

const Header = styled.header``;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  margin-top: 5px;
`;

const StyledButtonPill = styled(ButtonPill)`
  max-height: 30px;
  ${ButtonPillWrapper} {
    max-height: 30px;
  }
`;
const MedicalSummaryKeyEventsButton = styled(KeyEventsButton)`
  margin-left: auto;

  @media ${device.desktopMin} {
    margin-right: 76px;
  }
  @media (max-width: ${SMALLEST_MOBILE_WIDTH}px) {
    margin-right: 0;
  }
`;


type Props = {};
interface URLParams {
  drugId: string;
  strategyId: string;

}

export const AccessStrategySummary = (props: Props) => {
  const [showTimeline, setShowTimeline] = useState(true);
  const { strategyId, drugId }: URLParams = useParams();
  const navHeight = useGetNavHeight();
  const useMobileIcon = useWidth() < 512;
  const [keyEventsSidebarOpen, setKeyEventsSidebarOpen] = useState(false);

  const [{ user }] = useAuthContext();
  const { isLead, isCountryContributor, isGlobalContributor } = verifyUserRole(
    user?.role,
    user?.country
  );

  const selectedRegion = 'global';

  const isUserCountry = isLead
  ? selectedRegion === 'global'
  : lowerCase(selectedRegion) ===
    lowerCase(user?.country || '');

  const showKeyEventsButton =
  (isLead || isCountryContributor) && isUserCountry && showTimeline;

  return (
    <>
      <AccessStrategyNav substep={SubStep.Summary}/>
      <Main topMargin={navHeight}>
        <Header>
          <Buttons>
            <Subtitle2 className="summary_title" style={{ marginRight: 5 }}>
              Summary
            </Subtitle2>
            <StyledButtonPill
              text="Timeline"
              level={showTimeline ? 'primary-solid' : 'secondary'}
              color={showTimeline ? colors.white : colors.black}
              onClick={() => {
                setShowTimeline(true);
              }}
            />

          
            <StyledButtonPill
              text="Financials"
              level={!showTimeline ? 'primary-solid' : 'secondary'}
              color={!showTimeline ? colors.white : colors.black}
              onClick={() => {
                setShowTimeline(false);
              }}
            />

          </Buttons>
          {showKeyEventsButton && (
              <MedicalSummaryKeyEventsButton
                buttonText="Show events"
                mobile={useMobileIcon}
                onClick={() => setKeyEventsSidebarOpen(true)}
              />
            )}
          <KeyEventsSidebar
              type={Step.AccessStrategy}
              isOpen={keyEventsSidebarOpen}
              onClose={() => setKeyEventsSidebarOpen(false)}
              strategyId={Number(strategyId)}
              region={lowerCase('global')}
              step={Step.AccessStrategy}
              isGlobalContributor={isGlobalContributor}
              canEnable={
                (isLead || isCountryContributor) &&
                lowerCase('global') ===
                  (isLead ? 'global' : lowerCase('global'))
              } 
            />
        </Header>
        {showTimeline ? (
          <AccessStrategySummaryTimeline
            showTimeline={showTimeline}
            drugId={drugId}
            strategyId={strategyId}
          />
        ) : (
          <AccessStrategySummaryFinancials
            drugId={drugId}
            strategyId={strategyId}
          />
        )}
      </Main>
    </>
  );
};
