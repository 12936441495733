import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { Navbar } from 'components/Navbar';
import { PostIts } from 'components/PostIts';
import { StrategyDefinitionHeader } from 'components/StrategyDefinitionHeader';
import {
  BodyNormal,
  ButtonLabel,
  StepTab,
  Subtitle2,
  TabGroup,
} from 'components/shared';
import { ImperativeSummarySidebar } from 'components/shared/ImperativeSummarySidebar';
import {
  MainContent,
  PostItsPageWrapper,
  SidebarDesktopWrapper,
  SidebarMobileWrapper,
  StepHeader,
} from 'components/shared/SidebarPageComponents';
import { useAuthContext } from 'contexts/AuthContext';
import {
  Step,
  StrategicQuestionFragment,
  SubStep,
  useStrategicQuestionsQuery,
  useStrategyQuery,
  useStrategyUpdateMutation,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import useGetElementTop from 'hooks/useGetElementTop';
import { usePostItCardMove } from 'hooks/usePostItCardMove';
import { usePostItCards } from 'hooks/usePostItCards';
import { usePostItGroups } from 'hooks/usePostItGroups';
import { camelCase, kebabCase } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { buildStrategyURL } from 'utils/buildStrategyURL';
import useSidePaneTopOffset from 'utils/useSidePaneTopOffset';
import { verifyUserRole } from 'utils/verifyUserRole';
import { sortPostIts } from '../hooks';
import { colors, themes, polling } from 'constants/index';

const overlapWidth = 675;

const ContentHeader = styled.div`
  @media (min-width: 1440px) {
    margin-bottom: 15px;
  }
`;

export const StepBarsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
`;

export const TabGroupWrapper = styled.div`
  margin-top: 0px;
  overflow: hidden;
  @media ${device.tabletMax} {
    margin-top: 20px;
  }
`;

const HeaderSubtitle = styled(BodyNormal)`
  @media ${device.tabletMin} {
    display: inline;
  }
`;

const RecapButton = styled(ButtonLabel)`
  margin-bottom: 15px;

  color: ${colors.purple};

  @media ${device.tabletMin} {
    display: inline;
    margin-left: 5px;
  }
`;
type fields = 'strategicProblem' | 'winningAspiration' | 'strategicQuestion';

const defaultValues = {
  strategicQuestion: '',
  winningAspiration: '',
  strategicProblem: '',
};
interface URLParams {
  drugId: string;
  strategyId: string;
  substep: string;
}

export const StrategicQuestion: React.FC = () => {
  const { drugId, strategyId, substep }: URLParams = useParams();
  const history = useHistory();

  // Sidebar logic
  const isDesktop = useDesktop();
  const totalNavHeight = isDesktop ? 110 : 160;
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const sidebarDesktopRef = useRef<HTMLDivElement | null>(null);
  const desktopWrapperTop = useGetElementTop(sidebarDesktopRef);
  const topOffset = useSidePaneTopOffset(sidebarDesktopRef, totalNavHeight);
  const isWinningAspiration = substep === kebabCase(SubStep.WinningAspiration);
  const showRecapButton = !isWinningAspiration;

  useEffect(() => {
    if (!substep) {
      history.replace(
        `/d/${drugId}/strategy/${strategyId}/2_1/winning-aspiration`
      );
    }
  }, [drugId, history, strategyId, substep]);

  const [
    activeQuestion,
    setActiveQuestion,
  ] = useState<StrategicQuestionFragment | null>();

  const [values, setValues] = useState(defaultValues);

  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const [strategyUpdate] = useStrategyUpdateMutation();

  const { data } = useStrategicQuestionsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
      },
    },
  });

  const filteredStrategicQuestions = useMemo(
    () =>
      data?.strategicQuestions?.items.filter((question) =>
        ['winningAspiration', 'strategicProblem', 'strategicQuestion'].includes(
          question.field
        )
      ) || [],

    [data]
  );

  useEffect(() => {
    const toFind = camelCase(substep) || 'winningAspiration';
    const newActiveQuestion = filteredStrategicQuestions.find(
      (question) => question?.field === toFind
    );

    if (newActiveQuestion) {
      setActiveQuestion(newActiveQuestion);
    }
  }, [filteredStrategicQuestions, substep]);

  const { data: strategyData, startPolling, stopPolling } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ strategy }) => {
      const data = {
        strategicQuestion: strategy?.strategicQuestion || '',
        winningAspiration: strategy?.winningAspiration || '',
        strategicProblem: strategy?.strategicProblem || '',
      };

      const activeTextBox = document.querySelector(
        "[name='strategyDefinition']"
      );

      //If text box is in focus, don't update its values
      if (!activeTextBox || activeTextBox !== document.activeElement) {
        setValues(data);
      }
    },
  });

  useEffect(() => {
    startPolling(polling.default);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  const strategy = strategyData?.strategy;

  const {
    items: groupItems,
    loading: groupsLoading,
    createGroup,
    updateGroup,
    removeGroup,
    error: groupsError,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Strategicquestion,
        substep: activeQuestion?.field as SubStep,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Strategicquestion,
      substep: activeQuestion?.field as SubStep,
    }
  );

  const {
    items: cardItems,
    loading: cardsLoading,
    createCard,
    updateCard,
    removeCard,
    error: cardsError,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Strategicquestion,
        substep: (activeQuestion?.field as SubStep) || undefined,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Strategicquestion,
      substep: (activeQuestion?.field as SubStep) || undefined,
    }
  );
  const groups = sortPostIts(groupItems, cardItems);

  const onChange = usePostItCardMove(groups, updateCard);

  return (
    <>
      <Navbar
        stepNumber="2.1"
        title={themes.explore.tools['2.1'].name}
        prev={{
          title: themes.discover.tools['1.5'].name,
          url: buildStrategyURL(drugId, strategyId, '1_5'),
        }}
        next={{
          title: themes.explore.tools['2.2'].name,
          url: `/d/${drugId}/strategy/${strategyId}/2_2`,
        }}
      >
        <TabGroupWrapper>
          <TabGroup componentName="StepTab">
            <StepBarsWrapper>
              {filteredStrategicQuestions.map((strategicQuestion) => {
                if (!strategicQuestion) return null;
                return (
                  <StepTab
                    key={strategicQuestion.title}
                    active={strategicQuestion.field === activeQuestion?.field}
                    text={`Part ${strategicQuestion.position}: ${strategicQuestion.title}`}
                    onClick={() => {
                      history.push(
                        `/d/${drugId}/strategy/${strategyId}/2_1/${kebabCase(
                          strategicQuestion.field
                        )}`
                      );
                      setActiveQuestion(strategicQuestion);
                    }}
                  />
                );
              })}
            </StepBarsWrapper>
          </TabGroup>
        </TabGroupWrapper>
      </Navbar>

      <SidebarMobileWrapper totalNavHeight={totalNavHeight}>
        <StepHeader>
          <ContentHeader>
            <Subtitle2
              style={{
                marginBottom: 5,
              }}
            >
              Consider/re-consider the{' '}
              {activeQuestion?.title.toLocaleLowerCase()}
            </Subtitle2>
            <HeaderSubtitle
              color={colors.greyDark}
              style={{
                marginBottom: showRecapButton ? 5 : 15,
              }}
            >
              Use the prompts below to explore ideas around a{' '}
              {activeQuestion?.title.toLocaleLowerCase()}. One descriptor per
              note.
            </HeaderSubtitle>
            {showRecapButton && (
              <RecapButton onClick={() => setSidebarOpen(!sidebarOpen)}>
                {sidebarOpen ? 'Hide recap' : 'Show recap'}
              </RecapButton>
            )}
          </ContentHeader>

          {strategy && activeQuestion && activeQuestion?.collaboration ? (
            <StrategyDefinitionHeader
              type={activeQuestion.type}
              collaboration={activeQuestion?.collaboration}
              totalUsers={strategy.users.length}
              isLead={isLead}
              title={activeQuestion.title}
              textValue={String(values[activeQuestion.field as fields])}
              handleSave={async (val) => {
                strategyUpdate({
                  variables: {
                    id: +strategyId,
                    data: {
                      [activeQuestion.field]: val,
                    },
                  },
                });
              }}
            />
          ) : null}
        </StepHeader>

        <SidebarDesktopWrapper
          ref={sidebarDesktopRef}
          sidebarOpen={sidebarOpen && !isWinningAspiration}
          isRelative={desktopWrapperTop > totalNavHeight}
          totalNavHeight={topOffset}
        >
          <ImperativeSummarySidebar
            sidebarOpen={sidebarOpen && !isWinningAspiration}
            setSidebarOpen={setSidebarOpen}
            desktopWrapperTop={desktopWrapperTop}
            totalNavHeight={totalNavHeight}
            strategy={strategy}
          />

          <ErrorWrapper
            isLoading={groupsLoading || cardsLoading}
            errors={[groupsError, cardsError]}
            dataMissing={false}
          >
            <PostItsPageWrapper
              paddingTop={false}
              fullWidthMobile
              totalNavHeight={totalNavHeight}
              sidebarOpen={sidebarOpen && !isWinningAspiration}
              overlapWidth={overlapWidth}
            >
              <MainContent
                sidebarOpen={sidebarOpen && !isWinningAspiration}
                overlapWidth={overlapWidth}
              >
                <PostIts
                  step={Step.Strategicquestion}
                  groups={groups}
                  addCard={createCard}
                  removeCard={removeCard}
                  updateCard={updateCard}
                  handleCardChange={onChange}
                  createGroup={createGroup}
                  updateGroup={updateGroup}
                  removeGroup={removeGroup}
                  createGroupHide
                />
              </MainContent>
            </PostItsPageWrapper>
          </ErrorWrapper>
        </SidebarDesktopWrapper>
      </SidebarMobileWrapper>
    </>
  );
};
