import { Alignment, PostItCard, PostItCardType, CompetitiveCategory, Stakeholder, Step, useAlignmentCreateMutation } from 'data/graphql/generated';
import { usePostItCardsPayload } from 'hooks/usePostItCards';
import { useState } from 'react';
import { move, swap } from 'react-grid-dnd';

export function useOutcomesCardMove(
  groups: {
    includes: PostItCard[];
    excludes: PostItCard[];
  },
  updateCard: usePostItCardsPayload['updateCard'],
  addCard: usePostItCardsPayload['createCard'],
  refetchGroupsWithCards: () => void,
  refetchCards: () => void,
  category: CompetitiveCategory | null, 
  stakeholder: Stakeholder,
  stakeholderDefinitionId: string,
  competitorId: string,
  selectedPostItGroupId: number,
  sortingType: string = PostItCardType.Outcome
) {
  const [createAlignment] = useAlignmentCreateMutation();
  const refetch = () => {
    refetchGroupsWithCards();
    refetchCards();
  }
  const [loading, setLoading] = useState<boolean>(false);

  const updateCards = async (cards: PostItCard[], include: boolean, type: PostItCardType) => {
    return Promise.all(
      cards.map(async (card, idx) => {
        await updateCard({
          ...card,
          include,
          type,
          pos: 1000 - idx,
        });
      })
    );
  };

  const hasOrderChanged = (originalArray: PostItCard[], updatedArray: PostItCard[]) => {
    if (originalArray.length !== updatedArray.length) {
      return true;
    }

    for (let i = 0; i < originalArray.length; i++) {
      const originalItem = originalArray[i];
      const updatedItem = updatedArray[i];

      if (originalItem.id !== updatedItem.id || originalItem.pos !== updatedItem.pos) {
        return true;
      }
    }

    return false;
  }

  // target id will only be set if dragging from one dropzone to another.
  const onChange = async (
    sourceId: "includes" | "excludes",
    sourceIndex: number,
    targetIndex: number,
    targetId?: "includes" | "excludes" | undefined
  ) => {
    const t = sortingType === PostItCardType.Outcome ? PostItCardType.Outcome : PostItCardType.KeyInsight;

    if (targetId) {
      const result = move(
        groups[sourceId],
        groups[targetId],
        sourceIndex,
        targetIndex
      );

      setLoading(true);
      if (targetId === "includes") {
        const sourceCard = groups[sourceId][sourceIndex];

        if (sourceCard.stakeholder !== stakeholder && sortingType !== PostItCardType.KeyInsight && category) {
          const card = await addCard({
            postItGroup: selectedPostItGroupId,
            pos: 1000,
            title: sourceCard.title,
            category: category, 
            ...(Step.Keystakeholders ? { step: Step.Keystakeholders, stakeholder, include: true } : undefined),
            competitor: +competitorId,
            ...(stakeholderDefinitionId ? { stakeholderDefinition: Number(stakeholderDefinitionId) } : undefined),
            type: PostItCardType.Plain,
          });
          const collaborationId = card?.data?.postItCardCreate?.collaboration?.id ?? null;
          const strategyId = card?.data?.postItCardCreate?.strategyId ?? null;

          if (collaborationId && strategyId) {
            await createAlignment({
              variables: {
                data: { collaborationId, strategyId: Number(strategyId) },
              },
              update: (cache, { data }) => {
                cache.modify({
                  id: cache.identify({
                    __ref: `Collaboration:${data?.alignmentCreate.collaborationId}`,
                  }),
                  fields: {
                    alignmentCount(alignment) {
                      return alignment + 1;
                    },
                    alignments(
                      alignments: Pick<
                        Alignment,
                        'id' | 'createdAt' | 'collaborationId'
                      >[]
                    ) {
                      return [...alignments, data?.alignmentCreate];
                    },
                  },
                });
              },
            });
          }

          setLoading(false);

          refetch()
          return;
        }

        await Promise.all([
          updateCards(result[1], true, PostItCardType.Plain),
          updateCards(result[0], false, t),
        ]);


      } else {
        await Promise.all([
          updateCards(result[0], true, PostItCardType.Plain),
          updateCards(result[1], false, t),
        ]);
      }

      setLoading(false);
      refetch();
      return;
    }

    const result = swap(groups[sourceId], sourceIndex, targetIndex);
    const originalArray = groups[sourceId]
    if (!hasOrderChanged(originalArray, result)) {
      return;
    }

    setLoading(true);
    await Promise.all(
      result.map(async (card, idx) => {
        await updateCard({
          ...card,
          include: targetId ? (targetId === 'includes') : card.include,
          type: targetId ? (targetId === 'includes' ? PostItCardType.Plain : t) : card.type,
          pos: 1000 - idx,
        });
      })
    );
    setLoading(false);
    refetch();
  }

  return { onChange, loading };
}
