import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useDrugsQuery } from 'data/graphql/generated';
import { TabGroup } from 'components/shared';
import { useParams } from 'react-router-dom';
import { KeyInsightsSidePanel } from './KeyInsightsSidePanel';
import { checkIfFullNmblr } from 'containers/Strategies';
import useDesktop from 'hooks/useDesktop';

const StepBarsWrapper = styled.div`
  align-items: center;
  width: 100%;
  display: flex;

  > * + * {
    margin-left: 15px;
  }
`;

const TabGroupWrapper = styled.div`
  margin-top: 0px;
`;

interface Props {
  isLead: boolean;
}

export const KeyInsightsMenu: React.FC<Props> = ({ isLead }) => {
  const { drugId } = useParams<{
    drugId: string;
  }>();

  const { data: drugData } = useDrugsQuery({
    variables: { where: { id: Number(drugId) } },
    fetchPolicy: 'network-only',
  });

  const ProductOnDrugs = drugData?.drugs?.items?.filter(
    (e) => e.id === Number(drugId)
  )[0]?.ProductOnDrugs;

  const miniFeatureSet = useMemo(() => {
    if (ProductOnDrugs) {
      return ProductOnDrugs?.flatMap((f: any) => {
        if (f.Product?.features)
          return f.Product?.features?.map((g: any) => g.featureSet);
        return [];
      });
    }
    return [];
  }, [ProductOnDrugs]);

  const isFullNmblr = checkIfFullNmblr(miniFeatureSet);
  const isDesktop = useDesktop();

  return (
    <TabGroupWrapper>
      <TabGroup componentName="StepTab">
        <StepBarsWrapper>
          {isLead && isDesktop &&
            <KeyInsightsSidePanel
              isFullNmblr={isFullNmblr}
            />
          }
        </StepBarsWrapper>
      </TabGroup>
    </TabGroupWrapper>
  );
};