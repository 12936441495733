import styled from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { Navbar } from 'components/Navbar';
import { StepTab, TabGroup } from 'components/shared';
import { StepBarsWrapper, TabGroupWrapper } from 'containers/StrategicQuestion';
import { SubStep } from 'data/graphql/generated';
import { colors } from 'constants/colors';
import URLHelper from 'utils/URLHelper';
import { CriticalMetricsParams } from '../../../shared-data-access-critical-metrics/src';
import { EvaluationButton } from './EvaluationButton';
import { useGlobalContext } from 'contexts/GlobalContext';
import { themes } from 'constants/index';

interface Props {
  substep: SubStep;
}

const StyledTabGroupWrapper = styled(TabGroupWrapper)`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const Divider = styled.div`
  height: 30px;
  width: 1px;
  background: ${colors.white50};
  margin-right: 15px;
  margin-left: 5px;
`;

export const ButtonContainerDesktop = styled.div`
  display: flex;
  margin-left: 5px;

  ${Divider} {
    margin-right: 10px;
  }
`;

export function CriticalMetricsNavbar({ substep }: Props) {
  const {
    drugId,
    strategyId,
    competitiveAdvantageRowUrlParam: competitiveAdvantageRowId,
  } = useParams<CriticalMetricsParams>();
  const history = useHistory();

   // handle global context path
  const [ leadAndLag, setLeadAndLag] = useGlobalContext(
    'leadAndLag'
  );

  // handle global context path
  const [ strategicImperativeId] = useGlobalContext(
    'strategicImperativeId'
  );
  useEffect(() => {
    if (strategicImperativeId && leadAndLag) {
      const url =  URLHelper.getCriticalMetricsUrlBySubStep(leadAndLag, {
        drugId,
        strategyId,
        competitiveAdvantageRowId: strategicImperativeId,
      });
      history.push(
        url
      );

    } else {
      const url =  URLHelper.getCriticalMetricsUrlBySubStep(leadAndLag, {
        drugId,
        strategyId,
        competitiveAdvantageRowId,
      });
      history.push(
        url
      );
    }
  }, [drugId, history,strategyId, competitiveAdvantageRowId, strategicImperativeId, leadAndLag ]);

  return (
    <Navbar
      stepNumber="3.5"
      title={themes.develop.tools['3.5'].name}
      prev={{
        title: themes.develop.tools['3.4'].name,
        url: `/d/${drugId}/strategy/${strategyId}/3_4`,
      }}
      next={{
        title: themes.activate.tools['4.1'].name,
        url: `/d/${drugId}/strategy/${strategyId}/4_1`,
      }}
    >
      <StyledTabGroupWrapper>
        <TabGroup componentName="StepTab">
          <StepBarsWrapper>
            {[
              {
                title: 'Part 1: Indicators',
                substep: SubStep.Indicators,
                className: 'cypress-indicators-tab',
              },
              {
                title: 'Part 2: Goals',
                substep: SubStep.Goals,
                className: 'cypress-goals-tab',
              },
            ].map((v) => {
              return (
                <StepTab
                  key={v.substep}
                  active={v.substep === substep}
                  text={v.title}
                  className={v.className}
                  onClick={() => {
                    if (v.substep === substep) {
                      return;
                    }

                    const url =  URLHelper.getCriticalMetricsUrlBySubStep(v.substep, {
                      drugId,
                      strategyId,
                      competitiveAdvantageRowId,
                    });
                    history.push(
                      url
                    );

                    setLeadAndLag(v.substep);
                  }}
                />
              );
            })}

            <ButtonContainerDesktop>
              <Divider />

              <EvaluationButton
                text={'Tracking'}
                isActive={substep === SubStep.Tracking}
                onClick={() => {
                  const url = URLHelper.getCriticalMetricsTrackingUrl(
                    drugId,
                    strategyId,
                    competitiveAdvantageRowId
                  );
                  setLeadAndLag(SubStep.Tracking);

                  history.replace(url);
                }}
              />
            </ButtonContainerDesktop>
          </StepBarsWrapper>
        </TabGroup>
      </StyledTabGroupWrapper>
    </Navbar>
  );
}
