import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { Page } from 'components/Page';
import { Navbar } from 'components/Navbar';
import {
  BodyNormal,
  StepHeaderBar,
  Subtitle2,
  BodySmall,
} from 'components/shared';
import { colors } from 'constants/colors';
import { device } from 'utils/breakpoints';
import {
  useEndpointTargetsQuery,
  Step,
  useStakeholderDefinitionsQuery,
  Sort,
} from 'data/graphql/generated';
import { Table } from 'components/CreatingValue/Table';
import { PostItsEmpty } from '../components/PostItsEmpty';
import { Loading } from 'components/Loading';
import { polling, themes } from 'constants/index';
import { Observations } from 'components/Observations';
import { useToastContext } from 'contexts/ToastContext';
import { useQuery } from 'hooks/useQuery';
import useDesktop from 'hooks/useDesktop';

interface URLParams {
  drugId: string;
  strategyId: string;
  stakeholderDefinitionId: string;
  competitorId: string;
}

const PageWrapper = styled(Page)`
  overflow: hidden;
  @media ${device.tabletMax} {
    padding-top: 30px;
  }
`;

const StyledStepHeaderBar = styled(StepHeaderBar)`
  padding: 15px 15px 0 15px;
  min-height: unset;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 50px;

  @media ${device.tablet} {
    margin-top: 100px;
  }
`;

const StepHeaderBarWrapper = styled.div`
  margin-top: 105px;

  @media ${device.tabletMin} {
    margin-top: 105px;
  }

  @media ${device.desktopMin} {
    margin-top: 0px;
  }
`;

export const EvidenceGenerationIdeas: React.FC = () => {
  const {
    drugId,
    strategyId,
  }: URLParams = useParams();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);

  const originalIdeaId = useQuery().get('originalIdeaId');

  const history = useHistory();
  const isDesktop = useDesktop()
  const [createToast, removeToast] = useToastContext();

  const {
    data: stakeholderDefinitions,
    error: stakeholderDefinitionsError,
    loading: stakeholderDefinitionsLoading,
    startPolling,
    stopPolling,
  } = useStakeholderDefinitionsQuery({
    variables: {
      orderBy: { createdAt: Sort.Asc },
      where: {
        strategyId: +strategyId,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    startPolling(polling.default);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  const validStakeholderDefinitions = useMemo(
    () =>
      stakeholderDefinitions?.stakeholderDefinitions?.items?.filter(
        (definition) => definition.title
      ) || [],
    [stakeholderDefinitions]
  );

  useEffect(() => {
    // When user navigates to the page or toggles the stakeholder, select the first stakeholder definition by default
    if (validStakeholderDefinitions?.length) {
      history.replace({
        pathname: `/d/${drugId}/strategy/${strategyId}/2_5`,
        search: params.toString(),
      });
    }
  }, [
    drugId,
    strategyId,
    history,
    validStakeholderDefinitions,
    originalIdeaId,
    params,
  ]);

  const {
    data: endpointTargetData,
    loading: endpointTargetLoading,
    error: endpointTargetError,
    refetch,
    startPolling: endpointStartPolling,
    stopPolling: endpointStopPolling,
  } = useEndpointTargetsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
      },
      strategyId: +strategyId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    endpointStartPolling(polling.default);
    return () => endpointStopPolling();
  }, [endpointStartPolling, endpointStopPolling]);

  const hasStakeholderError =
    !stakeholderDefinitionsLoading && !validStakeholderDefinitions.length;

  const targetPatientError = JSON.stringify(stakeholderDefinitionsError) || '';

  const hasStakeholderNotIdentifiedError = targetPatientError?.includes(
    'STAKEHOLDER_NOT_IDENTIFIED'
  );

  const competitiveLandscapeOpportunities = useMemo(
    () =>
      endpointTargetData?.competitiveLandscapeOpportunities?.items
      || [],
    [endpointTargetData?.competitiveLandscapeOpportunities?.items]
  );

  useEffect(() => {
    if (!originalIdeaId) return;
    const getOriginalIdea = () =>
      document.querySelector(`#endpoint_${originalIdeaId.split('_')[0]}`);

    // This prevents it from creating an observer if the item already exists on mount
    const existingOriginalIdea = getOriginalIdea();

    let observerHandle: MutationObserver | null = null;

    if (!existingOriginalIdea) {
      let observer = new MutationObserver(function (mutations) {
        const originalIdea = getOriginalIdea();

        if (!!originalIdea) {
          originalIdea.scrollIntoView({ block: 'center' });
          originalIdea.classList.add('originalIdea');

          observer.disconnect();
        }
      });

      observer.observe(document, {
        attributes: false,
        childList: true,
        characterData: false,
        subtree: true,
      });
      observerHandle = observer;
    }
    return () => {
      observerHandle?.disconnect();
    };
  }, [competitiveLandscapeOpportunities, endpointTargetData, originalIdeaId]);

  useEffect(() => {
    if (!!originalIdeaId) {
      const { id, setCTAClick } = createToast(
        'Return to 4.2 Access Strategy',
        'Return'
      );

      history.listen(() => {
        removeToast(id);
      });

      setCTAClick(() => {
        history.push(
          `/d/${drugId}/strategy/${strategyId}/4_2/evidence?returnToId=${originalIdeaId}`
        );
      });
    }
  }, [createToast, drugId, history, originalIdeaId, removeToast, strategyId]);

  return (
    <>
      <Navbar
        stepNumber="2.5"
        title={themes.explore.tools['2.5'].name}
        prev={{
          title: themes.explore.tools['2.4'].name,
          url: `/d/${drugId}/strategy/${strategyId}/2_4`,
        }}
        next={{
          title: themes.develop.tools['3.1'].name,
          url: `/d/${drugId}/strategy/${strategyId}/3_1`,
        }}
        navMenuChildren={null}
        disableSecondary={isDesktop ? true : false}
      />
      <StepHeaderBarWrapper>
        <Observations step={Step.CreatingValue} />

        <StyledStepHeaderBar>
          {stakeholderDefinitionsLoading ? (
            <Loading style={{ width: 80, marginTop: 15 }} />
          ) : (
            <>
              <Subtitle2>
                Consider evidence generation ideas for each outcome.
              </Subtitle2>
              <BodyNormal
                color={colors.greyDark}
                style={{ marginBottom: '15px' }}
              >
                Groups automatically created from outcomes marked as an “Opportunity” in Competitive Landscape.
              </BodyNormal>
            </>
          )}
        </StyledStepHeaderBar>

        <ErrorWrapper
          isLoading={endpointTargetLoading || stakeholderDefinitionsLoading}
          errors={[endpointTargetError]}
          dataMissing={false}
        >
          <PageWrapper paddingTop={false} fullWidthMobile>
            {hasStakeholderError ? (
              <PostItsEmpty
                title={
                  hasStakeholderNotIdentifiedError
                    ? 'Nothing to discuss'
                    : 'No stakeholder definitions to assess'
                }
              >
                {hasStakeholderNotIdentifiedError ? (
                  <>
                    <BodySmall
                      color={colors.greyDark}
                      style={{ display: 'inline' }}
                    >
                      Evidence generation ideas can only be added when a Lead
                      marks an outcome as an Opportunity in{' '}
                    </BodySmall>
                    <Link
                      to={`/d/${drugId}/strategy/${strategyId}/1_3`}
                      style={{
                        color: colors.greyDark,
                        display: 'inline',
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      1.3 {themes.discover.tools['1.3'].name}{' '}
                    </Link>
                  </>
                ) : (
                  <>
                    <BodySmall
                      color={colors.greyDark}
                      style={{ display: 'inline' }}
                    >
                      Leads must define stakeholders in{' '}
                    </BodySmall>
                    <Link
                      to={`/d/${drugId}/strategy/${strategyId}/1_2`}
                      style={{
                        color: colors.greyDark,
                        display: 'inline',
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      1.2 {themes.discover.tools['1.2'].name}{' '}
                    </Link>
                    <BodySmall
                      color={colors.greyDark}
                      style={{ display: 'inline' }}
                    >
                      to start assessing the competitive landscape
                    </BodySmall>
                  </>
                )}
              </PostItsEmpty>
            ) : null}

            {competitiveLandscapeOpportunities.length > 0 ? (
              competitiveLandscapeOpportunities.map((opportunity) => {
                if (!opportunity) return null;
                return (
                  <Table
                    key={opportunity.id}
                    opportunity={opportunity}
                    endpointTargets={
                      endpointTargetData?.endpointTargets?.items?.filter(
                        ({ opportunityId }) => opportunityId === opportunity.id
                      ) || []
                    }
                    strategyId={+strategyId}
                    refetch={refetch}
                  />
                );
              })
            ) : (
              <>
                {!hasStakeholderError ? (
                  <PostItsEmpty title="Nothing to discuss for this stakeholder definition">
                    <BodySmall
                      color={colors.greyDark}
                      style={{ display: 'inline' }}
                    >
                      Endpoints can only be added when a Lead marks an outcome as
                      an “Opportunity” in{' '}
                    </BodySmall>
                    <Link
                      to={`/d/${drugId}/strategy/${strategyId}/1_3`}
                      style={{
                        color: colors.greyDark,
                        display: 'inline',
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      1.3 {themes.discover.tools['1.3'].name}{' '}
                    </Link>
                  </PostItsEmpty>
                ) : null}
              </>
            )}
          </PageWrapper>
        </ErrorWrapper>
      </StepHeaderBarWrapper>
    </>
  );
};
