import { ModalForm } from 'components/ModalForm';
import { ButtonPill } from 'components/shared';
import FormTextInput from 'components/shared/FormTextInput';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { TooltipWrapper } from 'components/shared/ButtonPill';
import {
  Stakeholder,
  FileModel,
  useCompetitorCreateMutation,
  useCompetitorDeleteMutation,
  useCompetitorUpdateMutation,
  useRequestFileUploadMutation,
  Step,
  SubStep,
} from 'data/graphql/generated';
import { CompetitorModalState } from 'types';
import { uploadFile } from 'utils/uploadFile';
import { LogoUploader } from './LogoUploader';
import { usePostItGroups } from 'hooks/usePostItGroups';

export const competitorModalDefaultState = {
  title: '',
  type: null,
  competitorId: null,
  error: '',
  image: '',
};

interface Props {
  stakeholder: Stakeholder;
  drugId: number;
  typeCompetitor: string;
  createUpdateCompetitorModal: CompetitorModalState;
  setCreateUpdateCompetitorModal: React.Dispatch<
    React.SetStateAction<CompetitorModalState>
  >;
  strategyId: string;
  stakeholderDefinitionId: string;
  refetchCompetitors(): Promise<void>;
}

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;

  ${TooltipWrapper} {
    width: 100%;
  }
`;
export type imageStateType =
  | undefined
  | (Partial<File> & {
      preview: string;
    })
  //Removed saved image
  | '';
export const CreateUpdateCompetitorModal: React.FC<Props> = ({
  stakeholder,
  typeCompetitor,
  createUpdateCompetitorModal,
  setCreateUpdateCompetitorModal,
  strategyId,
  drugId,
  stakeholderDefinitionId,
  refetchCompetitors,
}) => {
  const [defaultCompetitorId, setCompetitorId] = useState(0);
  const [competitorCreate] = useCompetitorCreateMutation();
  const [competitorUpdate] = useCompetitorUpdateMutation();
  const [competitorDelete] = useCompetitorDeleteMutation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const {
    items: groupItems,
    createGroup,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.CompetitorPositioning,
        competitorId: defaultCompetitorId ? +defaultCompetitorId : null,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.CompetitorPositioning,
      competitorId: defaultCompetitorId ? +defaultCompetitorId : 0,
    }
  );
  const [errMsg, setErrMsg] = useState('');
  const [imageState, setImageState] = useState<imageStateType>(
    createUpdateCompetitorModal?.image && {
      preview: createUpdateCompetitorModal.image,
    }
  );

  const [requestFileUpload] = useRequestFileUploadMutation();

  let { type, title, competitorId, error, image } = createUpdateCompetitorModal;

  function closeModal() {
    setCreateUpdateCompetitorModal(competitorModalDefaultState);
  }

  useEffect(() => {
    if (!!image) setImageState({ preview: image });
  }, [image]);

  useEffect(() => {
    return () => {
      //Avoids memory leaks
      if (!!imageState) URL.revokeObjectURL(imageState.preview);
    };
  }, [imageState]);

  const hasCreatedGroup = useRef(false);

  useEffect(() => {
    if (defaultCompetitorId && !hasCreatedGroup.current) {
      hasCreatedGroup.current = true; // Mark as executed
      (async () => {
        if (groupItems.length === 0) {
          try {
            await createGroup({
              title: 'Unnamed group',
            });
          } catch (err) {
            console.error('Error creating group:', err);
            hasCreatedGroup.current = false; // Allow retry on error
          }
        }

      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCompetitorId]);

  useEffect(() => {
    return () => {
      setImageState('');
      setErrMsg('');
    };
  }, []);

  async function handleCreateUpdateCompetitor() {
    if (type === 'add') {
      if (!title)
        return setCreateUpdateCompetitorModal({
          ...createUpdateCompetitorModal,
          error: 'Name cannot be blank',
        });

      try {
        setLoading(true);

        const competitor = await competitorCreate({
          variables: {
            data: {
              title,
              stakeholder,
              type: typeCompetitor,
              strategyId: +strategyId,
              stakeholderDefinitionId: +stakeholderDefinitionId,
            },
          },
        });

        if (competitor.data?.competitorCreate.id) {
          const newCompetitorId = competitor.data?.competitorCreate.id;
          setCompetitorId(newCompetitorId); // Update the state asynchronously
        }
        
        // upload image and update competitor
        if (
          competitor.data?.competitorCreate.id &&
          !!imageState &&
          imageState.preview
        ) {
          const id = competitor.data?.competitorCreate.id;
          const { data } = await requestFileUpload({
            variables: {
              data: {
                strategyId: +strategyId,
                model: FileModel.Competitor,
                modelId: id,
                name: imageState.name,
              },
            },
          });

          if (!data?.requestFileUpload) {
            //delete created competitor
            await competitorDelete({ variables: { id } });
            throw Error('Upload failed');
          }

          await uploadFile([imageState] as File[], data.requestFileUpload);

          if (!!competitor.data.competitorCreate.id) {
            await competitorUpdate({
              variables: {
                id: competitor.data.competitorCreate.id,
                data: {
                  type: typeCompetitor,
                  stakeholder,
                  image: `${data.requestFileUpload.url}/${data.requestFileUpload.fields.key}`,
                },
              },
            });
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          setErrMsg(error.message);
        }
      } finally {
        await refetchCompetitors();
        setLoading(false);
        closeModal();
      }
    } else {
      // Type === 'edit'
      if (!title)
        return setCreateUpdateCompetitorModal({
          ...createUpdateCompetitorModal,
          error: 'Name cannot be blank',
        });

      try {
        if (!competitorId) return;

        setCompetitorId(competitorId); // Update the state asynchronously

        setLoading(true);
        let imageURL = '';

        if (imageState === '' && imageState !== image) {
          //Removing image
          await competitorUpdate({
            variables: {
              id: competitorId,
              data: {
                stakeholder,
                type: typeCompetitor,
                title,
                image: imageURL,
              },
            },
          });
        } else if (!!imageState && imageState.preview !== image) {
          //Update with new image
          const { data } = await requestFileUpload({
            variables: {
              data: {
                strategyId: +strategyId,
                model: FileModel.Competitor,
                modelId: competitorId,
                name: imageState.name,
              },
            },
          });

          if (!data?.requestFileUpload) {
            throw Error('Upload failed');
          }

          await uploadFile([imageState] as File[], data.requestFileUpload);

          imageURL = `${data.requestFileUpload.url}/${data.requestFileUpload.fields.key}`;

          await competitorUpdate({
            variables: {
              id: competitorId,
              data: {
                stakeholder,
                type: typeCompetitor,
                title,
                image: imageURL,
              },
            },
          });
        } else {
          await competitorUpdate({
            variables: { id: competitorId, data: { stakeholder, title, type: typeCompetitor} },
          });
        }

      } catch (error) {
        if (error instanceof Error) {
          setErrMsg(error.message);
        }
      } finally {
        await refetchCompetitors();
        setLoading(false);
        closeModal();
      }
    }
  }

  return (
    <ModalForm
      visible={!!type}
      handleClose={closeModal}
      heading={type === 'add' ? 'Add a competitor' : 'Edit competitor'}
    >
      <FormTextInput
        onChange={(e) => {
          setCreateUpdateCompetitorModal({
            ...createUpdateCompetitorModal,
            competitorId,
            title: e.target.value,
            error: '',
          });
        }}
        value={title}
        name="title"
        type="text"
        title="Name"
        errorMessage={error}
        autocomplete="off"
        autoFocus
      />

      <LogoUploader
        errMsg={errMsg}
        setErrMsg={setErrMsg}
        imageState={imageState}
        setImageState={setImageState}
      />

      <ButtonsContainer>
        <ButtonPill onClick={closeModal} text="Cancel" level="secondary" />

        <ButtonPill
          clickClassName="cypress-competitor-save"
          onClick={handleCreateUpdateCompetitor}
          text={type === 'add' ? 'Add' : 'Save'}
          loading={loading}
          disabled={!!error || loading}
        />
      </ButtonsContainer>
    </ModalForm>
  );
};
