import { polling, zIndex } from 'constants/index';
import {
  Stakeholder,
  Step,
  PostItCard,
  usePostItCardsQuery,
  PostItCardType,
  SubStep,
} from 'data/graphql/generated';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { SubMenu } from 'components/shared';
import { usePostItCards } from 'hooks/usePostItCards';
import { ErrorModal } from './ErrorModal';
import { sortPostItsKeyInsights } from 'hooks';
import { usePostItGroups } from 'hooks/usePostItGroups';
import { useOutcomesCardMove } from 'hooks/useOutcomesCardMove';
import { KeyInsightsPanelMenu } from './shared/KeyInsightsPanelMenu';

interface Props {
  offsetTopOverride?: number;
  isFullNmblr: boolean;
}

const InsightButtonWrapper = styled.div<{ open: boolean }>`
  display: flex;
  z-index: ${zIndex.observationsBtn};

  opacity: ${({ open }) => Number(!open)};
  pointer-events: ${({ open }) => (open ? 'none' : 'all')};
  transition: all 0.1s;
`;

interface URLParams {
  drugId: string;
  strategyId: string;
}

export const KeyInsightsSidePanel = ({
  offsetTopOverride,
  isFullNmblr,
}: Props) => {
  const {
    drugId,
    strategyId,
  }: URLParams = useParams();

  const [open, setOpen] = useState(false);
  const [deleteErrorModal, setDeleteErrorModal] = useState(false);

  const {
    createCard,
    updateCard,
    removeCard,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWho,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.TheWho
    }
  );

  const {
    data: groupsWithCardsData,
    refetch: refetchCards,
    loading: groupsWithCardsLoading,
  } = usePostItCardsQuery({
    variables: {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWho,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    items: groupItems,
    loading,
    startPolling,
    stopPolling
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        substep: SubStep.TheWho,
        step: Step.Positioning,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.TheWho,
    }
  );

  // for "What are the brand position that matter to key insights?" PostItGroups for 3.1.2 brand positioning
  const postItGroups = useMemo(() => sortPostItsKeyInsights(groupItems, groupsWithCardsData?.postItCards?.items || []), [groupItems, groupsWithCardsData]);

  // get all cards from PostItGroups
  const allCards = useMemo(() => postItGroups.flatMap(e => e.cards), [postItGroups]) as PostItCard[];
  const selectedPostItGroupId = useMemo(() => postItGroups[0]?.id, [postItGroups])

  const mappedCards = useMemo(() => {
    // Filter and sort include cards
    const includes = allCards
      .filter(card =>
        card.include &&
        card.type === PostItCardType.Plain
      )
      .sort((a, b) => b.pos - a.pos) as PostItCard[];

    // Filter and sort exclude cards
    const excludes = allCards
      .filter(card =>
        (card.include === false)
      )
      .filter(excludedItem =>
        !includes.some(includedItem => includedItem.title === excludedItem.title)
      )
      .sort((a, b) => {
        if (a.pos === b.pos) {
          return b.id - a.id;
        }

        return b.pos - a.pos;
      }) as PostItCard[];

    // Remove duplicate titles from excludes
    const filteredIncludes = excludes.filter((item, index, self) =>
      self.findIndex(excludedItem => excludedItem.title === item.title) === index
    );

    return { includes, excludes: filteredIncludes };
  }, [allCards]);

  const { onChange, loading: movingOrSwapping } = useOutcomesCardMove(
    mappedCards,
    updateCard,
    createCard,
    refetchCards,
    refetchCards,
    null,
    Stakeholder.Patient,
    '',
    '',
    selectedPostItGroupId,
    PostItCardType.KeyInsight
  );

  return (
    <>
      <InsightButtonWrapper open={false}>
        <SubMenu
          icon="Target"
          text="Key insights"
          onClick={() => {
            setOpen(true);
          }}
        />
      </InsightButtonWrapper>

      {/* key insights side panel menu */}
      <KeyInsightsPanelMenu
        readonly
        isFullNmblr={isFullNmblr}
        loading={groupsWithCardsLoading || loading}
        onChange={onChange}
        addCard={createCard}
        updateCard={updateCard}
        removeCard={async (vals) => {
          try {
            return await removeCard(vals);
          } catch (err) {
            if (err instanceof Error && (err.message === 'DELETE_ERROR' || err.message === 'Card Something went wrong')) {
              setDeleteErrorModal(true);
            }
          }
        }}
        refetch={() => {
          // refetchGroupsWithCards();
          refetchCards();
        }}
        groups={mappedCards}
        selectedPostItGroupId={selectedPostItGroupId}
        setOpen={setOpen}
        open={open}
        offsetTopOverride={offsetTopOverride}
        movingOrSwapping={movingOrSwapping}
        startPolling={() => startPolling(polling.default)}
        stopPolling={stopPolling}
      />

      <ErrorModal
        visible={deleteErrorModal}
        handleClose={() => setDeleteErrorModal(false)}
        title="Cannot delete this insight"
        text="Content in one or more stakeholders depends on this insight. You may move it to “Not included”."
      />
    </>
  );
};