import { FC } from 'react';
import styled from 'styled-components/macro';

import { colors } from 'constants/index';
import { BodyNormal } from './TextStyles';
import { Icon } from './Icon';
import { ToastLevel } from 'types';
import { AnimatingWaveIcon } from './AnimatingWaveIcon';
import { device } from 'utils/breakpoints';

const CTAWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;

  :hover {
    background-color: ${colors.white70};
  }
`;

const Wrapper = styled.div`
  height: 40px;
  background: ${colors.cream};
  border: 1px solid ${colors.black30};
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px 10px 10px 5px;
  display: flex;
  align-items: center;
  position: relative;
`;

const CopyWrapper = styled.div`
  height: 40px;
  background: ${colors.cream};
  border: 1px solid ${colors.black30};
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px 10px 10px 5px;
  display: flex;
  width: 280px;
  align-items: center;
  position: relative;
  margin-top: 20px;

  @media ${device.mobile}, ${device.tablet} {
    margin-top: 0px;
  }
`;

interface Props {
  level: ToastLevel;
  text: string;
  close(): void;
  onCTAClick(): void;
}

export const ToastPreview: FC<Props> = ({ level, text, close, onCTAClick }) => {
  if (level === 'Saving') {
    return (
      <Wrapper style={{ justifyContent: 'center' }}>
        <AnimatingWaveIcon className="icon" size={30} />
        <BodyNormal style={{ marginLeft: 6 }}>Saving...</BodyNormal>
      </Wrapper>
    );
  }

  if (level === 'Return') {
    return (
      <Wrapper style={{ padding: 5 }}>
        <CTAWrapper onClick={onCTAClick}>
          <Icon name="Back" size={30} color={colors.black} />
          <BodyNormal style={{ marginLeft: 5, paddingRight: 10 }}>
            {text}
          </BodyNormal>
        </CTAWrapper>

        <CTAWrapper>
          <Icon name="Close" size={30} color={colors.black} onClick={close} />
        </CTAWrapper>
      </Wrapper>
    );
  }

  if (level === 'Copy') {
    return (
      <CopyWrapper>
        <CTAWrapper style={{ pointerEvents: 'none' }}>
          <Icon name="Copy" size={30} color={colors.purple} />
          <BodyNormal style={{ marginLeft: 5, paddingRight: 10 }}>
            {text}
          </BodyNormal>
        </CTAWrapper>
        <CTAWrapper>
          <Icon name="Close" size={30} color={colors.black} onClick={close} />
        </CTAWrapper>
      </CopyWrapper>
    );
  }

  return (
    <Wrapper>
      <Icon
        name="Info"
        size={30}
        color={level === 'Info' ? colors.purple : colors.red}
        style={{ marginRight: 5 }}
      />
      <BodyNormal>{text}</BodyNormal>
      <Icon
        name="Close"
        size={30}
        color={colors.black}
        style={{ cursor: 'pointer' }}
        onClick={close}
      />
    </Wrapper>
  );
};
