import { globalContributor } from 'constants/index';
import { countries } from 'constants/countries';
import {
  KeyInsight,
  PatientFlowBlock,
  PostItGroup,
  PostItGroupFragment,
  User,
  Step as PageStep,
  PostItCardType,
  CommercialTacticFragment,
  LongTermSolutionFragment,
  AccessStrategyPrioritiseSupportingMessageFragment,
  SubStep,
  SupportingMessageTacticUpdateInput,
  BlockType,
} from 'data/graphql/generated';
import { usePostItCardsPayload } from 'hooks/usePostItCards';

export enum Roles {
  USER = 'USER',
  ADMIN = 'ADMIN',
  LEAD = 'LEAD',
  CONTRIBUTOR = 'CONTRIBUTOR',
}

export type CommercialTacticFragmentWithGlobalFocusedStatus = CommercialTacticFragment & {
  isGlobalFocused?: boolean;
  isLocalFocused?: boolean;
  isGlobalTactic?: boolean;
  isGlobalLocalTactic?: boolean;
  commercialTacticId?: number;
};

export type PositioningTabKebabCase =
  | 'competitor-positioning'
  | 'the-who'
  | 'the-why'
  | 'summary';

export interface BlockTree extends PatientFlowBlock {
  depth: number;
  left: number;
  children: BlockTree[];
  parent?: BlockTree;
  hasIndependentAncestor: boolean;
  hasLeveragePointSibling: boolean;
}

export interface Company {
  id: number;
  name: string;
}

export interface Drug {
  id: number;
  name: string;
  Strategy: Strategy[];
}

export interface Strategy {
  id: number;
  name: string;
  users: User[];
}

export type StakeholdersFullStringForGroups =
  | 'Patients'
  | 'HCPs'
  | 'Payors'
  | 'Providers'
  | 'Policymakers';

export type StakeholdersFullString =
  | 'Patients'
  | 'Healthcare professionals'
  | 'Payors'
  | 'Providers'
  | 'Policymakers';

export type StakeholderFullString =
  | 'Patient'
  | 'Healthcare professional'
  | 'Payor'
  | 'Provider'
  | 'Policymaker';

export interface Block {
  id: number;
  lastUpdated: string;
  createdAt: string;

  x: number;
  y: number;
  type: BlockType;
  text: string;
  isDragging: boolean;
  isDeleting: boolean;

  column: number;

  keyStakeHolderPatient: boolean;
  keyStakeHolderHealthcare: boolean;
  keyStakeHolderPayor: boolean;
  keyStakeHolderProvider: boolean;
}

export type ConnectionSide = 'top' | 'bottom' | 'left' | 'right';

export interface Connection {
  id: number;
  toBlockId: number;
  toSide: ConnectionSide;
  fromBlockId: number;
  fromSide: ConnectionSide;
  path: number[];
}

export interface Column {
  id: number;
  name: string;
  idx: number;
  width: number;
}

export interface Position {
  x: number;
  y: number;
}

export type Cursor =
  | 'default'
  | 'move'
  | 'pointer'
  | 'grabbing'
  | 'text'
  | 'col-resize';

export type CardType = PostItCardType;

export type Step = PageStep;

export type ReadableStep =
  | 'Key Stakeholders'
  | 'Patient Journey'
  | 'Moments That Matter'
  | 'Key Insights';

interface Model {
  id: number;
  companyId?: number;
  company?: Company;
  strategyId?: number;
  strategy?: Strategy;
  drugId?: number;
  drug?: Drug;

  lastUpdated: string | Date;
  createdAt: string | Date;
}

export interface Card extends Model {
  title: string;
  country: Country;
  type: CardType;
  postItGroupId: number;
  postItGroup?: number | PostItGroup;
  pos: number;
  collaboration: {
    id: number;
    alignmentCount: number;
    commentCount: number;
    attachmentCount: number;
  };
  user: User;
  image: string;
}

// export interface PostItCard extends Card {}

// export interface PostItGroup extends Model {
//   title: string;
//   subtitle: string;
//   cards: PostItCard[];
//   preset: boolean;
// }

export interface PostItGroupAndCards extends PostItGroupFragment {
  cards: usePostItCardsPayload['items'];
}
export interface StakeholderDefinition extends Model {
  title: string;
  image: string;
}

export type IconName =
  | 'Action'
  | 'Alert'
  | 'Add patient'
  | 'Adaptation'
  | 'Arrow-down'
  | 'Arrow-right'
  | 'Arrow-left'
  | 'Arrow-up'
  | 'Attachment'
  | 'Back'
  | 'Camera'
  | 'Chart'
  | 'Checkcircle-on'
  | 'Checkcircle-off'
  | 'Checkcircle-on-white'
  | 'Chevron-down'
  | 'Chevron-down-full'
  | 'Chevron-right-full'
  | 'Chevron-up'
  | 'Clock'
  | 'Close'
  | 'Collapse right'
  | 'Comment'
  | 'Complete'
  | 'Delete'
  | 'Dollar'
  | 'Download'
  | 'Edit'
  | 'Ellipse'
  | 'Export'
  | 'Files'
  | 'Frown'
  | 'Gap'
  | 'Grip'
  | 'GradientTick'
  | 'GradientBlueTick'
  | 'Hash'
  | 'HCP'
  | 'Heart'
  | 'Help'
  | 'Home'
  | 'Image'
  | 'Hurdle'
  | 'Incomplete'
  | 'Step Complete'
  | 'Step Incomplete'
  | 'Jobs'
  | 'Lead'
  | 'Lag'
  | 'LoadingBar'
  | 'LoadingBarGradient'
  | 'Lock'
  | 'Lightning'
  | 'Menu'
  | 'Minus'
  | 'More'
  | 'MoreText'
  | 'MoreTextActive'
  | 'MoreDetailActive'
  | 'MoreDetail'
  | 'Notifications OFF'
  | 'Notifications ON'
  | 'Notification'
  | 'Observation'
  | 'OverlayChevron'
  | 'Patient'
  | 'Pattern'
  | 'People'
  | 'Percent'
  | 'Product'
  | 'Pin'
  | 'BluePin'
  | 'PinSolid'
  | 'Provider'
  | 'Plus'
  | 'Sad'
  | 'Star'
  | 'StarPurple'
  | 'StarOutline'
  | 'StarOutlinePurple'
  | 'Strategy'
  | 'GradientStrategy'
  | 'Target'
  | 'Text'
  | 'Thumbs up Active'
  | 'Thumbs up'
  | 'Tick'
  | 'Tick Small'
  | 'Touchpoint'
  | 'Trash'
  | 'Treatment'
  | 'Trigger'
  | 'Upload'
  | 'Unexpected behaviour'
  | 'Wave segment'
  | 'X-Cross'
  | 'Key-sparks'
  | 'Gradient-Key-sparks'
  | 'Global'
  | 'LeadGlobe'
  | 'Send'
  | 'SpinOff'
  | 'TrashOutline'
  | 'Pencil'
  | 'FullLogo'
  | 'FullLogo2'
  | 'FullLogoWhite'
  | 'StarSelected'
  | 'StarUnselected'
  | 'DollarSelected'
  | 'DollarUnselected'
  | 'MedicalLeadership'
  | 'DataGeneration'
  | 'DataDissemination'
  | 'MedicalCommunityIntegration'
  | 'MedicalKnowledge'
  | 'PatientAdvocacy'
  | 'Calendar'
  | 'CalendarPlus'
  | 'Undo'
  | 'Redo'
  | 'Underline'
  | 'EditorText'
  | 'OrderedList'
  | 'List'
  | 'Link'
  | 'Left'
  | 'Italic'
  | 'EditorImage'
  | 'HeadingOne'
  | 'HeadingTwo'
  | 'Center'
  | 'Bold'
  | 'Info'
  | 'CommentEmptyState'
  | 'AlignEmptyState'
  | 'NotificationEmptyState'
  | 'GenericEmptyState'
  | 'FileEmptyState'
  | 'GradientFileEmptyState'
  | 'Policymaker'
  | 'HighPriority'
  | 'Copy'
  | 'HelpCircle'
  | 'Stage'
  | 'ExternalLink'
  | 'Atom';

export const CountryConstants = {
  GLOBAL: 'global',
};

export const countryArr = countries.map((country) => country.name);

export type Country = typeof countryArr[number];
export type CountryGlobal = Country | 'global';

export type CountryGlobalRegional = Country | typeof globalContributor;
export const countryAndGlobalRegionalArr = [
  globalContributor,
  ...countryArr,
] as CountryGlobalRegional[];

export const getCountry = (country: string) => {
  if (country === CountryConstants.GLOBAL) {
    return 'global / regional';
  }

  return countryAndGlobalRegionalArr.find(
    (x) => x === country
  ) as CountryGlobalRegional;
};

export type TabTypes =
  | 'StepTab'
  | 'ContributionTab'
  | 'CompetitveLandscapeCompetitor'
  | 'CategoryTab'
  | 'KeyStakeholderCreator'
  | 'CompetitorTab'
  | 'KeyStatement';

export interface DragItemProps {
  type: 'observation';
  id: number | undefined;
  source: string;
}

export interface Position {
  x: number;
  y: number;
}

export type ExcludedFeature =
  | 'drugLink'
  | 'strategyLink'
  | 'filesLink'
  | 'profile'
  | 'notifications'
  | 'navItems'
  | 'navContent'
  | 'sidebarTrigger';
export type IncludedFeature =
  | 'userLink'
  | 'productLink';

export interface URLParams {
  drugId: string;
  strategyId: string;
  stakeholder: string;
}

export interface CompetitorModalState {
  title: string;
  type: 'add' | 'edit' | null;
  competitorId: number | null;
  error: string;
  image: string;
}

export type StrategicPossibilityKeyStakeholder = Pick<
  StakeholderDefinition,
  'id' | 'image' | 'title'
>;

export type StrategicPossibilityUnlockingInsight = Pick<
  KeyInsight,
  'id' | 'text'
>;

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

// Removes null from fields in object. Can be used to fix instances where codegen Maybe assigns null to an incorrect field.
export type NonNull<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export const DragNDropItems = {
  PIN: 'pin',
  CARD: 'card',
};

export type DraggableCardType = {
  type: string;
  data:
    | PreviewOrExistingTactic
    | PreviewOrExistingSolution
    | AccessStrategyPrioritiseSupportingMessageFragment;
};

export type PreviewOrExistingTactic =
  | CommercialTacticFragmentWithGlobalFocusedStatus
  | { localUid: string };

export type PreviewOrExistingSolution =
  | LongTermSolutionFragment
  | { localUid: string };

export type AccessStrategySubStep =
  | SubStep.Prioritisation
  | SubStep.Gaps
  | SubStep.Evidence
  | SubStep.WinningActions
  | SubStep.Pico;

export type AccessStrategyRowData = NonNull<
  Pick<
    SupportingMessageTacticUpdateInput,
    'budget' | 'text' | 'responsibility' | 'focus'
  >
> &
  Omit<
    SupportingMessageTacticUpdateInput,
    'budget' | 'text' | 'responsibility' | 'focus'
  >;

export type ToastLevel = 'Info' | 'Warning' | 'Saving' | 'Return' | 'Copy';
