import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { colors } from 'constants/index';
import {
  Exact,
  KeyInsightDeleteMutation,
  KeyInsightFragment,
  KeyInsightOrderByInput,
  KeyInsightUpdateInput,
  KeyInsightUpdateMutation,
  KeyInsightWhereInput,
  KeyInsightsDocument,
  Maybe,
  ObservationFragment,
  usePostItGroupUpdateMutation,
} from 'data/graphql/generated';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import useMobile from 'hooks/useMobile';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled, { css, keyframes } from 'styled-components/macro';
import { DragItemProps } from 'types';
import {
  apolloDeleteHelper,
  apolloUpdateHelper,
} from 'utils/apolloQueryHelpers';
import { device } from 'utils/breakpoints';
import { formatCreatedDate } from 'utils/formatCreatedDate';
import {
  BodyNormal,
  BodySmall,
  ButtonLabel,
  ButtonPill,
  ButtonRound,
  Caption,
  Collaboration,
  DraggableObservation,
  Subtitle2,
} from './shared';
import { TextAreaInput } from './shared/TextAreaInput';

const ObservationsTransitionWrapper = styled.div<{
  state: TransitionStatus;
  maxHeight: () => number;
  addingObservations: boolean;
}>`
  overflow: hidden;
  transition: all 0.3s;

  ${({ state, maxHeight }) => {
    switch (state) {
      case 'entering':
        return css`
          max-height: 0px;
        `;

      case 'entered':
        return css`
          max-height: ${maxHeight()}px;
        `;

      case 'exiting':
        return css`
          max-height: ${maxHeight()}px;
        `;

      case 'exited':
        return css`
          max-height: 0px;
        `;
    }
  }}

  ${({ addingObservations }) => {
    if (addingObservations) {
      return css`
        max-height: max-content;
      `;
    }
  }}

  margin-top: 15px;
`;

export const ReadOnlyTextWrapper = styled.div`
  margin-top: 9px;
  margin-bottom: 5px;
`;

interface Props {
  setPreviewHasText: React.Dispatch<React.SetStateAction<boolean>>;
  enableAddObservations: boolean;
  showAddObservations: number | boolean;
  setShowAddObservations: React.Dispatch<
    React.SetStateAction<number | boolean>
  >;

  keyInsightsQueryVars: Exact<{
    where?: KeyInsightWhereInput | null | undefined;
    orderBy?: KeyInsightOrderByInput | null | undefined;
    skip?: number | null | undefined;
    take?: number | null | undefined;
  }>;
  observationsData:
    | Array<Maybe<{ __typename?: 'Observation' } & ObservationFragment>>
    | undefined;
  insightData:
    | KeyInsightFragment
    | {
        localUid: string;
      }
    | null;
  setObservationsPaneFilterData: React.Dispatch<
    React.SetStateAction<(number | null)[]>
  >;
  deletePreviewInsight(): void;
  preview?: boolean;
  updateKeyInsight: (
    options?:
      | MutationFunctionOptions<
          KeyInsightUpdateMutation,
          Exact<{
            id: number;
            data: KeyInsightUpdateInput;
          }>
        >
      | undefined
  ) => Promise<
    FetchResult<
      KeyInsightUpdateMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  deleteKeyInsight: (
    options?:
      | MutationFunctionOptions<KeyInsightDeleteMutation, Exact<{ id: number }>>
      | undefined
  ) => Promise<FetchResult<any> | void>;
  createKeyInsight: (
    text: string,
    observations: (number | null)[],
    localUid: string
  ) => Promise<void>;
  id: number | undefined;
  theWhoPageId: number | undefined;
  isNewItem: boolean;
  setRemovingFromInsight: React.Dispatch<React.SetStateAction<boolean>>;
  disableCollaboration?: boolean;
  readonly?: boolean;
}

const Wrapper = styled.div<{
  isActive: boolean;
}>`
  position: relative;
  width: 100%;
  background: ${({ isActive }) =>
    isActive
      ? `linear-gradient(      90deg,      rgb(255 255 255 / 90%) 0%,rgb(255 255 255 / 90%) 100%),radial-gradient(circle at bottom left,
      rgba(10, 3, 18, 1) 0%,
      rgba(72, 16, 167, 1) 10%,
      rgba(75, 126, 235, 1) 20%,
      rgba(83, 123, 234, 1) 50%,
      rgba(234, 78, 209, 1) 60%,
      rgba(254, 247, 253, 1) 80% 100%
    )`
      : colors.white};

  background-attachment: fixed;

  background-clip: padding-box;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;

  border: 1px solid transparent;
  transition: max-height 0.3s linear;
  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: 0;
    left: 0;
    border-radius: 5px;
    z-index: -1;
    margin: -1px;
    background: ${colors.black};
    opacity: ${({ isActive }) => (isActive ? 1 : 0.3)};
    background-image: ${({ isActive }) =>
      isActive ? 'linear-gradient(134deg, #7800FF,#FF00C8 )' : 'none'};
    transition: opacity 0.3s;
  }
`;

const Footer = styled.div`
  margin-top: 10px;
  margin-left: auto;
  @media ${device.tabletMax} {
    margin-bottom: 0px;
  }
  display: flex;
  justify-content: space-between;
`;

const SelectedObservations = styled.div``;

const EmptyState = styled.div`
  width: 100%;
  border-radius: 5px;
  background: ${colors.white};
  border: 0.5px solid ${colors.black30};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const DropZone = styled.div<{
  isOver: boolean;
  showingAllObservations: boolean;
  dropMode: boolean;
  topMargin: boolean;
  noObservations: boolean;
}>`
  margin-top: ${({ topMargin }) => (topMargin ? '20px' : '0px')};

  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* This selects every child element excluding the first Item. It is called a lobotomised owl. Replacement for gap */
  > * + * {
    margin-top: 5px;
  }

  @media ${device.tabletMax} {
    margin-top: ${({ noObservations }) => (noObservations ? '0' : '25px')};
    border: 0px dashed ${colors.greyDark};
  }

  ${({ showingAllObservations }) => {
    if (showingAllObservations) {
      return css`
        border: none;
        padding: 0;
      `;
    }
  }};

  ${({ dropMode, isOver }) => {
    if (dropMode) {
      return css`
        border: 2px dashed ${() => (isOver ? colors.purple : colors.greyDark)};
        padding: 5px;
        min-height: 145px;
      `;
    }
  }};

  position: relative;

  ::after {
    content: 'Drop to support insight';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.white90};
    z-index: 2000;
    border-radius: 5px;

    font-family: ABCFavorit;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    opacity: 0;
    pointer-events: none;

    ${({ isOver }) => {
    return (
      isOver &&
      css`
          opacity: 1;
          pointer-events: all;
        `
    );
  }}

    transition: all 0.2s;
  }

  overflow: hidden;
`;

const ObservationsButtonWrapper = styled.div`
  max-height: 40px;
  overflow: hidden;
  transition: all 0.1s linear;
  margin-right: 15px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
`;

const grow = keyframes`
  from {
    max-height: 0px;
  }
  to {
    max-height: 30px;
  }
`;

export const StyledCaption = styled(Caption) <{ state: boolean }>`
  position: absolute;
  top: 15px;
  right: 15px;

  ${({ state }) => {
    if (state) {
      return css`
        animation: ${grow} 0.2s forwards ease-in;
      `;
    }
  }}

  overflow: hidden;
  text-align: end;
  transition: all 0.3s;
`;

export const StyledDeleteButton = styled(ButtonRound) <{ show: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  transform: translate(50%, -50%);
  opacity: ${({ show }) => Number(show)};
  pointer-events: ${({ show }) => (show ? 'all' : 'none')};
`;

export const DeleteConfirmation = styled.div<{ show: boolean }>`
  width: 100%;
  height: 100%;
  padding: 20px 15px;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: ${colors.white};
  z-index: ${({ show }) => (show ? 1000 : 1)};
  border-radius: inherit;
  p {
    text-align: center;
  }
`;

export const DeleteConfirmationBody = styled.div`
  margin-top: 5px;
`;

export const DeleteConfirmationFooter = styled.div`
  display: flex;

  /* This selects every child element excluding the first Item. It is called a lobotomised owl. Replacement for gap */
  > * + * {
    margin-left: 20px;
  }

  margin-top: 10px;
  p {
    cursor: pointer;
  }
`;

export const Insight = ({
  setObservationsPaneFilterData,
  deletePreviewInsight,
  preview,
  keyInsightsQueryVars,
  deleteKeyInsight,
  updateKeyInsight,
  id,
  theWhoPageId,
  insightData,
  observationsData,
  showAddObservations,
  setShowAddObservations,
  createKeyInsight,
  enableAddObservations,
  isNewItem,
  setRemovingFromInsight,
  setPreviewHasText,
  disableCollaboration = false,
  readonly = false,
}: Props) => {
  const isMobile = useMobile();
  const [data, setData] = useState(insightData as KeyInsightFragment);

  const [title, setTitle] = useState(!preview ? data?.text : '');
  const [didBlur, setDidBlur] = useState(false);

  const [selectedObservations, setSelectedObservations] = useState<
    (number | null)[]
  >(data?.observationIds?.map((val) => val) || []);
  const isAlreadyMounted = useRef(false);

  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [observationsDataState, setobservationsDataState] = useState(
    observationsData
  );
  const [groupUpdate] = usePostItGroupUpdateMutation();

  useEffect(() => {
    setobservationsDataState(observationsData);
    setData(insightData as KeyInsightFragment);
    setSelectedObservations(
      insightData && 'observationIds' in insightData
        ? insightData?.observationIds?.map((val) => val)
        : []
    );
  }, [observationsData, insightData]);

  useEffect(() => {
    isAlreadyMounted.current &&
      setObservationsPaneFilterData(selectedObservations);
    isAlreadyMounted.current = true;
  }, [selectedObservations, setObservationsPaneFilterData]);

  const [{ isOver, item }, drop] = useDrop(() => ({
    options: { id: 'OI' },
    accept: 'observation',
    drop: async (item: { type: string; id: number | undefined }) => {
      const id = item?.id;
      setSelectedObservations((observations) => {
        if (typeof id === 'number' && !observations.includes(id)) {
          return [id, ...observations];
        }
        return observations;
      });
      return { id };
    },

    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
      item: monitor.getItem(),
    }),
  }));

  const addingObservations = showAddObservations === id;
  const textValidCheck = didBlur && !title;

  useEffect(() => {
    if (preview && !!title) {
      setPreviewHasText(true);
    }

    if (preview && !title) {
      setPreviewHasText(false);
    }
  }, [title, preview, setPreviewHasText]);

  useEffect(() => {
    //If the item was in the insight and it's been moved out of the insight
    const itemData = item as DragItemProps;

    const itemInInsight =
      itemData?.id && selectedObservations.includes(itemData?.id);

    if (!isOver && itemInInsight && addingObservations) {
      setRemovingFromInsight(true);
      return;
    }
    if ((isOver && itemInInsight) || !itemData?.id) {
      setRemovingFromInsight(false);
    }
  }, [
    isOver,
    selectedObservations,
    item,
    setRemovingFromInsight,
    addingObservations,
  ]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const textArea = useRef<HTMLTextAreaElement>(null);

  const saveObservations = useCallback(async () => {
    setObservationsPaneFilterData([]);
    setShowAddObservations(false);

    if (preview && !!title) {
      if (insightData?.localUid) {
        await createKeyInsight(
          title,
          selectedObservations,
          insightData?.localUid
        );
      }
    }

    data?.text &&
      (await updateKeyInsight({
        variables: {
          id: Number(id),
          data: {
            observations: selectedObservations,
          },
        },
        update: apolloUpdateHelper({
          responseField: 'keyInsightUpdate',
          query: KeyInsightsDocument,
          queryVars: keyInsightsQueryVars,
          queryName: 'keyInsights',
        }),
      }));
  }, [
    setObservationsPaneFilterData,
    setShowAddObservations,
    preview,
    title,
    data?.text,
    updateKeyInsight,
    id,
    selectedObservations,
    keyInsightsQueryVars,
    insightData?.localUid,
    createKeyInsight,
  ]);

  const deleteButtonRef = useRef<HTMLDivElement>(null);
  const observationsButtonWrapperRef = useRef<HTMLDivElement>(null);

  useClickOutsideComponent(
    containerRef,
    (event) => {
      setConfirmDelete(false);
      setShowDeleteButton(false);

      if (!title) {
        deletePreviewInsight();
      } else if (addingObservations) {
        saveObservations();
      }
    },
    [
      'ObservationsPane',
      'observations--toggle',
      'cypress-assign-observations-modal',
    ]
  );

  const ObservationsTransitionWrapperRef = useRef<HTMLDivElement>(null);
  const noObservations = !selectedObservations.length;

  if (!preview && !data) {
    return null;
  }

  return (
    <Wrapper
      data-cy="insight"
      isActive={addingObservations}
      ref={(e) => {
        drop(e);
        containerRef.current = e;
      }}
    >
      <StyledCaption color={colors.greyDark} state={isNewItem}>
        {data && typeof data.createdAt === 'string'
          ? formatCreatedDate(Number(data.createdAt))
          : null}
      </StyledCaption>
      {!readonly || !!preview ? (
        <TextAreaInput
          $invalid={textValidCheck}
          $borderless
          ref={textArea}
          autoFocus={preview}
          rows={1}
          value={title}
          onChange={async (e) => {
            const target = e.target as HTMLTextAreaElement;
            setTitle(target.value);
          }}
          onFocus={(e) => {
            textArea.current?.select();
            setShowDeleteButton(true);
          }}
          onBlur={async (e) => {
            setDidBlur(true);

            if (addingObservations) {
              return;
            }
            const deleteBtn = deleteButtonRef.current;

            const newFocusedElement = e.relatedTarget as Node | null;

            if (newFocusedElement) {
              if (!deleteBtn?.contains(newFocusedElement)) {
                setShowDeleteButton(false);
              }

              //If clicking on "Assign observations, don't create the observations yet"
              if (
                observationsButtonWrapperRef.current?.contains(
                  newFocusedElement
                )
              ) {
                return;
              }
            }

            if (preview && !!e.target.value && insightData?.localUid) {
              await createKeyInsight(
                e.target.value,
                selectedObservations,
                insightData?.localUid
              );
              return;
            }

            if (!e.target.value) {
              !preview && setTitle(data?.text || '');
              return;
            }
            if (!preview) {
              await updateKeyInsight({
                variables: {
                  id: Number(id),
                  data: {
                    text: e.target.value,
                    observations: data?.observationIds,
                  },
                },
                update: apolloUpdateHelper({
                  responseField: 'keyInsightUpdate',
                  query: KeyInsightsDocument,
                  queryVars: keyInsightsQueryVars,
                  queryName: 'keyInsights',
                }),
              });

              if (!theWhoPageId) {
                await groupUpdate({
                  variables: {
                    id: Number(theWhoPageId),
                    data: {
                      title: e.target.value,
                    },
                  },
                });
              }
            }
          }}
        />
      ) : (
        <ReadOnlyTextWrapper>
          <Subtitle2 color={colors.black}>{title}</Subtitle2>
        </ReadOnlyTextWrapper>
      )}
      <Footer>
        {disableCollaboration ? null : (
          <Collaboration
            disabled={preview}
            collaboration={data?.collaboration}
          />
        )}
      </Footer>

      {(disableCollaboration || readonly) ? null : (
        <Transition timeout={300} in={enableAddObservations}>
          {(state) => {
            return (
              <ObservationsTransitionWrapper
                ref={ObservationsTransitionWrapperRef}
                state={state}
                addingObservations={addingObservations}
                maxHeight={() =>
                  ObservationsTransitionWrapperRef?.current?.scrollHeight || 175
                }
              >
                <Buttons>
                  <ObservationsButtonWrapper
                    data-cy="assign-observations-btn"
                    ref={observationsButtonWrapperRef}
                  >
                    {!addingObservations ? (
                      <ButtonPill
                        disabled={preview}
                        iconName="Plus"
                        text={isMobile ? 'Observations' : 'Assign observations'}
                        level="secondary"
                        onClick={(e) => {
                          setObservationsPaneFilterData(selectedObservations);
                          setShowAddObservations(id || -1);
                        }}
                      />
                    ) : (
                      <ButtonPill
                        iconName="Tick"
                        text="Done"
                        level="secondary"
                        onClick={async () => {
                          saveObservations();
                        }}
                      />
                    )}
                  </ObservationsButtonWrapper>
                </Buttons>

                <SelectedObservations>
                  <DropZone
                    showingAllObservations={enableAddObservations}
                    dropMode={addingObservations}
                    topMargin={
                      !!selectedObservations.length ||
                      (!selectedObservations.length && addingObservations)
                    }
                    isOver={isOver && addingObservations}
                    noObservations={noObservations}
                  >
                    {!selectedObservations.length && addingObservations ? (
                      <EmptyState>
                        <Subtitle2 color={colors.greyDark}>
                          Drag observations here
                        </Subtitle2>
                        <BodySmall color={colors.greyDark}>
                          Bring in observations from the column on the right
                        </BodySmall>
                      </EmptyState>
                    ) : null}

                    {selectedObservations.map((observation) => {
                      const observationData = observationsDataState?.find(
                        (val) => val?.id === observation
                      );

                      if (!observationData) {
                        return null;
                      }

                      return (
                        <DraggableObservation
                          source="Insight"
                          enableDrag={addingObservations}
                          onEnd={(dropResult, monitor) => {
                            if (!monitor.didDrop()) {
                              setSelectedObservations((observations) => {
                                const newArr = observations.filter(
                                  (val) => val !== observation
                                );
                                const otherarr = (newArr as unknown) as number[];
                                setObservationsPaneFilterData(otherarr);
                                return newArr;
                              });
                            }
                          }}
                          deleteObservation={
                            !addingObservations
                              ? undefined
                              : () => {
                                  setSelectedObservations((observations) => {
                                    const newArr = observations.filter(
                                      (val) => val !== observation
                                    );
                                    const otherarr = (newArr as unknown) as number[];
                                    setObservationsPaneFilterData(otherarr);
                                    return newArr;
                                  });
                                }
                          }
                          key={observation || -1}
                          data={observationData}
                        />
                      );
                    })}
                  </DropZone>
                </SelectedObservations>
              </ObservationsTransitionWrapper>
            );
          }}
        </Transition>
      )}
      {confirmDelete ? (
        <DeleteConfirmation show={confirmDelete}>
          <div>
            <BodyNormal color={colors.black}>Delete this insight?</BodyNormal>
          </div>
          <DeleteConfirmationBody>
            <BodyNormal color={colors.black60}>
              Any discussion and files will be lost.
            </BodyNormal>
          </DeleteConfirmationBody>
          <DeleteConfirmationFooter>
            <ButtonLabel
              onClick={() => {
                setConfirmDelete(false);
                setShowDeleteButton(false);
              }}
            >
              Cancel
            </ButtonLabel>
            <ButtonLabel
              color={colors.red}
              onClick={async (e) => {
                e.stopPropagation();
                if (preview) {
                  deletePreviewInsight();
                  return;
                }
                setShowAddObservations(false);

                await deleteKeyInsight({
                  variables: { id: Number(id) },
                  update: apolloDeleteHelper({
                    responseField: 'keyInsightDelete',
                    query: KeyInsightsDocument,
                    queryVars: keyInsightsQueryVars,
                    queryName: 'keyInsights',
                  }),
                });
              }}
            >
              Delete
            </ButtonLabel>
          </DeleteConfirmationFooter>
        </DeleteConfirmation>
      ) : null}
      <StyledDeleteButton
        innerRef={deleteButtonRef}
        iconName="Trash"
        level="secondary"
        size="small"
        tooltip=""
        onClick={() => {
          setConfirmDelete(true);
        }}
        show={showDeleteButton}
      />
    </Wrapper>
  );
};
