import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components';
import { ButtonLabel } from './TextStyles';

interface Props {
  text: string;
  active?: boolean;
  onClick?(): void;
  className?: string;
}

interface ActiveProps {
  active: boolean;
}

const Wrapper = styled.div<ActiveProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 15px;

  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 25px;

  background: ${({ active }) => (active ? colors.purple : colors.purple10)};

  p {
    white-space: nowrap;
    color: ${({ active }) => (active ? colors.white : colors.black)};
    transition: color 0.3s;
  }

  &:hover {
    background: ${({ active }) => (active ? colors.purple : colors.purple10a)};
  }
`;

export const OutcomesStepTab = ({
  text,
  active = false,
  onClick,
  className = '',
}: Props) => {
  return (
    <Wrapper
      data-cy="step-tab"
      className={`${className} stakeholderTab`}
      active={active}
      onClick={onClick}
    >
      <ButtonLabel>{text}</ButtonLabel>
    </Wrapper>
  );
};
