import styled from 'styled-components/macro';
import { TacticUpdateInput } from 'data/graphql/generated';
import { useModal } from 'hooks';
import { colors } from 'constants/colors';
import { PicoSuggestionRowData } from 'components/4-1-medical-strategy/data-access-medical-tactics-picos';
import { ViewPicoSuggestionDetailsModal } from 'components/4-1-medical-strategy/feature-medical-tactics-pico-modal';
import { PicoTacticRow } from './PicoTacticRow';

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.greyLight};
  margin-bottom: 5px;
`;

interface PicoSuggestionWithTacticRowProps {
  isFirstRow: boolean;
  isDesktop: boolean;
  currency: string;
  picoSuggestion: PicoSuggestionRowData;
  onUpdate: (
    data: TacticUpdateInput & {
      competitiveAdvantageRow: number;
      localUid: string;
      strategyId: number;
    }
  ) => Promise<void>;
}

export const PicoSuggestionWithTacticRow = ({
  isFirstRow,
  isDesktop,
  currency,
  picoSuggestion,
  onUpdate,
}: PicoSuggestionWithTacticRowProps) => {
  const { isOpen, open, close } = useModal();

  return (
    <>
      {isFirstRow && !isDesktop && <Divider />}

      <ViewPicoSuggestionDetailsModal
        isOpen={isOpen}
        onClose={close}
        picoSuggestion={{
          id: picoSuggestion.id,
          name: picoSuggestion.name,
          population: picoSuggestion.population,
          intervention: picoSuggestion.intervention,
          comparison: picoSuggestion.comparison,
          outcome: picoSuggestion.outcome,
        }}
        tacticId={picoSuggestion.tactic?.id}
      />

      <PicoTacticRow
        pico={{
          id: picoSuggestion.id,
          name: picoSuggestion.name,
        }}
        data={picoSuggestion.tactic}
        currency={currency}
        isLead={false}
        userMayEditRow={true}
        isDesktop={isDesktop}
        onViewPicoDetails={open}
        onUpdate={onUpdate}
      />
    </>
  );
};
