import { CompetitorFragment, Stakeholder } from 'data/graphql/generated';
import React from 'react';
import styled from 'styled-components';
import { TabGroup } from 'components/shared';
import { CompetitorTab } from './CompetitorTab';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from 'contexts/GlobalContext';

export const Wrapper = styled.div`
  width: fit-content;
  overflow: auto;
`;

interface Props {
  competitors: CompetitorFragment[];
  selectedCompetitorId: number;
  drugId: number;
  strategyId: number;
  stakeholder: Stakeholder;
  selectedStakeholderDefinitionId: number;
  onClick?(): void;
}

export const CompetitorTabs: React.FC<Props> = ({
  competitors,
  selectedCompetitorId,
  drugId,
  strategyId,
  stakeholder,
  selectedStakeholderDefinitionId,
  onClick,
}) => {
  const history = useHistory();

  // handle global context path
  const [ , setCompetitorIdGlobal ] = useGlobalContext(
    'competitorIdGlobal'
  );

 
  function setSelectedCompetitor(competitorId: number) {
    if (onClick) {
      onClick();
    } else {
      history.push(
        `/d/${drugId}/strategy/${strategyId}/3_1/competitor-positioning/${competitorId}`
      );
      setCompetitorIdGlobal(competitorId);
    }
  }

  return (
    <Wrapper>
      <TabGroup componentName="CompetitorTab" key={competitors.length}>
        <>
          {competitors.map((competitor, index) => {
            return (
              <div
                key={competitor.id}
                style={{ display: 'inline-block', padding: '0 2px' }}
              >
                <CompetitorTab
                  text={competitor.title}
                  image={competitor?.image}
                  active={competitor.id === selectedCompetitorId}
                  onClick={() => setSelectedCompetitor(competitor.id)}
                />
              </div>
            );
          })}
        </>
      </TabGroup>
    </Wrapper>
  );
};
