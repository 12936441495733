import React, { FC, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { useHelpContext } from 'contexts/HelpContext';
import { useHistory } from 'react-router-dom';
import { useUserSafeUpdateMutation } from 'data/graphql/generated';
import { useAuthContext } from 'contexts/AuthContext';

import { BodyNormal, ButtonPill } from './shared';
import { colors, themes, zIndex } from '../constants';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Background = styled.div`
  display: block;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: ${zIndex.overlay};
  background: rgba(20, 20, 39, 0.4);
  opacity: 0;
  animation: ${fadeIn} 0.3s;
  animation-fill-mode: forwards;
  overflow-y: auto;
`;

export const Content = styled.div`
  display: block;
  margin: 120px auto 80px auto;
  opacity: 0;
  animation: ${fadeIn} 0.3s;
  animation-fill-mode: forwards;
  background: ${colors.white};
  z-index: ${zIndex.dialog};
  width: 90%;
  max-width: 887px;
  box-shadow: 0px 10px 20px 0px rgb(0, 0, 0 / 10%),
    0px 1px 5px 0px rgb(0, 0, 0 / 5%);
  border-radius: 5px;
  overflow: hidden;
`;

const Image = styled.div`
  aspect-ratio: 334 / 127;
  background: #efe6fc;
`;

const ContentText = styled.div`
  padding: 30px;

  @media (min-width: 700px) {
    display: flex;
    flex-direction: row;
  }
`;

const ContentButton = styled(ButtonPill)`
  margin-top: 10px;
  width: 100%;

  @media (min-width: 700px) {
    width: 90px;
    float: right;
  }
`;

const Heading = styled.h3`
  margin: 0px 0px 15px 0px;
  font-family: 'ABCFavoritExpanded';
  font-weight: 300;
  font-size: 32px;
  line-height: 45px;

  @media (min-width: 700px) {
    padding-right: 15px;
    width: ${(328 / 827) * 100}%;
  }
`;

const Desc = styled.div`
  @media (min-width: 700px) {
    width: ${(499 / 827) * 100}%;
  }
`;

interface HelpContent {
  img: string;
  title: string;
  desc: string;
}

const stepContent: { [key: string]: HelpContent } = {
  '1_1': {
    img: 'Illustration=1.3 Patient Journey.png',
    title: themes.discover.tools['1.1'].name,
    desc: `Build out the patient's journey, capturing the sequence of events that a patient experiences within a given healthcare system or across providers.
    The aim is to identify the 'I want-to-understand moments', 'I want-to-do moments', 'I need-to-find moments', and 'I need-to-decide moments' where decisions are being made and preferences are being shaped (AKA ‘the moments that matter’). These represent opportunity to improve patient outcomes, improve patient satisfaction, reduce healthcare costs, or inform health policy. Deeply understand each moment: which stakeholders, the decisions that are taken, needs, emotions and problems to solve for.`,
  },
  '1_2': {
    img: 'Illustration=1.1 Key Stakeholder.png',
    title: themes.discover.tools['1.2'].name,
    desc: `The first step in developing a successful strategy is defining the possible key stakeholders and deepening our understanding of them.

  Add ideas for each stakeholder type by switching using the tabs at the top of the page. There are questions to prompt your thinking or you can add your own by creating a new group. The Brand Lead will make a final decision on the stakeholder definition(s) and distill the key relevant observations.`,
  },
  '1_3': {
    img: 'Illustration=1.2 Competitive Landscape.png',
    title: themes.discover.tools['1.3'].name,
    desc: `To evaluate the competition it’s important to consider how well they perform against the outcomes that matter to stakeholders. These were defined in answer to the question ‘What are the outcomes that matter to each stakeholder’ in the first step.
  Add the relevant competitors for each stakeholder and discuss and rate their performance against each outcome. The Lead will review the evaluation to determine where the opportunities for differentiation for your Brand lie.`,
  },
  '1_4': {
    img: 'Illustration=1.4 Key Trends Analysis.png',
    title: themes.discover.tools['1.4'].name,
    desc: `The Emerging Trends / Changes previously identified could include changes in consumer/customer/other stakeholder’s behaviour, emerging technologies, new regulatory requirements, or shifts in cultural attitudes.
    These emerging trends/changes could impact on the long-term success of the Brand.
    The team should consider the emerging future landscape and prioritise the trends or changes they want to keep an eye on. Make assumptions about when these will make an impact, and considering whether they present an opportunity / risk.`,
  },
  '1_5': {
    img: 'Illustration=1.5 Key Insights.png',
    title: themes.discover.tools['1.5'].name,
    desc: `Key insights represent the unmet need(s) at different stages along the patient journey. Insights are expressed in terms of thoughts and feelings.
  To demonstrate the validity of insights, assign observations built up through the process so far. If an insight can’t be supported you may need to go back and add observations that have been missed.`,
  },
  '2_1': {
    img: 'Illustration=2.1 Strategic Question.png',
    title: themes.explore.tools['2.1'].name,
    desc: `Here we align on the statement that defines the ambition of the strategy and what it needs to solve for.
  To establish the strategic question, the team first needs to align on the winning aspiration for the brand and then the strategic problem - anything that is going to get in the way. The strategic problem is then flipped to describe the strategic question. The Lead will suggest how best to express each element and the team can align to show approval.`,
  },
  '2_2': {
    img: 'Illustration=2.2 Patient Flow.png',
    title: themes.explore.tools['2.2'].name,
    desc: `Establishing where patients are lost helps to identify possible opportunities for growth early on.

The team will align around the patient flow, then individual markets can quantify the respective populations. Finally, the whole team aligns around the leverage points - where to focus for growth.`,
  },
  '2_3': {
    img: 'Illustration=2.4 Strategic Possibilities.png',
    title: themes.explore.tools['2.3'].name,
    desc: `A strategic possibility is a description of how we might choose to win at a leverage point by driving stakeholder behaviour. The driver of the behaviour is informed by the insights we have about that stakeholder.
  Suggest strategic possibilities for each leverage point. The Lead will review the level of alignment and enthusiasm for each and decide which to focus on for further consideration.`,
  },
  '2_4': {
    img: 'Illustration=2.5 Drivers & Barriers.png',
    title: themes.explore.tools['2.4'].name,
    desc: `What would have to be true for this strategy to be a success?
    Consider from a stakeholder, competitor and company perspective. Add required truths and rate your confidence. Identify barriers and drivers.`,
  },
  '2_5': {
    img: 'Illustration=2.3 Evidence Generation Ideas.png',
    title: themes.explore.tools['2.5'].name,
    desc: `Establishing where patients are lost helps to identify possible opportunities for growth early on.
    The team will align around how best to design the patient flow, so that the opportunities for growth are revealed. Then individual markets can quantify the respective populations. Finally, the whole team aligns around the leverage points they want to explore for growth.`,
  },
  '3_1': {
    img: 'Illustration=3.2 Brand Positioning.png',
    title: themes.develop.tools['3.1'].name,
    desc: `For a brand to succeed, it needs to occupy a distinctive place in the mind of the key stakeholders. This means considering what space is available, pinning down the key stakeholder motivation and what value it delivers to them.
    Work through the parts of the positioning and add suggestions. The Lead will draft the positioning statement for the team to align on.
    `,
  },
  '3_2': {
    img: 'Illustration=3.3 Core Value Messages.png',
    title: themes.develop.tools['3.2'].name,
    desc: `The Disease Burden and Unmet Need informs the Core Value Proposition, while Clinical, Humanistic and Economic Value Messages form the Value Platform.
    Add suggestions to each part and the Lead will summarise the key points to form the summary Value Proposition.
    `,
  },
  '3_3': {
    img: 'Illustration=3.1 Strategic Imperatives.png',
    title: themes.develop.tools['3.3'].name,
    desc: `The Drivers and Barriers should be used to form the Key Issues that need to be addressed for this strategy to succeed.
  Discuss the Key Issue for each Strategic Imperative. The Lead will make a decision on where to focus, provide the overall rationale for the decisions and set the priority of Key Issues in the summary view.
  `,
  },
  '3_4': {
    img: 'Illustration=3.4 Objectives and Distinctive Capabilities.png',
    title: themes.develop.tools['3.4'].name,
    desc: `Strategic Objectives guide the design and development of tactics. Distinctive Capabilities are required to implement this strategy and distinguish the brand.
  Add suggestions to each part and the Lead will summarise the key points for the team to align around.
  `,
  },
  '3_5': {
    img: 'Illustration=3.5 Lead and Lag Indicators.png',
    title: themes.develop.tools['3.5'].name,
    desc: `The Lead and Lag Indicators are critical performance metrics for the strategy. Lead Indicators are metrics that provide advance notice, while Lag Indicators are informative after the fact.
  Add suggestions for Lead and Lag Indicators that are practical for each strategic imperative. The Lead will summarise the key points for the team to align around.
  `,
  },
  '4_1': {
    img: 'Illustration=4.2 Medical Strategy.png',
    title: themes.activate.tools['4.1'].name,
    desc: `Hurdles (External) and Gaps (Internal) can block the ability to succeed with the strategy [the strategic imperative]. Medical Objectives should be considered to overcome these blockers. The Tactics to achieve these Medical Objectives form the Medical Strategy plan.
  The Lead should guide the team and review contributions to finalise Hurdles and Gaps, Medical Objectives, Tactics and budget.
  `,
  },
  '4_2': {
    img: 'Illustration=4.1 Access Strategy.png',
    title: themes.activate.tools['4.2'].name,
    desc: `Supporting Messages need to be supported with evidence. Where there are gaps, evidence needs to be generated using the PICO framework. Tactics to support the delivery of the Supporting Messages form the Access Strategy plan.
  Markets will be assigned Payor Archetypes to align around the priority supporting messages and the Lead will choose which evidence gaps to focus on. The team can then align around evidence generation and tactics.
  `,
  },
  '4_3': {
    img: 'Illustration=4.3 Commercial Strategy.png',
    title: themes.activate.tools['4.3'].name,
    desc: `Big Ideas are your opportunity to share game-changing initiatives. Commercial Tactics are ways to achieve strategic objectives. The most significant Big Ideas and Tactics form the Commercial Strategy plan.
  The team should suggest Big Ideas and provide as much detail as possible to help the Lead decide where to focus. When the team aligns around the priorities and the budget, the timings of the plan can be decided.
  `,
  },
  '4_4': {
    img: 'Illustration=4.4 Risk Mitigation.png',
    title: themes.activate.tools['4.4'].name,
    desc: `The Barriers previously identified might give rise to key risks to be considered. Solutions to mitigate these risks can help the strategy to succeed.
  The team should suggest solutions and the Lead will decide which ones to focus on and form the Risk Mitigation plan.
  `,
  },
  '4_5': {
    img: 'Illustration=4.5 Long-term Strategy.png',
    title: themes.activate.tools['4.5'].name,
    desc: `The Future Trends / Changes previously identified may have an impact on the the long term success of the Brand. Planning for when these will make an impact, considering whether they present an opportunity / risk and developing solutions will help the Long-term Strategy to succeed.
  The team should consider the future landscape and prioritise solutions to adapt to it. These solutions can be visualised in the timeframe of key dates and the classic market adoption plot.`,
  },
};

export const HelpModal: FC = () => {
  const { location } = useHistory();
  const [updateUser] = useUserSafeUpdateMutation();
  const [{ user, refetch: refetchUser }] = useAuthContext();

  const step = location.pathname.split('/')[5];
  const stepAlreadyDismissed = (user?.helpDismissed || []).includes(step);

  const content = stepContent[(step as string) || ''];

  const [visible, setHelp] = useHelpContext();

  // Keyboard shortcuts
  const keydownListener = useCallback(
    (keydownEvent) => {
      if (!visible) return;
      const { key } = keydownEvent;

      if (key === 'Escape') setHelp(null);
    },
    [visible, setHelp]
  );

  // Bind keyboard shortuts
  useEffect(() => {
    window.addEventListener('keydown', keydownListener, true);
    return () => window.removeEventListener('keydown', keydownListener, true);
  }, [keydownListener]);

  if (!visible || !content) return null;

  async function closeModal() {
    if (!stepAlreadyDismissed) {
      if (!user?.id) return;

      await updateUser({
        variables: {
          data: {
            helpDismissed: [...(user?.helpDismissed || []), step],
          },
        },
      });

      refetchUser && (await refetchUser());
    }
    setHelp(false);
  }

  return (
    <Background
      onClick={(e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
          closeModal();
        }
      }}
    >
      <Content>
        <Image>
          <img src={`/help/${content.img}`} alt={content.title} />
        </Image>

        <ContentText>
          <Heading>{content.title}</Heading>

          <Desc>
            {content.desc.split('\n').map((c, idx) => (
              <BodyNormal key={idx} style={{ marginBottom: 20 }}>
                {c}
              </BodyNormal>
            ))}

            <ContentButton text="Got it" onClick={closeModal} />
          </Desc>
        </ContentText>
      </Content>
    </Background>
  );
};
